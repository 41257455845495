import { FileNode, FileNodeTag, FileNodeType } from '../types/DataDelivery';

export function getPathWithoutOrganization(filenode: FileNode): string {
  if (!filenode.path) {
    return '';
  }
  // Assume filenode.path will be something like: 3c97cab1-0e56-482e-b588-167f19245dcf\folder1\folder2\file.tif
  const pathParts = filenode.path.split('\\');
  //if (pathParts.length > 1) {
    pathParts.shift();
  //}
  return pathParts.join('\\');
}


export function findFileNodeRecursively(root: FileNode, id: string): FileNode {
  if (root.id === id) {
    return root;
  } else if (root.children && root.children.length > 0) {
    let i = 0;
    let fileNodeToUpdate = null;
    for (i = 0; i < root.children.length; i++) {
      fileNodeToUpdate = findFileNodeRecursively(root.children[i], id);
      if (fileNodeToUpdate) break;
    }
    return fileNodeToUpdate;
  } else {
    return null;
  }
}

export function mergeFileNodes(node1: FileNode, node2: FileNode): FileNode {
  // If both nodes don't have the same ID, we should not merge them.
  if (node1.id !== node2.id) {
    return node2;
  }

  const mergedNode: FileNode = JSON.parse(JSON.stringify(node2));
  mergedNode.color = node1.color;
  mergedNode.children = [];

  const childrenMap = new Map<string, FileNode>();

  // First, populate the map with children from node1.
  if (node1.children && node1.children.length > 0) {
    for (const child of node1.children) {
      childrenMap.set(child.id, child);
    }
  }

  // Then, update or add the children from node2.
  if (node2.children && node2.children.length > 0) {
    for (const child2 of node2.children) {
      if (childrenMap.has(child2.id)) {
        // Merge the children with matching IDs.
        const child1 = childrenMap.get(child2.id)!;
        childrenMap.set(child2.id, mergeFileNodes(child1, child2));
      } else {
        childrenMap.set(child2.id, child2);
      }
    }
  }
  const newChildren = Array.from(childrenMap.values());
  if (!node2.children || node2.children.length === 0) {
    childrenMap.clear();
  } else {
    for (const mergedChild of newChildren) {
      if (node2.children.filter((node2Child) => node2Child.id === mergedChild.id).length === 0) {
        childrenMap.delete(mergedChild.id);
      }
    }
  }
  mergedNode.children = Array.from(childrenMap.values());

  return mergedNode;
}

export function getFileNodeTag(fileNode: FileNode): FileNodeTag {
  const tag: FileNodeTag = {
    background: 'gray',
    text: '\u200b',
  };
  if (fileNode.fileType === FileNodeType.RASTER) {
    tag.background = 'rgb(110,176,253)';
    tag.text = 'GTIF';
  } else if (fileNode.fileType === FileNodeType.POINT_CLOUD) {
    tag.background = 'rgb(253,198,110)';
    tag.text = 'LAZ';
  } else if (fileNode.fileType === FileNodeType.VECTOR) {
    tag.background = 'rgb(221,116,248)';
    tag.text = 'Vector';
  } else if (fileNode.fileType === FileNodeType.SHAPEFILE) {
    tag.background = 'rgb(237, 183, 237)';
    tag.text = 'SHP';
  } else if (fileNode.fileType === FileNodeType.TEXT) {
    tag.background = 'rgb(103, 101, 139)';
    tag.text = 'Text';
  } else if (fileNode.fileType === FileNodeType.GEOPACKAGE) {
    tag.background = 'rgb(56, 72, 154)';
    tag.text = 'GPKG';
  } else if (fileNode.fileType === FileNodeType.GSRAS) {
    tag.background = 'rgb(125, 125, 115)';
    tag.text = 'Raster';
  } else if (fileNode.fileType === FileNodeType.GSVEC) {
    tag.background = 'rgb(40, 189, 233)';
    tag.text = 'Vector';
  } else if (fileNode.fileType === FileNodeType.PDF) {
    tag.background = 'rgb(180, 11, 0)';
    tag.text = 'PDF';
  } else if (fileNode.fileType === FileNodeType.IMAGE) {
    tag.background = 'rgb(255, 105, 180)';
    tag.text = 'Image';
  } else {
    return null;
  }
  return tag;
}

export function colorizeFileNode(fileNode: FileNode) {
  if (!fileNode.color) {
    fileNode.color = '#000000'.replace(/0/g, function () {
      return (~~(Math.random() * 16)).toString(16);
    });
  }
  if (fileNode.children && fileNode.children.length > 0) {
    fileNode.children.forEach((childFileNode: FileNode) => {
      colorizeFileNode(childFileNode);
    });
  }
};
