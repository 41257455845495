import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography } from '@progress/kendo-react-common';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import SummaryOverview from './SummaryOverview';
import SummaryDeliverables from './SummaryDeliverables';
import SummaryProcesses from './SummaryProcesses';
import { SapFlowFileDetails, SapFlowTransaction } from '../../types';

const StyledButton = styled(Button)`
  font-size: 0.85rem;
  font-family: var(--bs-body-font-family);
  color: var(--geosap-body-text);
  flex-grow: 1;

  &.k-group-start {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  &.k-group-end {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &.selected {
    background-color: var(--geosap-sapflow-details-selected-button-bg);
  }
`;

type SummaryTab = 'overview' | 'deliverables' | 'processes';

interface Props {
  sapFlowInfos: SapFlowTransaction;
  fileDetails: SapFlowFileDetails;
}

const Summary: React.FC<Props> = ({ sapFlowInfos, fileDetails }) => {
  const [selectedTab, setSelectedTab] = useState<SummaryTab>('overview');

  const switchTab = (tab: SummaryTab) => {
    setSelectedTab(tab);
  };

  const isSelected = (tab: SummaryTab): string => (selectedTab === tab ? 'selected' : '');

  return (
    <div style={{ border: '1px solid var(--geosap-k-button-md)' }} className="p-4 rounded-3 bg-white">
      <Typography.h5 textAlign="center" textTransform="uppercase" className="pb-3">
        {sapFlowInfos.sapflowname}
      </Typography.h5>
      <ButtonGroup className="d-flex pb-5">
        <StyledButton className={`py-1 ${isSelected('overview')}`} onClick={() => switchTab('overview')}>
          Overview
        </StyledButton>
        <StyledButton className={`py-1 ${isSelected('deliverables')}`} onClick={() => switchTab('deliverables')}>
          Deliverables
        </StyledButton>
        <StyledButton className={`py-1 ${isSelected('processes')}`} onClick={() => switchTab('processes')}>
          Processes
        </StyledButton>
      </ButtonGroup>
      {
        {
          overview: <SummaryOverview sapFlowInfos={sapFlowInfos} fileDetails={fileDetails} />,
          deliverables: <SummaryDeliverables {...sapFlowInfos} />,
          processes: <SummaryProcesses {...sapFlowInfos} />,
        }[selectedTab]
      }
    </div>
  );
};

export default Summary;
