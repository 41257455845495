import { AddLayerConfig } from '../../types';
import { AddLayerWizardState, initialState, AddLayerWizardStep } from './addLayerWizardState';

export interface ChangeViewerAction {
  type: 'CHANGED_VIEWER';
  payload: {
    viewerConfigId: string
  };
}

export interface SelectTypeAction {
  type: 'SELECT_TYPE';
  payload: {
    layerConfig: AddLayerConfig;
    nextStep: AddLayerWizardStep;
  };
}

export interface SubmitUrlAction {
  type: 'SUBMIT_URL';
  payload: {
    url: string;
    nextStep: AddLayerWizardStep;
  };
}

export interface SelectWmsLayerAction {
  type: 'SELECT_WMS_LAYER';
  payload: {
    //projectName: string;
  };
}

export interface SelectWfsLayerAction {
  type: 'SELECT_WFS_LAYER';
  payload: {
    //category: Category;
  };
}

export interface DoneAction {
  type: 'DONE';
  payload: {
    //step: AddLayerWizardStep;
  };
}

export interface GoBackAction {
  type: 'GO_BACK';
  payload: {
    //step: AddLayerWizardStep;
  };
}

export type AddLayerWizardAction =
  | ChangeViewerAction
  | SelectTypeAction
  | SubmitUrlAction
  | SelectWmsLayerAction
  | SelectWfsLayerAction
  | DoneAction
  | GoBackAction;

export const addLayerWizardReducer = (
  currentState: AddLayerWizardState,
  action: AddLayerWizardAction
): AddLayerWizardState => {
  switch (action.type) {
    case 'CHANGED_VIEWER':
      return {
        ...currentState,
        viewerConfigId: action.payload.viewerConfigId,
      };
      case 'SELECT_TYPE':
        return {
          ...currentState,
          layerConfig: action.payload.layerConfig,
          step: action.payload.nextStep,
        };
    case 'SUBMIT_URL': {
      const baseUrl: string = action.payload.url;
      //let owsUrl: string = action.payload.url;
      return {
        ...currentState,
        url: baseUrl,
        //owsUrl: owsUrl,
        step: action.payload.nextStep
      };
    }
    case 'SELECT_WMS_LAYER': {
      return {
        ...currentState,
      };
    }
    case 'SELECT_WFS_LAYER': {
      return {
        ...currentState,
      };
    }
    case 'DONE': {
      return {
        ...initialState,
        viewerConfigId: currentState.viewerConfigId,
        done: true
      };
    }
    case 'GO_BACK': {
      let previousStep = AddLayerWizardStep.UNSUPPORTED;
      if(currentState.step === AddLayerWizardStep.SELECT_WMS_LAYER || currentState.step === AddLayerWizardStep.SELECT_WFS_LAYER) {
        previousStep = AddLayerWizardStep.SUBMIT_URL;
      }
      else/* if(currentState.step === AddLayerWizardStep.SUBMIT_URL || currentState.step === AddLayerWizardStep.SELECT_GEOTIF) */{
        previousStep = AddLayerWizardStep.SELECT_TYPE;
      }
      return {
        ...currentState,
        step: previousStep
      };
    }
  }
};
