import { useMutation, useQueryClient } from 'react-query';
import { LOCAL_STORAGE_CURRENT_ORG } from '../../common/constants';
import { Organization } from '../../types';
import { useAppContext } from '../../context/app';
import useUser from './useUser';

const useSelectOrganization = () => {
  const { dispatch } = useAppContext();
  const { getCurrentOrganization } = useUser();
  const queryClient = useQueryClient();

  return useMutation((selectedOrganization: Organization) => {
    return new Promise<void>((resolve) => {
      // Build the userAuthority mapping
      if(getCurrentOrganization() && (!selectedOrganization || getCurrentOrganization().id !== selectedOrganization.id)) {
        // Clear cached
        queryClient.removeQueries(['rootStructure', getCurrentOrganization().id]);
      }
      dispatch({ type: 'SELECT_ORGANIZATION', payload: { organization: selectedOrganization } });
      resolve();
    });
  });
};

export default useSelectOrganization;
