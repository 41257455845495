import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Avatar } from '@progress/kendo-react-layout';
import { Typography } from '@progress/kendo-react-common';
import { RegisterWizardProvider, useCreateRegisterWizardState } from '../../context/registerWizard';
import { useUser } from '../../hooks/authentication';
import {
  SettingsPaths,
  ACCOUNT_OVERVIEW,
  ACCOUNT_USER,
  ACCOUNT_GEOSAP,
  ACCOUNT_NOTIFICATIONS,
  ACCOUNT_PLANS,
  ACCOUNT_ORGANIZATION,
} from './';
import { Icon } from '../../components/layout';
import { AuthorityLevel, AuthorityType } from '../../types';

const AccountSettingsContainer: React.FC = () => {
  const wizardState = useCreateRegisterWizardState();
  const location = useLocation();
  const { getUser, userHasAuthority } = useUser();
  const navigate = useNavigate();

  return (
    <RegisterWizardProvider value={wizardState}>
      <div className="d-flex flex-column h-10 p-3">
        <div className="container col-12 col-lg-10">
          <div className="p-3 flex-column d-flex align-items-center">
            <Avatar rounded="full" size="large" themeColor="secondary">
              <Icon iconClass="k-i-user" size="52px" color="var(--geosap-profile-icon-color)"></Icon>
            </Avatar>
            <Typography.p fontSize="xsmall" className="mb-2">
              {getUser().email}
            </Typography.p>
            <div className="w-100 d-flex align-items-center justify-content-between">
              {Object.keys(SettingsPaths).map(
                (
                  settingKey:
                    | typeof ACCOUNT_OVERVIEW
                    | typeof ACCOUNT_USER
                    | typeof ACCOUNT_GEOSAP
                    | typeof ACCOUNT_ORGANIZATION
                    | typeof ACCOUNT_NOTIFICATIONS
                    | typeof ACCOUNT_PLANS
                ) => {
                  if (
                    settingKey === ACCOUNT_ORGANIZATION &&
                    !userHasAuthority(AuthorityType.ORG_AUTHORITY, AuthorityLevel.READ)
                  )
                    return null;
                  if (settingKey === ACCOUNT_GEOSAP) return null;
                  if (settingKey === ACCOUNT_PLANS) return null;
                  if (settingKey === ACCOUNT_NOTIFICATIONS) return null;
                  const active = SettingsPaths[settingKey] === location.pathname;
                  return (
                    <span
                      key={settingKey}
                      onClick={() => {
                        navigate(SettingsPaths[settingKey]);
                      }}
                      style={{
                        cursor: 'pointer',
                        color: active ? 'var(--geosap-primary-color)' : 'var(--geosap-secondary-color)',
                        fontWeight: active ? 'bold' : 'normal',
                        fontSize: active ? '1.1rem' : '1rem',
                      }}
                    >
                      {settingKey}
                    </span>
                  );
                }
              )}
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <Outlet />
          </div>
        </div>
      </div>
    </RegisterWizardProvider>
  );
};

export default AccountSettingsContainer;
