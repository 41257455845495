import { useMutation } from 'react-query';
import { SigninResponse, User } from '../../types';
import { useAppContext } from '../../context/app';
import { useAxiosInstance } from '../common';
import { LOCAL_STORAGE_USER, LOCAL_STORAGE_CURRENT_ORG, LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_TOKEN_TYPE } from '../../common/constants';

const endpoint = '/authentication/signup_confirmation_callback';

interface Params {
    confirmation_token: string,
}

const useConfirmEmail = () => {
  const { dispatch } = useAppContext();
  const axios = useAxiosInstance();

  return useMutation(async (activationParams: Params) => { 
    const response = await axios.post<SigninResponse>(endpoint, {}, {params: activationParams});
    if (response) {
      if (response.data && response.data.accessToken) {
        const user: User = {id: response.data.id, email: response.data.email, firstName: response.data.firstName, lastName: response.data.lastName, organizations: response.data.organizations};
        localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, response.data.accessToken);
        localStorage.setItem(LOCAL_STORAGE_TOKEN_TYPE, response.data.tokenType);
        let selectedOrganization = null;
        if (response.data.organizations && response.data.organizations?.length === 1) {
          selectedOrganization = response.data.organizations[0];
        }
        if (!response.data.organizations || response.data.organizations?.length === 0) {
          selectedOrganization = null;
        }

        if(selectedOrganization) {
          localStorage.setItem(LOCAL_STORAGE_CURRENT_ORG, JSON.stringify(selectedOrganization));
        }

        dispatch({ type: 'LOGIN', payload: { user: user, accessToken: response.data.accessToken, tokenType: response.data.tokenType } });
        dispatch({ type: 'SELECT_ORGANIZATION', payload: { organization: selectedOrganization } });
      }
    }
  });
};

export default useConfirmEmail;