import { SapFlowFileDetails } from '../../../types';
import { useQuery } from 'react-query';
import { useAxiosInstance } from '../../common/';

const useTransactionFileDetails = () => {

  const getFileDetails = (transId: string) => {
    const axios = useAxiosInstance();
    return useQuery(
      ['sapflowFileDetails', transId],
      async () => {
        const result = await axios.get<SapFlowFileDetails>("/sapflow/" + transId + "/filedetails");
        return result.data;
      },
      { retry: false }
    );
  };

  return { getFileDetails };
};

export default useTransactionFileDetails;
