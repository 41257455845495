import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Typography } from '@progress/kendo-react-common';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Popup } from '@progress/kendo-react-popup';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { SvgIcon } from '@progress/kendo-react-common';
import { xIcon, clipboardIcon, trackChangesEnableIcon } from '@progress/kendo-svg-icons';
import { FileNode, FileNodeReviewStatus } from '../../../types/DataDelivery';
import { SubmitButton } from '../../../components/form';
import { useUser } from '../../../hooks/authentication';
import { Loader } from '@progress/kendo-react-indicators';
import relativeTimeSince from '../../../converters/relativeDateTime';
import { fileNodeReviewStatusToText } from '../../../converters/enumTranslator';
import { Annotation, AnnotationType } from '../../../types/DataDelivery/Annotation';
import { useAddAnnotation, useFileNodeAnnotations } from '../../../hooks/data_delivery';
import { Button } from '@progress/kendo-react-buttons';

const AnnotationContainer = styled.div`
  background: white;
  border-radius: 8px;
  overflow: hidden;
  max-height: 80%;
`;

const AnnotationInput = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--geosap-steps-background);
  padding: 4px;
  flex: 1;
`;

interface NavigationSidebarProps {
  selectedFileNode: FileNode;
  onBlurAnnotation(): void;
}

const AnnotationSidebar: React.FC<NavigationSidebarProps> = (props: NavigationSidebarProps) => {
  const [fileNode, setFileNode] = useState<FileNode>(null);
  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const [commentText, setCommentText] = useState<string>('');
  const [selectedReviewStatus, setSelectedReviewStatus] = useState<FileNodeReviewStatus>(FileNodeReviewStatus.IDLE);
  const [hoveringReview, setHoveringReview] = useState<boolean>(false);
  const [reviewMenuOffset, setReviewMenuOffset] = useState<{ left: number; top: number }>({ left: 0, top: 0 });
  const [hovering, setHovering] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const messagesEndRef = useRef(null);
  const reviewButtonRef = useRef(null);
  const { getUser } = useUser();
  const fileNodeAnnotationsQuery = useFileNodeAnnotations(props.selectedFileNode?.id);
  const addAnnotationMutation = useAddAnnotation(props.selectedFileNode?.id);

  let fadeTimer: NodeJS.Timeout = null;

  useEffect(() => {
    if (props.selectedFileNode) {
      if (fadeTimer) clearTimeout(fadeTimer);
      if (!fileNode) {
        // Opening a new node
        setFileNode(props.selectedFileNode);
        setSelectedReviewStatus(props.selectedFileNode.reviewStatus);
      } else {
        setFileNode(props.selectedFileNode);
      }
      setShow(true);
    } else {
      // Fading out
      setShow(false);
    }
  }, [props.selectedFileNode]);

  useEffect(() => {
    if (fileNodeAnnotationsQuery.isSuccess) {
      setAnnotations(fileNodeAnnotationsQuery.data);
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [
    fileNode,
    fileNodeAnnotationsQuery.isSuccess,
    fileNodeAnnotationsQuery.isRefetching,
    fileNodeAnnotationsQuery.isFetching,
    fileNodeAnnotationsQuery.data,
  ]);

  useEffect(() => {
    if (!show) {
      fadeTimer = setTimeout(() => {
        setFileNode(null);
      }, 200);
    }
  }, [show]);

  useEffect(() => {
    if (reviewButtonRef.current) {
      const rect = reviewButtonRef.current.getBoundingClientRect();
      setReviewMenuOffset({
        left: rect.right,
        top: rect.bottom,
      });
    }
  }, [reviewButtonRef.current]);

  const handleInputTextChanged = (text: string) => {
    setCommentText(text);
  };

  const handleSendAnnotation = () => {
    addAnnotationMutation
      .mutateAsync({
        type: selectedReviewStatus === fileNode.reviewStatus ? AnnotationType.COMMENT : AnnotationType.REVIEW_STATUS,
        message: commentText,
        reviewStatus: selectedReviewStatus,
      })
      .then(() => {
        setCommentText('');
      });
  };

  const renderAnnotation = (annotation: Annotation, index: number) => {
    const userSender = annotation.user?.id === getUser().id;
    if (annotation.type === AnnotationType.COMMENT) {
      return (
        <div key={index} className="d-flex flex-column my-2">
          <div className="d-flex">
            <span>{annotation.message}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span style={{ fontSize: '0.8rem', color: 'rgb(200,200,200)' }}>{annotation.user.firstName}</span>
            <span style={{ fontSize: '0.8rem', color: 'rgb(200,200,200)' }}>
              {relativeTimeSince(annotation.createdDate)}
            </span>
          </div>
        </div>
      );
    } else if (annotation.type === AnnotationType.REVIEW_STATUS) {
      return (
        <div key={index} className="d-flex  flex-column my-4">
          <div className="d-flex">
            {`${userSender ? 'You' : annotation.user.firstName} marked this item as`} &nbsp;
            <span style={{ fontStyle: 'italic' }}>{`${fileNodeReviewStatusToText(annotation.reviewStatus)}.`}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span style={{ fontSize: '0.8rem', color: 'rgb(200,200,200)', flex: '1' }}>{annotation.message}</span>
            <span style={{ fontSize: '0.8rem', color: 'rgb(200,200,200)' }}>
              {relativeTimeSince(annotation.createdDate)}
            </span>
          </div>
        </div>
      );
    }
  };

  const renderReviewMenu = () => {
    const selectedStyle = { backgroundColor: 'var(--geosap-primary-color)', color: 'white' };
    return (
      <Popup show={hoveringReview} offset={reviewMenuOffset} popupClass={'popup-content'}>
        <Menu
          vertical={true}
          style={{ display: 'inline-block' }}
          onSelect={({ item, itemId, nativeEvent, syntheticEvent, target }) => {
            if (itemId === '0') {
              setSelectedReviewStatus(FileNodeReviewStatus.IDLE);
            }
            if (itemId === '1') {
              setSelectedReviewStatus(FileNodeReviewStatus.MARKED_FOR_REVIEW);
            }
            if (itemId === '2') {
              setSelectedReviewStatus(FileNodeReviewStatus.REVIEWED);
            }
            syntheticEvent.stopPropagation();
            setHoveringReview(false);
          }}
        >
          <MenuItem
            key={0}
            cssStyle={selectedReviewStatus === FileNodeReviewStatus.IDLE ? selectedStyle : null}
            text="IDLE"
            disabled={
              props.selectedFileNode?.reviewStatus === FileNodeReviewStatus.IDLE &&
              props.selectedFileNode?.reviewStatus === selectedReviewStatus
            }
            render={() => <span>Idle</span>}
          />
          <MenuItem
            key={1}
            cssStyle={selectedReviewStatus === FileNodeReviewStatus.MARKED_FOR_REVIEW ? selectedStyle : null}
            text="REVIEW"
            disabled={
              props.selectedFileNode?.reviewStatus === FileNodeReviewStatus.MARKED_FOR_REVIEW &&
              props.selectedFileNode?.reviewStatus === selectedReviewStatus
            }
            render={() => <span>Mark for Review</span>}
          />
          <MenuItem
            key={2}
            cssStyle={selectedReviewStatus === FileNodeReviewStatus.REVIEWED ? selectedStyle : null}
            text="REVIEWED"
            disabled={
              props.selectedFileNode?.reviewStatus === FileNodeReviewStatus.REVIEWED &&
              props.selectedFileNode?.reviewStatus === selectedReviewStatus
            }
            render={() => <span>Mark has Reviewed</span>}
          />
        </Menu>
      </Popup>
    );
  };

  if (!fileNode) return null;

  return (
    <AnnotationContainer
      className={`d-flex flex-column position-absolute animated-general ${
        show ? (hovering ? 'opacity-100' : 'opacity-75') : 'opacity-0'
      }`}
      style={{
        right: 20,
        top: 40,
        bottom: 20,
        zIndex: 1,
        width: expanded ? 'var(--geosap-datadelivery-annotations-width)' : '40px',
        height: expanded ? '600px' : '40px',
        justifyContent: expanded ? 'normal' : 'center',
        alignItems: expanded ? 'normal' : 'center',
        border: '1px solid var(--geosap-selected)',
      }}
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      {!expanded && (
        <SvgIcon
          icon={trackChangesEnableIcon}
          style={{
            color: 'black',
            fontSize: '1.2rem',
            cursor: 'pointer',
          }}
          onClick={() => {
            setExpanded(true);
          }}
        />
      )}
      {expanded && (
        <div className="d-flex flex-column position-relative w-100 h-100">
          <SvgIcon
            icon={xIcon}
            style={{
              color: 'white',
              position: 'absolute',
              top: '0.25rem',
              right: '0.25rem',
              fontSize: '1.2rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              setExpanded(false);
              //setHovering(false);
              //props.onBlurAnnotation();
            }}
          />
          <div
            className="d-flex flex-column"
            style={{ color: 'rgb(190,190,190)', background: 'rgb(116,116,116)', padding: '1rem 2rem' }}
          >
            <Typography.h4
              style={{
                color: 'rgb(190,190,190)',
                padding: '24px 0px',
                display: 'flex',
              }}
            >
              <div style={{ display: 'flex' }}>{fileNode?.name}</div>
            </Typography.h4>
            <Typography.h4
              style={{
                color: 'rgb(190,190,190)',
                padding: '24px 0px',
                display: 'flex',
              }}
            >
              <div style={{ display: 'flex' }}>{fileNodeReviewStatusToText(fileNode?.reviewStatus)}</div>
            </Typography.h4>
          </div>
          <div className="d-flex flex-column flex-1 m-2 overflow-auto">
            {fileNodeAnnotationsQuery.isLoading && <Loader></Loader>}

            {fileNodeAnnotationsQuery.isSuccess && annotations.length === 0 && (
              <Typography.h5
                fontSize="small"
                style={{
                  color: 'rgb(190,190,190)',
                  padding: '24px 0px',
                  display: 'flex',
                }}
              >
                Add a comment to this item.
              </Typography.h5>
            )}
            {fileNodeAnnotationsQuery.isSuccess &&
              annotations.length > 0 &&
              annotations.map((annotation, index) => renderAnnotation(annotation, index))}

            <div ref={messagesEndRef}></div>
          </div>
          <div className="d-flex flex-column ms-4 me-4 mb-4 mt-1">
            <div className="d-flex justify-content-center align-items-center">
              <AnnotationInput className="my-2">
                <input
                  type={'text'}
                  name={''}
                  placeholder={'Add a comment to this node.'}
                  className="searchbox-input"
                  style={{ width: '100%', color: 'rgb(190,190,190)', background: 'transparent', border: 'none' }}
                  onChange={(e) => {
                    handleInputTextChanged(e.target.value);
                  }}
                  value={commentText}
                />
              </AnnotationInput>
              <div
                onMouseEnter={() => {
                  setHoveringReview(true);
                }}
                onMouseLeave={() => {
                  setHoveringReview(false);
                }}
              >
                <Button
                  ref={(button) => {
                    reviewButtonRef.current = button?.element;
                  }}
                  svgIcon={clipboardIcon}
                  themeColor={selectedReviewStatus === fileNode?.reviewStatus ? 'base' : 'primary'}
                  style={{ height: 'fit-content', padding: '0.5rem 0.5rem', marginLeft: '0.5rem' }}
                ></Button>
                {renderReviewMenu()}
              </div>
            </div>
            <SubmitButton
              label="Add Annotation"
              disabled={!(commentText || selectedReviewStatus !== fileNode.reviewStatus)}
              loading={addAnnotationMutation.isLoading}
              onClick={handleSendAnnotation}
            />
          </div>
        </div>
      )}
    </AnnotationContainer>
  );
};

export default AnnotationSidebar;
