import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Feature, MapBrowserEvent } from 'ol';
import OLVectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON.js';
import MapContext from '../Map/MapContext';
import { useConsumeViewerState } from '../../../../context/viewer';
import MeasurementTool from './MeasurementTool';
import {
  Layer2DClickResult,
  LayerType,
  Measurement2D,
  NavigationMode2D,
  SapFlowViewConfig,
  ViewLayer,
  ViewLayerParam,
} from '../../../../types';
import { Geometry } from 'ol/geom';
import { useViewLayerCreate } from '../../../../hooks/viewerconfig';
import MapContextData from '../Map/MapContextData';
import VectorSource from 'ol/source/Vector';

interface Props {
  show: boolean;
  zIndex: number;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
  handleMapClick: (evt: MapBrowserEvent<any>) => Promise<Layer2DClickResult>;
} | null;
const VCMeasurementTool = forwardRef<Ref, Props>((props: Props, ref) => {
  const { map } = useContext<MapContextData>(MapContext);
  const { dispatch, viewConfig, layers, mode2d, selectedLayer } = useConsumeViewerState();
  const [measurementFeatures, setMeasurementFeatures] = useState<Feature<Geometry>[]>([]);
  const viewLayerCreateMutation = useViewLayerCreate();
  const layersRef = useRef(layers);
  const measurementToolRef = useRef(null);

  useEffect(() => {
    if (!map) return;
    layersRef.current = layers;

    const measurementLayers = layers.filter((layer) => layer.layerType === LayerType.Measurement2D && layer.active);
    let features: Feature<Geometry>[] = [];
    const writer = new GeoJSON();
    features = measurementLayers.map((measurementLayer: ViewLayer) => {
      if (!measurementLayer.paramsMap['geojson']) {
        // TODO
        //measurementLayer.viewState = ViewLayerState.ERRORED
      }
      const feature = writer.readFeature(measurementLayer.paramsMap['geojson']);
      feature.setProperties({
        id: measurementLayer.id,
        name: measurementLayer.displayName,
        selected: selectedLayer?.id === measurementLayer.id,
      });
      return feature;
    });
    setMeasurementFeatures(features);
  }, [map, layers, selectedLayer]);

  const handleNewMeasurement = (feature: Feature) => {
    const writer = new GeoJSON();
    const geoJsonStr = writer.writeFeature(feature);
    const measurementLayers = layersRef.current.filter(
      (layer) =>
        layer.layerType === LayerType.Measurement2D &&
        layer.paramsMap['geometryType'] === feature.getGeometry().getType()
    );

    let displayName = 'Measurement ' + measurementLayers.length + 1;
    if (feature.getGeometry().getType() === 'LineString') {
      displayName = 'Distance ' + (measurementLayers.length + 1);
    } else if (feature.getGeometry().getType() === 'Polygon') {
      displayName = 'Area ' + (measurementLayers.length + 1);
    }
    const params: ViewLayerParam[] = [
      {
        key: 'geojson',
        value: geoJsonStr,
      },
      {
        key: 'geometryType',
        value: feature.getGeometry().getType(),
      },
    ];
    viewLayerCreateMutation
      .mutateAsync({
        viewConfigId: viewConfig.id,
        params: {
          displayName: displayName,
          uri: null,
          layerType: LayerType.Measurement2D,
          active: true,
          params: params,
        },
      })
      .then((layer) => {
        dispatch({ type: 'SELECT_LAYER', payload: { layer } });
      });
  };

  const zoomToLayer = (layer: any) => {
    //setZoomWhenReady(true);
    const matchingFeatures = measurementFeatures.filter((feature: Feature<Geometry>) => {
      const featureProperties = feature.getProperties();
      return featureProperties['id'] && featureProperties['id'] === layer.id;
    });
    if (matchingFeatures.length === 0) {
      return;
    }
    zoomToFeature(matchingFeatures[0]);
  };

  function zoomToFeature(feature: Feature) {
    // Get the geometry of the feature
    const geometry = feature.getGeometry();

    if (geometry) {
      // Get the extent of the feature's geometry
      const extent = geometry.getExtent();

      // Fit the map view to the extent of the feature
      map.getView().fit(extent, {
        padding: [50, 50, 50, 50], // Optional padding around the extent
        duration: 1000, // Optional animation duration in milliseconds
      });
    }
  }

  const handleMapClick = async (evt: MapBrowserEvent<any>): Promise<Layer2DClickResult> => {
    const olLayer: OLVectorLayer<VectorSource<Feature<Geometry>>> = measurementToolRef.current.getOlLayer();
    const features = await olLayer.getFeatures(evt.pixel);
    const feature = features.length ? features[0] : undefined;
    if (feature) {
      const featureProperties = feature.getProperties();
      const layerId = featureProperties['id'];
      const matchingLayers = layers.filter((layer) => layer.id === layerId);
      if (matchingLayers && matchingLayers.length) {
        return { layer: matchingLayers[0] };
      }
    }
    return null;
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
    handleMapClick,
  }));

  return (
    <MeasurementTool
      {...props}
      ref={(el: any) => {
        measurementToolRef.current = el;
      }}
      active={mode2d === NavigationMode2D.LINE_MEASUREMENT || mode2d === NavigationMode2D.POLYGON_MEASUREMENT}
      measurements={measurementFeatures}
      onMeasurementAdded={handleNewMeasurement}
    ></MeasurementTool>
  );
});
VCMeasurementTool.displayName = 'VCMeasurementTool';

export default VCMeasurementTool;
