import FileNodeReviewStatus from './FileNodeReviewStatus';

export enum AnnotationType {
  COMMENT = 'COMMENT',
  REACTION = 'REACTION',
  REVIEW_STATUS = 'REVIEW_STATUS',
}

export interface Annotation {
  id: string;
  user: { id: string; firstName: string; lastName: string; url: string };
  filenode: string;
  type: AnnotationType;
  reviewStatus: FileNodeReviewStatus;
  message?: string;
  createdDate: string;
}

export interface AnnotationAddedDTO {
  annotationId: string;
  fileNodeId: string;
  rootFileNodeId: string;
  userId: string;
  userFirstName: string;
  userLastName: string;
  type: AnnotationType;
  reviewStatus: FileNodeReviewStatus;
  message?: string;
  createdDate: string;
}
