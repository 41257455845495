import { useQueryClient, useMutation } from 'react-query';
import { useAxiosInstance } from '../common';
import { useAppContext } from '../../context/app';

interface DownloadParams {
    fileNodeId: string,
    fileNodeName: string,
}

const useDownloadFileNode = () => {
  const axios = useAxiosInstance();
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: DownloadParams) => {
      const response = await axios.get(`${process.env.REACT_APP_GEOSAP_SERVERURL}/filenode/${params.fileNodeId}/download`);
      return response.data;
    },
    {
      onSuccess: (data, variables) => {
        const { requestId, downloadUrl, downloadFileName } = data;
        dispatch({
          type: 'CREATE_ZIP_NOTIFICATION',
          payload: {
            zipNotification: {
              totalBytes: 1,
              currentBytes: 0,
              fileTotalBytes: 0,
              fileCurrentBytes: 0,
              timeStamp: '',
              requestId: requestId,
              downloadUrl: downloadUrl,
              downloading: false,
              downloadFileName: downloadFileName,
            },
          },
        });
      }
    }
  );
};

export default useDownloadFileNode;