import React, { useEffect, useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@progress/kendo-react-common';
import { cameraIcon, gearIcon } from '@progress/kendo-svg-icons';
import { Typography } from '@progress/kendo-react-common';
import { Button } from '@progress/kendo-react-buttons';
import { AuthorityLevel, AuthorityType, LayerType, ViewLayer, NavigationMode2D } from '../../../types';
import RasterSymbology from './RasterSymbology';
import { useConsumeViewerState } from '../../../context/viewer';
import { Select, ShareResourceButton, SubmitButton } from '../../../components/form';
import MapContext from './Map/MapContext';
import { useUser } from '../../../hooks/authentication';
import { ReactComponent as DistanceLogo } from '../../../assets/icons/toolbar-distance.svg';
import { ReactComponent as PointLogo } from '../../../assets/icons/toolbar-draw-point.svg';
import { ReactComponent as LinesLogo } from '../../../assets/icons/toolbar-draw-lines.svg';
import { ReactComponent as CircleLogo } from '../../../assets/icons/toolbar-draw-circle.svg';
import { ReactComponent as PolygonLogo } from '../../../assets/icons/toolbar-polygon.svg';
import { Tooltip } from '@progress/kendo-react-tooltip';
import IconButton from '../../../components/form/IconButton';

const StyledIcon = styled(SvgIcon)`
  transition: color 0.15s ease-in-out;
  color: var(--bs-body-color);
  &:hover {
    color: white;
  }
`;

const Container = styled.div`
  background: var(--geosap-navbar-color);
  z-index: 1;
  height: var(--geosap-viewer-toolbar-height);
`;

const ToolSeparator = styled.div`
  width: 2px;
  background: white;
  margin-top: 10px;
  margin-bottom: 10px;
`;

interface OLMenuBarProps {
  onLayerUpdated?: (layer: ViewLayer) => void;
  onSaveView?: () => void;
}

const OLMenuBar: React.FC<OLMenuBarProps> = (props: OLMenuBarProps) => {
  const { dispatch, olMap, mode2d } = useConsumeViewerState();
  const { userHasAuthority } = useUser();
  const { viewConfig, selectedLayer } = useConsumeViewerState();
  const [showGeotiffControls, setShowGeotiffControls] = useState(false);
  const [showRasterSymbology, setShowRasterSymbology] = useState(false);

  const handleTakeScreenshot = () => {
    olMap.once('rendercomplete', function () {
      const mapCanvas = document.createElement('canvas');
      const size = olMap.getSize();
      mapCanvas.width = size[0];
      mapCanvas.height = size[1];
      const mapContext = mapCanvas.getContext('2d');
      Array.prototype.forEach.call(
        olMap.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
        function (canvas: any) {
          if (canvas.width > 0) {
            const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
            const matrix = [
              parseFloat(canvas.style.width) / canvas.width,
              0,
              0,
              parseFloat(canvas.style.height) / canvas.height,
              0,
              0,
            ];
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
            const backgroundColor = canvas.parentNode.style.backgroundColor;
            if (backgroundColor) {
              mapContext.fillStyle = backgroundColor;
              mapContext.fillRect(0, 0, canvas.width, canvas.height);
            }
            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );
      mapContext.globalAlpha = 1;
      mapContext.setTransform(1, 0, 0, 1, 0, 0);

      const a = document.createElement('a');
      a.href = mapCanvas.toDataURL();
      a.download = viewConfig.pageName || ''; // Optionally set a filename
      a.style.display = 'none';

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
    olMap.renderSync();
  };

  const renderMenuButton = (active: boolean, action: () => void, SvgComponentType: any, tooltipText: string) => {
    return (
      <li role="button" onClick={action} className="px-1 position-relative">
        <Tooltip anchorElement="target" position="bottom" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
          <SvgComponentType
            fill={active ? '#89C541' : '#aaa'}
            stroke={active ? '#89C541' : '#aaa'}
            style={{
              width: 'var(--geosap-viewer-toolbar-icon-width)',
              height: 'var(--geosap-viewer-toolbar-icon-width)',
            }}
          />
          <a style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 1 }} title={tooltipText} />
        </Tooltip>
      </li>
    );
  };

  return (
    <Container className="d-flex w-100">
      {selectedLayer &&
        (selectedLayer.layerType === LayerType.GeoTif ||
          selectedLayer.layerType === LayerType.GSRAS ||
          selectedLayer.layerType === LayerType.GSVEC) && (
          <RasterSymbology
            visible={showRasterSymbology}
            onClose={() => setShowRasterSymbology(false)}
            zIndex={100}
            onLayerUpdated={props.onLayerUpdated}
          ></RasterSymbology>
        )}

      {/* Measure Tools */}
      <ToolSeparator />
      <div className="px-3 py-1 flex-column d-flex">
        <Typography.p fontSize="small" themeColor="light" className="mb-0">
          Measure
        </Typography.p>
        <ul className="flex-grow-1 d-flex">
          {renderMenuButton(
            mode2d === NavigationMode2D.LINE_MEASUREMENT,
            () => {
              if (mode2d === NavigationMode2D.LINE_MEASUREMENT)
                dispatch({ type: 'CHANGE_NAVIGATION_2D', payload: { mode: NavigationMode2D.NORMAL } });
              else dispatch({ type: 'CHANGE_NAVIGATION_2D', payload: { mode: NavigationMode2D.LINE_MEASUREMENT } });
            },
            LinesLogo,
            'Measure the distance between two points.'
          )}
          {renderMenuButton(
            mode2d === NavigationMode2D.POLYGON_MEASUREMENT,
            () => {
              if (mode2d === NavigationMode2D.POLYGON_MEASUREMENT)
                dispatch({ type: 'CHANGE_NAVIGATION_2D', payload: { mode: NavigationMode2D.NORMAL } });
              else dispatch({ type: 'CHANGE_NAVIGATION_2D', payload: { mode: NavigationMode2D.POLYGON_MEASUREMENT } });
            },
            PolygonLogo,
            'Area measurements between multiple points.'
          )}
        </ul>
      </div>
      {/* Drawing Tools
      <ToolSeparator />
      <div className="px-3 py-1 flex-column d-flex">
        <Typography.p fontSize="small" themeColor="light" className="mb-0">
          Drawing
        </Typography.p>
        <ul className="flex-grow-1 d-flex">
          {renderMenuButton(
            mode2d === NavigationMode2D.POINT_DRAWING,
            () => {
              if (mode2d === NavigationMode2D.POINT_DRAWING)
                dispatch({ type: 'CHANGE_NAVIGATION_2D', payload: { mode: NavigationMode2D.NORMAL } });
              else dispatch({ type: 'CHANGE_NAVIGATION_2D', payload: { mode: NavigationMode2D.POINT_DRAWING } });
            },
            PointLogo,
            ''
          )}
          {renderMenuButton(
            mode2d === NavigationMode2D.LINESTRING_DRAWING,
            () => {
              if (mode2d === NavigationMode2D.LINESTRING_DRAWING)
                dispatch({ type: 'CHANGE_NAVIGATION_2D', payload: { mode: NavigationMode2D.NORMAL } });
              else dispatch({ type: 'CHANGE_NAVIGATION_2D', payload: { mode: NavigationMode2D.LINESTRING_DRAWING } });
            },
            LinesLogo,
            ''
          )}
          {renderMenuButton(
            mode2d === NavigationMode2D.POLYGON_DRAWING,
            () => {
              if (mode2d === NavigationMode2D.POLYGON_MEASUREMENT)
                dispatch({ type: 'CHANGE_NAVIGATION_2D', payload: { mode: NavigationMode2D.POLYGON_DRAWING } });
              else dispatch({ type: 'CHANGE_NAVIGATION_2D', payload: { mode: NavigationMode2D.POLYGON_DRAWING } });
            },
            PolygonLogo,
            ''
          )}
          {renderMenuButton(
            mode2d === NavigationMode2D.CIRCLE_DRAWING,
            () => {
              if (mode2d === NavigationMode2D.CIRCLE_DRAWING)
                dispatch({ type: 'CHANGE_NAVIGATION_2D', payload: { mode: NavigationMode2D.NORMAL } });
              else dispatch({ type: 'CHANGE_NAVIGATION_2D', payload: { mode: NavigationMode2D.CIRCLE_DRAWING } });
            },
            CircleLogo,
            ''
          )}
        </ul>
      </div> */}
      <ToolSeparator />
      <div className="d-flex justify-content-center align-items-center px-4">
        <SubmitButton
          label={'Symbology'}
          size="small"
          full={false}
          onClick={() => {
            setShowRasterSymbology(true);
          }}
        ></SubmitButton>
      </div>
      <ToolSeparator />
      {userHasAuthority(AuthorityType.VIEWER_AUTHORITY, AuthorityLevel.UPDATE) && (
        <div className="px-3 py-1 d-flex align-items-center">
          <SubmitButton
            style={{ height: 'fit-content' }}
            label={'Set Default View'}
            full={false}
            size="small"
            onClick={props.onSaveView}
          ></SubmitButton>
        </div>
      )}
      <ToolSeparator style={{ marginLeft: 'auto' }} />
      <div style={{ display: 'flex' }}>
        {userHasAuthority(AuthorityType.VIEWER_AUTHORITY, AuthorityLevel.UPDATE) && (
          <IconButton
            icon={gearIcon}
            style={{ color: 'white', marginLeft: '0.5rem', marginRight: '0.25rem' }}
            hoveredStyle={{ color: 'lightgray', marginLeft: '0.5rem', marginRight: '0.25rem' }}
            tooltip={'Display viewer options.'}
            onClick={(e) => {
              e.preventDefault();
              dispatch({ type: 'CHANGE_VIEW_CONFIG_PROPERTIES_OPENED', payload: { opened: true } });
              return true;
            }}
          ></IconButton>
        )}
        {userHasAuthority(AuthorityType.VIEWER_AUTHORITY, AuthorityLevel.SHARE) && (
          <ShareResourceButton
            resourceName={viewConfig?.pageName}
            transactionId={viewConfig?.transaction?.id}
            fileNodeId={viewConfig?.filenode?.id}
            style={{ marginLeft: '0.25rem', marginRight: '0.5rem' }}
            iconStyle={{ color: 'white' }}
            iconHoveredStyle={{ color: 'lightgray' }}
          ></ShareResourceButton>
        )}
      </div>
      <div className="px-3 py-1 flex-column d-flex">
        <Typography.p fontSize="small" themeColor="light" className="mb-0" style={{ color: 'var(--geosap-disabled)' }}>
          Export
        </Typography.p>

        <div className="py-1 d-flex">
          <Button
            fillMode="flat"
            size={'small'}
            themeColor={'light'}
            onClick={() => {
              handleTakeScreenshot();
            }}
          >
            .png
          </Button>
          {/*<Button fillMode="flat" size={'small'} themeColor={'light'} disabled={true}>
            .pdf
          </Button>*/}
        </div>
      </div>
      <ToolSeparator style={{ marginRight: '1.5rem' }} />
    </Container>
  );
};

export default OLMenuBar;
