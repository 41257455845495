import React, { useEffect, useState } from 'react';
import {
  FileNodeUploadWizardProvider,
  useCreateFileNodeUploadWizardState,
  FileNodeUploadWizardStep,
  FileNodeUploadWizardProviderValue,
} from '../../../context/fileNodeUploadWizard';
import SelectFiles from './SelectFiles';
import { SvgIcon } from '@progress/kendo-react-common';
import { chevronLeftIcon } from '@progress/kendo-svg-icons';
import SelectDestination from './SelectDestination';
import { TusUpload } from '../../../types';

interface Props {
  wizardState: FileNodeUploadWizardProviderValue;
  onDone: (upload: TusUpload) => void;
  onClose: () => void;
}

const FileNodeUploadWizard: React.FC<Props> = ({ wizardState, onDone, onClose }) => {
  const { step, dispatch, done } = wizardState;

  //useEffect(() => {
  //  if (done) {
  //    onDone();
  //  }
  //}, [done]);

  return (
    <FileNodeUploadWizardProvider value={wizardState}>
      <div className="d-flex flex-column h-100 position-relative">
        {step > 0 && (
          <div
            style={{ position: 'absolute', top: 0, left: 0, cursor: 'pointer', zIndex: 1000 }}
            onClick={() => {
              dispatch({ type: 'GO_BACK', payload: {} });
            }}
          >
            <SvgIcon icon={chevronLeftIcon} size="xlarge"></SvgIcon>
          </div>
        )}
        <div className="h-100">
          <div className="h-100 row align-items-center justify-content-center">
            {
              {
                [FileNodeUploadWizardStep.SELECT_FILES]: <SelectFiles />,
                [FileNodeUploadWizardStep.SELECT_DESTINATION]: <SelectDestination onDone={onDone} />,
                [FileNodeUploadWizardStep.TRACK_PROGRESS]: null,
                [FileNodeUploadWizardStep.UNSUPPORTED]: null,
              }[step]
            }
          </div>
        </div>
      </div>
    </FileNodeUploadWizardProvider>
  );
};

export default FileNodeUploadWizard;
