import React from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Switch } from '@progress/kendo-react-inputs';
import { SubmitButton } from '../../components/form';

const NotificationSettings: React.FC = () => {
  return (
    <div className="container-fluid p-2 border bg-white">
      <div className="d-flex flex-column col-12 col-md-8 mx-auto">
        <Typography.h2 className='text-center pt-3 pb-1'>
          Notification Settings
        </Typography.h2>

        {/* ACCOUNT NOTIFICATIONS */}
        <Typography.h5 className=''>
          Account Notifications
        </Typography.h5>
        <span className=''>
          Communications related to billing, plan usage and organization changes.
        </span>
        <div className="d-flex flex-column py-3">
            <div className="d-flex">
              <span className='col-6 text-center d-flex align-items-center ps-3'>EMAIL</span>
                <div className="col-6">
                  <Switch
                    className="neutral my-1"
                    onChange={(event) => {
                      console.log("Clickng on switch");
                    }}
                    //checked={}
                    //disabled={}
                  />
                </div>
            </div>
            <div className="d-flex">
              <span className='col-6 text-center d-flex align-items-center ps-3'>TEXT MESSAGES (SMS)</span>
                <div className="col-6">
                <Switch
                  className="neutral my-1"
                  onChange={(event) => {
                    console.log("Clickng on switch");
                    event.nativeEvent.stopPropagation();
                    event.syntheticEvent.stopPropagation();
                    //event.nativeEvent.preventDefault();
                  }}
                  //checked={}
                  //disabled={}
                />
                </div>
            </div>
          </div>

        {/* SAPFLOW STATUS NOTIFICATIONS */}
        <Typography.h5 className=''>
          SapFLOW Status Notifications
        </Typography.h5>
        <span className=''>
          Communications specific to the status of SapFLOWs being processed on your account.
        </span>
        <div className="d-flex flex-column py-3">
            <div className="d-flex">
              <span className='col-6 text-center d-flex align-items-center ps-3'>EMAIL</span>
                <div className="col-6">
                  <Switch
                    className="neutral my-1"
                    onChange={(event) => {
                      console.log("Clickng on switch");
                    }}
                    //checked={}
                    //disabled={}
                  />
                </div>
            </div>
            <div className="d-flex">
              <span className='col-6 text-center d-flex align-items-center ps-3'>TEXT MESSAGES (SMS)</span>
                <div className="col-6">
                <Switch
                  className="neutral my-1"
                  onChange={(event) => {
                    console.log("Clickng on switch");
                    event.nativeEvent.stopPropagation();
                    event.syntheticEvent.stopPropagation();
                    //event.nativeEvent.preventDefault();
                  }}
                  //checked={}
                  //disabled={}
                />
                </div>
            </div>
          </div>

        {/* GENERAL EMAIL COMMUNICATIONS */}
        <Typography.h5 className=''>
          General Email Communications
        </Typography.h5>
        <span className=''>
          Determines which types of email communication you would like to receive from us.
        </span>
        <div className="d-flex flex-column py-3">
            <div className="d-flex">
              <span className='col-6 text-center d-flex align-items-center ps-3'>Marketing</span>
                <div className="col-6">
                  <Switch
                    className="neutral my-1"
                    onChange={(event) => {
                      console.log("Clickng on switch");
                    }}
                    //checked={}
                    //disabled={}
                  />
                </div>
            </div>
            <div className="d-flex">
              <span className='col-6 text-center d-flex align-items-center ps-3'>Product Training</span>
                <div className="col-6">
                <Switch
                  className="neutral my-1"
                  onChange={(event) => {
                    console.log("Clickng on switch");
                    event.nativeEvent.stopPropagation();
                    event.syntheticEvent.stopPropagation();
                    //event.nativeEvent.preventDefault();
                  }}
                  //checked={}
                  //disabled={}
                />
                </div>
            </div>
            <div className="d-flex">
              <span className='col-6 text-center d-flex align-items-center ps-3'>New Feature Updates</span>
                <div className="col-6">
                <Switch
                  className="neutral my-1"
                  onChange={(event) => {
                    console.log("Clickng on switch");
                    event.nativeEvent.stopPropagation();
                    event.syntheticEvent.stopPropagation();
                    //event.nativeEvent.preventDefault();
                  }}
                  //checked={}
                  //disabled={}
                />
                </div>
            </div>
          </div>
            <div className="pt-5 d-flex justify-content-center">
              <SubmitButton label="Save Changes" disabled={false} full={false}/>
            </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
