import { LayerType, SapFlowViewConfig, ViewLayer } from '../types';

export const updateLayerParam = (layer: ViewLayer, key: string, value: any) => {
    layer.paramsMap[key] = value;
    let keyFound = false;
    if(!layer.params)layer.params = [];
    for (let i = 0; i < layer.params.length; i++) {
      if (layer.params[i].key === key) {
        layer.params[i].value = JSON.stringify(value);
        keyFound = true;
        break;
      }
    }
    if (!keyFound) {
      layer.params.push({ key, value: JSON.stringify(value) });
    }
  };

  export const updateViewConfigParam = (viewConfig: SapFlowViewConfig, key: string, value: any) => {
      viewConfig.paramsMap[key] = value;
      let keyFound = false;
      if(!viewConfig.params)viewConfig.params = [];
      for (let i = 0; i < viewConfig.params.length; i++) {
        if (viewConfig.params[i].key === key) {
          viewConfig.params[i].value = JSON.stringify(value);
          keyFound = true;
          break;
        }
      }
      if (!keyFound) {
        viewConfig.params.push({ key, value: JSON.stringify(value) });
      }
    };