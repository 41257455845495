import React, { useRef, useState, useEffect, ReactNode } from 'react';
import PropTypes from 'prop-types';
import './Map.css';
import MapContext from './MapContext';
import MapEvent from 'ol/MapEvent';
import * as ol from 'ol';
import 'ol/ol.css';
import { Coordinate } from 'ol/coordinate';
import { MapOptions } from 'ol/Map';
import { Projection } from 'ol/proj';

interface Props {
  children: ReactNode;
  zoom: number;
  center: Coordinate;
  handleCameraMoved: (coordinates: Coordinate, zoom: number) => void;
  handleMapEvent: (event: any, map: ol.Map) => void;
  projection?: string;
}
const Map: React.FC<Props> = ({ children, zoom, center, handleCameraMoved, handleMapEvent }) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);

  // on component mount
  useEffect(() => {
    const projection = new Projection({ code: 'EPSG:3857' });
    const options: MapOptions = {
      view: new ol.View({ zoom, center }),
      layers: [],
      controls: [],
      overlays: [],
    };

    const mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);
    mapObject.on('moveend', (evt: MapEvent) => {
      handleCameraMoved(evt.map.getView().getCenter(), evt.map.getView().getZoom());
    });
    mapObject.on('postrender', (evt: MapEvent) => {
      handleMapEvent(evt, mapObject);
    });
    mapObject.on('loadstart', (evt: MapEvent) => {
      handleMapEvent(evt, mapObject);
    });
    mapObject.on('loadend', (evt: MapEvent) => {
      handleMapEvent(evt, mapObject);
    });
    mapObject.on('pointermove', (evt: MapEvent) => {
      handleMapEvent(evt, mapObject);
    });
    mapObject.on('click', (evt: MapEvent) => {
      handleMapEvent(evt, mapObject);
    });

    return () => mapObject.setTarget(undefined);
  }, []);

  // zoom change handler
  useEffect(() => {
    if (!map) return;

    map.getView().setZoom(zoom);
  }, [zoom]);

  // center change handler
  useEffect(() => {
    if (!map) return;

    map.getView().setCenter(center);
  }, [center]);

  return (
    <MapContext.Provider value={{ map }}>
      <div ref={mapRef} className="ol-map">
        {children}
      </div>
    </MapContext.Provider>
  );
};

Map.propTypes = {
  children: PropTypes.any,
  zoom: PropTypes.any,
  center: PropTypes.any,
  handleCameraMoved: PropTypes.func,
  projection: PropTypes.any,
};

export default Map;
