import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { Typography } from '@progress/kendo-react-common';
import { CentralizedContent } from '../components/layout';
import { Input, SubmitButton } from '../components/form';
import { REQUIRED_FIELD, VALID_PASSWORD_FIELD } from '../common/constants';
import { useResetPassword } from '../hooks/authentication';
import { useAppContext } from '../context/app';

const BrandImage = styled.img`
  display: block;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
`;

interface FormValues {
    password: string;
    passwordConfirmation: string;
}

const validationSchema = yup.object({
    password: yup.string().required(REQUIRED_FIELD).matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/, VALID_PASSWORD_FIELD),
    passwordConfirmation: yup
      .string()
      .test('passwords-match', "Password confirmation doesn't match", (value, { parent }) => value === parent.password),
});

const ResetPassword: React.FC = () => {
  const { dispatch } = useAppContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const requestPasswordResetMutation = useResetPassword();
  const [redirecting, setRedirecting] = React.useState<boolean>(false);
  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const resetCode = searchParams.get("resetCode");
    if (!resetCode) {
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: { notification: { type: 'error', content: 'Invalid reset code.' } },
        });
        navigate("/login");
    }
  }, []);

  const resetPassword = (data: FormValues) => {
    requestPasswordResetMutation.mutate({password: data.password, resetCode: searchParams.get("resetCode")}, {
      onSuccess: ()=>{
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: { notification: { type: 'success', content: 'Successfully sent password reset email.' } },
        });
        setRedirecting(true);
        setTimeout(()=>{
          navigate("login");
        }, 2000);
    }});
  }

  return (
    <CentralizedContent>
      <div className="col-xs-12">
        <BrandImage src="/assets/brand-black.png" alt="GeoSAP brand" className="p-3 pb-4" />
          <Typography.h2 className='text-center'>PASSWORD RESET</Typography.h2>
          <Typography.p className='text-center'>Please type in a new password.</Typography.p>
          <form onSubmit={handleSubmit(resetPassword)}>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input {...field} type="password" placeholder="Password" maxLength={40} error={error?.message} />
              )}
            />
            <Controller
              name="passwordConfirmation"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input {...field} type="password" placeholder="Confirm Password" maxLength={40} error={error?.message} />
              )}
            />
            <div className="pt-3">
              <SubmitButton label="Reset Password" loading={requestPasswordResetMutation.isLoading || redirecting} />
            </div>
          </form>
      </div>
    </CentralizedContent>
  );
};

export default ResetPassword;