import { useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../common';

interface Params {
    name: string,
    description: string,
    location: string,
    industry: string,
    sharing: boolean
}

const useOrganizationUpdate = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();

  return useMutation(async (params: Params) => { 
    return axios.put("/user_management/organization", params);
  },
  {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['organizations'], { refetchInactive: true });
    },
  });
};

export default useOrganizationUpdate;
