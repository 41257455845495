import { useQueryClient, useMutation } from 'react-query';
import { LayerType, ViewLayer, ViewLayerParam } from '../../types';
import { useAxiosInstance } from '../common';

interface ViewLayerCreateDTO {
    viewConfigId: string;
    params: {
        displayName: string;
        uri: string;
        layerType: LayerType;
        active: boolean;
        params?: ViewLayerParam[];
    };
}

const useViewLayerCreate = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: ViewLayerCreateDTO) => {
      const response = await axios.post<ViewLayer>("viewer_config/" + params.viewConfigId + "/layer", params.params);
      return response.data;
    },
    {
      onSuccess: (data: ViewLayer, variables: ViewLayerCreateDTO) => {
        queryClient.invalidateQueries(['viewLayer', data.id], { refetchInactive: true });
        queryClient.invalidateQueries(['viewerConfig', variables.viewConfigId], { refetchInactive: true });
      },
    }
  );
};

export default useViewLayerCreate;
