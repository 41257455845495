enum FileNodeType {
  IMAGE = 'IMAGE',
  POINT_CLOUD = 'POINT_CLOUD',
  RASTER = 'RASTER',
  VECTOR = 'VECTOR',
  TEXT = 'TEXT',
  GEOPACKAGE = 'GEOPACKAGE',
  SUPPORT = 'SUPPORT',
  SHAPEFILE = 'SHAPEFILE',
  SHAPEFILE_ATTR = 'SHAPEFILE_ATTRIBUTE',
  SHAPEFILE_INDEX = 'SHAPEFILE_INDEX',
  SHAPEFILE_PROJECTION = 'SHAPEFILE_PROJECTION',
  DIR = 'DIR',
  OTHER = 'OTHER',
  GSRAS = 'GSRAS',
  GSVEC = 'GSVEC',
  NONE = 'NONE',
  PDF = 'PDF',
}
export default FileNodeType;
