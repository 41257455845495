
import { FileNodeReviewStatus } from '../types/DataDelivery';

export function fileNodeReviewStatusToText(status: FileNodeReviewStatus): string {
    switch(status) {
        case FileNodeReviewStatus.IDLE: 
            return "Idle"
        case FileNodeReviewStatus.MARKED_FOR_REVIEW: 
            return "Ready for review"
        case FileNodeReviewStatus.REVIEWED: 
            return "Reviewed"
    }
}