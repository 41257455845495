import axios from 'axios';
import {register} from 'ol/proj/proj4.js';
import { Unit } from '../types';

declare const window: any;
const Potree = window.Potree;
const proj4 = window.proj4 || require('proj4');

export const fetchProj4 = async (code: string) => {

    let codeDefined = false;
    try{
        proj4(code);
        codeDefined = true;
    }
    catch(e){console.log();}
    if(!codeDefined) {
        const splittedCode = code.split(':');
        const authority = splittedCode[0];
        const authorityCode = splittedCode[1];
        if(authority === 'EPSG') {
            const url = `https://epsg.io/${authorityCode}.proj4`;
            const projString = (await axios.get<string>(url)).data;
            proj4.defs(code, projString);
            register(proj4);
        }
    }
}

export const getProjUnits = (projStr: string): Unit => {
    // Split the proj4 string into individual parameters
    const parameters = projStr.split(' ');

    // Look for the +units parameter
    for (const param of parameters) {
        if (param.startsWith('+units=')) {
            // Extract the unit value
            const units = param.split('=')[1];
            if(units === 'm') {
                return Unit.METER;
            }
            else if(units === 'ft' || units === 'us-ft') {
                return Unit.FEET;
            }
        }
    }

    // If no +units parameter is found, assume meters
    return Unit.METER;
    
}

export const unitToPotreeLengthUnits = (unit: Unit) => {
    if(unit === Unit.METER) {
        return Potree.LengthUnits.METER;
    }
    else if(unit === Unit.FEET) {
        return Potree.LengthUnits.FEET;
    }
}