export interface Vector3 {
  x: number;
  y: number;
  z: number;
}

export const compareVectors = (a: Vector3, b: Vector3) => {
  return a.x !== b.x || a.y !== b.y || a.z !== b.z;
};

const EPSILON = 1e-10;

export const isZeroVector = (a: Vector3) => {
  return Math.abs(a.x) < EPSILON && Math.abs(a.y) < EPSILON && Math.abs(a.z) < EPSILON;
};

export const vectorToString = (a: Vector3) => {
  return a.x + ', ' + a.y + ', ' + a.z;
};
