import { useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../common/';

const endpoint = '/viewer_config/layer';

interface Params {
  viewerId: string;
  layerId: string;
}

const useRemoveLayer = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const result = await axios.delete<string>("/viewer_config/" + params.viewerId + "/layer/" + params.layerId);
      return result.data;
    },
    {
      onSuccess: (data: any, variables: Params) => {
        queryClient.invalidateQueries(['viewerConfig', variables.viewerId], { refetchInactive: true });
      },
    }
  );
};

export default useRemoveLayer;