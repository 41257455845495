import React from 'react';

interface Props {
  progress: number;
  progressClassname?: string;
  progressStyle?: any;
  text?: string;
  textColor?: string;
  disableAnimation?: boolean;
}

const ProgressBar: React.FC<Props> = ({
  progress,
  progressClassname,
  progressStyle,
  disableAnimation,
  textColor,
  text,
}) => {
  return (
    <>
      <div
        className="progress"
        style={{ position: 'relative', width: '100%', lineHeight: '23px', height: '1.4rem', fontWeight: 'bold' }}
      >
        <div
          className={`progress-bar ${
            /*progress === 100 || */ disableAnimation ? '' : 'progress-bar-striped progress-bar-animated'
          } ${progressClassname ? progressClassname : ''}`}
          style={{ ...{ width: `${progress}%` }, ...progressStyle }}
          role="progressbar"
          aria-valuenow={progress}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
        <span
          style={{
            position: 'absolute',
            right: 8,
            color: textColor ? textColor : progress === 100 ? 'white' : 'unset',
          }}
        >
          {text ? text : progress === 100 ? ' Completed' : progress.toFixed(2) + '%'}
        </span>
      </div>
    </>
  );
};

export default ProgressBar;
