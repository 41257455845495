import { useQuery } from 'react-query';
import { LOCAL_STORAGE_CURRENT_ORG } from '../../common/constants';
import { Organization } from '../../types';
import { useAxiosInstance } from '../common';
import { useAppContext } from '../../context/app';
import useUser from './useUser';

const endpoint = '/user_management/organization/';

const useOrganization = (organizationId: string) => {
  const axios = useAxiosInstance();
  const { dispatch } = useAppContext();
  const { isAuthenticated, getCurrentOrganization } = useUser();
  return useQuery(['organization', organizationId], async () => {
    if(organizationId) {
      const result = await axios.get<Organization>(endpoint + organizationId);
      if(isAuthenticated && getCurrentOrganization() && getCurrentOrganization().id === organizationId) {
        localStorage.setItem(LOCAL_STORAGE_CURRENT_ORG, JSON.stringify(result.data));
        dispatch({ type: 'SELECT_ORGANIZATION', payload: { organization: result.data } });
      }
      return result.data;
    }
    else {
      return null;
    }
  });
};

export default useOrganization;
