import { useQuery } from 'react-query';
import { FileNode, SapFlowGroup } from '../../types/DataDelivery';
import { useAxiosInstance } from '../common';
import { SapFlowConfig } from '../../types';

const useSapGroups = () => {
  const axios = useAxiosInstance();
  return useQuery(['sapGroups'], async () => {
    const result = await axios.get<SapFlowGroup[]>('file_sapgroup');
    return result.data;
  });
};

export default useSapGroups;
