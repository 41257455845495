import React, { useEffect, useState } from 'react';
import { Fade } from '@progress/kendo-react-animation';

interface Props {
  children: JSX.Element;
  className?: string;
  childClassName?: string;
}

const TransitionAnimation: React.FC<Props> = ({ children, className, childClassName }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => setShow(true), 100);
  }, []);

  return <Fade className={className ? className : 'w-100'} componentChildClassName={childClassName ? childClassName : 'w-100'}  >{show && <div className="p-1 h-100">{children}</div>}</Fade>;
};

export default TransitionAnimation;
