import ItemNodeFilesDetails from './ItemNodeFilesDetails';
import { SapFlowConfigProcessNode, SapFlowConfigItemNode } from './SapFlowConfig';

export interface SapFlowConfigType {
  id: string;
  name: string;
}

export interface SapFlowTransaction {
  id: string;
  sapflowname: string;
  status: SapFlowTransactionItemStatus;
  createdDate: string;
  lastModifiedDate: string;
  project: {
    id: string;
    name: string;
  };
  types: SapFlowConfigType[];
  processnodes: SapFlowProcessNode[];
  itemnodes: SapFlowConfigItemNode[];
  transactionHistory: SapFlowTransactionStatus[];
  transactionPercentComplete: number;
  transactionMessage: string;
  transactionStartTime: number;
  transactionFinishTime: number;
  transactionRuntime: number;
}

export enum SapFlowTransactionItemStatus {
  TRANSACTION_CREATED = 'TRANSACTION_CREATED',
  TRANSACTION_READY = 'TRANSACTION_READY',
  TRANSACTION_STARTED = 'TRANSACTION_STARTED',
  TRANSACTION_IN_PROCESS = 'TRANSACTION_IN_PROCESS',
  TRANSACTION_FINISHED = 'TRANSACTION_FINISHED',
  TRANSACTION_ERRORED = 'TRANSACTION_ERRORED',
  TRANSACTION_TIMED_OUT = 'TRANSACTION_TIMED_OUT',
}

export enum SapFlowProcessStatus {
  CREATED = "CREATED",
  STARTED = "STARTED",
  COMPLETED = "COMPLETED",
  ERRORED = "ERRORED",
  IN_PROGRESS = "IN_PROGRESS",
}

export interface SapFlowTransactionItem {
  id: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  status: SapFlowTransactionItemStatus;
  fileformat: string;
  group: boolean;
  type: string;
}

export interface SapFlowTransactionStatus {
  id: string;
  currentTransactionCPUUsage: number;
  currentTransactionMemoryUsage: number;
  currentTransactionPercentComplete: number;
  currentTransactionRuntime: number;
  statusMessage: string;
  statusTime: string;
  transactionID: string;
  transactionStatus: SapFlowTransactionItemStatus;
  selected?: boolean;
}

export interface SapFlowProcessNode {
  displayname: string;
  id: string;
  inputitems: any; //{Input: "674b8fc1-67fd-4b57-967d-537930f13f5d"}
  order: number;
  outputitems: any; //{Output: "674b8fc1-67fd-4b57-967d-537930f13f5d"}
  params: any; // [{key: "view", value: ["false"]}]
  percentComplete: number;
  processFinishTime: number;
  processNodeStatusHistory: SapFlowProcessNodeStatus[];
  processRuntime: number;
  processStartTime: string;
  processMessage: string;
  sapname: string;
  status: SapFlowProcessStatus;
  sync: boolean;
  timeout: number;
  toolblockdescription: string
  toolblockname: string;
}

export interface SapFlowProcessNodeStatus {
  id: string;
  transactionID: string;
  processNodeID: string;
  currentProcessStatus: SapFlowProcessStatus;
  statusMessage: string;
  statusTime: string;
  TotalProcessNodeCount: number;
  currentProcessNodeCount: number;
  currentProcessRuntime: number;
  currentProcessPercentComplete: number;
  currentProcessNodeIndex: number;
  selected?: boolean;
}

export interface SapFlowFileDetails {
  input: ItemNodeFilesDetails[];
  intermediary: ItemNodeFilesDetails[];
  output: ItemNodeFilesDetails[];
}

export interface RequiredFileStatus {
  ProvisionType: 'Upload' | 'Reuse';
  Uploaded: boolean;
  FilesToReuse: any;
}