export const LOCAL_STORAGE_USER = 'user';
export const LOCAL_STORAGE_TOKEN_TYPE = 'tokenType';
export const LOCAL_STORAGE_ACCESS_TOKEN = 'accessToken';
export const LOCAL_STORAGE_CURRENT_ORG = 'currentOrg';
export const LOCAL_STORAGE_DEVICE_ID = 'deviceId';
export const REFETCH_INTERVAL = 0.5 * 60 * 1000;

// Common validation messages
export const REQUIRED_FIELD = 'Please enter a value';
export const VALID_EMAIL_FIELD = 'Please enter a valid email. E.g.: anna@example.com';
export const VALID_PASSWORD_FIELD = "Password must be 8 or more characters and contain least 1 number and 1 special character. ";
