import { BandInfo, LayerType, ViewLayer, VisualizationType, RAMP_COLORS } from '../types';

export const getOrGenerateDefaultVisualizationParams = (layer: ViewLayer) => {
    let visualization = layer.paramsMap['visualization'];
    if(visualization) {
        return visualization;
    }
    else if (layer.layerType === LayerType.GeoTif || layer.layerType === LayerType.GSRAS) {
        const bands: BandInfo[] = layer.paramsMap['bands'];
        if(!bands || bands.length === 0){
            return null;
        }
        visualization = {
          type: VisualizationType.SINGLEBAND,
          opacity: 1,
          singleband: {
            bandName: bands[0].name,
            bandIndex: bands[0].index,
            rampColor: RAMP_COLORS[0],
            min: bands[0].min,
            max: bands[0].max,
            currentMin: bands[0].currentMin,
            currentMax: bands[0].currentMax,
          },
          hillshade: {
            bandName: 'Band 1',
            bandIndex: bands[0].index,
            verticalExageration: 1,
            sunElevation: 45,
            sunAzimuth: 45,
          },
        };
        if (bands.length >= 3) {
          visualization.rgb = {
            redBandIndex: bands[0].index,
            redBandCurrentMin: bands[0].currentMin,
            redBandCurrentMax: bands[0].currentMax,
            blueBandIndex: bands[1].index,
            blueBandCurrentMin: bands[1].currentMin,
            blueBandCurrentMax: bands[1].currentMax,
            greenBandIndex: bands[2].index,
            greenBandCurrentMin: bands[2].currentMin,
            greenBandCurrentMax: bands[2].currentMax,
            gamma: 1,
            brightness: 0.5,
            contrast: 1,
          };
        }
    }
    else if (layer.layerType === LayerType.GSVEC) {
        visualization = {
          type: VisualizationType.VECTOR,
          opacity: 1,
          vector: {
            fillColor: '#0000AA',
            strokeColor: '#000000',
            strokeWidth: 1,
          },
        };
    }
    return visualization;
}