import React, { useState, useRef, useEffect } from 'react';
import { Input, SubmitButton } from '../../../../components/form';
import { Typography } from '@progress/kendo-react-common';
import styled from 'styled-components';
import { AddLayerConfig, AddLayerType } from '../../../../types';
import { AddLayerWizardStep, useConsumeAddLayerWizardState } from '../../../../context/addLayerWizard';
import TransitionAnimation from '../../../../components/TransitionAnimation';

const SelectWMSLayer: React.FC = () => {
  const [url, setUrl] = useState<string>('');
  const [nextStep, setNextStep] = useState<AddLayerWizardStep>(AddLayerWizardStep.UNSUPPORTED);
  const [text, setText] = useState<string>('');
  const { dispatch, layerConfig } = useConsumeAddLayerWizardState();

  useEffect(() => {
    let newNextStep = AddLayerWizardStep.UNSUPPORTED;
    let newText = 'Enter a file url.';
    if (layerConfig?.type === AddLayerType.WMS) {
      newNextStep = AddLayerWizardStep.SELECT_WMS_LAYER;
      newText = 'Enter a WMS service url.';
    } else if (layerConfig?.type === AddLayerType.WFS) {
      newNextStep = AddLayerWizardStep.SELECT_WFS_LAYER;
      newText = 'Enter a WFS service url.';
    } else if (layerConfig?.type === AddLayerType.Geotiff) {
      //nextStep = AddLayerWizardStep.UPLOAD;
    } else if (layerConfig?.type === AddLayerType.Shapefile) {
      //nextStep = AddLayerWizardStep.UPLOAD;
    }
    setNextStep(newNextStep);
    setText(newText);
  }, [layerConfig, layerConfig.type]);

  const handleUrlSubmitted = () => {
    dispatch({ type: 'SUBMIT_URL', payload: { url, nextStep } });
  };

  return (
    <TransitionAnimation>
      <>
        <Typography.h5
          style={{
            margin: '25px',
          }}
        >
          {text}
        </Typography.h5>
        <Input
          type="text"
          name="URL"
          value={url}
          onChange={(e: any) => {
            setUrl(e.target.value);
          }}
        />
        <div className="pt-3">
          <SubmitButton
            label="Submit details"
            uppercase={false}
            full={false}
            disabled={!url}
            onClick={handleUrlSubmitted}
          />
        </div>
      </>
    </TransitionAnimation>
  );
};

export default SelectWMSLayer;
