import React, { useReducer, useContext } from 'react';
import { FileNodeUploadWizardState, initialState } from './fileNodeUploadWizardState';
import { fileNodeUploadWizardReducer, FileNodeUploadWizardAction } from './fileNodeUploadWizardReducer';
import { FileNode } from '../../types/DataDelivery';

export interface FileNodeUploadWizardProviderValue extends FileNodeUploadWizardState {
  dispatch: (action: FileNodeUploadWizardAction) => void;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  value: FileNodeUploadWizardProviderValue;
}

const FileNodeUploadWizardContext = React.createContext({
  ...initialState,
  dispatch: () => {
    // not implemented
  },
} as FileNodeUploadWizardProviderValue);

export const FileNodeUploadWizardProvider: React.FC<Props> = ({ children, value }) => {
  return <FileNodeUploadWizardContext.Provider value={value}>{children}</FileNodeUploadWizardContext.Provider>;
};

/*
  Create Sap Flow Wizard state.
*/
export const useCreateFileNodeUploadWizardState = (rootFileNode: FileNode): FileNodeUploadWizardProviderValue => {
  const [state, dispatch] = useReducer(fileNodeUploadWizardReducer, {
    ...initialState,
    rootFileNode,
  });
  return {
    ...state,
    dispatch,
  };
};

/*
  Consume Sap Flow Wizard state.
*/
export const useConsumeFileNodeUploadWizardState = () => {
  return useContext(FileNodeUploadWizardContext);
};
