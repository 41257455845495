import { useMutation } from 'react-query';
import { LOCAL_STORAGE_USER } from '../../common/constants';
import { useAppContext } from '../../context/app';
import { User } from '../../types';
import { useAxiosInstance } from '../common/';

const endpoint = '/user_management/users/user';

interface Params {
  firstName?: string;
  lastName?: string;
}

const useUserUpdate = (userId: string) => {
  const axios = useAxiosInstance();
  const { dispatch } = useAppContext();
  return useMutation(
    async (params: Params) => {
      const response = await axios.put<User>("/user_management/users/" + userId, params);

      const user: User = response.data;
      localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));

      dispatch({ type: 'REFRESH_USER', payload: { user: user } });
      return response.data;
    },
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(['sapFlowView', userId, transactionId], { refetchInactive: true });
      },
    }
  );
};

export default useUserUpdate;
