import { useQueryClient, useMutation } from 'react-query';
import { SapFlowViewConfig } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'viewer_config';

interface AddExistingLayerParams {
  viewerId: string;
  viewLayerId: string;
}

const useViewerConfigAddExistingViewLayer = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: AddExistingLayerParams) => {
      const response = await axios.post<SapFlowViewConfig>(
        endpoint + '/' + params.viewerId + '/layer?viewlayer_id=' + params.viewLayerId
      );
      return response.data;
    },
    {
      onSuccess: (data: any, variables: AddExistingLayerParams) => {
        queryClient.invalidateQueries(['viewerConfig', variables.viewerId], { refetchInactive: true });
      },
    }
  );
};

export default useViewerConfigAddExistingViewLayer;
