import { useQueryClient, useMutation } from 'react-query';
import { useAxiosInstance } from '../common';
import { FileNode } from '../../types/DataDelivery';

const useGenerateConfigSapFlows = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (configId: string) => {
      const response = await axios.post<FileNode>('dataproject/generateConfigSapFlows', null, {
        params: {
          configId,
        },
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sapGroups'], { refetchInactive: true });
      },
    }
  );
};

export default useGenerateConfigSapFlows;
