import React, { useEffect, useState, forwardRef, useMemo, useCallback, useImperativeHandle } from 'react';
import { threeToCesiumPosition } from '../../../converters/projectionHelper';
import { BingMapsImageryProvider, OpenStreetMapImageryProvider } from 'cesium';
import { useConsumeViewerState } from '../../../context/viewer';

// To avoid transpiler error on window.Potree  https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
declare const window: any;

const Cesium = window.Cesium;
const THREE = window.THREE;

let cesiumViewer: any = null;

interface Props {
  show?: boolean;
  height?: number;
}

type Ref = {
  initializeCesium: () => void;
  updateCamera: (threeScene: any) => void;
  getCesiumViewer: () => void;
} | null;

const CesiumViewer = forwardRef<Ref, Props>((props, ref) => {
  const { dispatch, potreeProjection, baseMap } = useConsumeViewerState();
  const [stateCesiumViewer, setStateCesiumViewer] = useState<any>(null);
  const [googleApiSessionToken, setGoogleApiSessionToken] = useState<string>(null);

  const getCesiumViewer = () => cesiumViewer;

  const initializeCesium = () => {
    cesiumViewer = new Cesium.Viewer('cesiumContainer', {
      useDefaultRenderLoop: false,
      animation: false,
      baseLayerPicker: false,
      //terrainProvider: Cesium.createWorldTerrain(),
      fullscreenButton: false,
      geocoder: false,
      homeButton: false,
      infoBox: false,
      sceneModePicker: false,
      selectionIndicator: false,
      timeline: false,
      navigationHelpButton: false,
      imageryProvider: null, //Cesium.createOpenStreetMapImageryProvider({ url: 'https://a.tile.openstreetmap.org/' }),
      terrainShadows: Cesium.ShadowMode.DISABLED,
    });

    window.cesiumViewer = cesiumViewer;

    const cp = new Cesium.Cartesian3(1496000.8666, 884142.8473, 209.3778);
    cesiumViewer.camera.setView({
      destination: cp,
      orientation: {
        heading: 10,
        pitch: -Cesium.Math.PI_OVER_TWO * 0.5,
        roll: 0.0,
      },
    });

    return cesiumViewer;
  };

  useEffect((): any => {
    let interval: any = null;
    if (stateCesiumViewer === null) {
      initializeCesium();
      interval = setInterval(() => {
        cesiumViewer.render();
      }, 100);
    }

    setStateCesiumViewer(cesiumViewer);
    if (interval) {
      return () => {
        return () => clearInterval(interval);
      };
    }
  }, []);

  const fetchGoogleSessionToken = async (apiKey: string) => {
    const url = `https://tile.googleapis.com/v1/createSession?key=${apiKey}`;
    const params = {
      highDpi: true,
      language: 'en-US',
      mapType: 'roadmap',
      region: 'US',
      scale: 'scaleFactor2x',
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    };

    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error(`Failed to fetch session token. Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error_message) {
        throw new Error(`Error fetching session token: ${data.error_message}`);
      }

      return data.session;
    } catch (error) {
      throw new Error(`Failed to fetch session token: ${error.message}`);
    }
  };

  useEffect(() => {
    if (baseMap?.type === 'Google') {
      fetchGoogleSessionToken(process.env.REACT_APP_GOOGLE_API_KEY).then((sessionId) => {
        setGoogleApiSessionToken(sessionId);
      });
    }
  }, [baseMap]);

  useEffect(() => {
    const layers = cesiumViewer.imageryLayers;
    const baseLayer = layers.get(0);
    layers.remove(baseLayer);
    if (props.show) {
      if (baseMap?.type === 'OpenStreetMap') {
        layers.addImageryProvider(
          new Cesium.OpenStreetMapImageryProvider({
            url: 'https://a.tile.openstreetmap.org/',
          })
        );
      } else if (baseMap?.type === 'Google' && googleApiSessionToken) {
        layers.addImageryProvider(
          new Cesium.UrlTemplateImageryProvider({
            url: `https://tile.googleapis.com/v1/2dtiles/{z}/{x}/{y}?key=${process.env.REACT_APP_GOOGLE_API_KEY}&session=${googleApiSessionToken}`,
            credit: 'Google Maps Imagery',
          })
        );
      } else if (baseMap?.type === 'Bing') {
        Cesium.BingMapsImageryProvider.fromUrl('https://dev.virtualearth.net', {
          key: process.env.REACT_APP_BING_MAPS_KEY,
          mapStyle: Cesium.BingMapsStyle.AERIAL,
        }).then((bingImageryProvider: BingMapsImageryProvider) => {
          layers.addImageryProvider(bingImageryProvider);
        });
      } else if (baseMap?.type === 'Other') {
        layers.addImageryProvider(
          new Cesium.UrlTemplateImageryProvider({
            url: baseMap.url,
            credit: baseMap.attribution,
          })
        );
      }
    }
  }, [props.show, baseMap, googleApiSessionToken]);

  const updateCamera = (threeScene: any) => {
    if (cesiumViewer && props.show) {
      const camera = threeScene.getActiveCamera();
      const pTarget = threeScene.view.getPivot();

      const pPos = new THREE.Vector3(0, 0, 0).applyMatrix4(camera.matrixWorld);
      const pRight = new THREE.Vector3(600, 0, 0).applyMatrix4(camera.matrixWorld);
      const pUp = new THREE.Vector3(0, 600, 0).applyMatrix4(camera.matrixWorld);

      const cPos = threeToCesiumPosition(pPos, potreeProjection);
      const cUpTarget = threeToCesiumPosition(pUp, potreeProjection);
      const cTarget = threeToCesiumPosition(pTarget, potreeProjection);

      let cDir = Cesium.Cartesian3.subtract(cTarget, cPos, new Cesium.Cartesian3());
      let cUp = Cesium.Cartesian3.subtract(cUpTarget, cPos, new Cesium.Cartesian3());

      // Only go forward if the target and actual position aren't exactly the same
      if (cDir.x === 0 && cDir.y === 0 && cDir.z === 0) {
        return;
      }

      cDir = Cesium.Cartesian3.normalize(cDir, new Cesium.Cartesian3());
      cUp = Cesium.Cartesian3.normalize(cUp, new Cesium.Cartesian3());

      cesiumViewer.camera.setView({
        destination: cPos,
        orientation: {
          direction: cDir,
          up: cUp,
        },
      });

      const aspect = camera.aspect;
      if (aspect < 1) {
        const fovy = Math.PI * (camera.fov / 180);
        cesiumViewer.camera.frustum.fov = fovy;
      } else {
        const fovy = Math.PI * (camera.fov / 180);
        const fovx = Math.atan(Math.tan(0.5 * fovy) * aspect) * 2;
        cesiumViewer.camera.frustum.fov = fovx;
      }
      cesiumViewer.render();
    }
  };

  useImperativeHandle(ref, () => ({
    initializeCesium,
    updateCamera,
    getCesiumViewer,
  }));

  return (
    <div
      id="cesiumContainer"
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: 'green',
        opacity: props.show ? 1 : 0,
        zIndex: props.show ? 1 : -1,
      }}
    ></div>
  );
});

CesiumViewer.displayName = 'CesiumViewer';

export default CesiumViewer;
