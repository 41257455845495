import { useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../common';
import { Organization } from '../../types';
import { useAppContext } from '../../context/app';

interface Params {
  organizationId: string;
  promo: boolean;
}

const useOrgMakePromo = () => {
  const axios = useAxiosInstance();
  const { dispatch, selectedOrganization } = useAppContext();

  return useMutation(
    async (params: Params) => {
      return axios.put<Organization>(
        '/user_management/organization/' + params.organizationId + '/promo',
        {},
        { params: { promo: params.promo } }
      );
    },
    {
      onSuccess: (data, variables) => {
        if (data.data && data.data.id === selectedOrganization.id) {
          dispatch({ type: 'SELECT_ORGANIZATION', payload: { organization: data.data } });
        }
      },
    }
  );
};

export default useOrgMakePromo;
