import React, { useEffect, useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { SapFlowFileDetails, SapFlowTransaction } from '../../types';
import { formatSizeUnits } from '../../converters/formatSizeUnits';

const InfoRow: React.FC<{ label: string; value: string | number }> = ({ label, value }) => (
  <li>
    <strong>{label}: </strong>
    <span>{value}</span>
  </li>
);

interface Props {
  sapFlowInfos: SapFlowTransaction;
  fileDetails: SapFlowFileDetails;
}

const SummaryOverview: React.FC<Props> = ({ sapFlowInfos, fileDetails }) => {
  const [inputFilesProcessed, setInputFileProcessed] = useState<number>(0);
  const [inputFilesSize, setInputFilesSize] = useState<number>(0);
  const [outputFilesSize, setOutputFilesSize] = useState<number>(0);
  const [requiredInputs, setRequiredInputs] = useState<number>(0);

  useEffect(() => {
    setInputFileProcessed(fileDetails.input.length);
    setInputFilesSize(fileDetails.input.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0));
    setOutputFilesSize(fileDetails.output.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0));
    setRequiredInputs(
      sapFlowInfos.itemnodes.filter((itemNode) => {
        return itemNode.type === 'SUPPLIED';
      }).length
    );
  }, [sapFlowInfos, fileDetails]);

  const timeElapsed = (timeInSec: number): string => {
    const timeInMin = Math.floor(timeInSec / 60);
    const hours = Math.floor(timeInMin / 60);
    const minutes = timeInMin % 60;
    const seconds = timeInSec % 60;
    const hoursText = hours ? `${hours.toString(10).padStart(2, '0')}h` : '';
    const minutesText = minutes ? `${minutes.toString(10).padStart(2, '0')}m` : '';
    const secondsText = seconds ? `${seconds.toString(10).padStart(2, '0')}s` : '';
    if (hoursText && minutesText) {
      return `${hoursText} ${minutesText}`;
    }
    return hoursText ? hoursText : `${minutesText} ${secondsText}`;
  };

  return (
    <div>
      <div className="container-fluid pb-4">
        <div className="row">
          <div className="col-6 text-center" style={{ borderRight: '1px solid var(--bs-body-color)' }}>
            <Typography.h4>{5}</Typography.h4>
            <Typography.h5>
              <div>Credits</div>
              <div>Used</div>
            </Typography.h5>
          </div>
          <div className="col-6 text-center" style={{ borderLeft: '1px solid var(--bs-body-color)' }}>
            <Typography.h4>{timeElapsed(sapFlowInfos.transactionRuntime)}</Typography.h4>
            <Typography.h5>
              <div>Processing</div>
              <div>Time</div>
            </Typography.h5>
          </div>
        </div>
      </div>
      <ul className="pb-4" style={{ fontSize: '0.8rem' }}>
        <InfoRow label="Date Executed" value={sapFlowInfos.transactionStartTime} />
        <InfoRow label="Input Files Processed" value={inputFilesProcessed} />
        <InfoRow label="Total Input File Size" value={formatSizeUnits(inputFilesSize)} />
        <InfoRow label="Total Output File Size" value={formatSizeUnits(outputFilesSize)} />
      </ul>
      <div style={{ background: 'var(--geosap-kendo-button-bg)', fontSize: '0.8rem' }} className="p-3 rounded-3">
        <Typography.h6>{sapFlowInfos.sapflowname}</Typography.h6>
        {/*<p>{sapFlow.description}</p>*/}
        <ul className="ps-3">
          <InfoRow label="Number of SAPs in flow" value={sapFlowInfos.processnodes.length} />
          <InfoRow label="Last Edited" value={sapFlowInfos.lastModifiedDate} />
          <InfoRow label="Process Types" value={'Unknown'} />
          <InfoRow label="Required Inputs" value={requiredInputs} />
        </ul>
      </div>
    </div>
  );
};

export default SummaryOverview;
