import { useQuery } from 'react-query';
import { DeploymentServer } from '../../types';
import { useAxiosInstance } from '../common';

const useServers = () => {
  const axios = useAxiosInstance();
  return useQuery(['servers'], async () => {
    const result = await axios.get<DeploymentServer[]>("server");
    return result.data;
  });
};

export default useServers;
