import { useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../common';

interface Params {
  filenode_id: string
}

const useShareFileNode = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();

  return useMutation(async (params: Params) => { 
    const result = await axios.post("/user_management/sharing/filenode", {}, {params});
    return result.data;
  },
  {
    onSuccess: (data, variables) => {
      //queryClient.invalidateQueries(['organizationInvitations', variables.organizationId], { refetchInactive: true });
    },
  });
};

export default useShareFileNode;
