import { useQuery } from 'react-query';
import { Page, SapFlowProject } from '../../../types';
import { useAxiosInstance } from '../../common/';

const endpoint = 'project';

interface TransQueryParam {
  page: number;
  size: number;
  sort?: string;
}

const useProjects = (page: number, size: number, sort: string[]) => {
  const axios = useAxiosInstance();

  const params: TransQueryParam = {page, size}
  if(sort && sort.length > 0)
    params.sort = sort.join(',');

  return useQuery(
    ['projects', page, sort],
    async () => {
      const result = await axios.get<Page<SapFlowProject>>(endpoint, {params});
      return result.data;
    },
    { retry: false }
  );
};

export default useProjects;
