import { useMutation } from 'react-query';
import { useAxiosInstance } from '../common';

const useRunSapFlow = () => {
  const axios = useAxiosInstance();

  return useMutation(
    async (sapFlowId: string) => {
      return axios.post('/file_sapflow/' + sapFlowId + '/resume_async');
    },
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(['transactions'], { refetchInactive: true });
      },
    }
  );
};

export default useRunSapFlow;
