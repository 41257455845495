import React, { useReducer, useContext } from 'react';
import {
  LOCAL_STORAGE_USER,
  LOCAL_STORAGE_TOKEN_TYPE,
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_CURRENT_ORG,
} from '../../common/constants';
import { UploadContextState, initialState } from './uploadContextState';
import { uploadContextReducer, UploadContextAction } from './uploadContextReducer';

interface Props {
  children: JSX.Element;
}

interface UploadContextProviderValue extends UploadContextState {
  dispatch: (action: UploadContextAction) => void;
}

const UploadContextContext = React.createContext({
  ...initialState,
  dispatch: () => {
    // not implemented
  },
} as UploadContextProviderValue);

export const UploadContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(uploadContextReducer, {
    ...initialState,
  });

  return (
    <UploadContextContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </UploadContextContext.Provider>
  );
};

export const useUploadContext = () => {
  return useContext(UploadContextContext);
};
