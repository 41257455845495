import React, { useState, useEffect } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Input, NumericInput, SubmitButton } from '../../../../components/form';
import { BandInfo, ViewLayer } from '../../../../types';
import { Controller, UseFormReturn } from 'react-hook-form';
import { SvgIcon } from '@progress/kendo-react-common';
import { chevronLeftIcon } from '@progress/kendo-svg-icons';
import { Checkbox } from '@progress/kendo-react-inputs';

interface GeotiffPropertiesProps {
  layer: ViewLayer;
  form: UseFormReturn<any>;
}

const GeotiffProperties: React.FC<GeotiffPropertiesProps> = (props: GeotiffPropertiesProps) => {
  const [expandedBandsSections, setExpandedBandsSections] = useState<number[]>([]);
  const [expandedExtents, setExpandedExtents] = useState<boolean>(false);

  const toggleBandSection = (index: number) => {
    if (expandedBandsSections.includes(index)) {
      setExpandedBandsSections(expandedBandsSections.filter((item) => item !== index));
    } else {
      setExpandedBandsSections([...expandedBandsSections, index]);
    }
  };

  useEffect(() => {
    props.form.setValue('projection', props.layer?.paramsMap['projection']);
    props.form.setValue('defaultNodata', props.layer?.paramsMap['defaultNodata']);
    props.layer?.paramsMap.bands.forEach((band: BandInfo, index: number) => {
      props.form.setValue(`bands[${index}].index`, band.index);
      props.form.setValue(`bands[${index}].name`, band.name);
      props.form.setValue(`bands[${index}].currentMin`, band.currentMin);
      props.form.setValue(`bands[${index}].currentMax`, band.currentMax);
      props.form.setValue(`bands[${index}].datatype`, band.datatype);
      props.form.setValue(`bands[${index}].bits`, band.bits);
      props.form.setValue(`bands[${index}].min`, band.min);
      props.form.setValue(`bands[${index}].max`, band.max);
      props.form.setValue(`bands[${index}].mean`, band.mean);
      props.form.setValue(`bands[${index}].stddev`, band.stddev);
      props.form.setValue(`bands[${index}].nodata`, band.nodata);
      props.form.setValue(`bands[${index}].validPercent`, band.validPercent);
    });
    props.layer?.paramsMap.extents.forEach((extent: number, index: number) => {
      props.form.setValue(`extents[${index}]`, extent);
    });
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography.h3>Geotiff Layer Properties</Typography.h3>

      <div>
        <label>Projection: </label>
        <Controller
          control={props.form.control}
          name={'projection'}
          render={({ field, fieldState: { error } }: any) => {
            return <Input className="w-75" {...field} error={error?.message} type="text" />;
          }}
        />
      </div>

      <div>
        <label>Use Default NoData handling: </label>
        <Controller
          control={props.form.control}
          name={'defaultNodata'}
          render={({ field, fieldState: { error } }: any) => {
            return <Checkbox {...field} />;
          }}
        />
      </div>

      <div style={{ marginTop: '10px' }}>
        {props.layer?.paramsMap.bands.map((band: any, index: number) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <Typography.h5
              style={{ cursor: 'pointer' }}
              onClick={() => {
                toggleBandSection(index);
              }}
            >
              <SvgIcon
                icon={chevronLeftIcon}
                size="medium"
                className="animated-transform ml-auto mr-auto k-color-primary"
                style={{ transform: expandedBandsSections.includes(index) ? 'rotate(270deg)' : 'rotate(180deg)' }}
              />
              {` Band ${index + 1}`}
            </Typography.h5>

            {expandedBandsSections.includes(index) && (
              <div style={{ marginLeft: '20px' }}>
                <div>
                  <label>Min: </label>
                  <Controller
                    control={props.form.control}
                    name={`bands[${index}].min`}
                    render={({ field, fieldState: { error } }: any) => {
                      return (
                        <NumericInput
                          className="w-75"
                          {...field}
                          error={error?.message}
                          onChange={(e) => {
                            props.form.setValue(`bands[${index}].currentMin`, e.target.value);
                            props.form.setValue(`bands[${index}].min`, e.target.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
                <div>
                  <label>Max: </label>
                  <Controller
                    control={props.form.control}
                    name={`bands[${index}].max`}
                    render={({ field, fieldState: { error } }: any) => {
                      return (
                        <NumericInput
                          className="w-75"
                          {...field}
                          error={error?.message}
                          onChange={(e) => {
                            props.form.setValue(`bands[${index}].currentMax`, e.target.value);
                            props.form.setValue(`bands[${index}].max`, e.target.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
                <div>
                  <label>No Data: </label>
                  <Controller
                    control={props.form.control}
                    name={`bands[${index}].nodata`}
                    render={({ field, fieldState: { error } }: any) => {
                      return (
                        <NumericInput
                          className="w-75"
                          {...field}
                          error={error?.message}
                          onChange={(e) => {
                            props.form.setValue(`bands[${index}].nodata`, e.target.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
                {/* Add more fields as needed */}
              </div>
            )}
          </div>
        ))}
      </div>
      <div style={{ marginTop: '10px' }}>
        <Typography.h5
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setExpandedExtents(!expandedExtents);
          }}
        >
          <SvgIcon
            icon={chevronLeftIcon}
            size="medium"
            className="animated-transform ml-auto mr-auto k-color-primary"
            style={{ transform: expandedExtents ? 'rotate(270deg)' : 'rotate(180deg)' }}
          />
          Extents
        </Typography.h5>

        {expandedExtents && (
          <div style={{ marginLeft: '20px' }}>
            {props.layer?.paramsMap.extents.map((extent: number, index: number) => {
              if (index % 2 === 1) return null;
              return (
                <div key={index} style={{ display: 'flex' }}>
                  <div>
                    <label>Lat: </label>
                    <Controller
                      control={props.form.control}
                      name={`extents[${index}]`}
                      render={({ field, fieldState: { error } }: any) => {
                        return <NumericInput className="w-75" {...field} error={error?.message} />;
                      }}
                    />
                  </div>
                  <div>
                    <label>Long: </label>
                    <Controller
                      control={props.form.control}
                      name={`extents[${index + 1}]`}
                      render={({ field, fieldState: { error } }: any) => {
                        return <NumericInput className="w-75" {...field} error={error?.message} />;
                      }}
                    />
                  </div>
                </div>
              );
            })}
            {/* Add more fields as needed */}
          </div>
        )}
      </div>
    </div>
  );
};

export default GeotiffProperties;
