import _ from 'lodash';

interface Difference {
  [key: string]: {
    before: any;
    after: any;
  };
}

type AnyObject = Record<string, any>;

export const deepBeforeAndAfterDiff = (obj1: AnyObject, obj2: AnyObject): Difference => {
  const changes: Difference = {};

  const findChanges = (obj1: AnyObject, obj2: AnyObject, path: string[] = []): void => {
    if (_.isEqual(obj1, obj2)) {
      return;
    }
    if (_.isObject(obj1) && _.isObject(obj2) && !_.isArray(obj1) && !_.isArray(obj2)) {
      const keys = _.union(_.keys(obj1), _.keys(obj2));
      keys.forEach(key => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        findChanges(obj1[key] as AnyObject, obj2[key] as AnyObject, path.concat(key));
      });
    } else {
      changes[path.join('.')] = { before: obj1, after: obj2 };
    }
  };

  findChanges(obj1, obj2);

  return changes;
};

export const deepDiff = (obj1: AnyObject, obj2: AnyObject): AnyObject => {
  const changes: AnyObject = {};

  const findChanges = (obj1: AnyObject, obj2: AnyObject, changes: AnyObject): void => {
    if (_.isEqual(obj1, obj2)) {
      return;
    }
    if (_.isObject(obj1) && _.isObject(obj2) && !_.isArray(obj1) && !_.isArray(obj2)) {
      const keys = _.union(_.keys(obj1), _.keys(obj2));
      keys.forEach(key => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!_.isEqual(obj1[key], obj2[key])) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (_.isObject(obj1[key]) && _.isObject(obj2[key]) && !_.isArray(obj1[key]) && !_.isArray(obj2[key])) {
            changes[key] = {};
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            findChanges(obj1[key], obj2[key], changes[key]);
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            changes[key] = obj2[key];
          }
        }
      });
    } else {
      changes = obj2;
    }
  };

  findChanges(obj1, obj2, changes);

  return changes;
};