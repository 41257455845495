import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Avatar } from '@progress/kendo-react-layout';
import { Typography } from '@progress/kendo-react-common';
import { useUser } from '../../hooks/authentication';
import { Icon } from '../../components/layout';

const AccountSettingsContainer: React.FC = () => {
  const location = useLocation();
  const { getUser, userHasAuthority } = useUser();
  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column h-10 p-3">
      <div className="container col-12 col-lg-10">
        <div className="p-3 flex-column d-flex align-items-center">
          <Avatar rounded="full" size="large" themeColor="secondary">
            <Icon iconClass="k-i-user" size="52px" color="var(--geosap-profile-icon-color)"></Icon>
          </Avatar>
          <Typography.p fontSize="xsmall" className="mb-2">
            {getUser().email}
          </Typography.p>
          <div className="w-100 d-flex align-items-center justify-content-between">
            <span
              onClick={() => {
                navigate('organizations');
              }}
              style={{
                cursor: 'pointer',
                color:
                  '/management/organizations' === location.pathname
                    ? 'var(--geosap-primary-color)'
                    : 'var(--geosap-secondary-color)',
                fontWeight: '/management/organizations' === location.pathname ? 'bold' : 'normal',
                fontSize: '/management/organizations' === location.pathname ? '1.1rem' : '1rem',
              }}
            >
              Organizations
            </span>
            <span
              onClick={() => {
                navigate('users');
              }}
              style={{
                cursor: 'pointer',
                color:
                  '/management/users' === location.pathname
                    ? 'var(--geosap-primary-color)'
                    : 'var(--geosap-secondary-color)',
                fontWeight: '/management/users' === location.pathname ? 'bold' : 'normal',
                fontSize: '/management/users' === location.pathname ? '1.1rem' : '1rem',
              }}
            >
              Users
            </span>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsContainer;
