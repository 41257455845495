import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { Typography } from '@progress/kendo-react-common';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Loader } from '@progress/kendo-react-indicators';
import { SvgIcon } from '@progress/kendo-react-common';
import { chevronLeftIcon, infoCircleIcon, moreVerticalIcon, shareIcon, gearIcon } from '@progress/kendo-svg-icons';
import { Select, ShareResourceButton, SubmitButton } from '../../../components/form';
import {
  AuthorityLevel,
  AuthorityType,
  GenericLayerType,
  LayerType,
  Measurement2D,
  TypeToGenericMap,
  UserRole,
  ViewLayer,
  ViewLayerState,
} from '../../../types';
import { useConsumeViewerState } from '../../../context/viewer';
import { useNavigate } from 'react-router-dom';
import { RestrictedSubmitButton } from '../../../components/restricted';
import { TooltipIcon } from '../../../components/feedback';
import { useUser } from '../../../hooks/authentication';
import ViewerPropertiesModal from './ViewerPropertiesModal';
import LayerPropertiesModal from './LayerProperties';
import ContextMenu from './ContextMenu';
import AddLayerModal from './AddLayer/AddLayerModal';
import RenameLayerModal from './RenameLayerModal';
import DeleteLayerModal from './DeleteLayerModal';
import { getWfsPreviewLayerLegendUrl } from '../../../common/viewLayerHelper';
import { WrappedOrEllipsisSpan } from '../../../components';
import Tooltipped from '../../../components/Tooltipped';
import IconButton from '../../../components/form/IconButton';
import { updateLayerParam } from '../../../common/viewerConfigHelper';

const LayersContainer = styled.div`
  background: white;
  width: var(--geosap-viewer-layers-width);
`;

const LayerElement = styled.div`
  position: relative;
  border-radius: 4px;
  border: 1px solid var(--geosap-steps-background);
  color: var(--geosap-steps-background);
  background: white;
  padding: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  font-size: 0.85rem;
  cursor: pointer;
`;

interface LayersToolbarProps {
  onLayerUpdated?: (layer: ViewLayer, persistent?: boolean, immediate?: boolean) => void;
  onZoomToLayer(layer: any): void;
  onDeleteLayer(layer: any): void;
  onDownloadLayer(layer: any): void;
}

const LayersToolbar: React.FC<LayersToolbarProps> = (props: LayersToolbarProps) => {
  const {
    dispatch,
    viewConfig,
    viewConfigParams,
    layers,
    selectedLayer,
    availableBaseMaps,
    baseMap,
    layersToolbarVisible,
    viewConfigPropertiesOpened,
  } = useConsumeViewerState();
  const [sortedLayers, setSortedLayers] = React.useState<any>({});
  const [addingLayer, setAddingLayer] = React.useState(false);
  const [showProperties, setShowProperties] = React.useState(null);
  //const [showViewerConfigs, setShowViewerConfigs] = React.useState(false);
  const [contextMenuLayer, setContextMenuLayer] = React.useState(null);
  const [contextOffset, setContextOffset] = React.useState(null);
  const [renamingLayer, setRenamingLayer] = React.useState(null);
  const [deletingLayer, setDeletingLayer] = React.useState(null);
  const [layerLegendOpen, setLayerLegendOpen] = React.useState(null);
  const [isForeground, setIsForeground] = React.useState<boolean>(layersToolbarVisible);
  const prevLayersOpenedRef = useRef<boolean>();
  const { userHasAuthority, getViewAccessToken, getSharedFileNode, userHasRole } = useUser();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    if (prevLayersOpenedRef.current !== layersToolbarVisible) {
      if (layersToolbarVisible) {
        setTimeout(() => {
          setIsForeground(true);
        }, 200);
      } else {
        setIsForeground(false);
      }
    }
    prevLayersOpenedRef.current = layersToolbarVisible;
  }, [layersToolbarVisible]);

  useEffect(() => {
    if (layers) {
      // Initialized sorted array
      const newSortedLayers: any = {};
      Object.values(GenericLayerType).forEach((key) => {
        newSortedLayers[key] = [];
      });
      layers.forEach((layer: ViewLayer, index: number) => {
        const genType: GenericLayerType = TypeToGenericMap[layer.layerType];
        newSortedLayers[genType].push(layer);
        if (layer.layerType === LayerType.GSVEC) {
          setLayerLegendOpen(layer.id);
        }
      });
      Object.keys(newSortedLayers).forEach((key) => {
        if (!newSortedLayers[key].length) {
          delete newSortedLayers[key];
        } else {
          newSortedLayers[key] = newSortedLayers[key].sort((a: ViewLayer, b: ViewLayer) => {
            if (a.displayName < b.displayName) {
              return -1; // a should come before b in the sorted order
            }
            if (a.displayName > b.displayName) {
              return 1; // a should come after b in the sorted order
            }
            return 0; // a and b are equal
          });
        }
      });
      setSortedLayers(newSortedLayers);
    }
  }, [layers]);

  useEffect(() => {
    document.addEventListener('click', () => {
      if (contextMenuLayer) {
        setContextMenuLayer(null);
      }
    });
  }, [contextMenuLayer]);

  const extractLayerWarning = (layer: ViewLayer) => {
    // Shapefile should have a Projection associated
    if (layer.paramsMap && layer.paramsMap['error']) {
      return layer.paramsMap['error'];
    }
    if (layer.layerType === LayerType.ShapeFile) {
      // Shapefile should have a Projection associated
      if (!layer.paramsMap || !layer.paramsMap['projection']) {
        return 'Shapefile projection information is missing. EPSG:6590 is assumed.';
      }
    }
    if (layer.layerType === LayerType.GSRAS) {
      // Shapefile should have a Projection associated
      if (!layer.paramsMap || !layer.paramsMap['bands'] || layer.paramsMap['bands'].length === 0) {
        if (userHasRole(UserRole.ROLE_SYSTEMADMIN)) {
          return "Band information for this raster aren't available. Try cleaning/re-processing the FileNode.";
        } else {
          return "Band information for this raster aren't available.";
        }
      }
    }
    return null;
  };

  const renderLayerElement = (layer: ViewLayer, index: number) => {
    const baseMapLayer = layer.layerType === LayerType.BaseMap;
    const layerWarning = extractLayerWarning(layer);
    const selected: boolean = layer.id === selectedLayer?.id;
    return (
      <LayerElement
        key={index}
        style={{
          background: selected ? 'var(--geosap-selected)' : 'white',
          color: selected ? 'white' : 'var(--geosap-steps-background)',
          //height: baseMapLayer ? 'unset' : '60px',
        }}
        onClick={() => {
          if (layer !== selectedLayer) {
            dispatch({ type: 'SELECT_LAYER', payload: { layer } });
          }
        }}
      >
        {!baseMapLayer && (
          <ContextMenu
            layer={contextMenuLayer}
            index={index}
            offset={contextOffset}
            onRenameLayer={setRenamingLayer}
            onZoomToLayer={props.onZoomToLayer}
            onDeleteLayer={setDeletingLayer}
            onDownloadLayer={props.onDownloadLayer}
            onLayerProperties={(layer) => {
              setShowProperties(layer);
            }}
          />
        )}
        {!baseMapLayer && (
          <div
            style={{ position: 'absolute', right: '0px', width: '12px', height: '12px', marginTop: '-8px' }}
            onClick={(e) => {
              e.stopPropagation();
              setContextOffset({ left: e.clientX, top: e.clientY });
              setContextMenuLayer(layer);
              return true;
            }}
          >
            <SvgIcon icon={moreVerticalIcon} style={{ width: '100%', height: '100%' }} />
          </div>
        )}
        <div className="d-flex">
          <Checkbox
            className="layers-checkbox"
            label={''}
            value={layer.active}
            onChange={(e) => {
              layer.active = e.value;
              layer.lastUpdated = new Date().getTime();
              props.onLayerUpdated(layer);
            }}
          />
          <WrappedOrEllipsisSpan
            wrapped={true}
            style={{
              fontWeight: 'bold',
              paddingRight: '0.5rem',
              paddingLeft: '1rem',
              width: '85%',
            }}
          >
            {layer.displayName}
          </WrappedOrEllipsisSpan>
        </div>
        <div style={{ width: 'fit-content', marginLeft: 'auto' }}>
          <TooltipIcon
            show={layerWarning ? true : false}
            icon={infoCircleIcon}
            message={layerWarning}
            iconStyle={{ fontSize: '24px' }}
          ></TooltipIcon>
        </div>
        {layer.viewState === ViewLayerState.LOADING && (
          <div style={{ width: 'fit-content', marginLeft: 'auto' }}>
            <Loader size="small" type={'converging-spinner'} themeColor={selected ? 'light' : 'primary'} />
          </div>
        )}
        {baseMapLayer && (
          <Select
            name={'BaseMap'}
            value={baseMap}
            error={null}
            data={availableBaseMaps}
            dataItemKey="id"
            textField="displayName"
            placeholder=""
            loading={false}
            onChange={(e) => {
              dispatch({ type: 'CHANGE_BASE_MAP', payload: { baseMap: e.value } });
            }}
            onBlur={() => {
              //
            }}
            autoFocus
          />
        )}
        {layer.layerType === LayerType.WFS ||
          (layer.layerType === LayerType.GSVEC && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{ fontSize: '0.75rem', marginLeft: 'auto', marginTop: 'auto' }}
                onClick={() => {
                  if (layerLegendOpen === layer.id) {
                    setLayerLegendOpen(null);
                  } else {
                    setLayerLegendOpen(layer.id);
                  }
                }}
              >
                Legend
              </div>
              {layerLegendOpen === layer.id && (
                <img style={{ width: 'fit-content' }} src={getWfsPreviewLayerLegendUrl(layer, { bgColor: 0xff0000 })} />
              )}
            </div>
          ))}
        {/*!baseMapLayer && <span className="">Processed: 05/10/2022</span>*/}
        {/*!baseMapLayer && <span className="">Last edit: 05/10/2022</span>*/}
      </LayerElement>
    );
  };

  let backNavigationAllowed: boolean = viewConfig?.filenode?.id ? true : false;
  if (backNavigationAllowed && getViewAccessToken()) {
    // If the viewer is from a shared resource. Don't allow if we are already visualizing the shared ressource
    if (viewConfig.filenode.id === getSharedFileNode().id) {
      backNavigationAllowed = false;
    }
  }

  return (
    <LayersContainer
      className="d-flex flex-column position-absolute animated-left overflow-auto"
      style={{ left: layersToolbarVisible ? 0 : '-var(--geosap-viewer-layers-width)', top: 0, bottom: 0 }}
    >
      <div
        className="d-flex px-3 py-2 align-items-center"
        style={{ color: 'var(--geosap-navbar-selected-item-color)', background: 'var(--geosap-steps-background)' }}
      >
        {backNavigationAllowed && (
          <SvgIcon
            icon={chevronLeftIcon}
            size="large"
            style={{
              marginRight: '12px',
              cursor: 'pointer',
              marginLeft: '-8px',
            }}
            onClick={() => {
              if (getViewAccessToken()) {
                navigate(
                  '/shared-deliver/' + viewConfig?.filenode?.rootId + '?current=' + viewConfig?.filenode?.parentId
                );
              } else {
                navigate('/deliver/' + viewConfig?.filenode?.rootId + '?current=' + viewConfig?.filenode?.parentId);
              }
            }}
          />
        )}
        <Typography.p fontSize="small" className="mb-0">
          {viewConfig?.pageName}
        </Typography.p>
      </div>
      <RestrictedSubmitButton
        type="button"
        label="Add layer"
        uppercase={false}
        full={false}
        //disabled={!viewConfig?.project?.id}
        onClick={() => {
          setAddingLayer(true);
        }}
        loading={false}
        className="mx-3 mt-2 font-exo"
        style={{ fontSize: '1rem', padding: '0.75rem 2rem', fontWeight: 500 }}
        authorityType={AuthorityType.VIEWER_AUTHORITY}
        authorityLevel={AuthorityLevel.UPDATE}
      />
      {Object.keys(sortedLayers).map((sortedLayerType) => {
        return (
          <div key={sortedLayerType} className="d-flex flex-column px-3 py-2">
            <Typography.p fontSize="small" className="mb-0" style={{ color: 'var(--geosap-steps-background)' }}>
              {sortedLayerType}
            </Typography.p>
            {sortedLayers[sortedLayerType].map((layer: ViewLayer, index: number) => renderLayerElement(layer, index))}
          </div>
        );
      })}
      {/*<ItemNodeUploadModal
        show={addingLayer}
        handleClose={() => {
          setAddingLayer(false);
        }}
        transactionId={props.transactionId}
        projectId={props.projectId}
      />*/}
      {viewConfigPropertiesOpened && (
        <ViewerPropertiesModal
          show={viewConfigPropertiesOpened}
          onViewerUpdated={() => {
            console.log();
          }}
          handleClose={() => dispatch({ type: 'CHANGE_VIEW_CONFIG_PROPERTIES_OPENED', payload: { opened: false } })}
        />
      )}
      {showProperties && (
        <LayerPropertiesModal
          layer={showProperties}
          show={showProperties !== null}
          onLayerUpdated={props.onLayerUpdated}
          handleClose={() => setShowProperties(null)}
        />
      )}
      <AddLayerModal
        show={addingLayer}
        handleDone={() => {
          if (viewConfig.filenode) {
            queryClient.invalidateQueries(['fileNodeView', viewConfig.filenode.id], { refetchInactive: true });
          } else if (viewConfig.transaction) {
            queryClient.invalidateQueries(['sapFlowView', viewConfig.transaction.id], { refetchInactive: true });
          }
          setAddingLayer(false);
        }}
        handleClose={() => {
          setAddingLayer(false);
        }}
        viewerConfigId={viewConfig?.id}
      />
      <RenameLayerModal
        show={renamingLayer !== null}
        handleClose={() => {
          setRenamingLayer(null);
        }}
        defaultValue={renamingLayer?.displayName}
        onConfirm={(value: string) => {
          renamingLayer.displayName = value;
          renamingLayer.lastUpdated = new Date().getTime();
          props.onLayerUpdated(renamingLayer, true, true);
          setRenamingLayer(null);
        }}
      />
      <DeleteLayerModal
        show={deletingLayer !== null}
        handleClose={() => {
          setDeletingLayer(null);
        }}
        defaultValue={deletingLayer?.displayName}
        onConfirm={() => {
          props.onDeleteLayer(deletingLayer);
          setDeletingLayer(null);
        }}
      />
    </LayersContainer>
  );
};

export default LayersToolbar;
