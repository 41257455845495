export enum AuthorityType {
    VIEWER_AUTHORITY = "VIEWER_AUTHORITY",
    TRANSACTION_AUTHORITY = "TRANSACTION_AUTHORITY",
    CONFIG_AUTHORITY = "CONFIG_AUTHORITY",
    USER_AUTHORITY = "USER_AUTHORITY",
    ORG_AUTHORITY = "ORG_AUTHORITY",
    SYSTEM_AUTHORITY = "SYSTEM_AUTHORITY",
    PROJECT_AUTHORITY = "PROJECT_AUTHORITY",
    DATA_AUTHORITY = "DATA_AUTHORITY"
}

export enum AuthorityLevel {
    CREATE = "CREATE",
    READ = "READ",
    UPDATE = "UPDATE",
    DELETE = "DELETE",
    EXECUTE = "EXECUTE",
    SHARE = "SHARE"
}

export interface Authority {
    canCreate: boolean,
    canRead: boolean,
    canUpdate: boolean,
    canDelete: boolean,
    canShare: boolean,
    canExecute: boolean,
    permissionType: AuthorityType,
}