import React from 'react';
import styled from 'styled-components';

interface Props {
  iconClass: string;
  size?: string;
  color?: string;
  onClick?: VoidFunction;
}

const SpanIcon = styled.span<Omit<Props, 'iconClass'>>`
  color: ${(props) => props.color || 'unset'};
  font-size: ${(props) => props.size || 'unset'};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'unset')};
  &:hover {
    opacity: ${(props) => (props.onClick ? '0.5' : 'unset')};
  }
`;

const Icon: React.FC<Props> = ({ iconClass, onClick, ...spanProps }) => {
  return <SpanIcon {...spanProps} className={`k-icon ${iconClass}`} onClick={onClick}></SpanIcon>;
};

export default Icon;
