import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {loadStripe} from '@stripe/stripe-js';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { useAppContext } from '../../context/app';
import { useUser } from '../../hooks/authentication';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const SubscriptionReturn: React.FC = () => {
  const { getCurrentOrganization } = useUser();
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [retrievingPaymentIntent, setRetrievingPaymentIntent] = React.useState<boolean>(true);
  const [paymentIntentStatus, setPaymentIntentStatus] = React.useState<string>("");

  useEffect(()=>{
    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
    );
  
    // Retrieve the PaymentIntent
    stripePromise.then((stripe)=>{
        stripe.retrievePaymentIntent(clientSecret).then((paymentIntentContainer: any) => { //https://stripe.com/docs/payments/payment-methods#payment-notification
            const paymentIntent: any = paymentIntentContainer.paymentIntent;
            setRetrievingPaymentIntent(false);

            setPaymentIntentStatus(paymentIntent.status);
            switch (paymentIntent.status) {
              case 'succeeded':
                dispatch({
                  type: 'SHOW_NOTIFICATION',
                  payload: { notification: { type: 'success', content: 'Subscription actived.' } },
                });
                queryClient.invalidateQueries(['organization', getCurrentOrganization().id], { refetchInactive: true });
                break;
            
              case 'requires_payment_method':
                dispatch({
                  type: 'SHOW_NOTIFICATION',
                  payload: { notification: { type: 'error', content: 'Problem occured during subscription billing.' } },
                });
                //queryClient.invalidateQueries(['projects'], { refetchInactive: true });
                break;
            
              default:
                //message.innerText = 'Something went wrong.';
                break;
            }
            setTimeout(()=>{
              navigate("/settings/plans");
            },3000);
        });
    })
  }, []);

  return (

    <div className="container">
        <div className="row align-items-center justify-content-center" style={{ paddingTop: '4rem' }}>
            {retrievingPaymentIntent && 
              <div>
                <Skeleton shape="rectangle" className="w-100" style={{ height: '600px' }} />
              </div>
            }
            {!retrievingPaymentIntent && paymentIntentStatus === "succeeded" &&
              <div>
                <Typography.h3>Thank you for purchasing GeoSAP services.</Typography.h3>
                <Typography.h4>You will be redirected to your organization page promptly.</Typography.h4>
              </div>
            }
            {!retrievingPaymentIntent && paymentIntentStatus === "processing" &&
              <div>
                <Typography.h3>Payment is being processed. You will be notified upon success and activation.</Typography.h3>
                <Typography.h4>You will be redirected to your organization page promptly.</Typography.h4>
              </div>
            }
            {!retrievingPaymentIntent && paymentIntentStatus === "payment_failed" &&
              <div>
                <Typography.h3>Payment failed. Please try again later.</Typography.h3>
                <Typography.h4>You will be redirected to your organization page promptly.</Typography.h4>
              </div>
            }
            {!retrievingPaymentIntent && paymentIntentStatus === "requires_payment_method" &&
              <div>
                <Typography.h3>We are missing billing information to complete the transaction.</Typography.h3>
                <Typography.h3>Please contact administrator if this problem persist.</Typography.h3>
                <Typography.h4>You will be redirected to your organization page promptly.</Typography.h4>
              </div>
            }
        </div>
    </div>
  );
};

export default SubscriptionReturn;
