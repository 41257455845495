import { useMutation } from 'react-query';
import { useAxiosInstance } from '../common';

const endpoint = '/stripe/cancel_subscription';

const useCancelStripeSubscription = () => {
  const axios = useAxiosInstance();

  return useMutation(async () => { 
    const response = await axios.post<any>(endpoint);
    if (response) {
        return response.data;
    }
  });
};

export default useCancelStripeSubscription;