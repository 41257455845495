import React, { useEffect } from 'react';
import { Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { useAppContext } from '../../context/app';

let timeoutId: number;

const defaultSuccessMessage = 'Success';
const defaultErrorMessage = 'Unexpected application error. If error persists, please contact support.';

const AppNotification: React.FC = () => {
  const { notification, dispatch } = useAppContext();

  const handleClose = () => dispatch({ type: 'CLEAR_NOTIFICATION' });

  useEffect(() => {
    if (notification && notification.type === 'success') {
      clearTimeout(timeoutId);
      timeoutId = window.setTimeout(handleClose, 6000);
    }
  }, [notification]);

  if (!notification) {
    return null;
  }

  const getNotificationContent = () => {
    if (notification.content) {
      return notification.content;
    }
    if (notification.type === 'success') {
      return defaultSuccessMessage;
    }
    return defaultErrorMessage;
  };
  return (
    <Fade className="position-fixed top-0 end-0 mt-2 me-3">
      <Notification type={{ style: notification.type, icon: true }} closable={true} onClose={handleClose}>
        {getNotificationContent()}
      </Notification>
    </Fade>
  );
};

export default AppNotification;
