import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { GridColumn } from '@progress/kendo-react-grid';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StyledGrid } from '../../components/styled';
import { EServerType, DeploymentServer, ServerTask } from '../../types';
import { useServers } from '../../hooks/system';
import ServerTasks from './ServerTasks';

const Servers: React.FC = () => {
  const [gsServers, setGsServers] = useState([]);
  const [rootsServers, setRootsServers] = useState([]);
  const [foliageServers, setFoliageServers] = useState([]);
  const [imageServers, setImageServers] = useState([]);
  const [commServers, setCommServers] = useState([]);
  const [dataProcServers, setDataProcServers] = useState([]);
  const serversQuery = useServers();

  useEffect(() => {
    if (serversQuery.isSuccess) {
      setGsServers(
        serversQuery.data
          .filter((serverData: DeploymentServer) => serverData.geosapServerEnabled)
          .map((serverData: DeploymentServer) => ({
            id: serverData.id,
            name: serverData.machineName,
            type: EServerType.GS_SERVER,
            address: serverData.inetAddress,
            status: serverData.geosapServerOnline,
            expandable: true,
            expanded: false,
          }))
      );
      setRootsServers(
        serversQuery.data
          .filter((serverData: DeploymentServer) => serverData.rootsServerEnabled)
          .map((serverData: DeploymentServer) => ({
            id: serverData.id,
            name: serverData.machineName,
            type: EServerType.GS_ROOTS_SERVER,
            address: serverData.inetAddress,
            status: serverData.rootsServerOnline,
            expandable: true,
          }))
      );
      setFoliageServers(
        serversQuery.data
          .filter((serverData: DeploymentServer) => serverData.foliageServerEnabled)
          .map((serverData: DeploymentServer) => ({
            id: serverData.id,
            name: serverData.machineName,
            type: EServerType.GS_FOLIAGE_SERVER,
            address: serverData.inetAddress,
            status: serverData.foliageServerOnline,
            expandable: true,
          }))
      );
      setImageServers(
        serversQuery.data
          .filter((serverData: DeploymentServer) => serverData.imageServerEnabled)
          .map((serverData: DeploymentServer) => ({
            id: serverData.id,
            name: serverData.machineName,
            type: EServerType.GS_IMAGE_SERVER,
            address: serverData.inetAddress,
            status: serverData.imageServerOnline,
            expandable: true,
          }))
      );
      setCommServers(
        serversQuery.data
          .filter((serverData: DeploymentServer) => serverData.communicatorServerEnabled)
          .map((serverData: DeploymentServer) => ({
            id: serverData.id,
            name: serverData.machineName,
            type: EServerType.GS_COMMUNICATOR_SERVER,
            address: serverData.inetAddress,
            status: serverData.communicatorServerOnline,
            expandable: true,
          }))
      );
      setDataProcServers(
        serversQuery.data
          .filter((serverData: DeploymentServer) => serverData.dataProcServerEnabled)
          .map((serverData: DeploymentServer) => ({
            id: serverData.id,
            name: serverData.machineName,
            type: EServerType.GS_DATA_PROCESSING_SERVER,
            address: serverData.inetAddress,
            status: serverData.dataProcServerOnline,
            expandable: true,
          }))
      );
    }
  }, [serversQuery.isSuccess, serversQuery.data]);

  const renderServerData = (title: string, serverData: any[], setServerData: (newData: any[]) => void) => {
    return (
      <div style={{ paddingTop: '1rem' }}>
        <Typography.h6 fontWeight="normal" className="text-uppercase">
          {title}
        </Typography.h6>
        <StyledGrid
          data={serverData}
          pageable
          sortable
          className="mt-3"
          detail={ServerTasks}
          expandField="expanded"
          selectable={{ enabled: true }}
          onRowClick={({ dataItem }) => {
            const newServerData: any[] = JSON.parse(JSON.stringify(serverData));
            newServerData.forEach((data: any) => {
              if (data.id === dataItem.id) data.expanded = !data.expanded;
            });
            setServerData(newServerData);
            //
          }}
          rowRender={(trElement, props) => {
            const trProps: any = {
              style: { cursor: 'pointer' },
            };
            return React.cloneElement(
              trElement,
              {
                ...trProps,
              },
              trElement.props.children as any
            );
          }}
        >
          <GridColumn
            field="name"
            title="Machine"
            cell={({ dataItem }) => {
              const trans: any = dataItem;
              return <td className="fw-bold">{trans.name}</td>;
            }}
          />
          <GridColumn field="address" title="Address" width={170} />
          <GridColumn field="status" title="Status" width={170} />
        </StyledGrid>
      </div>
    );
  };

  return (
    <div className="container-fluid p-5">
      <Typography.h3 fontWeight="normal" className="text-uppercase" style={{ paddingBottom: '1rem' }}>
        Servers
      </Typography.h3>
      {serversQuery.isLoading && <Skeleton shape="rectangle" style={{ height: '330px' }} />}
      {!serversQuery.isLoading && serversQuery.data.length === 0 && (
        <Typography.h3>No data available for GeoSAP Servers</Typography.h3>
      )}
      {!serversQuery.isLoading && gsServers.length > 0 && renderServerData('GS-Servers', gsServers, setGsServers)}
      {!serversQuery.isLoading &&
        dataProcServers.length > 0 &&
        renderServerData('DataProc', dataProcServers, setDataProcServers)}
      {!serversQuery.isLoading &&
        rootsServers.length > 0 &&
        renderServerData('Roots Servers', rootsServers, setRootsServers)}
      {!serversQuery.isLoading &&
        foliageServers.length > 0 &&
        renderServerData('Foliage Servers', foliageServers, setFoliageServers)}
      {!serversQuery.isLoading &&
        imageServers.length > 0 &&
        renderServerData('Image Servers', imageServers, setImageServers)}
      {!serversQuery.isLoading &&
        commServers.length > 0 &&
        renderServerData('Communicator Servers', commServers, setCommServers)}
    </div>
  );
};

export default Servers;
