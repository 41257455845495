import { useQueryClient, useMutation } from 'react-query';
import { useAxiosInstance } from '../common';

interface DeleteParams {
  fileNodeId: string,
  rootId: string
}

const useForgetFileNode = (ordId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: DeleteParams) => {
      const response = await axios.post("filenode/" + params.fileNodeId + "/forget");
      return response.data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['rootStructure', ordId], { refetchInactive: true });
        queryClient.invalidateQueries(['fileNodeStructure', variables.rootId], { refetchInactive: true });
      },
    }
  );
};

export default useForgetFileNode;