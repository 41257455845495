import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { useConfirmEmail } from '../hooks/authentication';
import { useAppContext } from '../context/app';
import { SubmitButton } from '../components/form';

const ConfirmEmail: React.FC = () => {
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const useConfirmEmailMutation = useConfirmEmail();

  useEffect(() => {
    console.log('Activation code: ' + queryParameters.get('activationCode'));
    if (queryParameters.get('activationCode')) {
      useConfirmEmailMutation.mutate({ confirmation_token: queryParameters.get('activationCode') });
    } else {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { type: 'error', content: 'Activation Code invalid. Redirecting.' } },
      });
      //setTimeout(()=>{
      //    navigate("/login");
      //},1500);
    }
  }, [queryParameters.get('activationCode')]);

  useEffect(() => {
    if (useConfirmEmailMutation.isSuccess) {
      //setHandledCreationResponse(true);
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { type: 'success', content: 'Thank you for confirming your email.' } },
      });
      setTimeout(() => {
        navigate('/');
      }, 1500);
    } else if (useConfirmEmailMutation.isError) {
      //setHandledCreationResponse(true);
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { type: 'error', content: 'Activation Failed. Please contact administrator.' } },
      });
      //setTimeout(()=>{
      //    navigate("/login");
      //},1500);
    }
  }, [useConfirmEmailMutation.isSuccess, useConfirmEmailMutation.isError]);

  return (
    <div className="container-fluid p-2 p-lg-5">
      <div className="row">
        <Typography.h3>Confirm Email</Typography.h3>
      </div>
      <div className="row">
        <Typography.h5>We are confirming your email.</Typography.h5>
      </div>
      <SubmitButton label="Continue" loading={true} />
    </div>
  );
};

export default ConfirmEmail;
