import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Feature, Map } from 'ol';
import GeoJSON from 'ol/format/GeoJSON.js';
import MapContext from '../Map/MapContext';
import { useConsumeViewerState } from '../../../../context/viewer';
import {
  DrawingMode,
  LayerType,
  NavigationMode2D,
  SapFlowViewConfig,
  ViewLayer,
  ViewLayerParam,
} from '../../../../types';
import { Geometry } from 'ol/geom';
import { useViewLayerCreate } from '../../../../hooks/viewerconfig';
import MapContextData from '../Map/MapContextData';
import DrawingTool from './DrawingTool';

interface Props {
  show: boolean;
  zIndex: number;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
} | null;
const VCDrawingTool = forwardRef<Ref, Props>((props: Props, ref) => {
  const { map } = useContext<MapContextData>(MapContext);
  const { dispatch, viewConfig, mode2d, layers, selectedLayer } = useConsumeViewerState();
  const [drawingFeatures, setDrawingFeatures] = useState<Feature<Geometry>[]>([]);
  const viewLayerCreateMutation = useViewLayerCreate();
  const layersRef = useRef(layers);

  useEffect(() => {
    if (!map) return;
    layersRef.current = layers;

    const drawingLayers = layersRef.current.filter((layer) => layer.layerType === LayerType.Drawing2D && layer.active);
    let features: Feature<Geometry>[] = [];
    const writer = new GeoJSON();
    features = drawingLayers.map((drawingLayer: ViewLayer) => {
      if (!drawingLayer.paramsMap['geojson']) {
        // TODO
        //drawingLayer.viewState = ViewLayerState.ERRORED
      }
      const feature = writer.readFeature(drawingLayer.paramsMap['geojson']);
      feature.setProperties({
        id: drawingLayer.id,
        name: drawingLayer.displayName,
        selected: selectedLayer.id === drawingLayer.id,
      });
      return feature;
    });
    setDrawingFeatures(features);
  }, [map, layers, selectedLayer]);

  const handleNewDrawing = (feature: Feature) => {
    const writer = new GeoJSON();
    const geoJsonStr = writer.writeFeature(feature);
    const drawingLayers = layers.filter(
      (layer) =>
        layer.layerType === LayerType.Drawing2D && layer.paramsMap['geometryType'] === feature.getGeometry()?.getType()
    );

    let displayName = 'Drawing ' + drawingLayers.length + 1;
    if (feature.getGeometry()?.getType() === 'Point') {
      displayName = 'Point ' + (drawingLayers.length + 1);
    } else if (feature.getGeometry()?.getType() === 'LineString') {
      displayName = 'Line ' + (drawingLayers.length + 1);
    } else if (feature.getGeometry()?.getType() === 'Polygon') {
      displayName = 'Poly ' + (drawingLayers.length + 1);
    } else if (feature.getGeometry()?.getType() === 'Circle') {
      displayName = 'Circle ' + (drawingLayers.length + 1);
    }
    const params: ViewLayerParam[] = [
      {
        key: 'geojson',
        value: geoJsonStr,
      },
      {
        key: 'geometryType',
        value: feature.getGeometry()?.getType(),
      },
    ];
    viewLayerCreateMutation.mutateAsync({
      viewConfigId: viewConfig.id,
      params: {
        displayName: displayName,
        uri: null,
        layerType: LayerType.Drawing2D,
        active: true,
        params: params,
      },
    });
  };

  const zoomToLayer = (layer: any) => {
    //setZoomWhenReady(true);
    const matchingFeatures = drawingFeatures.filter((feature: Feature<Geometry>) => {
      const featureProperties = feature.getProperties();
      return featureProperties['id'] && featureProperties['id'] === layer.id;
    });
    if (matchingFeatures.length === 0) {
      return;
    }
    zoomToFeature(matchingFeatures[0]);
  };

  function zoomToFeature(feature: Feature) {
    // Get the geometry of the feature
    const geometry = feature.getGeometry();

    if (geometry) {
      // Get the extent of the feature's geometry
      const extent = geometry.getExtent();

      // Fit the map view to the extent of the feature
      map.getView().fit(extent, {
        padding: [50, 50, 50, 50], // Optional padding around the extent
        duration: 1000, // Optional animation duration in milliseconds
      });
    }
  }

  useImperativeHandle(ref, () => ({
    zoomToLayer,
  }));

  const getDrawingMode = (): DrawingMode => {
    if (mode2d === NavigationMode2D.POINT_DRAWING) {
      return DrawingMode.Point;
    } else if (mode2d === NavigationMode2D.LINESTRING_DRAWING) {
      return DrawingMode.LineString;
    } else if (mode2d === NavigationMode2D.POLYGON_DRAWING) {
      return DrawingMode.Polygon;
    } else if (mode2d === NavigationMode2D.CIRCLE_DRAWING) {
      return DrawingMode.Circle;
    }
    return null;
  };

  return (
    <DrawingTool
      {...props}
      active={getDrawingMode() ? true : false}
      drawingMode={getDrawingMode()}
      drawings={drawingFeatures}
      onDrawingAdded={handleNewDrawing}
    ></DrawingTool>
  );
});
VCDrawingTool.displayName = 'VCDrawingTool';

export default VCDrawingTool;
