import React, { useEffect, useState } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { SvgIcon } from '@progress/kendo-react-common';
import { shareIcon } from '@progress/kendo-svg-icons';
import { Dialog as ASD, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { RangeSlider, SliderLabel } from '@progress/kendo-react-inputs';
import { useConsumeViewerState } from '../../../context/viewer';
import SubmitButton from '../../../components/form/SubmitButton';
import Dialog from '../../../components/Dialog';
import { NumericInput, Select } from '../../../components/form';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { FeatureAttributeType } from '../../../types';

const rangeNumLabels = 5;

interface Props {
  visible: boolean;
  onClose: any;
  attributes: any;
}

const VectorAttributeFilter: React.FC<Props> = ({ visible, onClose, attributes }) => {
  const { dispatch, selectedLayer } = useConsumeViewerState();
  const [selectedAttribute, setSelectedAttribute] = useState<any>(null);
  const [selectedAttributeValue, setSelectedAttributeValue] = useState<string>(null);
  const [selectedAttributeRange, setSelectedAttributeRange] = useState<{ min: number; max: number }>(null);

  useEffect(() => {
    setSelectedAttribute(null);
    setSelectedAttributeValue(null);
    setSelectedAttributeRange(null);
  }, [attributes]);

  useEffect(() => {
    if (selectedAttribute) {
      setSelectedAttributeValue(null);
      if (
        selectedAttribute.type === FeatureAttributeType.REAL ||
        selectedAttribute.type === FeatureAttributeType.INTEGER
      ) {
        setSelectedAttributeRange({ min: selectedAttribute.min, max: selectedAttribute.max });
      } else {
        setSelectedAttributeRange(null);
      }
    }
  }, [selectedAttribute]);

  const renderAttributeOptions = (): JSX.Element => {
    if (!selectedAttribute) return null;
    else if (
      selectedAttribute.type === FeatureAttributeType.REAL ||
      selectedAttribute.type === FeatureAttributeType.INTEGER
    ) {
      const labelGap: number = (selectedAttribute.max - selectedAttribute.min) / (rangeNumLabels - 1);
      const labels: number[] = [];
      for (let i = 0; i < rangeNumLabels; i++) {
        if (selectedAttribute.type === FeatureAttributeType.INTEGER) {
          labels.push(Math.round(selectedAttribute.min + i * labelGap));
        } else {
          labels.push(selectedAttribute.min + i * labelGap);
        }
      }
      return (
        <div className={`w-100 d-flex flex-column align-items-center my-4`} style={{}}>
          <RangeSlider
            style={{ width: '100%' }}
            defaultValue={{
              start: selectedAttribute.min,
              end: selectedAttribute.max,
            }}
            step={10}
            min={selectedAttribute.min}
            max={selectedAttribute.max}
            value={{
              start: selectedAttributeRange?.min,
              end: selectedAttributeRange?.max,
            }}
            onChange={(e) => {
              if (selectedAttribute.type === FeatureAttributeType.INTEGER) {
                setSelectedAttributeRange({ min: Math.round(e.value.start), max: Math.round(e.value.end) });
              } else {
                setSelectedAttributeRange({ min: e.value.start, max: e.value.end });
              }
            }}
          >
            {labels.map((labelItems, i) => (
              <SliderLabel key={i} position={labelItems}>
                {labelItems.toString()}
              </SliderLabel>
            ))}
          </RangeSlider>
          <div className={`w-100 d-flex justify-content-around m-4`}>
            <NumericInput
              className="w-25"
              name=""
              label={'min'}
              placeholder={'few3w'}
              value={selectedAttributeRange?.min}
              onChange={(e) => {
                setSelectedAttributeRange({ min: e.value, max: selectedAttributeRange?.max });
              }}
            />
            <NumericInput
              className="w-25"
              name=""
              label={'max'}
              placeholder={'few3w'}
              value={selectedAttributeRange?.max}
              onChange={(e) => {
                setSelectedAttributeRange({ min: selectedAttributeRange?.min, max: e.value });
              }}
            />
          </div>
        </div>
      );
    } else if (selectedAttribute.type === FeatureAttributeType.STRING) {
      return (
        <Select
          name={'Attribute Value'}
          className="p-3"
          value={selectedAttributeValue}
          data={selectedAttribute.possibleValues}
          dataItemKey=""
          textField=""
          placeholder=""
          loading={false}
          onChange={(event: DropDownListChangeEvent) => {
            setSelectedAttributeValue(event.target.value);
          }}
          autoFocus
        ></Select>
      );
    }
  };

  const handleAttributeFilter = () => {
    dispatch({
      type: 'FILTER_FEATURES',
      payload: {
        layerId: selectedLayer.id,
        attribute: selectedAttribute,
        attributeValue: selectedAttributeValue,
        min: selectedAttributeRange?.min,
        max: selectedAttributeRange?.max,
      },
    });
  };

  return (
    <div>
      <Dialog
        show={visible}
        title={'Feature filter'}
        onClose={() => {
          onClose();
        }}
        style={{ width: '600px' }}
      >
        <p
          style={{
            margin: '25px',
            textAlign: 'center',
          }}
        >
          Select an atribute to filter features from:
        </p>
        <Select
          name={'Attribute'}
          className="p-3"
          value={selectedAttribute}
          data={attributes}
          dataItemKey="name"
          textField="name"
          placeholder=""
          loading={false}
          onChange={(event: DropDownListChangeEvent) => {
            setSelectedAttribute(event.target.value);
          }}
          autoFocus
        ></Select>
        {selectedAttribute && renderAttributeOptions()}
        <DialogActionsBar>
          <SubmitButton
            label="OK"
            themeColor="primary"
            onClick={() => {
              handleAttributeFilter();
              onClose();
            }}
          />
        </DialogActionsBar>
      </Dialog>
    </div>
  );
};

export default VectorAttributeFilter;
