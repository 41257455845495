import { useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../../common/';

interface Params {
  itemid: string;
}

const useDeleteTransactionItemNode = (transactionId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const result = await axios.delete<string>("/sapflow/itemnode/" + params.itemid);
      return result.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sapFlowView', transactionId], { refetchInactive: true });
      },
    }
  );
};

export default useDeleteTransactionItemNode;
