import { useQuery } from 'react-query';
import { useAxiosInstance } from '../../common';
import { ClientProject } from '../../../types';

const useFileNodeSapGroups = (orgId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['clientProjects', orgId], async () => {
    const result = await axios.get<ClientProject[]>("data_wrangling/projects");
    return result.data;
  });
};

export default useFileNodeSapGroups;
