import { useQuery } from 'react-query';
import { FileNode } from '../../types/DataDelivery';
import { useAxiosInstance } from '../common';

const useFilenodeStructure = () => {
  const axios = useAxiosInstance();
  return useQuery(['availableViewLayersFileNode'], async () => {
    const result = await axios.get<FileNode[]>('filenode/available_view_layers');
    return result.data;
  });
};

export default useFilenodeStructure;
