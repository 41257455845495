import React, { useReducer, useContext } from 'react';
import { SapFlowWizardState, initialState } from './sapFlowWizardState';
import { sapFlowWizardReducer, SapFlowWizardAction } from './sapFlowWizardReducer';

interface SapFlowWizardProviderValue extends SapFlowWizardState {
  dispatch: (action: SapFlowWizardAction) => void;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  value: SapFlowWizardProviderValue;
}

const SapFlowWizardContext = React.createContext({
  ...initialState,
  dispatch: () => {
    // not implemented
  },
} as SapFlowWizardProviderValue);

export const SapFlowWizardProvider: React.FC<Props> = ({ children, value }) => {
  return <SapFlowWizardContext.Provider value={value}>{children}</SapFlowWizardContext.Provider>;
};

/*
  Create Sap Flow Wizard state.
*/
export const useCreateSapFlowWizardState = (): SapFlowWizardProviderValue => {
  const [state, dispatch] = useReducer(sapFlowWizardReducer, {
    ...initialState,
  });
  return {
    ...state,
    dispatch,
  };
};

/*
  Consume Sap Flow Wizard state.
*/
export const useConsumeSapFlowWizardState = () => {
  return useContext(SapFlowWizardContext);
};
