import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useWebsocketClient } from '../../hooks/messaging';

interface Props {
  onClick: () => void;
}

const ServerStatus: React.FC<Props> = ({ onClick }) => {
  const { connected } = useWebsocketClient();
  const wsConnectedQuery = useQuery(
    'websocketConnected',
    () => {
      return false;
    },
    { enabled: false }
  );

  const renderServerStatus = () => {
    if (wsConnectedQuery.data) {
      return <div>🟢</div>;
    } else {
      return <div>🔴</div>;
    }
  };

  return <div onClick={onClick}>{renderServerStatus()}</div>;
};

export default ServerStatus;
