import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { CaretDownFill, CaretRightFill } from 'react-bootstrap-icons';
import { Typography } from '@progress/kendo-react-common';
import { GridColumn, GridDetailRowProps, GridCellProps, getSelectedState } from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-react-common';
import {
  ItemNodeFilesDetails,
  SapFlowProcessNodeStatus,
  SapFlowTransaction,
  SapFlowTransactionStatus,
} from '../../types';
import { useTable } from '../../hooks/common';
import { StyledGrid } from '../../components/styled';
import { formatSizeUnits } from '../../converters/formatSizeUnits';
import { v4 as uuidv4 } from 'uuid';
import { SapFlow, SapFlowStatus } from '../../types/DataDelivery';
const idGetter = getter('id');

interface RowModel {
  id: string;
  name: string;
  format: string;
  fileSize: string;
  date: string;
  status: string;
  expanded: boolean;
  items: RowModelItem[];
}

type RowModelItem = Omit<RowModel, 'items' | 'expanded'>;

const StyledFilesGrid = styled(StyledGrid)`
  .k-detail-row {
    background: var(--geosap-light);
  }
`;

interface Props {
  statusHistory: (SapFlowTransactionStatus | SapFlowStatus | SapFlowProcessNodeStatus)[];
}

const Logs: React.FC<Props> = ({ statusHistory }) => {
  const [data, setData] = useState<(SapFlowTransactionStatus | SapFlowStatus | SapFlowProcessNodeStatus)[]>([]);
  const [selectedState, setSelectedState] = React.useState<any>({});
  const onSelectionChange = React.useCallback(
    (event: any) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: 'id',
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  useEffect(() => {
    if (statusHistory) {
      setData(
        statusHistory.map((log) => {
          log.selected = false;
          return log;
        })
      );
    } else {
      setData([]);
    }
  }, [statusHistory]);

  const tableProps = useTable({
    pageSize: 10,
    data: data.map((item) => ({
      ...item,
      ['selected']: selectedState[idGetter(item)],
    })),
    sortDescriptor: { field: 'created', dir: 'desc' },
  });

  return (
    <div>
      <Typography.h4>Sap Flow Logs</Typography.h4>
      <StyledFilesGrid
        {...tableProps}
        pageable
        sortable
        dataItemKey="id"
        selectedField={'selected'}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: 'single',
        }}
        onSelectionChange={onSelectionChange}
        rowRender={(trElement, props) => {
          let trProps: any = null;
          trProps = {
            style: { cursor: 'pointer', textWrap: 'balance' },
          };
          return React.cloneElement(
            trElement,
            {
              ...trProps,
            },
            trElement.props.children as any
          );
        }}
      >
        <GridColumn field="statusTime" title="Date" />
        <GridColumn
          field="statusMessage"
          title="Message"
          cell={({ dataItem }: any) => (
            <td style={{ textWrap: dataItem.selected ? 'balance' : 'nowrap' } as any}>{dataItem.statusMessage}</td>
          )}
        />
      </StyledFilesGrid>
    </div>
  );
};

export default Logs;
