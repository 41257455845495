declare const proj4: any;

const useGeolocation = () => {
  function geolocationAvailable() {
    return 'geolocation' in navigator;
  }

  function getCurrentPosition() {
    return new Promise((resolve, reject) => {
      try {
        navigator.geolocation.getCurrentPosition((position) => {
          resolve(position.coords);
        });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  function getProjectedLocation(potreeProj: string) {
    return new Promise((resolve, reject) => {
      try {
        navigator.geolocation.getCurrentPosition((position) => {
          const source = proj4.defs('EPSG:4326');
          const toSceneTransform = proj4(source, potreeProj);
          const coords = toSceneTransform.forward([position.coords.longitude, position.coords.latitude]);
          resolve(coords);
        });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  return {
    geolocationAvailable,
    getCurrentPosition,
    getProjectedLocation,
  };
};

export default useGeolocation;
