declare const window: any;
declare const proj4: any;

export const threeToLeafletPosition = (pos: any) => {
    const xy = [pos.x, pos.y];
    const deg = proj4(
      '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +units=ft +no_defs',
      '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs'
    ).forward(xy);

    return deg;
};
