import React from 'react';
import { AuthorityType, AuthorityLevel } from '../../types';
import { useUser } from '../../hooks/authentication';

interface Props extends React.HTMLProps<HTMLDivElement> {
  authorityType: AuthorityType;
  authorityLevel: AuthorityLevel;
}

const RestrictedDiv: React.FC<Props> = (props) => {
  const { userHasAuthority } = useUser();

  // Destructuring to separate custom props and div element props
  const { authorityType, authorityLevel, children, ...divProps } = props;

  if (!userHasAuthority(authorityType, authorityLevel)) {
    return null;
  }

  return <div {...divProps}>{children}</div>;
};

export default RestrictedDiv;
