import React, { useState, useRef, useEffect } from 'react';
import { Popup, Offset } from '@progress/kendo-react-popup';
import { Menu, MenuItemModel } from '@progress/kendo-react-layout';
import { Unit } from '../../../types';

interface ProjectionContextMenuProps {
  offset: Offset;
  onUnitsSelected(unit: Unit): void;
}

const ProjectionContextMenu: React.FC<ProjectionContextMenuProps> = (props: ProjectionContextMenuProps) => {
  const availableOptions: MenuItemModel[] = [];
  Object.values(Unit).forEach((unitType: Unit) => {
    availableOptions.push({
      text: unitType,
      data: {
        action: () => {
          props.onUnitsSelected(unitType);
        },
      },
    });
  });

  return (
    <Popup show={props.offset !== null} offset={props.offset} popupClass={'popup-content'}>
      <Menu
        vertical={true}
        style={{ display: 'inline-block' }}
        items={availableOptions}
        onSelect={({ item, itemId, nativeEvent, target }) => {
          item.data.action();
        }}
      ></Menu>
    </Popup>
  );
};

export default ProjectionContextMenu;
