export const GEOSAP_PLAN_BASIC = "GEOSAP_BASIC"
export const GEOSAP_PLAN_PRO = "GEOSAP_PRO"
export const GEOSAP_PLAN_ENTREPRISE = "GEOSAP_ENTREPRISE"
export const GEOSAP_PLANS = {
  [GEOSAP_PLAN_BASIC]: {
    productId: process.env.REACT_APP_STRIPE_BASIC_PRODUCT,
    priceId: process.env.REACT_APP_STRIPE_BASIC_PRICE,
    title: "GeoSAP Basic",
    description: "1 User\n10GB of processing\n200GB storage",
    price: "$250/Month",
  },
  [GEOSAP_PLAN_PRO]: {
    productId: process.env.REACT_APP_STRIPE_PRO_PRODUCT,
    priceId: process.env.REACT_APP_STRIPE_PRO_PRICE,
    title: "GeoSAP Pro",
    description: "1 User\n100GB of processing\n1TB storage",
    price: "$600/Month",
  },
  [GEOSAP_PLAN_ENTREPRISE]: {
    productId: process.env.REACT_APP_STRIPE_ENTREPRISE_PRODUCT,
    priceId: "",
    title: "GeoSAP Entreprise",
    description: "Unlimited Users\n Unlimited Processing\nUnlimited Storage",
    price: "Contact Us For Pricing",
  },
}