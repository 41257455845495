import React, { useEffect, useState } from 'react';
import { RangeSlider, Slider, SliderLabel } from '@progress/kendo-react-inputs';
import { BandInfo, LayerType, ViewLayer, BandType } from '../../../../types';
import { updateLayerParam } from '../../../../common/viewerConfigHelper';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { RasterVisualization } from '../../../../types/Rendering';
import { DropDownList } from '@progress/kendo-react-dropdowns';

interface OLGeotifControlsProps {
  layer: ViewLayer;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

const RgbControls: React.FC<OLGeotifControlsProps> = ({ layer, onLayerUpdated }) => {
  const [initialized, setInitialized] = useState(false);
  const [availableBands, setAvailableBands] = useState<BandInfo[]>([]);
  const [currentRedBandIndex, setCurrentRedBandIndex] = useState(0);
  const [currentGreenBandIndex, setCurrentGreenBandIndex] = useState(1);
  const [currentBlueBandIndex, setCurrentBlueBandIndex] = useState(2);
  const [currentGamma, setCurrentGamma] = useState(1);
  const [currentBrightness, setCurrentBrightness] = useState(0.5);
  const [currentContrast, setCurrentContrast] = useState(1);
  const [redMin, setRedMin] = useState(0);
  const [redMax, setRedMax] = useState(0);
  const [currentRedMin, setCurrentRedMin] = useState(0);
  const [currentRedMax, setCurrentRedMax] = useState(0);
  const [greenMin, setGreenMin] = useState(0);
  const [greenMax, setGreenMax] = useState(0);
  const [currentGreenMin, setCurrentGreenMin] = useState(0);
  const [currentGreenMax, setCurrentGreenMax] = useState(0);
  const [blueMin, setBlueMin] = useState(0);
  const [blueMax, setBlueMax] = useState(0);
  const [currentBlueMin, setCurrentBlueMin] = useState(0);
  const [currentBlueMax, setCurrentBlueMax] = useState(0);

  useEffect(() => {
    if (layer) {
      const bands: BandInfo[] = layer.paramsMap['bands'];
      if (!bands) {
        return;
      }
      setAvailableBands(bands);
      const visualization: RasterVisualization = layer.paramsMap['visualization'];
      const rgbVis = visualization.rgb;
      const currentRedIndex =
        rgbVis.redBandIndex !== null && rgbVis.redBandIndex !== undefined ? rgbVis.redBandIndex - 1 : 0;
      const currentGreenIndex =
        rgbVis.greenBandIndex !== null && rgbVis.greenBandIndex !== undefined ? rgbVis.greenBandIndex - 1 : 1;
      const currentBlueIndex =
        rgbVis.blueBandIndex !== null && rgbVis.blueBandIndex !== undefined ? rgbVis.blueBandIndex - 1 : 2;
      setCurrentRedBandIndex(currentRedIndex);
      setCurrentGreenBandIndex(currentGreenIndex);
      setCurrentBlueBandIndex(currentBlueIndex);
      const getVisOrDefaultValue = (property: string, defaultValue: number) => {
        return rgbVis[property] !== null && rgbVis[property] !== undefined ? rgbVis[property] : defaultValue;
      };

      setRedMin(bands[currentRedIndex].min);
      setRedMax(bands[currentRedIndex].max);
      setCurrentRedMin(getVisOrDefaultValue('redBandCurrentMin', bands[currentRedIndex].currentMin));
      setCurrentRedMax(getVisOrDefaultValue('redBandCurrentMax', bands[currentRedIndex].currentMax));
      setGreenMin(bands[currentGreenIndex].min);
      setGreenMax(bands[currentGreenIndex].max);
      setCurrentGreenMin(getVisOrDefaultValue('greenBandCurrentMin', bands[currentGreenIndex].currentMin));
      setCurrentGreenMax(getVisOrDefaultValue('greenBandCurrentMax', bands[currentGreenIndex].currentMax));
      setBlueMin(bands[currentBlueIndex].min);
      setBlueMax(bands[currentBlueIndex].max);
      setCurrentBlueMin(getVisOrDefaultValue('blueBandCurrentMin', bands[currentBlueIndex].currentMin));
      setCurrentBlueMax(getVisOrDefaultValue('blueBandCurrentMax', bands[currentBlueIndex].currentMax));
      if (rgbVis.gamma !== null && rgbVis.gamma !== undefined) setCurrentGamma(rgbVis.gamma);
      if (rgbVis.brightness !== null && rgbVis.brightness !== undefined) setCurrentBrightness(rgbVis.brightness);
      if (rgbVis.contrast !== null && rgbVis.contrast !== undefined) setCurrentContrast(rgbVis.contrast);
      setInitialized(true);
    }
  }, [layer]);

  const handleBandChange = (newBand: BandInfo, bandType: BandType) => {
    if (!initialized) return;
    const vis: RasterVisualization = layer.paramsMap.visualization;
    if (bandType === BandType.RED) {
      vis.rgb.redBandIndex = newBand.index;
      vis.rgb.redBandCurrentMin = newBand.min;
      vis.rgb.redBandCurrentMax = newBand.max;
      setCurrentRedBandIndex(newBand.index - 1);
      setRedMin(newBand.min);
      setRedMax(newBand.max);
      setCurrentRedMin(newBand.currentMin);
      setCurrentRedMax(newBand.currentMax);
    } else if (bandType === BandType.GREEN) {
      vis.rgb.greenBandIndex = newBand.index;
      vis.rgb.greenBandCurrentMin = newBand.min;
      vis.rgb.greenBandCurrentMax = newBand.max;
      setCurrentGreenBandIndex(newBand.index - 1);
      setGreenMin(newBand.min);
      setGreenMax(newBand.max);
      setCurrentGreenMin(newBand.currentMin);
      setCurrentGreenMax(newBand.currentMax);
    } else if (bandType === BandType.BLUE) {
      vis.rgb.blueBandIndex = newBand.index;
      vis.rgb.blueBandCurrentMin = newBand.min;
      vis.rgb.blueBandCurrentMax = newBand.max;
      setCurrentBlueBandIndex(newBand.index - 1);
      setBlueMin(newBand.min);
      setBlueMax(newBand.max);
      setCurrentBlueMin(newBand.currentMin);
      setCurrentBlueMax(newBand.currentMax);
    }
    updateLayerParam(layer, 'visualization', vis);
    onLayerUpdated(layer);
  };

  const handleSliderChange = (bandType: BandType, newMin: number, newMax: number) => {
    const newLayer = JSON.parse(JSON.stringify(layer));
    const vis: RasterVisualization = newLayer.paramsMap.visualization;
    const rgbVis = vis.rgb;
    if (bandType === BandType.RED) {
      rgbVis.redBandCurrentMin = newMin;
      rgbVis.redBandCurrentMax = newMax;
      setCurrentRedMin(newMin);
      setCurrentRedMax(newMax);
    } else if (bandType === BandType.GREEN) {
      rgbVis.greenBandCurrentMin = newMin;
      rgbVis.greenBandCurrentMax = newMax;
      setCurrentGreenMin(newMin);
      setCurrentGreenMax(newMax);
    } else if (bandType === BandType.BLUE) {
      rgbVis.blueBandCurrentMin = newMin;
      rgbVis.blueBandCurrentMax = newMax;
      setCurrentBlueMin(newMin);
      setCurrentBlueMax(newMax);
    }
    updateLayerParam(newLayer, 'visualization', vis);
    onLayerUpdated(newLayer);
  };

  if (!availableBands || availableBands.length === 0) return null;

  return (
    <div className="d-flex flex-column p-2">
      <FloatingLabel
        label={'Red band'}
        editorId={'visType'}
        editorValue={currentRedBandIndex !== null && currentRedBandIndex !== undefined ? '' + currentRedBandIndex : ''}
        className="w-75 mx-auto pb-2"
      >
        <DropDownList
          style={{
            width: '200px',
          }}
          size="small"
          data={availableBands}
          value={availableBands[currentRedBandIndex]}
          dataItemKey="name"
          textField="name"
          onChange={(event: any) => {
            handleBandChange(event.target.value, BandType.RED);
          }}
          disabled={layer === null}
          popupSettings={{ width: 250 }}
        />
      </FloatingLabel>
      <RangeSlider
        style={{ marginLeft: '2.5rem', paddingTop: '0.4rem', paddingBottom: '1.2rem' }}
        step={(redMax - redMin) / 100}
        min={redMin}
        max={redMax}
        disabled={layer === null}
        value={{ start: currentRedMin, end: currentRedMax }}
        onChange={(e) => {
          handleSliderChange(BandType.RED, e.value.start, e.value.end);
        }}
      >
        {[
          { pos: availableBands[currentRedBandIndex].min, text: currentRedMin },
          { pos: availableBands[currentRedBandIndex].max, text: currentRedMax },
        ].map((label, i) => (
          <SliderLabel key={i} position={label.pos}>
            {label.text.toFixed(6).toString()}
          </SliderLabel>
        ))}
      </RangeSlider>
      <FloatingLabel
        label={'Green band'}
        editorId={'visType'}
        editorValue={
          currentGreenBandIndex !== null && currentGreenBandIndex !== undefined ? '' + currentGreenBandIndex : ''
        }
        className="w-75 mx-auto pb-2"
      >
        <DropDownList
          style={{
            width: '200px',
          }}
          size="small"
          data={availableBands}
          value={availableBands[currentGreenBandIndex]}
          dataItemKey="name"
          textField="name"
          onChange={(event: any) => {
            handleBandChange(event.target.value, BandType.GREEN);
          }}
          disabled={layer === null}
          popupSettings={{ width: 250 }}
        />
      </FloatingLabel>
      <RangeSlider
        style={{ marginLeft: '2.5rem', paddingTop: '0.4rem', paddingBottom: '1.2rem' }}
        step={(greenMax - greenMin) / 100}
        min={greenMin}
        max={greenMax}
        disabled={layer === null}
        value={{ start: currentGreenMin, end: currentGreenMax }}
        onChange={(e) => {
          handleSliderChange(BandType.GREEN, e.value.start, e.value.end);
        }}
      >
        {[
          { pos: availableBands[currentGreenBandIndex].min, text: currentGreenMin },
          { pos: availableBands[currentGreenBandIndex].max, text: currentGreenMax },
        ].map((label, i) => (
          <SliderLabel key={i} position={label.pos}>
            {label.text.toFixed(6).toString()}
          </SliderLabel>
        ))}
      </RangeSlider>
      <FloatingLabel
        label={'Blue band'}
        editorId={'visType'}
        editorValue={
          currentBlueBandIndex !== null && currentBlueBandIndex !== undefined ? '' + currentBlueBandIndex : ''
        }
        className="w-75 mx-auto pb-2"
      >
        <DropDownList
          style={{
            width: '200px',
          }}
          size="small"
          data={availableBands}
          value={availableBands[currentBlueBandIndex]}
          dataItemKey="name"
          textField="name"
          onChange={(event: any) => {
            handleBandChange(event.target.value, BandType.BLUE);
          }}
          disabled={layer === null}
          popupSettings={{ width: 250 }}
        />
      </FloatingLabel>
      <RangeSlider
        style={{ marginLeft: '2.5rem', paddingTop: '0.4rem', paddingBottom: '1.2rem' }}
        step={(blueMax - blueMin) / 100}
        min={blueMin}
        max={blueMax}
        disabled={layer === null}
        value={{ start: currentBlueMin, end: currentBlueMax }}
        onChange={(e) => {
          handleSliderChange(BandType.BLUE, e.value.start, e.value.end);
        }}
      >
        {[
          { pos: availableBands[currentBlueBandIndex].min, text: currentBlueMin },
          { pos: availableBands[currentBlueBandIndex].max, text: currentBlueMax },
        ].map((label, i) => (
          <SliderLabel key={i} position={label.pos}>
            {label.text.toFixed(6).toString()}
          </SliderLabel>
        ))}
      </RangeSlider>
      {/*<FloatingLabel label={'Gamma'} editorId={'visType'} editorValue={true} className="w-75 mx-auto pb-2">
        <Slider
          min={0}
          max={4}
          step={0.01}
          value={currentGamma}
          onChange={(e) => {
            if (!initialized) return;
            const vis: RasterVisualization = layer.paramsMap.visualization;
            vis.rgb.gamma = e.value;
            setCurrentGamma(e.value);
            updateLayerParam(layer, 'visualization', vis);
            onLayerUpdated(layer);
          }}
        ></Slider>
      </FloatingLabel>
      <FloatingLabel label={'Brightness'} editorId={'visType'} editorValue={true} className="w-75 mx-auto pb-2">
        <Slider
          min={-1}
          max={1}
          step={0.01}
          value={currentBrightness}
          onChange={(e) => {
            if (!initialized) return;
            const vis: RasterVisualization = layer.paramsMap.visualization;
            vis.rgb.brightness = e.value;
            setCurrentBrightness(e.value);
            updateLayerParam(layer, 'visualization', vis);
            onLayerUpdated(layer);
          }}
        ></Slider>
      </FloatingLabel>
      <FloatingLabel label={'Contrast'} editorId={'visType'} editorValue={true} className="w-75 mx-auto pb-2">
        <Slider
          min={-1}
          max={1}
          step={0.01}
          value={currentContrast}
          onChange={(e) => {
            if (!initialized) return;
            const vis: RasterVisualization = layer.paramsMap.visualization;
            vis.rgb.contrast = e.value;
            setCurrentContrast(e.value);
            updateLayerParam(layer, 'visualization', vis);
            onLayerUpdated(layer);
          }}
        ></Slider>
        </FloatingLabel>*/}
    </div>
  );
};

export default RgbControls;
