import React, { useReducer, useContext } from 'react';
import { AddLayerWizardState, initialState } from './addLayerWizardState';
import { addLayerWizardReducer, AddLayerWizardAction } from './addLayerWizardReducer';

export interface AddLayerWizardProviderValue extends AddLayerWizardState {
  dispatch: (action: AddLayerWizardAction) => void;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  value: AddLayerWizardProviderValue;
}

const AddLayerWizardContext = React.createContext({
  ...initialState,
  dispatch: () => {
    // not implemented
  },
} as AddLayerWizardProviderValue);

export const AddLayerWizardProvider: React.FC<Props> = ({ children, value }) => {
  return <AddLayerWizardContext.Provider value={value}>{children}</AddLayerWizardContext.Provider>;
};

/*
  Create Sap Flow Wizard state.
*/
export const useCreateAddLayerWizardState = (viewerConfigId: string): AddLayerWizardProviderValue => {
  const [state, dispatch] = useReducer(addLayerWizardReducer, {
    ...initialState,
    viewerConfigId,
  });
  return {
    ...state,
    dispatch,
  };
};

/*
  Consume Sap Flow Wizard state.
*/
export const useConsumeAddLayerWizardState = () => {
  return useContext(AddLayerWizardContext);
};
