import { useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../common';

interface Params {
  configId: string;
  configParams: any;
}

const useSapConfigParamsUpdate = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.put<any>('file_sapconfig/' + params.configId + '/params', params.configParams);
      return response.data;
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['sapConfigParams', variables.configId], { refetchInactive: true });
      },
    }
  );
};

export default useSapConfigParamsUpdate;
