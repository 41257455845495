import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useConsumeViewerState } from '../../../context/viewer';
import Tooltipped from '../../../components/TooltippedV2';
import { WrappedOrEllipsisSpan } from '../../../components/';

const OverlayContainer = styled.div`
  position: absolute;
  right: 1rem;
  bottom: calc(1rem + var(--geosap-viewer-status-height));
  display: flex;
  flex-direction: column;
  width: 20rem;
  color: black;
  font-weight: bold;
  background: white;
  z-index: 10;
  border-radius: 1rem;
  opacity: 0.5;
  padding: 0.65rem;
`;

// To avoid transpiler error on window.Potree  https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
declare const window: any;
const Potree = window.Potree;

interface Props {
  visible: boolean;
  onClose: any;
}

const FeatureAttributes: React.FC<Props> = (props: Props) => {
  const { viewerHeight, selectedFeature } = useConsumeViewerState();
  const [attributes, setAttributes] = React.useState([]);
  const [featuresProperties, setFeaturesProperties] = React.useState<any[]>([]);

  useEffect(() => {
    //setVisible(props.visible && selectedFeature !== null && selectedFeature !== undefined);
  }, [props.visible, selectedFeature]);

  useEffect(() => {
    if (selectedFeature && selectedFeature.feature) {
      const newFeaturesProperties: any[] = [];
      const properties = selectedFeature.feature.getProperties();
      const newAttributes: { name: string }[] = [];
      const newTableProperties: { [name: string]: any } = {};
      Object.keys(properties).forEach((attributeName) => {
        //newAttributes.push({ name: attributeName });
        if (attributeName === 'geometry' || attributeName === 'layerId') {
          //newTableProperties[attributeName] = JSON.stringify(properties[attributeName]);
        } else {
          newAttributes.push({ name: attributeName });
          newTableProperties[attributeName] = properties[attributeName];
        }
      });
      newTableProperties['id'] = selectedFeature.feature.getId();
      newFeaturesProperties.push(newTableProperties);
      setAttributes(newAttributes);
      setFeaturesProperties(newFeaturesProperties);
    }
  }, [selectedFeature]);

  if (!props.visible || featuresProperties.length <= 0) return null;

  return (
    <OverlayContainer>
      <Tooltipped style={{ width: '100%', overflow: 'hidden' }} label={featuresProperties[0]['id']}>
        <WrappedOrEllipsisSpan
          wrapped={true}
          style={{ fontSize: '1.2rem' /*, overflow: 'hidden', textOverflow: 'ellipsis'*/ }}
        >
          {featuresProperties[0]['id']}
        </WrappedOrEllipsisSpan>
      </Tooltipped>
      {attributes.map((attribute, index) => {
        return (
          <div key={index} style={{ display: 'flex', overflow: 'hidden' }}>
            <span>{`${attribute.name}:`}</span>
            &nbsp;
            <Tooltipped
              containerStyle={{ width: '100%', overflow: 'hidden' }}
              label={featuresProperties[0][attribute.name]}
            >
              <WrappedOrEllipsisSpan wrapped={true}>{featuresProperties[0][attribute.name]}</WrappedOrEllipsisSpan>
            </Tooltipped>
          </div>
        );
      })}
    </OverlayContainer>
  );
};

export default FeatureAttributes;
