import { useMutation } from 'react-query';
import {
  LOCAL_STORAGE_USER,
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_TOKEN_TYPE,
  LOCAL_STORAGE_CURRENT_ORG,
} from '../../common/constants';
import { User, SigninRequest, SigninResponse } from '../../types';
import { useAppContext } from '../../context/app';
import { useAxiosInstance } from '../common';

const endpoint = '/authentication/refresh';

const useRefreshUser = () => {
  const { dispatch } = useAppContext();
  const axios = useAxiosInstance();

  return useMutation(
    async () => {
      const response = await axios.post<SigninResponse>(endpoint);
      if (response) {
        if (response.data && response.data.accessToken) {
          const user: User = {
            id: response.data.id,
            email: response.data.email,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            organizations: response.data.organizations,
          };
          localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
          localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, response.data.accessToken);
          localStorage.setItem(LOCAL_STORAGE_TOKEN_TYPE, response.data.tokenType);

          dispatch({
            type: 'LOGIN',
            payload: { user: user, accessToken: response.data.accessToken, tokenType: response.data.tokenType },
          });
        }
      }
    }
  );
};

export default useRefreshUser;
