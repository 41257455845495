import { useQuery } from 'react-query';
import { BaseMap } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'base_map';

const useBaseMaps = () => {
  const axios = useAxiosInstance();
  return useQuery(['baseMaps'], async () => {
    const result = await axios.get<BaseMap[]>(endpoint);
    const baseMaps = result.data;
    return baseMaps;
  });
};

export default useBaseMaps;
