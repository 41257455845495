import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { useConsumeViewerState } from '../../../context/viewer';
import ProjectionSearchDialog from '../../../components/ProjectionSearchDialog';
import { Projection } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import { get as getProjection, getTransform } from 'ol/proj.js';
import { applyTransform } from 'ol/extent';
import { useAppContext } from '../../../context/app';
import ProjectionContextMenu from './ProjectionContextMenu';
import UnitsContextMenu from './UnitsContextMenu';
import { EPSGProjectionInfos, SapFlowViewConfig, Unit, ViewLayerParam } from '../../../types';
import { updateViewConfigParam } from '../../../common/viewerConfigHelper';

declare const proj4: any;

const StatusContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: var(--geosap-viewer-status-height);
  display: flex;
  background: var(--geosap-navbar-color);
  z-index: 5;
  color: rgb(215, 215, 215);
  padding: 0 0.5rem;
`;

interface StatusBarProps {
  onViewConfigParamsUpdate?: (viewConfig: SapFlowViewConfig, persistent: boolean, immediate: boolean) => void;
}

const defaultProjections = [
  {
    code: '3857',
    name: 'Pseudo-Mercator',
    proj4def:
      'PROJCS["WGS 84 / Pseudo-Mercator",GEOGCS["WGS 84",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4326"]],PROJECTION["Mercator_1SP"],PARAMETER["central_meridian",0],PARAMETER["scale_factor",1],PARAMETER["false_easting",0],PARAMETER["false_northing",0],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AXIS["Easting",EAST],AXIS["Northing",NORTH],EXTENSION["PROJ4","+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs"],AUTHORITY["EPSG","3857"]]',
    bbox: [85.06, -180, -85.06, 180],
  },
  {
    code: '4326',
    name: 'WGS84',
    proj4def:
      'GEOGCS["WGS 84", DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4326"]]',
    bbox: [90, -180, -90, 180],
  },
  {
    code: '6433',
    name: 'NAD83(2011) / Connecticut (m)',
    proj4def:
      'PROJCS["NAD83(2011) / Connecticut",GEOGCS["NAD83(2011)",DATUM["NAD83_National_Spatial_Reference_System_2011",SPHEROID["GRS 1980",6378137,298.257222101],TOWGS84[0,0,0,0,0,0,0]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","6318"]],PROJECTION["Lambert_Conformal_Conic_2SP"],PARAMETER["latitude_of_origin",40.8333333333333],PARAMETER["central_meridian",-72.75],PARAMETER["standard_parallel_1",41.8666666666667],PARAMETER["standard_parallel_2",41.2],PARAMETER["false_easting",304800.6096],PARAMETER["false_northing",152400.3048],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AXIS["Easting",EAST],AXIS["Northing",NORTH],AUTHORITY["EPSG","6433"]]',
    bbox: [42.05, -73.73, 40.98, -71.78],
  },
  {
    code: '6434',
    name: 'NAD83(2011) / Connecticut (ft)',
    proj4def:
      'PROJCS["NAD83(2011) / Connecticut (ftUS)",GEOGCS["NAD83(2011)",DATUM["NAD83_National_Spatial_Reference_System_2011",SPHEROID["GRS 1980",6378137,298.257222101],TOWGS84[0,0,0,0,0,0,0]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","6318"]],PROJECTION["Lambert_Conformal_Conic_2SP"],PARAMETER["latitude_of_origin",40.8333333333333],PARAMETER["central_meridian",-72.75],PARAMETER["standard_parallel_1",41.8666666666667],PARAMETER["standard_parallel_2",41.2],PARAMETER["false_easting",1000000],PARAMETER["false_northing",500000],UNIT["US survey foot",0.304800609601219],AXIS["Easting",EAST],AXIS["Northing",NORTH],AUTHORITY["EPSG","6434"]]',
    bbox: [42.05, -73.73, 40.98, -71.78],
  },
  {
    code: '32618',
    name: 'UTM 18N',
    proj4def:
      'PROJCS["WGS 84 / UTM zone 18N",GEOGCS["WGS 84",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4326"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",-75],PARAMETER["scale_factor",0.9996],PARAMETER["false_easting",500000],PARAMETER["false_northing",0],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AXIS["Easting",EAST],AXIS["Northing",NORTH],AUTHORITY["EPSG","32618"]]',
    bbox: [84, -78, 0, -72],
  },
];

const StatusBar: React.FC<StatusBarProps> = (props: StatusBarProps) => {
  const { dispatch, displayedProjection2D, viewConfig, viewConfigParams, units, currentPerspective, cameraPosition3D } =
    useConsumeViewerState();
  const { dispatch: dispatchApp } = useAppContext();
  const [projContextOffset, setProjContextOffset] = React.useState(null);
  const [unitsContextOffset, setUnitsContextOffset] = React.useState(null);
  const [selectingProjection, setSelectingProjection] = useState(false);

  useEffect(() => {
    const handleDocClick = () => {
      if (projContextOffset) {
        setProjContextOffset(null);
      }
      if (unitsContextOffset) {
        setUnitsContextOffset(null);
      }
    };
    document.addEventListener('click', handleDocClick);
    return () => {
      document.removeEventListener('click', handleDocClick);
    };
  }, [projContextOffset, unitsContextOffset]);

  const renderdisplayedProjection2D = () => {
    const [hovering, setHovering] = useState(false);
    if (!displayedProjection2D || currentPerspective !== '2D') return null;
    return (
      <div
        className="animated-general"
        style={{
          border: '1px gray solid',
          fontSize: '0.75rem',
          margin: '0.15rem 0.25rem',
          padding: '0 0.2rem',
          borderRadius: '2px',
          background: hovering ? 'rgb(150,150,150)' : 'inherit',
        }}
        onMouseEnter={() => {
          setHovering(true);
        }}
        onMouseLeave={() => {
          setHovering(false);
        }}
      >
        {displayedProjection2D?.getCode()}
      </div>
    );
  };

  const renderDisplayedUnits = () => {
    const [hovering, setHovering] = useState(false);
    return (
      <div
        className="animated-general"
        style={{
          border: '1px gray solid',
          fontSize: '0.75rem',
          margin: '0.15rem 0.25rem',
          padding: '0 0.2rem',
          borderRadius: '2px',
          background: hovering ? 'rgb(150,150,150)' : 'inherit',
        }}
        onMouseEnter={() => {
          setHovering(true);
        }}
        onMouseLeave={() => {
          setHovering(false);
        }}
        /*onClick={() => {
          setSelectingProjection(true);
        }}*/
      >
        {units}
      </div>
    );
  };

  const handleProjectionSelected = (projInfos: EPSGProjectionInfos) => {
    const newProjCode = 'EPSG:' + projInfos.code;
    proj4.defs(newProjCode, projInfos.proj4def);
    register(proj4);
    const newProj = getProjection(newProjCode);
    const fromLonLat = getTransform('EPSG:4326', newProj);

    let worldExtent = [projInfos.bbox[1], projInfos.bbox[2], projInfos.bbox[3], projInfos.bbox[0]];
    newProj.setWorldExtent(worldExtent);

    // approximate calculation of projection extent,
    // checking if the world extent crosses the dateline
    if (projInfos.bbox[1] > projInfos.bbox[3]) {
      worldExtent = [projInfos.bbox[1], projInfos.bbox[2], projInfos.bbox[3] + 360, projInfos.bbox[0]];
    }
    const extent = applyTransform(worldExtent, fromLonLat, undefined, 8);
    newProj.setExtent(extent);
    dispatch({ type: 'DISPLAYED_PROJ', payload: { proj: newProj } });
    setSelectingProjection(false);
    dispatchApp({
      type: 'SHOW_NOTIFICATION',
      payload: {
        notification: { type: 'success', content: 'Changed position projection to: ' + newProj.getCode() },
      },
    });
  };

  const handleUnitsSelected = (units: Unit) => {
    const newViewConfig: SapFlowViewConfig = JSON.parse(JSON.stringify(viewConfig));
    updateViewConfigParam(newViewConfig, 'units', units);
    props.onViewConfigParamsUpdate(newViewConfig, false, false);
    dispatch({ type: 'CHANGE_UNITS', payload: { units } });
  };

  return (
    <StatusContainer className="">
      <ProjectionSearchDialog
        show={selectingProjection}
        onClose={() => {
          setSelectingProjection(false);
        }}
        onSelect={(proj: Projection) => {
          dispatch({ type: 'DISPLAYED_PROJ', payload: { proj } });
          setSelectingProjection(false);
          dispatchApp({
            type: 'SHOW_NOTIFICATION',
            payload: {
              notification: { type: 'success', content: 'Changed position projection to: ' + proj.getCode() },
            },
          });
        }}
      ></ProjectionSearchDialog>

      <ProjectionContextMenu
        offset={projContextOffset}
        availableProjections={defaultProjections}
        onProjectionSelected={(proj) => {
          handleProjectionSelected(proj);
        }}
      />

      <UnitsContextMenu
        offset={unitsContextOffset}
        onUnitsSelected={(units: Unit) => {
          handleUnitsSelected(units);
        }}
      />
      <div
        style={{ display: 'flex', cursor: 'pointer' }}
        onClick={(e) => {
          if (currentPerspective !== '2D') return false;
          e.stopPropagation();
          if (!projContextOffset) {
            const clickedElement: any = e.target;
            const rect = clickedElement.getBoundingClientRect();
            setProjContextOffset({ left: rect.left, top: rect.top });
          } else {
            setProjContextOffset(null);
          }
          return true;
        }}
      >
        {renderdisplayedProjection2D()}
        {currentPerspective === '2D' && <span id="olCursorLocation"></span>}
        {currentPerspective === '3D' && (
          <span id="potreeCameraPosition">{`${cameraPosition3D.x}, ${cameraPosition3D.y}, ${cameraPosition3D.z}`}</span>
        )}
      </div>
      <div
        style={{ display: 'flex', cursor: 'pointer', marginLeft: 'auto' }}
        onClick={(e) => {
          e.stopPropagation();
          if (!unitsContextOffset) {
            const clickedElement: any = e.target;
            const rect = clickedElement.getBoundingClientRect();
            //setContextOffset({ left: e.clientX, top: e.clientY });
            setUnitsContextOffset({ left: rect.left, top: rect.top });
          } else {
            setUnitsContextOffset(null);
          }
          return true;
        }}
      >
        {renderDisplayedUnits()}
      </div>
    </StatusContainer>
  );
};

export default StatusBar;
