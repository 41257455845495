import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Typography } from '@progress/kendo-react-common';
import { Checkbox, Slider, SliderLabel } from '@progress/kendo-react-inputs';
import { useForm, Controller, UseFormReturn } from 'react-hook-form';
import {
  Appearance3D,
  LayerType,
  PotreePointShape,
  PotreePointSizing,
  Unit,
  ViewLayer,
  ViewLayerParam,
  ViewerConfigParams,
} from '../../../types';
import Dialog from '../../../components/Dialog';
import { Input, Select, SubmitButton } from '../../../components/form';
import { useViewerConfigParamsUpdate } from '../../../hooks/viewerconfig';
import { useConsumeViewerState } from '../../../context/viewer';
import { getCardinalEnumKeys } from '../../../common/enumHelper';

interface LayerPropertiesProps {
  show: boolean;
  handleClose: any;
  onViewerUpdated?: (params: ViewerConfigParams) => void;
}

const ViewerProperties: React.FC<LayerPropertiesProps> = (props: LayerPropertiesProps) => {
  const { dispatch, viewConfig, viewConfigParams, appearance3D } = useConsumeViewerState();
  const propertiesForm = useForm<any>();
  const viewConfigParamsUpdateMutation = useViewerConfigParamsUpdate();

  const updateProperties = async (formValues: any) => {
    dispatch({ type: 'CHANGE_APPEARANCE_3D', payload: { appearance3D: formValues } });
    props.handleClose();
    return;
  };

  useEffect(() => {
    const eyeDomelighting = appearance3D?.eyeDomeLighting;
    propertiesForm.setValue('pointBudget', appearance3D?.pointBudget ? appearance3D?.pointBudget : 3000000);
    propertiesForm.setValue('fov', appearance3D?.fov ? appearance3D?.fov : 60);
    propertiesForm.setValue('highQuality', appearance3D?.highQuality !== undefined ? appearance3D?.highQuality : false);
    propertiesForm.setValue('pointSize', appearance3D?.pointSize ? appearance3D?.pointSize : 0.5);
    propertiesForm.setValue(
      'pointSizing',
      appearance3D?.pointSizing !== undefined ? appearance3D?.pointSizing : PotreePointSizing.ADAPTIVE
    );
    propertiesForm.setValue(
      'pointShape',
      appearance3D?.pointShape !== undefined ? appearance3D?.pointShape : PotreePointShape.SQUARE
    );
    propertiesForm.setValue(
      'eyeDomelighting.enabled',
      eyeDomelighting?.enabled !== undefined ? eyeDomelighting?.enabled : false
    );
    propertiesForm.setValue('eyeDomelighting.radius', eyeDomelighting?.radius ? eyeDomelighting?.radius : 1.4);
    propertiesForm.setValue('eyeDomelighting.strength', eyeDomelighting?.strength ? eyeDomelighting?.strength : 1.4);
    propertiesForm.setValue('eyeDomelighting.opacity', eyeDomelighting?.opacity ? eyeDomelighting?.opacity : 1);
  }, [appearance3D]);

  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>Viewer Configuration</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
      style={{ width: '600px', maxWidth: '80%', maxHeight: '80%', minHeight: '500px' }}
    >
      <form style={{ flex: 1 }} onSubmit={propertiesForm.handleSubmit(updateProperties)}>
        <Typography.h3>3D Appearance</Typography.h3>

        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <label>Point budget: </label>
          <Controller
            control={propertiesForm.control}
            name={'pointBudget'}
            render={({ field, fieldState: { error }, onChange }: any) => {
              return (
                <Slider
                  min={100000}
                  max={10000000}
                  step={10000}
                  defaultValue={1000000}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                >
                  {[{ pos: field.value, text: field.value }].map((label, i) => (
                    <SliderLabel key={i} position={label.pos}>
                      {label.text?.toFixed(0).toString()}
                    </SliderLabel>
                  ))}
                </Slider>
              );
            }}
          />
        </div>

        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <label>FOV: </label>
          <Controller
            control={propertiesForm.control}
            name={'fov'}
            render={({ field, fieldState: { error }, onChange }: any) => {
              return (
                <Slider
                  min={20}
                  max={100}
                  step={1}
                  defaultValue={60}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                >
                  {[{ pos: field.value, text: field.value }].map((label, i) => (
                    <SliderLabel key={i} position={label.pos}>
                      {label.text?.toFixed(0).toString()}
                    </SliderLabel>
                  ))}
                </Slider>
              );
            }}
          />
        </div>

        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <label>High Quality: </label>
          <Controller
            control={propertiesForm.control}
            name="highQuality"
            render={({ field, fieldState: { error } }) => (
              <Checkbox {...field} className="k-form-field p-2 w-75 mx-auto" label={''} />
            )}
          />
        </div>

        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <label>Point Size: </label>
          <Controller
            control={propertiesForm.control}
            name={'pointSize'}
            render={({ field, fieldState: { error }, onChange }: any) => {
              return (
                <Slider
                  min={0}
                  max={3}
                  step={0.01}
                  defaultValue={1}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                >
                  {[{ pos: field.value, text: field.value }].map((label, i) => (
                    <SliderLabel key={i} position={label.pos}>
                      {label.text?.toFixed(2).toString()}
                    </SliderLabel>
                  ))}
                </Slider>
              );
            }}
          />
        </div>

        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <label>Point Sizing: </label>
          <Controller
            control={propertiesForm.control}
            name={'pointSizing'}
            render={({ field, fieldState: { error }, onChange }: any) => {
              return (
                <Select
                  data={getCardinalEnumKeys(PotreePointSizing).map((enumKey: any) => {
                    return { id: enumKey, value: PotreePointSizing[enumKey] };
                  })}
                  value={{
                    id: PotreePointSizing[field.value], //Object.keys(PotreePointSizing)[Object.values(PotreePointSizing).indexOf(field.value)],
                    value: field.value,
                  }}
                  onChange={(e) => {
                    field.onChange(e.value.value);
                  }}
                  dataItemKey={'value'}
                  textField={'id'}
                  name={'pointSizing'}
                ></Select>
              );
            }}
          />
        </div>

        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <label>Point Shape: </label>
          <Controller
            control={propertiesForm.control}
            name={'pointShape'}
            render={({ field, fieldState: { error }, onChange }: any) => {
              return (
                <Select
                  data={getCardinalEnumKeys(PotreePointShape).map((enumKey: any) => {
                    return { id: enumKey, value: PotreePointShape[enumKey] };
                  })}
                  value={{
                    id: PotreePointShape[field.value], //Object.keys(PotreePointSizing)[Object.values(PotreePointSizing).indexOf(field.value)],
                    value: field.value,
                  }}
                  onChange={(e) => {
                    field.onChange(e.value.value);
                  }}
                  dataItemKey={'value'}
                  textField={'id'}
                  name={'pointShape'}
                ></Select>
              );
            }}
          />
        </div>

        <div style={{ marginTop: 'auto' }}>
          <SubmitButton
            type="submit"
            label="Update"
            uppercase={false}
            full={false}
            disabled={false}
            loading={viewConfigParamsUpdateMutation.isLoading}
            className="mt-2 ml-auto"
          />
        </div>
      </form>
    </Dialog>
  );
};

export default ViewerProperties;
