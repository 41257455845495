import React, { useState, useRef, useEffect } from 'react';
import Dialog from '../../../components/Dialog';
import { Typography } from '@progress/kendo-react-common';
import { Input } from '@progress/kendo-react-inputs';
import { SubmitButton } from '../../../components/form';
import { FileNode } from '../../../types/DataDelivery';

interface DeleteFileNodeModalProps {
  show: boolean;
  handleClose: any;
  onConfirm: () => void;
  fileNode: FileNode;
}

const DeleteFileNodeModal: React.FC<DeleteFileNodeModalProps> = (props: DeleteFileNodeModalProps) => {
  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>Delete File</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography.p>{`Are you sure you wish to delete ${props.fileNode?.name}`}</Typography.p>
        <SubmitButton
          type="button"
          label="Submit"
          uppercase={false}
          full={false}
          disabled={false}
          onClick={() => {
            props.onConfirm();
          }}
          loading={false}
          className="mt-2 ml-auto"
        />
      </div>
    </Dialog>
  );
};

export default DeleteFileNodeModal;
