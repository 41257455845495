import { QueryClient } from 'react-query';
import {
    ServerTask,
    ServerTaskStatus,
    WSMessage,
  } from '../../../types';

const handleMessage = (wsMessage: WSMessage, queryClient: QueryClient) => {
    const newServerState: ServerTaskStatus = wsMessage.payload;
    const serverTasks: ServerTask[] = JSON.parse(JSON.stringify(queryClient.getQueryData(['serverTasks', newServerState.task.server_id, newServerState.task.server_type])));
    if (serverTasks) {
        let taskFound = false;
        serverTasks.forEach((serverTask: ServerTask) => {
            if(serverTask.id===newServerState.id){
                taskFound = true;
            }
        });
        queryClient.setQueryData(['serverTasks', newServerState.task.server_id, newServerState.task.server_type], serverTasks);
    }
}

export default handleMessage;