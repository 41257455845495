import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { useAppContext } from '../../context/app';
import { useUser } from '../../hooks/authentication';
import { CentralizedContent } from '../../components/layout';
import Overlayedloader from '../../components/OverlayedLoader';
import SubmitButton from '../../components/form/SubmitButton';
import styled from 'styled-components';

const BrandImage = styled.img`
  display: block;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
`;

const SubscriptionReturnSuccess: React.FC = () => {
  const { getCurrentOrganization } = useUser();
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <CentralizedContent>
      <div className="col-xs-12">
        <Overlayedloader show={loading} />
        {!loading && (
          <div className="col-xs-12">
            <BrandImage src="/assets/brand-black.png" alt="GeoSAP brand" className="p-3 pb-4" />
            <Typography.h2 className="text-center">Welcome To geoSAP !</Typography.h2>
            <Typography.p className="text-center">Your subscription was successfully activated.</Typography.p>
            <div className="d-flex justify-content-center">
              <SubmitButton
                label="GO TO SETTING"
                full={false}
                onClick={() => {
                  navigate('/settings/organization');
                }}
              />
            </div>
          </div>
        )}
      </div>
    </CentralizedContent>
  );
};

export default SubscriptionReturnSuccess;
