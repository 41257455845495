import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface Props {
  to: string;
  label: string;
  className?: string;
}

const StyledLink = styled(Link)`
  &:hover {
    color: var(--geosap-light);
  }
`;

const LinkButton: React.FC<Props> = ({ to, label, className }) => {
  const defaultClasses = 'k-button k-button-md k-button-rectangle k-button-solid k-button-solid-primary k-rounded-md';
  return (
    <StyledLink to={to} className={`${className} ${defaultClasses}`}>
      {label}
    </StyledLink>
  );
};

export default LinkButton;
