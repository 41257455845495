import { Feature } from 'ol';
import {
  Appearance3D,
  BaseMap,
  DrawingMode,
  FeatureAttributeType,
  LayerType,
  NavigationMode2D,
  SapFlowViewConfig,
  Unit,
  ViewLayer,
} from '../../types';
import { ViewerState, initialState } from './viewerState';
import { Projection } from 'ol/proj';
import OLMap from 'ol/Map';
import { getProjUnits } from '../../common/proj4Helper';

const createBaseMapLayer = (visible: boolean): ViewLayer => {
  return {
    id: '' + Math.floor(Math.random() * 100000000),
    uri: '',
    displayName: 'Base Map',
    active: visible,
    layerType: LayerType.BaseMap,
  };
};

const parseViewConfig = (stateViewConfig: SapFlowViewConfig, currentState: ViewerState): ViewerState => {
  // TODO: Deep copy the view config to avoid modifying the actual query data which might get cached
  const viewConfig = JSON.parse(JSON.stringify(stateViewConfig));
  const newState: ViewerState = { ...currentState };
  newState.viewConfig = viewConfig;
  newState.viewConfigParams = viewConfig.paramsMap;
  newState.layers = [];
  const newStatePropertyAssignment = (propertyName: string, defaultValue: any) => {
    newState[propertyName] = currentState[propertyName]
      ? currentState[propertyName]
      : viewConfig.paramsMap[propertyName]
      ? viewConfig.paramsMap[propertyName]
      : defaultValue;
  };
  newStatePropertyAssignment('cameraPosition2D', [0, 0]);
  newStatePropertyAssignment('zoomLevel2D', 16);
  newStatePropertyAssignment('cameraPosition3D', { x: 0, y: 0, z: 0 });
  newStatePropertyAssignment('cameraTarget3D', { x: 0, y: 0, z: 0 });
  newStatePropertyAssignment('baseMap', { id: '1', type: 'OpenStreetMap', displayName: 'OpenStreetMap' });
  newState.units = viewConfig.paramsMap.units ? viewConfig.paramsMap.units : newState.units;
  newState.appearance3D = viewConfig.paramsMap.appearance3D ? viewConfig.paramsMap.appearance3D : newState.appearance3D;
  newState.currentPerspective = viewConfig.paramsMap.selectedPerspective
    ? viewConfig.paramsMap.selectedPerspective
    : newState.currentPerspective;
  newState.displayBaseMap =
    viewConfig.paramsMap.displayBaseMap !== null && viewConfig.paramsMap.displayBaseMap !== undefined
      ? viewConfig.paramsMap.displayBaseMap
      : newState.displayBaseMap;

  // First layer is the base map layer displaying the map.
  const baseMapLayer: ViewLayer = createBaseMapLayer(newState.displayBaseMap);
  newState.layers.push(baseMapLayer);
  let initialLayerSelected: ViewLayer = null;

  let i = 0;
  const handleLayerInitialization = (
    layer: ViewLayer,
    perspective: '2D' | '3D',
    newState: ViewerState,
    defaultSelection = false
  ) => {
    newState.layers.push(layer);
    newState.currentPerspective = newState.currentPerspective ? newState.currentPerspective : perspective;
    if (defaultSelection) initialLayerSelected = initialLayerSelected ? initialLayerSelected : layer;
    if (layer.paramsMap && layer.paramsMap['generated']) {
      newState.selectedLayer = newState.selectedLayer ? newState.selectedLayer : layer;
    }
    newState.available2D = perspective === '2D' ? true : newState.available2D;
    newState.available3D = perspective === '3D' ? true : newState.available3D;
  };
  for (i = 0; i < viewConfig.layers.length; i++) {
    let layer: ViewLayer = viewConfig.layers[i];
    currentState.layers.forEach((currentStateLayer: ViewLayer) => {
      if (currentStateLayer.id === layer.id) {
        layer = currentStateLayer;
      }
    });
    if (layer.layerType === LayerType.OctreeBin || layer.layerType === LayerType.UNKNOWN.valueOf()) {
      handleLayerInitialization(layer, '3D', newState, true);
    } else if (layer.layerType === LayerType.ShapeFile) {
      handleLayerInitialization(layer, '2D', newState, true);
    } else if (layer.layerType === LayerType.GeoJSON) {
      handleLayerInitialization(layer, '2D', newState, true);
    } else if (layer.layerType === LayerType.GeoTif) {
      handleLayerInitialization(layer, '2D', newState, true);
    } else if (layer.layerType === LayerType.GSRAS) {
      handleLayerInitialization(layer, '2D', newState, true);
    } else if (layer.layerType === LayerType.GSVEC) {
      handleLayerInitialization(layer, '2D', newState, true);
    } else if (layer.layerType === LayerType.WMS) {
      handleLayerInitialization(layer, '2D', newState, true);
    } else if (layer.layerType === LayerType.WFS) {
      handleLayerInitialization(layer, '2D', newState, true);
    } else if (layer.layerType === LayerType.Measurement2D) {
      handleLayerInitialization(layer, '2D', newState);
    } else if (layer.layerType === LayerType.Measurement3D) {
      handleLayerInitialization(layer, '3D', newState);
    } else if (layer.layerType === LayerType.Drawing2D) {
      handleLayerInitialization(layer, '2D', newState);
    } else if (layer.layerType === LayerType.LAZ) {
      newState.layers.push(layer);
    }
    //layer.active = layer.generationType === GenerationType.GENERATED;
  }
  newState.selectedLayer = newState.selectedLayer ? newState.selectedLayer : initialLayerSelected;
  return newState;
};

export interface ViewConfigAction {
  type: 'VIEW_CONFIG';
  payload: {
    viewConfig: SapFlowViewConfig;
  };
}

export interface AssignDOMViewer {
  type: 'DOM_VIEWER';
  payload: {
    domRef: React.MutableRefObject<HTMLElement | null>;
  };
}

export interface ThrottleStateAction {
  type: 'THROTTLE_STATE';
  payload: {
    throttling: boolean;
  };
}

export interface SelectLayerAction {
  type: 'SELECT_LAYER';
  payload: {
    layer: ViewLayer;
  };
}

export interface LayerUpdateAction {
  type: 'LAYER_UPDATE';
  payload: {
    layer: ViewLayer;
  };
}

export interface ToggleLayersToolbarAction {
  type: 'TOGGLE_LAYERS_TOOLBAR';
}

export interface ChangePerspectiveAction {
  type: 'CHANGE_PERSPECTIVE';
  payload: {
    perspective: '3D' | '2D';
  };
}

export interface ChangeHeightAction {
  type: 'CHANGE_HEIGHT';
  payload: {
    height: number;
  };
}

export interface ChangeViewConfigPropertiesOpened {
  type: 'CHANGE_VIEW_CONFIG_PROPERTIES_OPENED';
  payload: {
    opened: boolean;
  };
}

export interface InitializedPotreeAction {
  type: 'POTREE_INIT';
}

export interface PotreeProjAction {
  type: 'POTREE_PROJ';
  payload: {
    proj: string;
    projUnits: Unit;
  };
}

export interface PotreeCameraMovedAction {
  type: 'POTREE_CAMERA_MOVED';
  payload: {
    position: { x: number; y: number; z: number };
    target: { x: number; y: number; z: number };
  };
}

export interface InitializedOLAction {
  type: 'OL_INIT';
  payload: {
    map: OLMap;
  };
}

export interface OlProjAction {
  type: 'OL_PROJ';
  payload: {
    proj: Projection;
  };
}

export interface OLCameraMovedAction {
  type: 'OL_CAMERA_MOVED';
  payload: {
    position: number[];
    zoomLevel: number;
  };
}

export interface DisplayedProjAction {
  type: 'DISPLAYED_PROJ';
  payload: {
    proj: Projection;
  };
}

export interface ChangeBaseMap {
  type: 'CHANGE_BASE_MAP';
  payload: {
    baseMap: BaseMap;
  };
}

export interface ChangeUnits {
  type: 'CHANGE_UNITS';
  payload: {
    units: Unit;
  };
}

export interface DrawingModeAction {
  type: 'DRAWING_MODE';
  payload: {
    drawingMode: DrawingMode;
  };
}

export interface ChangeNavigationMode2D {
  type: 'CHANGE_NAVIGATION_2D';
  payload: {
    mode: NavigationMode2D;
  };
}

export interface ChangeAppearance3D {
  type: 'CHANGE_APPEARANCE_3D';
  payload: {
    appearance3D: Appearance3D;
  };
}

export interface AddedMeasurementAction {
  type: 'ADDED_MEASUREMENT';
  payload: {
    measurement: any;
  };
}

export interface RemovedMeasurementAction {
  type: 'REMOVED_MEASUREMENT';
  payload: {
    measurement: any;
  };
}

export interface ClearMeasurementsAction {
  type: 'CLEAR_MEASUREMENTS';
}

export interface AddedProfileAction {
  type: 'ADDED_PROFILE';
  payload: {
    profile: any;
  };
}

export interface RemovedProfileAction {
  type: 'REMOVED_PROFILE';
  payload: {
    measurement: any;
  };
}

export interface ClearProfilesAction {
  type: 'CLEAR_PROFILES';
}

export interface SelectFeatureAction {
  type: 'SELECT_FEATURE';
  payload: {
    layerId: string;
    feature: Feature;
  };
}

export interface ClearFeatureAction {
  type: 'CLEAR_FEATURE';
}

export interface FeatureEnterAction {
  type: 'FEATURE_ENTER';
  payload: {
    layerId: string;
    featureId: any;
  };
}

export interface FeatureLeaveAction {
  type: 'FEATURE_LEAVE';
  payload: {
    layerId: string;
    featureId: any;
  };
}

export interface FeatureClearAction {
  type: 'FEATURE_CLEAR';
}

export interface FilterFeaturesAction {
  type: 'FILTER_FEATURES';
  payload: {
    layerId: string;
    attribute: { name: string; type: FeatureAttributeType; possibleValues: string[]; min: number; max: number };
    attributeValue: string;
    min: number;
    max: number;
  };
}

export interface ClearFilterFeatureAction {
  type: 'CLEAR_FEATURE_FILTER';
}

export interface CursorMoved2DAction {
  type: 'CURSOR_MOVED_2D';
  payload: {
    x: number;
    y: number;
  };
}

export type ViewerAction =
  | ViewConfigAction
  | AssignDOMViewer
  | ThrottleStateAction
  | SelectLayerAction
  | LayerUpdateAction
  | ToggleLayersToolbarAction
  | ChangePerspectiveAction
  | ChangeHeightAction
  | ChangeViewConfigPropertiesOpened
  | InitializedPotreeAction
  | PotreeProjAction
  | PotreeCameraMovedAction
  | InitializedOLAction
  | OlProjAction
  | OLCameraMovedAction
  | DisplayedProjAction
  | ChangeBaseMap
  | ChangeUnits
  | DrawingModeAction
  | ChangeNavigationMode2D
  | ChangeAppearance3D
  | AddedMeasurementAction
  | ClearMeasurementsAction
  | RemovedMeasurementAction
  | AddedProfileAction
  | ClearProfilesAction
  | RemovedProfileAction
  | SelectFeatureAction
  | ClearFeatureAction
  | FeatureEnterAction
  | FeatureLeaveAction
  | FeatureClearAction
  | FilterFeaturesAction
  | ClearFilterFeatureAction
  | CursorMoved2DAction;

export const viewerReducer = (currentState: ViewerState, action: ViewerAction): ViewerState => {
  switch (action.type) {
    case 'VIEW_CONFIG': {
      return parseViewConfig(action.payload.viewConfig, currentState);
    }
    case 'DOM_VIEWER': {
      return {
        ...currentState,
        domViewerRef: action.payload.domRef,
      };
    }
    case 'THROTTLE_STATE': {
      return {
        ...currentState,
        throttlingUpdate: action.payload.throttling,
      };
    }
    case 'SELECT_LAYER': {
      return {
        ...currentState,
        selectedLayer: action.payload.layer,
      };
    }
    case 'LAYER_UPDATE': {
      const index: number = currentState.layers.findIndex((x) => x.id === action.payload.layer.id);
      if (index >= 0) {
        const newlayers = JSON.parse(JSON.stringify(currentState.layers));
        const newLayer = JSON.parse(JSON.stringify(action.payload.layer));
        let displayBaseMap = currentState.displayBaseMap;
        newlayers[index] = newLayer;
        if (action.payload.layer.layerType === LayerType.BaseMap) {
          displayBaseMap = action.payload.layer.active;
        }
        return {
          ...currentState,
          displayBaseMap,
          layers: newlayers,
          selectedLayer:
            currentState.selectedLayer?.id === action.payload.layer.id ? newLayer : currentState.selectedLayer,
        };
      }
      return {
        ...currentState,
      };
    }
    case 'TOGGLE_LAYERS_TOOLBAR': {
      return {
        ...currentState,
        layersToolbarVisible: !currentState.layersToolbarVisible,
      };
    }
    case 'CHANGE_PERSPECTIVE': {
      return {
        ...currentState,
        currentPerspective: action.payload.perspective,
      };
    }
    case 'CHANGE_VIEW_CONFIG_PROPERTIES_OPENED': {
      return {
        ...currentState,
        viewConfigPropertiesOpened: action.payload.opened,
      };
    }
    case 'CHANGE_HEIGHT': {
      return {
        ...currentState,
        viewerHeight: action.payload.height,
      };
    }
    case 'POTREE_INIT': {
      return {
        ...currentState,
        potreeInitialized: true,
      };
    }
    case 'POTREE_PROJ': {
      let newUnits = currentState?.units;
      if (!currentState?.viewConfig?.paramsMap?.units) {
        // No unit has been saved to viewer config yet. Assign from Pointcloud projection.
        newUnits = action.payload.projUnits;
      }
      return {
        ...currentState,
        potreeProjection: action.payload.proj,
        units: newUnits,
      };
    }
    case 'POTREE_CAMERA_MOVED': {
      return {
        ...currentState,
        cameraPosition3D: action.payload.position,
        cameraTarget3D: action.payload.target,
      };
    }
    case 'OL_INIT': {
      const proj = action.payload.map?.getView()?.getProjection();
      return {
        ...currentState,
        olMapInitialized: true,
        olMap: action.payload.map,
        olMapProjection: proj,
        displayedProjection2D: currentState.displayedProjection2D ? currentState.displayedProjection2D : proj,
      };
    }
    case 'OL_PROJ': {
      return {
        ...currentState,
        olMapProjection: action.payload.proj,
        displayedProjection2D: currentState.displayedProjection2D
          ? currentState.displayedProjection2D
          : action.payload.proj,
      };
    }
    case 'OL_CAMERA_MOVED': {
      return {
        ...currentState,
        cameraPosition2D: action.payload.position,
        zoomLevel2D: action.payload.zoomLevel,
      };
    }
    case 'CHANGE_NAVIGATION_2D': {
      return {
        ...currentState,
        mode2d: action.payload.mode,
      };
    }
    case 'CHANGE_APPEARANCE_3D': {
      return {
        ...currentState,
        appearance3D: action.payload.appearance3D,
      };
    }
    case 'CHANGE_BASE_MAP': {
      return {
        ...currentState,
        baseMap: action.payload.baseMap,
      };
    }
    case 'CHANGE_UNITS': {
      return {
        ...currentState,
        units: action.payload.units,
      };
    }
    case 'DISPLAYED_PROJ': {
      return {
        ...currentState,
        displayedProjection2D: action.payload.proj,
      };
    }
    case 'DRAWING_MODE': {
      return {
        ...currentState,
        drawingMode: action.payload.drawingMode,
      };
    }
    case 'ADDED_PROFILE': {
      const newProfiles = [...currentState.profiles, action.payload.profile];
      console.log('New profiles: ' + JSON.stringify(newProfiles));
      return {
        ...currentState,
        profiles: newProfiles,
      };
    }
    case 'CLEAR_PROFILES': {
      return {
        ...currentState,
        profiles: [],
      };
    }
    case 'REMOVED_PROFILE': {
      //TODO
      return {
        ...currentState,
      };
    }
    case 'CLEAR_FEATURE_FILTER': {
      return {
        ...currentState,
      };
    }
    case 'SELECT_FEATURE': {
      return {
        ...currentState,
        selectedFeature: action.payload ? { layerId: action.payload.layerId, feature: action.payload.feature } : null,
      };
    }
    case 'CLEAR_FEATURE': {
      return {
        ...currentState,
        selectedFeature: null,
      };
    }
    case 'FEATURE_ENTER': {
      return {
        ...currentState,
        hoveredFeature: { layerId: action.payload.layerId, featureId: action.payload.featureId },
      };
    }
    case 'FEATURE_LEAVE': {
      let newHoveredFeature = currentState.hoveredFeature;
      if (
        currentState.hoveredFeature &&
        currentState.hoveredFeature.layerId === action.payload.layerId &&
        currentState.hoveredFeature.featureId === action.payload.featureId
      )
        newHoveredFeature = null;
      return {
        ...currentState,
        hoveredFeature: newHoveredFeature,
      };
    }
    case 'FEATURE_CLEAR': {
      return {
        ...currentState,
        hoveredFeature: null,
      };
    }
    case 'FILTER_FEATURES': {
      return {
        ...currentState,
        featureFilter: action.payload,
      };
    }
    case 'CURSOR_MOVED_2D': {
      return {
        ...currentState,
        cursorPosition2D: action.payload,
      };
    }
  }
};
