import { useMutation } from 'react-query';
import { useAxiosInstance } from '../common';

interface Params {
  fileNodeId: string;
  force?: boolean;
}

const useProcessFileNode = () => {
  const axios = useAxiosInstance();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post("filenode/" + params.fileNodeId + "/process", null, {params:{force: params.force}});
      return response.data;
    },
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(['rootStructure'], { refetchInactive: true });
      },
    }
  );
};

export default useProcessFileNode;
