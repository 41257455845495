import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { SapFlowItemNode, RequiredFileStatus } from '../../types';
import { useAppContext } from '../../context/app';
import { useConsumeSapFlowWizardState } from '../../context/sapFlowWizard';
import { useSapFlow, useRunSapFlow, useFileFormat, useReuseFiles } from '../../hooks/sapflow';
import { useUser } from '../../hooks/authentication';
import { SubmitButton } from '../../components/form';
import TransitionAnimation from '../../components/TransitionAnimation';
import InputDetailSelection from './InputDetailSelection';
import { useQueryClient } from 'react-query';

declare const window: any;

const UploadStep = () => {
  const { dispatch } = useAppContext();
  const { getUser, getCurrentOrganization } = useUser();
  const { sapFlow, project, projectName, sapFlowConfig } = useConsumeSapFlowWizardState();
  const { getSuppliedItems, getFileFormatNames } = useSapFlow();
  const { mutate } = useRunSapFlow();
  const { mutateAsync } = useReuseFiles();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [items, setItems] = useState<SapFlowItemNode[]>([]);
  const [fileUploadStatus, setFileUploadStatus] = useState({} as Record<string, RequiredFileStatus>);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const fileFormatQuery = useFileFormat(getFileFormatNames(sapFlow));
  const queryClient = useQueryClient();

  useEffect(() => {
    setItems(getSuppliedItems(sapFlow));
  }, [sapFlow]);

  useEffect(() => {
    if (fileFormatQuery.isSuccess) {
      const suppliedItems: SapFlowItemNode[] = [...getSuppliedItems(sapFlow)];
      fileFormatQuery.data.forEach((format, index) => {
        suppliedItems[index].acceptedFileExtensions = format.acceptedFileExtensions.map((format) =>
          format.split('.').pop().toUpperCase()
        );
      });
      setItems(suppliedItems);
    }
  }, [fileFormatQuery.isSuccess, fileFormatQuery.data]);

  const handleStatusChange = (newFileStatus: RequiredFileStatus, itemId: string) => {
    setFileUploadStatus((prevState) => ({
      ...prevState,
      [itemId]: newFileStatus,
    }));
    if (newFileStatus.ProvisionType === 'Upload' && newFileStatus.Uploaded) {
      queryClient.invalidateQueries(['reusableFiles', getUser().id], { refetchInactive: true });
    }
  };

  const handleSubmitClick = () => {
    setSubmitting(true);
    // Handle file reusing
    const reusePromises = items.map((item, index) => {
      if (
        fileUploadStatus[item.id] &&
        fileUploadStatus[item.id].FilesToReuse &&
        fileUploadStatus[item.id].FilesToReuse.length > 0
      ) {
        return mutateAsync({
          target_item: item.id,
          file_tuples: fileUploadStatus[item.id].FilesToReuse.map((file: any) => {
            return { file_name: file.name, item_id: file.itemId };
          }),
        });
      }
    });

    Promise.all(reusePromises).then(() => {
      mutate(
        { orgid: getCurrentOrganization().id, sapflowid: sapFlow.id, projectid: project.id },
        {
          onSuccess: () => {
            let nbUploads = 0;
            for (const key in fileUploadStatus) {
              if (fileUploadStatus[key] && fileUploadStatus[key].Uploaded) {
                nbUploads = nbUploads + 1;
              }
            }
            window.dataLayer.push({
              event: 'Run SAPflow',
              step: '6',
              stepName: 'SAPFlow Details',
              projectName: projectName,
              sapFlowType: sapFlowConfig?.name,
              numberOfUploads: nbUploads,
              sapFlowId: sapFlow.id,
            });
            const content = 'SAPFlow successfully started';
            dispatch({ type: 'SHOW_NOTIFICATION', payload: { notification: { content, type: 'success' } } });
            navigate(projectId ? `/project/${projectId}` : '/dashboard');
          },
        }
      );
    });
  };

  const isSubmitDisabled = (): boolean => {
    const allSubmitted = items.reduce(
      (submitted, item) =>
        submitted &&
        fileUploadStatus[item.id] &&
        (fileUploadStatus[item.id].Uploaded ||
          (fileUploadStatus[item.id].FilesToReuse && fileUploadStatus[item.id].FilesToReuse.length > 0)),
      true
    );
    return !allSubmitted;
  };

  return (
    <TransitionAnimation className="col-xs-12 col-lg-8 col-xl-6">
      <>
        <Typography.h2 fontWeight="light" textAlign="center">
          Upload required files
        </Typography.h2>
        <div>
          {fileFormatQuery.isLoading && (
            <Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />
          )}
          {fileFormatQuery.isSuccess &&
            items.map((item, index) => {
              return <InputDetailSelection key={index} itemnode={item} handleStatusChange={handleStatusChange} />;
            })}
          <div className="pt-3">
            <SubmitButton
              type="button"
              label="Submit details"
              uppercase={false}
              full={false}
              disabled={isSubmitDisabled()}
              onClick={handleSubmitClick}
              loading={isSubmitting}
            />
          </div>
        </div>
      </>
    </TransitionAnimation>
  );
};

export default UploadStep;
