import { useContext, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import MapContext from '../Map/MapContext';
import OLTileLayer from 'ol/layer/WebGLTile';
import GeoTIFF from 'ol/source/GeoTIFF.js';
import View, { ViewOptions } from 'ol/View';
import Extent from 'ol/extent';
import { transformExtent } from 'ol/proj.js';
import { ViewLayer } from '../../../../types';
import { RAMP_COLORS, RampColor, RampColorValue } from '../Styles/RampColor';
import { BandInfo } from '../../../../types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
GeoTIFF.prototype.getMetadata = function () {
  return this.metadata_;
};

const proj4 = window.proj4 || require('proj4');

interface Props {
  layer: ViewLayer;
  show: boolean;
  zIndex: number;
  style?: any;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
} | null;
const GeotiffLayer = forwardRef<Ref, Props>((props, ref) => {
  const { map } = useContext<any>(MapContext);
  const [tileLayer, setTileLayer] = useState(null);
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (!map || tileLayer) return;

    const geotiffSources = [
      {
        url: props.layer.uri,
      },
    ];
    const tileSource = new GeoTIFF({
      sources: geotiffSources,
      normalize: false,
    });
    setSource(tileSource);

    const newTileLayer = new OLTileLayer({
      source: tileSource,
      zIndex: props.zIndex,
    });

    newTileLayer.setVisible(props.show);
    newTileLayer.setProperties({ title: props.layer.displayName });

    map.addLayer(newTileLayer);
    newTileLayer.setZIndex(props.zIndex);
    setTileLayer(newTileLayer);

    return () => {
      if (map) {
        map.removeLayer(newTileLayer);
      }
    };
  }, [map]);

  useEffect(() => {
    if (tileLayer) {
      tileLayer.setStyle(props.style);
    }
  }, [props.style]);

  useEffect(() => {
    if (!map || !tileLayer) return;
    tileLayer.setVisible(props.show);
  }, [props.show]);

  useEffect(() => {
    // Construct style from layer information
    if (tileLayer) {
      const colorRamp = props.layer.paramsMap.colorRamp ? props.layer.paramsMap.colorRamp : RAMP_COLORS[0];
      if (props.layer.paramsMap.bands) {
        // calculate NDVI, bands come from the sources below
        //['/', ['-', nir, red], ['+', nir, red]],
        let newStyle: any = null;
        let colorExpression: any = null;
        let interpolationExpression = false;
        if (
          (!props.layer.paramsMap.visualizationType && props.layer.paramsMap.bands.length > 3) ||
          props.layer.paramsMap.visualizationType?.name === 'RGB'
        ) {
          const bands = props.layer.paramsMap.bands;
          colorExpression = [
            'array',
            bands[0].bits === 8
              ? ['/', ['band', 1], 255]
              : ['/', ['-', ['band', 1], bands[0].currentMin], bands[0].currentMax - bands[0].currentMin],
            bands[1].bits === 8
              ? ['/', ['band', 2], 255]
              : ['/', ['-', ['band', 2], bands[1].currentMin], bands[1].currentMax - bands[1].currentMin],
            bands[2].bits === 8
              ? ['/', ['band', 3], 255]
              : ['/', ['-', ['band', 3], bands[2].currentMin], bands[2].currentMax - bands[2].currentMin],
            ['case', ['==', ['band', 1], bands[0].nodata], 0, 1],
            //  ['/', ['band', bands.length + 1], 255],
          ];
        } else if (!props.layer.paramsMap.visualizationType) {
          const bands = props.layer.paramsMap.bands;
          const band = bands[0];
          const bandRange = band.currentMax - band.currentMin;
          const grayValue = ['/', ['-', ['band', band.index], band.currentMin], bandRange];
          colorExpression = ['interpolate', ['linear'], grayValue];

          colorRamp.values.forEach((rampValue: RampColorValue) => {
            colorExpression.push(rampValue.value);
            colorExpression.push(rampValue.color);
          });
          colorExpression = ['case', ['==', ['band', band.index], band.nodata], '#00000000', colorExpression];
          interpolationExpression = true;
        } else if (props.layer.paramsMap.visualizationType.name === 'NDVI') {
          console.log('');
        } else {
          // From selected band and ramp color, build OL Styling
          const bandArr = props.layer.paramsMap.bands.filter(
            (bandInfo: BandInfo) => bandInfo.name === props.layer.paramsMap.visualizationType.name
          );
          if (bandArr.length <= 0) {
            console.log('Unabled to find matching Band -_-');
          } else {
            const band: BandInfo = bandArr[0];
            const bandRange = band.currentMax - band.currentMin;
            //const grayValue = (band.bits === 8 ? ['/', ['band', (band.index + 1)], 255] : ['band', (band.index + 1)]);
            const grayValue = ['/', ['-', ['band', band.index], band.currentMin], bandRange];
            colorExpression = ['interpolate', ['linear'], grayValue];
            colorRamp.values.forEach((rampValue: RampColorValue) => {
              colorExpression.push(rampValue.value);
              colorExpression.push(rampValue.color);
            });
            colorExpression = ['case', ['==', ['band', band.index], band.nodata], '#00000000', colorExpression];
            interpolationExpression = true;
          }
        }
        // Account for nodata values
        /*if (interpolationExpression) {
          newStyle = {
            color: ['case', ['==', ['band', props.layer.paramsMap.bands.length + 1], 0], '#00000000', colorExpression],
          };
        } else*/ {
          newStyle = {
            color: colorExpression,
          };
        }
        tileLayer.setStyle(newStyle);
        setTimeout(() => {
          tileLayer.setStyle(newStyle);
        }, 1000);
      }
    }
  }, [props.layer, tileLayer]);

  const zoomToLayer = (layer: any) => {
    const projection = props.layer.paramsMap['projection'];
    const extents = props.layer.paramsMap['extents'];
    if (projection && extents) {
      let olExtents: Extent.Extent = [extents[0], extents[1], extents[2], extents[3]];
      if (projection !== map.getView().getProjection().getCode()) {
        olExtents = transformExtent(olExtents, projection, map.getView().getProjection().getCode());
      }
      map.getView().fit(olExtents);
    }
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
  }));

  return null;
});

GeotiffLayer.displayName = 'GeotiffLayer';

export default GeotiffLayer;
