import React from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';

interface DialogProps {
  style?: React.CSSProperties;
  children: JSX.Element | string;
  onClick?: () => {};
  wrapped: boolean;
}

const WrappedOrEllipsisSpan: React.FC<DialogProps> = (props: DialogProps) => {
  let spanStyle: React.CSSProperties = props.style ? props.style : {};
  if (props.wrapped) {
    spanStyle = { ...spanStyle, ...{ overflowWrap: 'anywhere' } };
  } else {
    spanStyle = { ...spanStyle, ...{ textOverflow: 'ellipsis', overflow: 'hidden', textWrap: 'nowrap' } };
  }
  return (
    <span style={spanStyle} onClick={props.onClick ? props.onClick : null}>
      {props.children}
    </span>
  );
};

export default WrappedOrEllipsisSpan;
