import { Cartesian3, Ellipsoid, Math } from 'cesium';

declare const window: any;
declare const proj4: any;

export const threeToCesiumPosition = (pos: any, threeProj: string) => {
  const Cesium = window.Cesium;
  const xy = [pos.x, pos.y];
  const height = pos.z / 3.28084; // TODO: This is a very simple feet to meter conversion. Should probably validate in which case it is necesary
  const mapProjection = proj4.defs('WGS84');
  const deg = proj4(threeProj, mapProjection).forward(xy);
  const cPos = Cesium.Cartesian3.fromDegrees(...deg, height);

  return cPos;
};

export const cesiumToThree = (coords: Cartesian3, threeProj: string) => {
  const carto = Ellipsoid.WGS84.cartesianToCartographic(coords);
  const lon = Math.toDegrees(carto.longitude);
  const lat = Math.toDegrees(carto.latitude);
  const xy = [lon, lat];
  const height = carto.height * 3.28084; // TODO: This is a very simple feet to meter conversion. Should probably validate in which case it is necesary
  const mapProjection = proj4.defs('WGS84');
  const newPos = proj4(mapProjection, threeProj).forward(xy);
  return [...newPos, height];
};
