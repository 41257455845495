import React, { useState, useEffect, useRef } from 'react';

interface TreeViewProps {
  item: any;
}

const FileExplorerItem: React.FC<TreeViewProps> = (props: TreeViewProps) => {
  return <div style={{ cursor: 'pointer' }}>{props.item.name}</div>;
};

export default FileExplorerItem;
