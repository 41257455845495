import { ProjectDetails } from '../../../types';
import { useQuery } from 'react-query';
import { useAxiosInstance } from '../../common/';

const useProjectDetails = (projectId: string) => {
  const axios = useAxiosInstance();
  return useQuery(
    ['projectDetails', projectId],
    async () => {
      const params = { project_id: projectId };
      const result = await axios.get<ProjectDetails>("project/" + projectId + "/details");
      return result.data;
    },
    { retry: false }
  );
};

export default useProjectDetails;
