import { useQueryClient, useMutation } from 'react-query';
import { SapFlowViewConfig, ViewLayerUpdateDTO, ViewLayer } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'viewer_config';

const useViewLayerUpdate = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: ViewLayerUpdateDTO) => {
      const response = await axios.put<ViewLayer>(endpoint + "/" + params.viewerId + "/layer/" + params.layerId, params);
      return response.data;
    },
    {
      onSuccess: (data: any, variables: ViewLayerUpdateDTO) => {
        queryClient.invalidateQueries(['viewerConfig', variables.viewerId], { refetchInactive: true });
        //queryClient.invalidateQueries(['fileNodeView', variables.viewerId], { refetchInactive: true });
      },
    }
  );
};

export default useViewLayerUpdate;
