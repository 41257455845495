import { useMutation } from 'react-query';
import { useAxiosInstance } from '../common';

const endpoint = '/stripe/create_subscription';

interface Params {
  productId: string;
  priceId: string;
}

const useStripeSubscription = () => {
  const axios = useAxiosInstance();

  return useMutation(async (params : Params) => { 
    const response = await axios.post<any>(endpoint, {}, { params });
    if (response) {
        if (response.data) {
            if (!response.data.subscriptionId) {
              console.log("Stripe subscription id could not be fetched");
            }
            if (!response.data.clientSecret) {
              console.log("Stripe Client Secret could not be fetched");
            }
        }
        return response.data;
    }
  });
};

export default useStripeSubscription;