import { useQuery } from 'react-query';
import { useAxiosInstance } from '../../common/';

const useProjectFileDetails = () => {

  const getFileDetails = (projectId: string) => {
    const endpoint = 'file_manager/proj_details';
    const axios = useAxiosInstance();
    return useQuery(
      ['projectFileDetails', projectId],
      async () => {
        const params = { proj_id: projectId };
        const result = await axios.get<any>(endpoint, { params });
        return result.data;
      },
      { retry: false }
    );
  };

  return { getFileDetails };
};

export default useProjectFileDetails;
