import React, { useState, useRef, useEffect } from 'react';
import { Popup, Offset } from '@progress/kendo-react-popup';
import { Menu, MenuItem, MenuItemModel } from '@progress/kendo-react-layout';
import { AuthorityLevel, AuthorityType, LayerType, ViewLayer } from '../../../types';
import { useUser } from '../../../hooks/authentication';
import { useConsumeViewerState } from '../../../context/viewer';

interface ContextMenuProps {
  layer: ViewLayer;
  index: number;
  offset: Offset;
  onRenameLayer(layer: any): void;
  onZoomToLayer(layer: any): void;
  onLayerProperties(layer: any): void;
  onDeleteLayer(layer: any): void;
  onDownloadLayer(layer: any): void;
}

const GeotiffProperties: React.FC<ContextMenuProps> = (props: ContextMenuProps) => {
  const { dispatch, viewerType } = useConsumeViewerState();
  const { userHasAuthority, getViewAccessToken, getSharedFileNode } = useUser();

  const baseMapLayer = props.layer?.layerType === LayerType.BaseMap;
  const availableOptions: MenuItemModel[] = [];
  const userCanUpdate: boolean = userHasAuthority(AuthorityType.VIEWER_AUTHORITY, AuthorityLevel.UPDATE);
  if (userCanUpdate) {
    availableOptions.push({
      text: 'Rename',
      data: {
        action: () => {
          props.onRenameLayer(props.layer);
        },
      },
      disabled: baseMapLayer,
    });
  }
  availableOptions.push({
    text: 'Zoom to',
    data: {
      action: () => {
        props.onZoomToLayer(props.layer);
      },
    },
    disabled: baseMapLayer,
  });

  if (userCanUpdate) {
    availableOptions.push({
      text: 'Properties',
      data: {
        action: () => {
          props.onLayerProperties(props.layer);
        },
      },
      disabled: baseMapLayer,
    });
  }
  if (viewerType === 'FILENODE') {
    availableOptions.push({
      text: 'Download',
      data: {
        action: () => {
          props.onDownloadLayer(props.layer);
        },
      },
    });
  }
  if (userCanUpdate) {
    availableOptions.push({
      text: 'Delete',
      data: {
        action: () => {
          props.onDeleteLayer(props.layer);
        },
      },
      disabled:
        false &&
        props.layer?.layerType !== LayerType.ShapeFile &&
        props.layer?.layerType !== LayerType.GeoTif &&
        props.layer?.layerType !== LayerType.WMS &&
        props.layer?.layerType !== LayerType.WFS,
    });
  }
  return (
    <Popup
      key={props.index}
      show={props.offset !== null && props.layer !== null}
      offset={props.offset}
      popupClass={'popup-content'}
    >
      <Menu
        key={props.index}
        vertical={true}
        style={{ display: 'inline-block' }}
        items={availableOptions}
        onSelect={({ item, itemId, nativeEvent, target }) => {
          item.data.action();
        }}
      ></Menu>
    </Popup>
  );
};

export default GeotiffProperties;
