import React, { useEffect, useState } from 'react';
import Dialog from '../../../components/Dialog';
import { SvgIcon, Typography } from '@progress/kendo-react-common';
import { chevronLeftIcon } from '@progress/kendo-svg-icons';
import { FileNode } from '../../../types/DataDelivery';
import { useTusUploader } from '../../../hooks/common';
import UploadProgress from './UploadProgress';
import UploadsBrowser from './UploadsBrowser';
import { useAppContext } from '../../../context/app';
import { useUploadContext } from '../../../context/uploadContext';
import { TusUpload } from '../../../types';

//interface FileNodeUploadModalProps {
//  rootFileNode: FileNode;
//}

const FileNodeUploadModal: React.FC /*<FileNodeUploadModalProps>*/ = (/*props: FileNodeUploadModalProps*/) => {
  const { dispatch: uploadDispatch, uploadsOpened, uploads, selectedUpload } = useUploadContext();
  const [files, setFiles] = useState<{ [key: string]: File }>({});

  useEffect(() => {
    const newFiles = { ...files };
    for (const upload of Object.values(uploads)) {
      newFiles[upload.fileId] = upload.file;
      if (selectedUpload && selectedUpload.fileId === upload.fileId) {
        uploadDispatch({ type: 'SELECT_UPLOADS', payload: { upload } });
      }
    }
    setFiles(newFiles);
  }, [uploads]);

  return (
    <Dialog
      show={uploadsOpened}
      title={<Typography.h3>Upload Data</Typography.h3>}
      onClose={() => {
        uploadDispatch({ type: 'UPLOADS_VISIBILITY', payload: { visibility: false } });
      }}
      style={{ width: '700px', maxWidth: '80%', maxHeight: '80%', height: '600px', overflow: 'hidden' }}
    >
      <div className="d-flex flex-column h-100 position-relative">
        {selectedUpload && (
          <div
            style={{ position: 'absolute', top: 0, left: 0, cursor: 'pointer', zIndex: 1000 }}
            onClick={() => {
              uploadDispatch({ type: 'SELECT_UPLOADS', payload: { upload: null } });
            }}
          >
            <SvgIcon icon={chevronLeftIcon} size="xlarge"></SvgIcon>
          </div>
        )}
        <div className="h-100">
          <div className="h-100 align-items-center justify-content-center">
            {selectedUpload ? (
              <UploadProgress
                upload={selectedUpload}
                onBack={() => uploadDispatch({ type: 'SELECT_UPLOADS', payload: { upload: null } })}
              />
            ) : (
              <UploadsBrowser
                onSelectUpload={(upload: TusUpload) => {
                  uploadDispatch({ type: 'SELECT_UPLOADS', payload: { upload } });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default FileNodeUploadModal;
