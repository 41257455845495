import { filterBy } from '@progress/kendo-react-data-tools';
import { mapTree, extendDataItem } from '@progress/kendo-react-common';
export const processTreeData = (data: any, state: any, fields: any) => {
  const { selectField, expandField, dataItemKey, subItemsField } = fields;
  const { expanded, values, filter } = state;
  const filtering = Boolean(filter && filter.value);
  return mapTree(filtering ? filterBy(data, [filter], subItemsField) : data, subItemsField, (item) => {
    const props = {
      [expandField]: expanded.includes(item[dataItemKey]),
      [selectField]: values && (values.filter((value: any)=>item[dataItemKey] === value[dataItemKey]).length>0),
    };
    return filtering
      ? extendDataItem(item, subItemsField, props)
      : {
          ...item,
          ...props,
        };
  });
};
export const expandedState = (item: any, dataItemKey: any, expanded: any) => {
  const nextExpanded = expanded.slice();
  const itemKey = item[dataItemKey];
  const index = expanded.indexOf(itemKey);
  index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);
  return nextExpanded;
};

export const updateTreeItemValue = (itemHierarchicalIndex: string, property: string, value: any, treeData: any) => {
  const itemIndexes = itemHierarchicalIndex.split('_');
  let currentTreeItem: any = treeData[parseInt(itemIndexes[0])];
  for (let i = 1; i < itemIndexes.length; i++) {
    currentTreeItem = currentTreeItem.items[parseInt(itemIndexes[i])];
  }
  currentTreeItem[property] = value;
  return treeData;
};

// Main function to handle the selection toggling
interface SelectableTreeItem {
  items: SelectableTreeItem[];
  selected: boolean;
}
export const toggleItemSelection = (tree: SelectableTreeItem[], hierarchicalIndex: string): any[] => {
  const hierarchicalPath = hierarchicalIndex.split("_").map((index) => parseInt(index, 10));
  const toggleHelper = (nodes: SelectableTreeItem[], indices: number[]): SelectableTreeItem[] => {
      const [currentIndex, ...restIndices] = indices;
      const node = nodes[currentIndex];

      if (restIndices.length === 0) {
          // Toggle the current node's selection
          const newSelectionState = node.selected === true ? false : true;
          toggleNodeSelection(node, newSelectionState);
      } else {
          // Recurse deeper into the tree
          node.items = toggleHelper(node.items, restIndices);

          // Update the current node's selection based on children
          updateParentSelection(node);
      }

      return nodes;
  };

  return toggleHelper(tree, hierarchicalPath);
};

// Recursive function to update the parent nodes based on children's selection
const updateParentSelection = (node: SelectableTreeItem): SelectableTreeItem => {
  if (node.items?.length > 0) {
    node.items = node.items.map(updateParentSelection);

    if (areAllChildrenSelected(node)) {
        node.selected = true;
    } else if (areAllChildrenUnselected(node)) {
        node.selected = false;
    } else {
        node.selected = null; // Partially selected
    }
  }

  return node;
};

const toggleNodeSelection = (node: SelectableTreeItem, shouldSelect: boolean | null): SelectableTreeItem => {
  // Set the current node's selection based on the target state
  node.selected = shouldSelect;

  // Recursively update all descendants
  node.items = node.items?.map(child => toggleNodeSelection(child, shouldSelect));

  return node;
};


// Helper function to check if all children are selected
export function areAllChildrenSelected (node: SelectableTreeItem): boolean {
  return node.items?.every((child: { selected: boolean; }) => child.selected === true);
};

// Helper function to check if all children are unselected
export function areAllChildrenUnselected (node: SelectableTreeItem): boolean {
  return node.items?.every((child: { selected: boolean; }) => child.selected === false);
};