import React, { useState, useEffect } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Input, NumericInput, SubmitButton } from '../../../../components/form';
import { BandInfo, ViewLayer } from '../../../../types';
import { Controller, UseFormReturn } from 'react-hook-form';
import { SvgIcon } from '@progress/kendo-react-common';
import { chevronLeftIcon } from '@progress/kendo-svg-icons';

interface ShapefilePropertiesProps {
  layer: ViewLayer;
  form: UseFormReturn<any>;
}

const ShapefileProperties: React.FC<ShapefilePropertiesProps> = (props: ShapefilePropertiesProps) => {
  useEffect(() => {
    props.form.setValue('projection', props.layer?.paramsMap['projection']);
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography.h3>Shapefile Properties</Typography.h3>

      <div>
        <label>Projection: </label>
        <Controller
          control={props.form.control}
          name={'projection'}
          render={({ field, fieldState: { error } }: any) => {
            return <Input className="w-75" {...field} error={error?.message} type="text" />;
          }}
        />
      </div>
    </div>
  );
};

export default ShapefileProperties;
