import React, { useEffect, useState, useRef } from 'react';
//import * as proj4 from 'proj4';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { Loader } from '@progress/kendo-react-indicators';
import { useAppContext } from '../../context/app';
import { useSearchParams } from 'react-router-dom';
import { FileNode, FileNodeType, FileNodeStatus } from '../../types/DataDelivery';
import { useUser } from '../../hooks/authentication';
import {
  useFileNodeGeolocationUpdate,
  useFileNodeNavigationOptionsUpdate,
  useFilenodeOverview,
  useFilenodeStructure,
  useProcessFileNode,
  useRootStructure,
} from '../../hooks/data_delivery';
import { colorizeFileNode, findFileNodeRecursively, mergeFileNodes } from '../../common/fileNodeHelper';
import OLViewer from './OpenLayers/OLViewer';
import NavigationSidebar from './NavigationSidebar/NavigationSidebar';
import AnnotationSidebar from './AnnotationSidebar/AnnotationSidebar';
import { AuthorityLevel, AuthorityType, UserRole } from '../../types';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import DataDeliveryNavigationOptions from '../../types/DataDelivery/FileNodeNavigationOptions';
import { useFileNodeActions } from '../../actions';
import { uuidv4 } from '../../common/uuid';

declare const window: any;

const GeosapViewer: React.FC = () => {
  const [root, setRoot] = useState<FileNode>(null);
  const [openedFileNode, setOpenedFileNode] = useState<FileNode>(null);
  const [selectedFileNode, setSelectedFileNode] = useState<FileNode>(null);
  const [hoveredFileNode, setHoveredFileNode] = useState<FileNode>(null);
  const [focusedFileNode, setFocusedFileNode] = useState<FileNode>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredNodes, setFilteredNodes] = React.useState<FileNode>(null);
  const [confirmingReprocessFilenode, setConfirmingReprocessFilenode] = React.useState<FileNode>(null);
  const { rootId } = useParams();
  const rootDataDelivery = rootId === 'root';
  const { dispatch } = useAppContext();
  const {
    getUser,
    getCurrentOrganization,
    getOrgSubscriptionValid,
    getViewAccessToken,
    userHasAuthority,
    isViewAuthenticated,
  } = useUser();
  const openLayerRef = useRef(null);
  const viewerContainerRef = useRef(null);
  const [viewerHeight, setViewerHeight] = useState<number>(900);
  const fileNodeStructureQuery = rootDataDelivery
    ? useRootStructure(getCurrentOrganization().id)
    : useFilenodeOverview(rootId);
  const fileNodeDescendantQuery =
    openedFileNode === null || openedFileNode.id === root.id
      ? useFilenodeOverview('00000000-0000-0000-0000-000000000000')
      : useFilenodeOverview(openedFileNode.id);
  const [navigationOptions, setNavigationOptions] = React.useState<DataDeliveryNavigationOptions>({
    showSupport: false,
    baseMap: null,
  });
  const processFileNodeMutation = useProcessFileNode();
  const {
    handleCreateFolderFileNode,
    handleUpdateFileNode,
    handleUpdateFileNodeGeolocation,
    handleProcessFileNode,
    handleCleanFileNode,
    handleDeleteFileNode,
    handleForgetFileNode,
    handleDownloadFileNode,
  } = useFileNodeActions(getCurrentOrganization().id);
  const updateFileNodeNavigationOptionsMutation = useFileNodeNavigationOptionsUpdate();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  //const sapViewQuery = useView(getUser().id, rootId);

  useEffect(() => {
    setViewerHeight(viewerContainerRef.current?.clientHeight);
    const handleResize = () => {
      setViewerHeight(viewerContainerRef.current?.clientHeight);
    };
    window.addEventListener('resize', handleResize);
    return window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (
      fileNodeStructureQuery.isSuccess &&
      !fileNodeStructureQuery.isFetching &&
      !fileNodeStructureQuery.isRefetching &&
      fileNodeStructureQuery.data
    ) {
      const data: FileNode = JSON.parse(JSON.stringify(fileNodeStructureQuery.data)); // Deep copy
      if (root && root.id === data.id) {
        // Root data already exists, go about replacing.
        const newRoot = mergeFileNodes(root, data);
        let newOpenedFileNode = newRoot,
          newSelectedFileNode = selectedFileNode,
          newFocusedFileNode = focusedFileNode;
        if (openedFileNode) {
          newOpenedFileNode = findFileNodeRecursively(newRoot, openedFileNode.id);
        }
        if (selectedFileNode) {
          newSelectedFileNode = findFileNodeRecursively(newRoot, selectedFileNode.id);
        }
        if (focusedFileNode) {
          newFocusedFileNode = findFileNodeRecursively(newRoot, focusedFileNode.id);
        }
        setRoot(JSON.parse(JSON.stringify(newRoot)));
        setOpenedFileNode(JSON.parse(JSON.stringify(newOpenedFileNode ? newOpenedFileNode : newRoot)));
        setSelectedFileNode(JSON.parse(JSON.stringify(newSelectedFileNode ? newSelectedFileNode : null)));
        setFocusedFileNode(JSON.parse(JSON.stringify(newFocusedFileNode ? newFocusedFileNode : null)));
        setNavigationOptions(newRoot.navigationOptions);
      } else {
        let openedFileNode = data;
        if (searchParams.get('current')) {
          const requestedOpenedFileNode = findFileNodeRecursively(data, searchParams.get('current'));
          if (requestedOpenedFileNode) openedFileNode = requestedOpenedFileNode;
        }
        colorizeFileNode(data);
        setRoot(data);
        setOpenedFileNode(openedFileNode);
        setNavigationOptions(data.navigationOptions);
      }
    }
  }, [
    fileNodeStructureQuery.isSuccess,
    fileNodeStructureQuery.isFetching,
    fileNodeStructureQuery.isRefetching,
    fileNodeStructureQuery.data,
  ]);

  useEffect(() => {
    if (
      fileNodeDescendantQuery.isSuccess &&
      !fileNodeDescendantQuery.isFetching &&
      !fileNodeDescendantQuery.isRefetching &&
      fileNodeDescendantQuery.data
    ) {
      const data: FileNode = JSON.parse(JSON.stringify(fileNodeDescendantQuery.data)); // Deep copy
      if (root) {
        // Go through root data and replace the descendant data
        const tempRoot: FileNode = JSON.parse(JSON.stringify(root)); // Deep copy
        const parentFilenode = findFileNodeRecursively(tempRoot, data.parentId);
        let childFound = false;
        if (parentFilenode) {
          parentFilenode.children = parentFilenode.children.map((childFilenode) => {
            if (childFilenode.id === data.id) {
              childFound = true;
              colorizeFileNode(data);
              return data;
            }
            return childFilenode;
          });
          if (!childFound) {
            colorizeFileNode(data);
            parentFilenode.children.push(data);
          }
        }

        const newRoot = mergeFileNodes(root, tempRoot);
        setRoot(JSON.parse(JSON.stringify(newRoot)));
        const newParentFilenode = findFileNodeRecursively(newRoot, data.id);

        setOpenedFileNode(JSON.parse(JSON.stringify(newParentFilenode)));
      }
    }
  }, [
    fileNodeDescendantQuery.isSuccess,
    fileNodeDescendantQuery.isFetching,
    fileNodeDescendantQuery.isRefetching,
    fileNodeDescendantQuery.data,
  ]);

  useEffect(() => {
    if (searchText && searchText !== '') {
      const lowCaseSearchText = searchText.toLowerCase();
      const findFileNodesByName = (node: FileNode, searchString: string): FileNode[] => {
        const results: FileNode[] = [];

        function searchInNode(node: FileNode) {
          if (node.name.toLowerCase().includes(searchString)) {
            results.push(node);
          }

          if (node.children && node.children.length > 0) {
            for (const child of node.children) {
              searchInNode(child);
            }
          }
        }

        searchInNode(node);
        return results;
      };
      const filteredFileNodes = findFileNodesByName(root, lowCaseSearchText);
      setFilteredNodes({
        id: 'filtered',
        name: 'Filtered Items',
        path: '',
        fileType: FileNodeType.NONE,
        status: FileNodeStatus.CREATED,
        isDir: true,
        isSupport: false,
        children: filteredFileNodes,
      });
    } else {
      setFilteredNodes(null);
    }
  }, [searchText]);

  const handleNavigateToFileNode = (fileNode: FileNode) => {
    if (rootDataDelivery) {
      if (getViewAccessToken()) {
        // Viewer mode
        navigate('/shared-deliver/' + fileNode.id);
      } else {
        navigate('/deliver/' + fileNode.id);
      }
    } else if (fileNode.isDir) {
      setOpenedFileNode(fileNode);
      setFilteredNodes(null);
      setSearchText('');
    } else if (
      fileNode.status === FileNodeStatus.READY
      //&& (fileNode.fileType === FileNodeType.POINT_CLOUD || fileNode.fileType === FileNodeType.RASTER)
    ) {
      /*if (!getOrgSubscriptionValid() && !isViewAuthenticated()) {
        dispatch({ type: 'CHANGE_SHOW_UNSBSCRIBED_DIALOG', payload: { show: true } });
        setSelectedFileNode(null);
        return;
      }*/
      if (
        fileNode.fileType === FileNodeType.TEXT ||
        fileNode.fileType === FileNodeType.PDF ||
        fileNode.fileType === FileNodeType.IMAGE
      ) {
        window.open(fileNode.viewLayer.uri, '_blank');
      } else {
        if (getViewAccessToken()) {
          // Viewer mode
          navigate('/shared-deliver/' + rootId + '/' + fileNode.id);
        } else {
          navigate('/deliver/' + rootId + '/' + fileNode.id);
        }
      }
    }
    setSelectedFileNode(null);
  };

  const handleViewFileNode = (fileNode: FileNode) => {
    if (fileNode !== openedFileNode) {
      if (getViewAccessToken()) {
        // Viewer mode
        navigate('/shared-deliver/' + rootId + '/' + fileNode.id);
      } else {
        navigate('/deliver/' + rootId + '/' + fileNode.id);
      }
    }
  };

  const handleZoomToFileNode = (fileNode: FileNode) => {
    openLayerRef.current?.zoomToFileNode(fileNode);
  };

  const handleUpdateNavigationOptions = (options: DataDeliveryNavigationOptions) => {
    setNavigationOptions(options);
    updateFileNodeNavigationOptionsMutation.mutateAsync({
      showSupport: options.showSupport,
      baseMapId: options.baseMap?.id,
      rootId: root?.id,
    });
  };

  const handleUploadFileNode = (file: File, parentFileNode: FileNode) => {
    // IMPORTANT The Notification itself is responsible for the upload (because of it's persistency in the app)
    dispatch({
      type: 'UPDATE_UPLOAD_NOTIFICATION',
      payload: {
        uploadNotification: {
          id: uuidv4(),
          parentFileNode: parentFileNode,
          fileName: file.name,
          file: file,
          progress: 0,
          started: false,
        },
      },
    });
  };

  const handleCreateDirFileNode = (name: string, parentFileNode: FileNode) => {
    handleCreateFolderFileNode(name, parentFileNode).then((createFileNode: FileNode) => {
      handleProcessFileNode(createFileNode);
    });
  };

  return (
    <div
      style={{
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <ConfirmationDialog
          style={{ width: '400px' }}
          show={confirmingReprocessFilenode !== null}
          loading={false}
          title={'Reprocess Data'}
          text={`The data for ${confirmingReprocessFilenode?.name} has already been processed. Do you really want to re-process it?`}
          onClose={() => setConfirmingReprocessFilenode(null)}
          onConfirm={() => {
            processFileNodeMutation
              .mutateAsync({ fileNodeId: confirmingReprocessFilenode?.id, force: true })
              .then(() => {
                dispatch({
                  type: 'SHOW_NOTIFICATION',
                  payload: { notification: { content: 'Started FileNode Processing', type: 'success' } },
                });
              });
            setConfirmingReprocessFilenode(null);
          }}
        ></ConfirmationDialog>
        <AnnotationSidebar
          selectedFileNode={selectedFileNode}
          onBlurAnnotation={() => {
            setSelectedFileNode(null);
          }}
        />
        <div style={{ position: 'relative', flex: 1 }}>
          <div
            ref={viewerContainerRef}
            className="animated-left"
            style={{
              position: 'absolute',
              top: '0',
              bottom: '0',
              right: '0',
              left: '0',
              //left: layersOpened ? 'var(--geosap-viewer-layers-width)' : '0',
              zIndex: 0,
              //background: 'linear-gradient(135deg, rgba(2,0,36,1) 0%, rgba(12,58,19,1) 50%, rgba(5,119,68,1) 100%)',
              background: 'rgba(242,239,233,1)',
            }}
          >
            <div
              className="overlay-container"
              style={{ display: root === null && !fileNodeStructureQuery.isError ? 'flex' : 'none' }}
            >
              <Loader size="small" type={'converging-spinner'} themeColor={'primary'} />
            </div>
            <OLViewer
              height={viewerHeight}
              rootFileNode={root}
              selectedFileNode={openedFileNode}
              focusedFileNode={hoveredFileNode}
              selectedLayer={null}
              onLayerUpdated={() => {
                console.log();
              }}
              onFileNodeHovered={(fileNode: FileNode) => {
                setHoveredFileNode(fileNode);
              }}
              onFileNodeSelected={handleNavigateToFileNode /*handleSelectFileNode*/}
              navigationOptions={navigationOptions}
              layers={null}
              show={true}
              coordinates={[0, 0]}
              ref={openLayerRef}
            ></OLViewer>
          </div>
        </div>
        <NavigationSidebar
          loading={fileNodeStructureQuery.isLoading || fileNodeDescendantQuery.isLoading}
          rootFileNode={root}
          selectedFileNode={selectedFileNode}
          openedFileNode={filteredNodes ? filteredNodes : openedFileNode}
          hoveredFileNode={hoveredFileNode}
          focusedFileNode={focusedFileNode}
          rootId={rootId}
          navigationOptions={navigationOptions}
          onUpdateNavigationOptions={handleUpdateNavigationOptions}
          onProcess={handleProcessFileNode}
          onCreateFolderFileNode={handleCreateDirFileNode}
          onUpdateFileNode={handleUpdateFileNode}
          onUpdateFileNodeGeolocation={handleUpdateFileNodeGeolocation}
          onCleanFileNode={handleCleanFileNode}
          onForgetFileNode={handleForgetFileNode}
          onDeleteFileNode={handleDeleteFileNode}
          onDownloadFileNode={handleDownloadFileNode}
          onSelectFileNode={handleNavigateToFileNode /*handleSelectFileNode*/}
          onViewFileNode={handleViewFileNode}
          onZoomToFileNode={handleZoomToFileNode}
          onUploadFileNode={handleUploadFileNode}
          onHoverFileNode={(fileNode: FileNode) => {
            setHoveredFileNode(fileNode);
          }}
          onBack={() => {
            if (openedFileNode.parentId) {
              const newSelectedFileNode = findFileNodeRecursively(root, openedFileNode.parentId);
              setOpenedFileNode(newSelectedFileNode);
            } else if (!userHasAuthority(AuthorityType.DATA_AUTHORITY, AuthorityLevel.UPDATE)) {
              navigate('/deliver/root');
            } else {
              navigate('/process');
            }
          }}
          searchText={searchText}
          onSearchTextChange={(text) => {
            setSearchText(text);
          }}
        />
      </div>
    </div>
  );
};

export default GeosapViewer;
