import React, { useEffect } from 'react';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Typography } from '@progress/kendo-react-common';
import { Fade } from '@progress/kendo-react-animation';
import { useAppContext } from '../../context/app';
import { ZipProgressNotification } from '../../types';
import { ProgressBar } from './';
import { SubmitButton } from '../form/';

let timeoutId: number;

const ZipProgressNotifications: React.FC = () => {
  const { zippingNotifications, dispatch } = useAppContext();

  const handleClose = (notif: ZipProgressNotification) =>
    dispatch({ type: 'CLEAR_ZIP_NOTIFICATION', payload: { requestId: notif.requestId } });

  //useEffect(() => {
  //  if (notification && notification.type === 'success') {
  //    clearTimeout(timeoutId);
  //    timeoutId = window.setTimeout(handleClose, 6000);
  //  }
  //}, [notification]);

  //if (!notification) {
  //  return null;
  //}

  const renderNotification = (notif: ZipProgressNotification) => {
    const notifProgress = (notif.currentBytes + notif.fileCurrentBytes) / notif.totalBytes;

    return (
      <Fade>
        <Notification
          type={{ style: notifProgress === 1 ? 'success' : 'info', icon: true }}
          closable={true}
          onClose={() => {
            handleClose(notif);
          }}
        >
          <Typography.h6 fontSize="small">
            {notifProgress === 1 && `Files ready for ${notif.downloadFileName}`}
            {notifProgress !== 1 && `Preparing files for ${notif.downloadFileName}`}
          </Typography.h6>
          <ProgressBar
            progress={notifProgress * 100}
            progressStyle={{ backgroundColor: 'var(--geosap-selected-blue)' }}
          />
          {notifProgress === 1 && (
            <SubmitButton
              label="Download"
              size="small"
              style={{ marginTop: '0.5rem' }}
              onClick={() => {
                const a = document.createElement('a');
                a.href = notif.downloadUrl;
                a.download = notif.downloadFileName || ''; // Optionally set a filename
                a.style.display = 'none';

                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                dispatch({ type: 'CLEAR_ZIP_NOTIFICATION', payload: { requestId: notif.requestId } });
                dispatch({
                  type: 'SHOW_NOTIFICATION',
                  payload: {
                    notification: {
                      content:
                        'Download started for ' + notif.downloadFileName + " files. Check your browser's downloads.",
                      type: 'success',
                    },
                  },
                });
              }}
            ></SubmitButton>
          )}
        </Notification>
      </Fade>
    );
  };

  return (
    <NotificationGroup
      style={{
        right: 500,
        top: 0,
        alignItems: 'flex-start',
        flexWrap: 'wrap-reverse',
      }}
    >
      {zippingNotifications.map((notif: ZipProgressNotification) => {
        return renderNotification(notif);
      })}
    </NotificationGroup>
  );
};

export default ZipProgressNotifications;
