import React, { ChangeEvent, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { SapFlowConfigParamType } from '../../types/';
import { Input, NumericInput, Select } from '../../components/form';
import { InputChangeEvent, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';

interface ListItem {
  value: string;
}

interface ListEditorProps {
  possibleValues?: string[];
  type: SapFlowConfigParamType;
  formInstance: any;
  values: ListItem[];
  onChange: (newValues: ListItem[]) => void;
}

const ListEditor: React.FC<ListEditorProps> = ({ possibleValues, type, formInstance, values, onChange }) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');

  const handleAddItem = () => {
    if (selectedItem || inputValue) {
      const newItem: ListItem = {
        value: inputValue,
      };
      onChange([...values, newItem]);
      setInputValue('');
    }
  };

  const handleRemoveItem = (index: number) => {
    const updatedItems = [...values];
    updatedItems.splice(index, 1);
    onChange(updatedItems);
  };

  const handleSelectedItemChange = (event: DropDownListChangeEvent) => {
    setSelectedItem(event.target.value);
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const renderInputField = () => {
    if (possibleValues && possibleValues.length > 0) {
      return (
        <Select
          className="w-75 p-3"
          data={possibleValues.map((val: string) => {
            return { name: val };
          })}
          name={''}
          dataItemKey={'name'}
          textField={'name'}
          value={inputValue}
          onChange={(event: DropDownListChangeEvent) => {
            handleInputChange(event.target.value);
          }}
        />
      );
    }
    if (type === SapFlowConfigParamType.ListString) {
      return (
        <Input
          type="text"
          name={''}
          value={inputValue}
          onChange={(event: InputChangeEvent) => {
            handleInputChange(event.target.value + '');
          }}
        />
      );
    } else if (type === SapFlowConfigParamType.ListInt) {
      return (
        <NumericInput
          className="w-75"
          name={''}
          value={isNaN(parseInt(inputValue)) ? null : parseInt(inputValue)}
          onChange={(event: NumericTextBoxChangeEvent) => {
            handleInputChange(event.target.value + '');
          }}
        />
      );
    } else if (type === SapFlowConfigParamType.ListFloat) {
      return (
        <NumericInput
          className="w-75"
          name={''}
          value={isNaN(parseFloat(inputValue)) ? null : parseFloat(inputValue)}
          onChange={(event: NumericTextBoxChangeEvent) => {
            handleInputChange(event.target.value + '');
          }}
        />
      );
    }
  };

  return (
    <div>
      {renderInputField()}
      <Button type="button" onClick={handleAddItem} className="mx-2">
        Add Item
      </Button>
      <ul>
        {values.map((item, index) => (
          <li key={index}>
            {item.value}
            <Button type="button" onClick={() => handleRemoveItem(index)} className="mx-2">
              Remove
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListEditor;
