import React from 'react';
import { Typography } from '@progress/kendo-react-common';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { REQUIRED_FIELD } from '../../common/constants';
import { SubmitButton, Select } from '../../components/form';
import { useForm, Controller } from 'react-hook-form';

interface FormValues {
  defaultUnit: string;
  defaultScales: string;
}

const validationSchema = yup.object({
  defaultUnit: yup.object().required(REQUIRED_FIELD),
  defaultScales: yup.object().required(REQUIRED_FIELD),
});

const GeoSAPSettings: React.FC = () => {
  const { control, formState } = useForm<FormValues>({
    defaultValues: {
      defaultUnit: '',
      defaultScales: '',
    },
    resolver: yupResolver(validationSchema),
  });

  return (
    <div className="container-fluid p-2 border bg-white">
      <div className="d-flex flex-column col-12 col-md-8 mx-auto">
        <Typography.h2 className='text-center pt-3 pb-1'>
          GeoSAP SETTINGS
        </Typography.h2>
        <Typography.h5 className=''>
          Measurement Settings
        </Typography.h5>

        <div className="d-flex flex-column py-3">
            <div className="d-flex">
              <span className='col-3 text-center d-flex align-items-center ps-3'>DEFAULT UNITS</span>
                <div className="col-9">
                  <Controller
                    control={control}
                    name="defaultUnit"
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        name={field.name}
                        value={field.value}
                        error={error?.message}
                        data={["ABC, DEF, GFD"].map((item)=>{return {id: item, label: item}})}
                        dataItemKey="id"
                        textField="label"
                        placeholder=""
                        loading={false}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        autoFocus
                      />
                    )}
                  />
                </div>
            </div>
            <div className="d-flex">
              <span className='col-3 text-center d-flex align-items-center ps-3'>DEFAULT SCALES</span>
                <div className="col-9">
                  <Controller
                    control={control}
                    name="defaultScales"
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        name={field.name}
                        value={field.value}
                        error={error?.message}
                        data={["ABC, DEF, GFD"].map((item)=>{return {id: item, label: item}})}
                        dataItemKey="id"
                        textField="label"
                        placeholder=""
                        loading={false}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        autoFocus
                      />
                    )}
                  />
                </div>
            </div>
            <div className="pt-5 d-flex justify-content-center">
              <SubmitButton label="Save Changes" disabled={!formState.isDirty} full={false}/>
            </div>
          </div>
      </div>
    </div>
  );
};

export default GeoSAPSettings;
