import { FileNodeUploadWizardState, initialState, FileNodeUploadWizardStep } from './fileNodeUploadWizardState';

export interface SelectFilesAction {
  type: 'SELECT_FILES';
  payload: {
    files: File[];
  };
}

export interface ChangeStepAction {
  type: 'CHANGE_STEP';
  payload: {
    step: FileNodeUploadWizardStep;
  };
}

export interface DoneAction {
  type: 'DONE';
  //payload: {
  //  step: FileNodeUploadWizardStep;
  //};
}

export interface GoBackAction {
  type: 'GO_BACK';
  payload: {
    //step: FileNodeUploadWizardStep;
  };
}

export type FileNodeUploadWizardAction = SelectFilesAction | DoneAction | GoBackAction | ChangeStepAction;

export const fileNodeUploadWizardReducer = (
  currentState: FileNodeUploadWizardState,
  action: FileNodeUploadWizardAction
): FileNodeUploadWizardState => {
  switch (action.type) {
    case 'SELECT_FILES':
      return {
        ...currentState,
        files: action.payload.files,
      };
    case 'CHANGE_STEP':
      return {
        ...currentState,
        step: action.payload.step,
      };
    case 'DONE': {
      return {
        ...initialState,
        done: true,
      };
    }
    case 'GO_BACK': {
      let previousStep = FileNodeUploadWizardStep.UNSUPPORTED;
      if (currentState.step === FileNodeUploadWizardStep.SELECT_DESTINATION) {
        previousStep = FileNodeUploadWizardStep.SELECT_FILES;
      }
      //else/* if(currentState.step === FileNodeUploadWizardStep.SUBMIT_URL || currentState.step === FileNodeUploadWizardStep.SELECT_GEOTIF) */{
      //  previousStep = FileNodeUploadWizardStep.SELECT_TYPE;
      //}
      return {
        ...currentState,
        step: previousStep,
      };
    }
  }
};
