import {
  TusUpload,
} from '../../types';

export interface UploadContextState {
  uploadsOpened: boolean;
  uploads: TusUpload[];
  selectedUpload: TusUpload | null;
  uploadsRetrieved: boolean;
}

export const initialState: UploadContextState = {
  uploadsOpened: false,
  uploads: [],
  uploadsRetrieved: false,
  selectedUpload: null,
};
