import React, { useEffect } from 'react';
import Dialog from '../../../../components/Dialog';
import { Typography } from '@progress/kendo-react-common';
import { useCreateAddLayerWizardState } from '../../../../context/addLayerWizard';
import AddLayerWizard from './AddLayerWizard';

interface AddLayerModalProps {
  show: boolean;
  handleDone: any;
  handleClose: any;
  viewerConfigId: string;
}

const AddLayerModal: React.FC<AddLayerModalProps> = (props: AddLayerModalProps) => {
  const wizardState = useCreateAddLayerWizardState(props.viewerConfigId);
  const { dispatch } = wizardState;

  useEffect(() => {
    dispatch({ type: 'CHANGED_VIEWER', payload: { viewerConfigId: props.viewerConfigId } });
  }, [props.viewerConfigId]);

  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>Add visualization layer</Typography.h3>}
      onClose={() => {
        dispatch({ type: 'DONE', payload: {} });
        props.handleClose();
      }}
      style={{ width: '600px', maxWidth: '80%', maxHeight: '80%', minHeight: '500px' }}
    >
      <AddLayerWizard
        viewerConfigId={props.viewerConfigId}
        wizardState={wizardState}
        onDone={props.handleDone}
        onClose={props.handleClose}
      />
    </Dialog>
  );
};

export default AddLayerModal;
