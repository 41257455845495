import { useContext, useEffect, useImperativeHandle, forwardRef, useState } from 'react';
import MapContext from '../Map/MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector.js';
import Feature from 'ol/Feature.js';
import { Fill, Icon, Stroke, Style } from 'ol/style.js';
import Point from 'ol/geom/Point.js';
import Polygon from 'ol/geom/Polygon.js';
import { fromLonLat } from 'ol/proj';
import { FileNode } from '../../../../types/DataDelivery';
import svg from '../../../../assets/icons/annotation.svg';
import { easeOut } from 'ol/easing.js';
import { unByKey } from 'ol/Observable.js';

interface Props {
  show: boolean;
  fileNode: FileNode;
  zIndex: number;
  highlight: boolean;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
  getOlLayer: (layer: any) => any;
} | null;
const VectorLayer = forwardRef<Ref, Props>(({ show, fileNode, zIndex, highlight }, ref) => {
  const { map } = useContext<any>(MapContext);
  const [style, setStyle] = useState<any>();
  const [vectorLayer, SetVectorLayer] = useState<any>();
  const [feature, setFeature] = useState<any>();

  useEffect(() => {
    if (!map || !fileNode || !fileNode.geolocation) {
      return;
    }

    const sitePoints = [];

    sitePoints.push(fromLonLat([fileNode.geolocation.lonMin, fileNode.geolocation.latMin]));
    sitePoints.push(fromLonLat([fileNode.geolocation.lonMin, fileNode.geolocation.latMax]));
    sitePoints.push(fromLonLat([fileNode.geolocation.lonMax, fileNode.geolocation.latMax]));
    sitePoints.push(fromLonLat([fileNode.geolocation.lonMax, fileNode.geolocation.latMin]));
    sitePoints.push(sitePoints[0]);
    const styles = [
      /* We are using two different styles for the polygons:
       *  - The first style is for the polygons themselves.
       *  - The second style is to draw the vertices of the polygons.
       *    In a custom `geometry` function the vertices of a polygon are
       *    returned as `MultiPoint` geometry, which will be used to render
       *    the style.
       */
      new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)',
        }),
      }),
    ];

    //let linearRing = new LinearRing(sitePoints);
    //let geometry = new Polygon([sitePoints]);
    const polygonFeature = new Feature({
      geometry: new Polygon([sitePoints]),
    });
    polygonFeature.setProperties({ disabledInteract: false, fileNode: fileNode });

    const source = new VectorSource({
      features: [polygonFeature],
    });

    const overlayLayer = new OLVectorLayer({
      source: source,
      style: styles,
    });

    //map.addLayer(overlayLayer);
    overlayLayer.setZIndex(zIndex - 1);

    const iconFeature = new Feature({
      geometry: new Point(fromLonLat([fileNode.geolocation.lonCenter, fileNode.geolocation.latCenter])),
      population: 4000,
      rainfall: 500,
      name: fileNode.id,
    });
    iconFeature.setProperties({ interactable: true, fileNode: fileNode });
    const iconStyle = new Style({
      image: new Icon({
        color: fileNode?.color ? fileNode?.color : '#000000',
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: svg,
        scale: 0.75,
      }),
    });
    setStyle(iconStyle);

    iconFeature.setStyle(iconStyle);

    setFeature(iconFeature);

    const vectorSource = new VectorSource({
      features: [iconFeature],
    });

    const newVectorLayer = new OLVectorLayer({
      source: vectorSource,
      visible: show,
      zIndex: 100000,
    });

    map.addLayer(newVectorLayer);
    newVectorLayer.setZIndex(zIndex);
    SetVectorLayer(newVectorLayer);

    return () => {
      if (map) {
        map.removeLayer(newVectorLayer);
        map.removeLayer(overlayLayer);
      }
    };
  }, [map, fileNode]);

  useEffect(() => {
    if (!vectorLayer) return;

    const duration = 250;
    function flash(feature: Feature, growing: boolean) {
      const start = Date.now();
      const listenerKey = vectorLayer.on('postrender', animate);
      map.render();

      function animate(event: any) {
        const frameState = event.frameState;
        const elapsed = frameState.time - start;
        if (elapsed >= duration) {
          unByKey(listenerKey);
          return;
        }
        const elapsedRatio = elapsed / duration;
        let scale = 0.75 + easeOut(elapsedRatio) * 0.25;
        if (!growing) {
          scale = 1 - easeOut(elapsedRatio) * 0.25;
        }
        const style = new Style({
          image: new Icon({
            color: fileNode?.color ? fileNode?.color : '#000000',
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: svg,
            scale: scale,
          }),
        });

        feature.setStyle(style);
        // tell OpenLayers to continue postrender animation
        map.render();
      }
    }

    (vectorLayer.getSource() as VectorSource).getFeatures().forEach((f: any) => {
      flash(f, highlight);
    });
  }, [highlight, vectorLayer]);

  const zoomToLayer = (layer: any) => {
    //map.getView().fit(source.getExtent());
  };

  const getOlLayer = (layer: any) => {
    return vectorLayer;
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
    getOlLayer,
  }));

  return null;
});
VectorLayer.displayName = 'VectorLayer';

export default VectorLayer;
