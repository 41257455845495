import { useQueryClient, useMutation } from 'react-query';
import { useAxiosInstance } from '../common';
import { FileNode } from '../../types/DataDelivery';

interface CreateParams {
  name: string;
  parentId: string;
  rootId: string;
}

const useCreateFolderFileNode = (orgId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: CreateParams) => {
      const response = await axios.post<FileNode>('filenode/dir', params);
      return response.data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['rootStructure', orgId], { refetchInactive: true });
        if (variables.rootId) {
          queryClient.invalidateQueries(['fileNodeStructure', variables.rootId], { refetchInactive: true });
        }
      },
    }
  );
};

export default useCreateFolderFileNode;
