import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { SvgIcon } from '@progress/kendo-react-common';
import { rotateIcon } from '@progress/kendo-svg-icons';
import { SapFlow, SapFlowGroup, SapFlowProcessNode } from '../../types/DataDelivery';
import { TreeView, TreeViewExpandChangeEvent } from '@progress/kendo-react-treeview';
import {
  useCleanConfigSapFlows,
  useCreateSapGroupFromTemplate,
  useGenerateConfigSapFlows,
  useSapGroups,
} from '../../hooks/data_delivery';
import {
  extractFirstAndLastLeaves,
  getSelectedBranches,
  removeTreeSapProcessNodes,
  sortSelectedSapItems,
} from '../../common/dataProcessingHelper';
import {
  DataProcessingContextProvider,
  useCreateDataProcessingContextState,
} from '../../context/dataProcessingContext';
import DataProjectTreeItem from './DataProcessingTreeItem';
import { TreeSapGroup, TreeSapFlow, TreeSapProcessNode, AuthorityType, AuthorityLevel } from '../../types';
import SapConfigProcessingConfiguration from './SapConfigProcessingConfiguration';
import SapFlowProcessingConfiguration from './SapFlowProcessingConfiguration';
import SapFlowStatusHistory from './SapFlowStatusHistory';
import ProcessNodeStatusHistory from './ProcessNodeStatusHistory';
import { Button } from '@progress/kendo-react-buttons';
import { SubmitButton } from '../../components/form';
import { RestrictedSubmitButton } from '../../components/restricted';
import { useQueryClient } from 'react-query';

const DataProcessing: React.FC = () => {
  const wizardState = useCreateDataProcessingContextState();
  const sapGroupsQuery = useSapGroups();
  const queryClient = useQueryClient();
  const [showSapConfigParams, setShowSapConfigParams] = useState(false);
  const [configuringSapConfig, setConfiguringSapConfig] = useState(null);
  const [showSapFlowParams, setShowSapFlowParams] = useState(false);
  const [configuringSapFlow, setConfiguringSapFlow] = useState(null);
  const [showSapFlowHistory, setShowSapFlowHistory] = useState(false);
  const [sapFlowHistory, setSapFlowHistory] = useState(null);
  const [showProcessNodeHistory, setShowProcessNodeHistory] = useState(false);
  const [processNodeHistory, setProcessNodeHistory] = useState(null);
  const generateSapGroupsMutation = useGenerateConfigSapFlows();
  const cleanConfigSapFlowsMutation = useCleanConfigSapFlows();
  const createSapGroupsFromTemplateMutation = useCreateSapGroupFromTemplate();
  useEffect(() => {
    if (sapGroupsQuery.isSuccess) {
      wizardState.dispatch({ type: 'ProcessSapGroups', payload: { sapGroups: sapGroupsQuery.data } });
    }
  }, [sapGroupsQuery.data]);

  const handleItemExpand = async (event: TreeViewExpandChangeEvent) => {
    const itemPath = event.itemHierarchicalIndex;
    const itemId = event.item.id;
    wizardState.dispatch({ type: 'HandleItemExpand', payload: { itemPath, itemId } });
  };

  const generateSapGroup = (item: TreeSapGroup, itemHierarchicalIndex: string) => {
    wizardState.dispatch({
      type: 'UpdateTreeItemValue',
      payload: { itemPath: itemHierarchicalIndex, property: 'generating', value: true },
    });
    generateSapGroupsMutation
      .mutateAsync(item.configId)
      .then(() => {
        wizardState.dispatch({
          type: 'UpdateTreeItemValue',
          payload: { itemPath: itemHierarchicalIndex, property: 'generating', value: false },
        });
      })
      .catch(() => {
        wizardState.dispatch({
          type: 'UpdateTreeItemValue',
          payload: { itemPath: itemHierarchicalIndex, property: 'generating', value: false },
        });
      });
  };

  const cleanSapGroup = (item: TreeSapGroup, itemHierarchicalIndex: string) => {
    wizardState.dispatch({
      type: 'UpdateTreeItemValue',
      payload: { itemPath: itemHierarchicalIndex, property: 'cleaning', value: true },
    });
    cleanConfigSapFlowsMutation
      .mutateAsync(item.configId)
      .then(() => {
        wizardState.dispatch({
          type: 'UpdateTreeItemValue',
          payload: { itemPath: itemHierarchicalIndex, property: 'cleaning', value: false },
        });
      })
      .catch(() => {
        wizardState.dispatch({
          type: 'UpdateTreeItemValue',
          payload: { itemPath: itemHierarchicalIndex, property: 'cleaning', value: false },
        });
      });
  };
  const closeSapConfigParams = () => {
    setShowSapConfigParams(false);
  };
  const closeSapFlowParams = () => {
    setShowSapFlowParams(false);
  };
  const closeSapFlowHistory = () => {
    setShowSapFlowHistory(false);
  };
  const closeProcessNodeHistory = () => {
    setShowProcessNodeHistory(false);
  };

  const handleCreateSapGroup = () => {
    // Start by sorting the selected sap items by their name which is either
    const selectedTreeData = getSelectedBranches(wizardState.treeData);
    // Cut off ProcessNode items from the selectedTreeData
    const selectedGroupsAndSapFlows = removeTreeSapProcessNodes(selectedTreeData);
    // Extract first and last leaves for group naming
    const [firstLeaf, lastLeaf] = extractFirstAndLastLeaves(selectedGroupsAndSapFlows);

    createSapGroupsFromTemplateMutation.mutateAsync(selectedGroupsAndSapFlows).then(() => {
      queryClient.invalidateQueries(['sapGroups'], { refetchInactive: true });
    });

    wizardState.dispatch({ type: 'CompleteSapGroupCreation', payload: {} });
  };

  const handleCancelSapGroupCreation = () => {
    wizardState.dispatch({ type: 'CancelSapGroupCreation', payload: {} });
  };

  // Define props for the wrapper component
  interface MemoizedDataProjectTreeItemProps {
    item: any;
    itemHierarchicalIndex: string;
    parent: any; // Update the type of parent according to your requirements
    onConfigure: () => void;
    onHistory: () => void;
  }

  const MemoizedDataProjectTreeItem: React.FC<MemoizedDataProjectTreeItemProps> = React.memo(
    ({ item, itemHierarchicalIndex, parent, onConfigure, onHistory }) => {
      return (
        <DataProjectTreeItem
          item={item}
          itemHierarchicalIndex={itemHierarchicalIndex}
          parent={parent}
          onConfigure={onConfigure}
          onHistory={onHistory}
          onGenerateSapGroup={generateSapGroup}
          onCleanSapGroup={cleanSapGroup}
        />
      );
    },
    (prevProps, nextProps) => {
      return true;
    }
  );
  MemoizedDataProjectTreeItem.displayName = 'MemoizedDataProjectTreeItem';

  return (
    <div className="h-100 p-5">
      <DataProcessingContextProvider value={wizardState}>
        <SapConfigProcessingConfiguration
          show={showSapConfigParams}
          handleClose={closeSapConfigParams}
          onConfirm={closeSapConfigParams}
          configId={(configuringSapConfig as TreeSapGroup)?.configId}
        ></SapConfigProcessingConfiguration>
        <SapFlowProcessingConfiguration
          show={showSapFlowParams}
          handleClose={closeSapFlowParams}
          onConfirm={closeSapFlowParams}
          configId={(configuringSapFlow as TreeSapFlow)?.configId}
          sapflowId={(configuringSapFlow as TreeSapFlow)?.id}
        ></SapFlowProcessingConfiguration>
        <SapFlowStatusHistory
          show={showSapFlowHistory}
          handleClose={closeSapFlowHistory}
          onConfirm={closeSapFlowHistory}
          sapflowId={(sapFlowHistory as TreeSapFlow)?.id}
        ></SapFlowStatusHistory>
        <ProcessNodeStatusHistory
          show={showProcessNodeHistory}
          handleClose={closeProcessNodeHistory}
          onConfirm={closeProcessNodeHistory}
          sapflowId={(sapFlowHistory as TreeSapFlow)?.id}
          processNodeId={(processNodeHistory as TreeSapProcessNode)?.id}
        ></ProcessNodeStatusHistory>
        <Typography.h3
          fontWeight="normal"
          className="text-uppercase"
          style={{ paddingBottom: '1rem', display: 'flex' }}
        >
          Client Project Processing
          <Button
            svgIcon={rotateIcon}
            themeColor={'base'}
            style={{ marginLeft: 'auto', height: 'fit-content', padding: '0.5rem 0.5rem' }}
            onClick={() => sapGroupsQuery.refetch()}
          ></Button>
          <RestrictedSubmitButton
            label={
              wizardState.creatingGroup
                ? wizardState.somethingSelected
                  ? 'Create Group'
                  : 'Cancel Creation'
                : 'Select SapFlows'
            }
            fillMode="outline"
            themeColor={wizardState.creatingGroup && !wizardState.somethingSelected ? 'error' : 'primary'}
            size="medium"
            height="fit-content"
            full={false}
            onClick={() => {
              wizardState.creatingGroup
                ? wizardState.somethingSelected
                  ? handleCreateSapGroup()
                  : handleCancelSapGroupCreation()
                : wizardState.dispatch({ type: 'StartSapGroupCreation' });
            }}
            authorityType={AuthorityType.DATA_AUTHORITY}
            authorityLevel={AuthorityLevel.EXECUTE}
            xMargin="mx-4"
          ></RestrictedSubmitButton>
        </Typography.h3>
        {sapGroupsQuery.isLoading ? (
          <p>Loading root file nodes...</p>
        ) : (
          <TreeView
            data={wizardState.treeData}
            expandIcons={true}
            item={(itemProps) => {
              const itemHierarchy = itemProps.itemHierarchicalIndex.split('_');
              let parent: TreeSapGroup | TreeSapFlow | TreeSapProcessNode = null;
              if (itemHierarchy.length > 1) {
                parent = wizardState.treeData[parseInt(itemHierarchy[0])];
                for (let i = 1; i < itemHierarchy.length - 1; i++) {
                  parent = parent.items[parseInt(itemHierarchy[i])];
                }
              }
              return (
                <MemoizedDataProjectTreeItem
                  item={itemProps.item}
                  itemHierarchicalIndex={itemProps.itemHierarchicalIndex}
                  parent={parent}
                  onConfigure={() => {
                    if (itemProps.item.type === 'TreeSapFlow') {
                      setShowSapFlowParams(true);
                      setConfiguringSapFlow(itemProps.item);
                    } else {
                      setShowSapConfigParams(true);
                      setConfiguringSapConfig(itemProps.item);
                    }
                  }}
                  onHistory={() => {
                    if (itemProps.item.type === 'TreeSapFlow') {
                      setShowSapFlowHistory(true);
                      setSapFlowHistory(itemProps.item);
                    } else if (itemProps.item.type === 'TreeSapProcessNode') {
                      setShowProcessNodeHistory(true);
                      setSapFlowHistory(parent);
                      setProcessNodeHistory(itemProps.item);
                    }
                  }}
                />
              );
            }}
            textField="name"
            onExpandChange={handleItemExpand}
          />
        )}
      </DataProcessingContextProvider>
    </div>
  );
};

export default DataProcessing;
