import React from 'react';
import { AuthorityType, AuthorityLevel } from '../../types';
import { useUser } from '../../hooks/authentication';

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  authorityType: AuthorityType;
  authorityLevel: AuthorityLevel;
}

const RestrictedAnchor: React.FC<Props> = (props) => {
  const { userHasAuthority } = useUser();

  // Destructuring to separate custom props and anchor element props
  const { authorityType, authorityLevel, children, ...anchorProps } = props;

  if (!userHasAuthority(authorityType, authorityLevel)) {
    return null;
  }

  return <a {...anchorProps}>{children}</a>;
};

export default RestrictedAnchor;
