export interface RampColor {
  name: string;
  values: RampColorValue[];
}

export interface RampColorValue {
  color: any;
  value: number;
}

export const RAMP_COLORS: RampColor[] = [
  {
    name: 'Grayscale',
    values: [
      {
        color: 'black',
        value: 0,
      },
      {
        color: 'white',
        value: 1,
      },
    ],
  },
  {
    name: 'RdToGn',
    values: [
      {
        color: [215, 25, 28],
        value: 0,
      },
      {
        color: [253, 174, 97],
        value: 0.25,
      },
      {
        color: [255, 255, 191],
        value: 0.5,
      },
      {
        color: [171, 221, 164],
        value: 0.75,
      },
      {
        color: [43, 131, 186],
        value: 1,
      },
    ],
  },
  {
    name: 'Viridis',
    values: [
      {
        color: [48, 18, 59],
        value: 0,
      },
      {
        color: [40, 188, 235],
        value: 0.25,
      },
      {
        color: [164, 252, 60],
        value: 0.5,
      },
      {
        color: [251, 126, 33],
        value: 0.75,
      },
      {
        color: [122, 4, 3],
        value: 1,
      },
    ],
  },
];
