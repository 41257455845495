import React, { useEffect, useState } from 'react';
import { RangeSlider, Slider, SliderLabel } from '@progress/kendo-react-inputs';
import DraggableWindow from '../../../../components/DraggableWindow';
import { BandInfo, LayerType, ViewLayer } from '../../../../types';
import { useConsumeViewerState } from '../../../../context/viewer';
import { updateLayerParam } from '../../../../common/viewerConfigHelper';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { RasterVisualization, RAMP_COLORS } from '../../../../types/Rendering';
import { NumericInput } from '../../../../components/form';

interface OLGeotifControlsProps {
  layer: ViewLayer;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

const SinglebandControls: React.FC<OLGeotifControlsProps> = ({ layer, onLayerUpdated }) => {
  const [availableBands, setAvailableBands] = useState([]);
  const [BandColorRamp, setCurrentlySelectedBand] = useState(null);
  const [availableColorRamps, setAvailableColorRamps] = useState([]);
  const [currentlySelectedColorRamp, setCurrentlySelectedColorRamp] = useState(RAMP_COLORS[0]);
  const [bandInfos, setBandInfos] = useState([]);
  const [currentBandIndex, setCurrentBandIndex] = useState(0);
  const [currentMin, setCurrentMin] = useState(0);
  const [currentMax, setCurrentMax] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [editingMin, setEditingMin] = useState(false);
  const [editingMax, setEditingMax] = useState(false);
  const gamma = layer.paramsMap.visualization.singleband.gamma | 1;
  const brightness = layer.paramsMap.visualization.singleband.gamma | 0.5;
  const contrast = layer.paramsMap.visualization.singleband.gamma | 1;

  useEffect(() => {
    if (layer) {
      const bands: BandInfo[] = layer.paramsMap['bands'];
      if (bands) {
        const visualization: RasterVisualization = layer.paramsMap['visualization'];
        const singlebandVis = visualization.singleband;
        setAvailableBands(bands);

        setAvailableColorRamps(RAMP_COLORS);
        setCurrentlySelectedColorRamp(singlebandVis.rampColor ? singlebandVis.rampColor : RAMP_COLORS[1]);

        setCurrentBandIndex(
          singlebandVis.bandIndex !== null && singlebandVis.bandIndex !== undefined ? singlebandVis.bandIndex - 1 : 0
        );

        if (singlebandVis.min !== null && singlebandVis.min !== undefined) {
          setMin(singlebandVis.min);
          if (singlebandVis.currentMin !== null && singlebandVis.currentMin !== undefined) {
            setCurrentMin(singlebandVis.currentMin);
          } else {
            setCurrentMin(singlebandVis.min);
          }
        }
        if (singlebandVis.max !== null && singlebandVis.max !== undefined) {
          setMax(singlebandVis.max);
          if (singlebandVis.currentMax !== null && singlebandVis.currentMax !== undefined) {
            setCurrentMax(singlebandVis.currentMax);
          } else {
            setCurrentMax(singlebandVis.max);
          }
        }
      }
    } else if (!layer) {
      setAvailableColorRamps([]);
      setCurrentlySelectedColorRamp(null);
    }
  }, [layer]);

  const handleBandChange = (newBand: BandInfo) => {
    const vis: RasterVisualization = layer.paramsMap.visualization;
    vis.singleband.bandIndex = newBand.index;
    vis.singleband.bandName = newBand.name;
    vis.singleband.min = newBand.min;
    vis.singleband.max = newBand.max;
    updateLayerParam(layer, 'visualization', vis);
    setCurrentBandIndex(newBand.index - 1);
    onLayerUpdated(layer);
  };

  const handleSliderChange = (newMin: number, newMax: number) => {
    const newLayer = JSON.parse(JSON.stringify(layer));
    const vis = newLayer.paramsMap.visualization;
    let i = 0;
    for (i = 0; i < newLayer.paramsMap.bands.length; i++) {
      if (newLayer.paramsMap.bands[i].name === vis.singleband.bandName?.name) {
        const newBands = newLayer.paramsMap.bands;
        newBands[i].currentMin = newMin;
        newBands[i].currentMax = newMax;
        updateLayerParam(newLayer, 'bands', newBands);
        break;
      }
    }
    const newVis = newLayer.paramsMap.visualization;
    newVis.singleband.currentMin = newMin;
    newVis.singleband.currentMax = newMax;
    updateLayerParam(newLayer, 'visualization', newVis);
    setCurrentMin(newMin);
    setCurrentMax(newMax);
    onLayerUpdated(newLayer);
  };

  return (
    <div className="d-flex flex-column p-2 mb-4">
      <FloatingLabel
        label={'Band'}
        editorId={'visType'}
        editorValue={currentBandIndex !== null && currentBandIndex !== undefined ? '' + currentBandIndex : ''}
        className="w-75 mx-auto pb-2"
      >
        <DropDownList
          style={{
            width: '200px',
          }}
          size="small"
          data={availableBands}
          value={availableBands[currentBandIndex]}
          dataItemKey="name"
          textField="name"
          onChange={(event: any) => {
            handleBandChange(event.target.value);
          }}
          disabled={layer === null}
          popupSettings={{ width: 250 }}
        />
      </FloatingLabel>

      <FloatingLabel
        label={'Color Ramp'}
        editorId={'colorRamp'}
        editorValue={currentlySelectedColorRamp ? '' + currentlySelectedColorRamp : ''}
        className="w-75 mx-auto pb-2"
      >
        <DropDownList
          style={{
            width: '200px',
          }}
          size="small"
          data={availableColorRamps}
          value={currentlySelectedColorRamp}
          dataItemKey="name"
          textField="name"
          onChange={(event: any) => {
            const vis = layer.paramsMap.visualization;
            vis.singleband.rampColor = event.target.value;
            updateLayerParam(layer, 'visualization', vis);
            setCurrentlySelectedColorRamp(event.target.value);
            onLayerUpdated(layer);
          }}
          disabled={layer === null}
          popupSettings={{ width: 250 }}
        />
      </FloatingLabel>

      <RangeSlider
        style={{ marginLeft: '2.5rem', paddingTop: '0.8rem' }}
        step={(max - min) / 100}
        min={min}
        max={max}
        disabled={layer === null}
        value={{ start: currentMin, end: currentMax }}
        onChange={(e) => {
          handleSliderChange(e.value.start, e.value.end);
        }}
      >
        {[
          { pos: min, text: min },
          { pos: max, text: max },
        ].map((label, i) => (
          <SliderLabel key={i} position={label.pos}>
            <span style={{ fontSize: '0.75rem' }}>{parseFloat(label.text.toFixed(6))}</span>
          </SliderLabel>
        ))}
      </RangeSlider>
      <div
        className="w-75 mx-auto pt-1 pb-2"
        style={{ display: 'flex', alignItems: 'center', marginTop: '1.5rem', justifyContent: 'space-between' }}
      >
        {!editingMin && (
          <span
            onClick={() => {
              setEditingMin(true);
              setEditingMax(false);
            }}
          >
            {parseFloat(currentMin.toFixed(6))}
          </span>
        )}
        {editingMin && (
          <NumericInput
            name={''}
            className="me-2"
            value={currentMin}
            onChange={(e) => {
              handleSliderChange(e.value, currentMax);
            }}
            hideSpinner={true}
            onBlur={() => {
              setEditingMin(false);
            }}
          />
        )}
        {!editingMax && (
          <span
            onClick={() => {
              setEditingMax(true);
              setEditingMin(false);
            }}
          >
            {parseFloat(currentMax.toFixed(6))}
          </span>
        )}
        {editingMax && (
          <NumericInput
            name={''}
            className="me-2"
            value={currentMax}
            onChange={(e) => {
              handleSliderChange(currentMin, e.value);
            }}
            hideSpinner={true}
            onBlur={() => {
              setEditingMax(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SinglebandControls;
