import { Feature } from 'ol';
import { Appearance3D, BaseMap, DrawingMode, FeatureAttributeType, NavigationMode2D, PotreePointShape, PotreePointSizing, SapFlowViewConfig, Unit, ViewLayer } from '../../types';
import { Projection } from 'ol/proj';
import OLMap from 'ol/Map';

export interface ViewerState {
  domViewerRef: React.MutableRefObject<HTMLElement | null> | null;
  viewerType: 'TRANSACTION' | 'FILENODE' | null;
  viewerHeight: number;
  viewConfig: SapFlowViewConfig;
  viewConfigParams: any;
  layers: ViewLayer[];
  selectedLayer: ViewLayer;
  currentPerspective: '2D' | '3D';
  layersToolbarVisible: boolean;
  displayBaseMap: boolean;
  available2D: boolean;
  available3D: boolean;
  viewConfigPropertiesOpened: any;
  throttlingUpdate: boolean;
  potreeInitialized: boolean;
  potreeProjection: string;
  olMap: OLMap;
  olMapInitialized: boolean;
  olMapProjection: Projection;
  displayedProjection2D: Projection;
  displayedProjection3D: Projection;
  drawingMode: DrawingMode;
  mode2d: NavigationMode2D;
  appearance3D: Appearance3D;
  profiles: any[];
  hoveredFeature: { layerId: string; featureId: any };
  availableBaseMaps: BaseMap[];
  baseMap: BaseMap;
  units: Unit;
  featureFilter: {
    layerId: string;
    attribute: { name: string; type: FeatureAttributeType; possibleValues: string[]; min: number; max: number };
    attributeValue: string;
    min: number;
    max: number;
  };
  cursorPosition2D: {
    x: number;
    y: number;
  };
  cameraPosition2D: number[];
  zoomLevel2D: number;
  cameraPosition3D: {
    x: number;
    y: number;
    z: number;
  }
  cameraTarget3D: {
    x: number;
    y: number;
    z: number;
  }
  selectedFeature: {
    layerId: string;
    feature: Feature
  }
  [x: string]: any;
}

export const initialState: ViewerState = {
  domViewerRef: null,
  viewerType: null,
  viewerHeight: 900,
  viewConfig: null,
  viewConfigParams: null,
  layers: [],
  selectedLayer: null,
  currentPerspective: null,
  layersToolbarVisible: true,
  displayBaseMap: true,
  available2D: false,
  available3D: false,
  viewConfigPropertiesOpened: false,
  throttlingUpdate: false,
  potreeInitialized: false,
  potreeProjection:
    '+proj=lcc +lat_0=32.1666666666667 +lon_0=-116.25 +lat_1=33.8833333333333 +lat_2=32.7833333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +units=us-ft +vunits=us-ft +no_defs +type=crs',
  olMap: null,
  olMapInitialized: false,
  olMapProjection: null,
  displayedProjection2D: null,
  displayedProjection3D: null,
  mode2d: NavigationMode2D.NORMAL,
  appearance3D: {
    pointBudget: 3000000,
    fov: 60,
    eyeDomeLighting: {
      enabled: false,
      radius: 1.4,
      strength: 1.4,
      opacity: 1,
    },
    highQuality: false,
    pointSize: 0.5,
    pointSizing: PotreePointSizing.ADAPTIVE,
    pointShape: PotreePointShape.SQUARE,
  },
  drawingMode: DrawingMode.Point,
  profiles: [],
  hoveredFeature: null,
  featureFilter: null,
  cursorPosition2D: {x: 0, y: 0},
  zoomLevel2D: 0,
  cameraPosition2D: null,
  cameraPosition3D: null,
  cameraTarget3D: null,
  selectedFeature: null,
  availableBaseMaps: [
    {id: "1", type: "OpenStreetMap", displayName: "OpenStreet Map"},
    {id: "2", type: "Google", displayName: "Google"},
    {id: "3", type: "Bing", displayName: "Bing Map"},
    {id: "4", type: "Other", displayName: "Esri World Imagery", url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", attribution: "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community"},
    {id: "5", type: "Other", displayName: "Esri Topo Map", url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}", attribution: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"}
  ],
  baseMap: null,
  units: Unit.METER
};
