import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridColumn, GridDetailRowProps } from '@progress/kendo-react-grid';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { REFETCH_INTERVAL } from '../../common/constants';
import {
  DashboardTransaction,
  SapFlowTransaction,
  SapFlowTransactionItemStatus,
  SapFlowTransactionStatus,
} from '../../types';
import { toDashboardTransactionSet, toSapFlow } from '../../converters/dashboardTransaction';
import { StyledGrid } from '../../components/styled';
import { ProgressBar } from '../../components/feedback';
import { useUser } from '../../hooks/authentication';
import { useTable } from '../../hooks/common';
import { useTransactions, useProjects, useDeleteTransaction, useRunSapFlow } from '../../hooks/sapflow';
import { useAppContext } from '../../context/app';
import { FetchIndicator } from '../../components/feedback';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import TransactionStatusCell from '../Dashboard/TransactionStatusCell';
import TransactionActionsCell from '../Dashboard/TransactionActionsCell';

const ErrorsLogViewer: React.FC<GridDetailRowProps> = (props: GridDetailRowProps) => {
  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (divRef.current?.parentElement?.tagName === 'TD') {
      const tdEl = divRef.current.parentElement as HTMLTableCellElement;
      tdEl.colSpan = tdEl.colSpan + 1;
      tdEl.ariaColIndex = '1';
    }
  }, []);

  return (
    <div ref={divRef}>
      <div className="px-4 py-2 mb-2 border-bottom">
        <Typography.p fontWeight="bold" fontSize="small" className="m-0">
          Latest error logs
        </Typography.p>
      </div>
      <div className="px-4 pb-1">
        {props.dataItem && props.dataItem.statusHistory && props.dataItem.statusHistory.length > 0 && (
          <ul>
            {props.dataItem.statusHistory /*.slice(0, 5)*/
              .map((statusLog: SapFlowTransactionStatus) => (
                <li key={`${statusLog.id}`} className="py-1">
                  <span className="ps-1">{statusLog.statusTime}</span>
                  <span className="ps-1">-</span>
                  <span className="ps-1">{statusLog.statusMessage}</span>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

interface Props {
  projectId: string;
}

const ProjectActivitySection: React.FC<Props> = ({ projectId }) => {
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const [data, setData] = useState<DashboardTransaction[]>([]);
  const { getUser, getCurrentOrganization } = useUser();
  const transactionsQuery = useTransactions(getUser().id, REFETCH_INTERVAL);
  const tableProps = useTable({
    pageSize: 10,
    data,
    sortDescriptor: { field: 'created', dir: 'desc' },
  });
  const [deletingTransaction, setDeletingTransaction] = useState<any>(null);
  const { mutate } = useRunSapFlow();
  const deleteTransactionMutation = useDeleteTransaction(() => {
    setDeletingTransaction(null);
  });

  const filterByProject = (trans: DashboardTransaction) => trans.projectId === projectId;

  useEffect(() => {
    if (transactionsQuery.isSuccess) {
      setData(toDashboardTransactionSet(transactionsQuery.data).filter(filterByProject));
    }
  }, [transactionsQuery.isSuccess, transactionsQuery.data]);

  const handleStart = async (transid: string) => {
    let sapFlow: SapFlowTransaction = null;
    try {
      sapFlow = transactionsQuery.data.filter((trans: SapFlowTransaction) => trans.id === transid)[0];
    } catch (e) {
      //
    }
    if (sapFlow === null) {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: "Couldn't start the sapflow transaction.", type: 'error' } },
      });
      return;
    }
    mutate(
      { orgid: getCurrentOrganization().id, sapflowid: sapFlow.id, projectid: sapFlow.project.id },
      {
        onSuccess: () => {
          const content = 'SAPFlow successfully started';
          dispatch({ type: 'SHOW_NOTIFICATION', payload: { notification: { content, type: 'success' } } });
        },
      }
    );
  };

  const handleDelete = async (transid: string) => {
    setDeletingTransaction(transid);
  };

  const handleDownload = async (transid: string) => {
    let trans: DashboardTransaction = null;
    const newData = data.map((item) => {
      if (transid === item.id) {
        trans = item;
        return {
          ...item,
          downloading: true,
        };
      } else {
        return {
          ...item,
        };
      }
    });
    if (!trans) return;
    setData(newData);
    const filesIdToDownload =
      !trans.outputFiles || trans.outputFiles.length === 0
        ? null
        : trans.outputFiles
            .map((file) => {
              return file.id;
            })
            .join();
    const url =
      process.env.REACT_APP_GEOSAP_SERVERURL +
      '/file_manager/download_items?user_id=' +
      getUser().id +
      '&transaction_id=' +
      transid +
      '&item_id_list=' +
      filesIdToDownload;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    //xhr.onprogress = (event) => {
    //  console.log('Progress: ' + event.loaded + ' vs ' + event.total);
    //  if (event.lengthComputable) {
    //    const percentCompleted = (event.loaded / event.total) * 100;
    //    setProgress(percentCompleted.toFixed(2));
    //  }
    //};

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 2) {
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: {
            notification: {
              type: 'success',
              content:
                'Files for transaction ' +
                transid +
                ' have been zipped and are now downloading. Please stay on this page.',
            },
          },
        });
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = trans.name + '_files.zip';
        link.click();
      } else {
        console.error('Error downloading file:', xhr.statusText);
      }
      const newData = data.map((item) => {
        if (transid === item.id) {
          return {
            ...item,
            downloading: false,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      setData(newData);
    };

    xhr.onerror = () => {
      console.error('Error downloading file:', xhr.statusText);
      const newData = data.map((item) => {
        if (transid === item.id) {
          return {
            ...item,
            downloading: false,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      setData(newData);
    };

    xhr.send();
  };

  const deleteTransaction = async (transid: string) => {
    await deleteTransactionMutation.mutateAsync({
      transid: transid,
    });
    setDeletingTransaction(null);
  };

  const handleExpand = (dataItem: DashboardTransaction) => {
    const newData = data.map((item) => ({
      ...item,
      expanded: dataItem.id === item.id ? !item.expanded : item.expanded,
    }));
    setData(newData);
  };

  const ActionsCellWithErrorHandler: React.FC<{ dataItem: DashboardTransaction }> = ({ dataItem }) => (
    <TransactionActionsCell
      dataItem={dataItem}
      onErrorClick={() => {
        handleExpand(dataItem);
      }}
      onStartClick={() => {
        handleStart(dataItem.id);
      }}
      onDeleteClick={() => {
        console.log('Going somewhere');
        handleDelete(dataItem.id);
      }}
      onDownloadClick={() => {
        handleDownload(dataItem.id);
      }}
    />
  );

  const StatusCellWithErrorHandler: React.FC<{ dataItem: DashboardTransaction }> = ({ dataItem }) => (
    <TransactionStatusCell dataItem={dataItem} />
  );

  return (
    <div>
      {deletingTransaction && (
        <ConfirmationDialog
          show={true}
          onConfirm={() => {
            deleteTransaction(deletingTransaction);
          }}
          onClose={() => {
            setDeletingTransaction(null);
          }}
          loading={deleteTransactionMutation.isLoading}
          title="Please confirm"
          text="Are you sure you wish to delete this Transaction ?"
        />
      )}
      <div className="row g-3">
        <div className="col-12 col-md-auto">
          <Typography.h4 fontWeight="normal">SAP FLOW ACTIVITY</Typography.h4>
        </div>
        <div className="col-12 col-md-auto">
          <FetchIndicator isFetching={transactionsQuery.isFetching} />
        </div>
      </div>
      <div className="mt-3">
        {transactionsQuery.isLoading && <Skeleton shape="rectangle" style={{ height: '330px' }} />}
        {transactionsQuery.isSuccess && data && (
          <StyledGrid
            {...tableProps}
            pageable
            sortable
            className="mt-3"
            detail={ErrorsLogViewer}
            expandField="expanded"
            selectable={{ enabled: true }}
            onRowClick={({ dataItem }) => {
              const trans: any = dataItem;
              if (trans.status === SapFlowTransactionItemStatus.TRANSACTION_CREATED) {
                // Redirect to sapflow wizard step 5
                navigate(`/sapflow`, { state: { sapflow: toSapFlow(trans) } });
              } else {
                navigate(`/details/${trans.id}`);
              }
            }}
            rowRender={(trElement, props) => {
              const trProps: any = {
                style: { cursor: 'pointer' },
              };
              return React.cloneElement(
                trElement,
                {
                  ...trProps,
                },
                trElement.props.children as any
              );
            }}
          >
            <GridColumn
              field="name"
              title="SAP Flow"
              cell={({ dataItem }) => {
                const trans: any = dataItem;
                return <td className="fw-bold">{trans.name}</td>;
              }}
            />
            {/* 
                todo: we need to understand what data we want to bring in this column
                <GridColumn field="type" title="SAP Type" /> 
            */}
            <GridColumn field="created" title="Date" format="{0:h:mm a - M/d/yy}" width={170} />
            <GridColumn cell={StatusCellWithErrorHandler} title="Processing Status" />
            {/*<GridColumn field="status" title="Processing Status" />*/}
            <GridColumn cell={ActionsCellWithErrorHandler} title="Actions" width={240} />
          </StyledGrid>
        )}
      </div>
    </div>
  );
};

export default ProjectActivitySection;
