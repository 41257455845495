import { useQuery } from 'react-query';
import { useAxiosInstance } from '../../common';
import { ClientProject, ConfigPathGroup } from '../../../types';

const useConfigPaths = (projectId: string, configId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['configPaths', projectId, configId], async () => {
    if(!projectId || !configId) {
        return null;
    }
    const result = await axios.get<ConfigPathGroup>(`data_wrangling/${projectId}/configs/${configId}/paths`);
    return result.data;
  });
};

export default useConfigPaths;
