import { useMutation, useQueryClient } from 'react-query';
import { useUser } from '../../hooks/authentication';
import { SapFlowViewConfig } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'viewer_config/wms';

interface Params {
  baseUrl: string;
  layer: string;
  workspace: string;
  version: string;
  projection: string;
  boundingBox: number[];
}

const useAddWmsLayer = (configId: string) => {
  const axios = useAxiosInstance();
  const { getUser } = useUser();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post<SapFlowViewConfig>("viewer_config/" + configId + "/wms", params);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sapFlowView', getUser().id, configId], {
          refetchInactive: true,
        });
      },
    }
  );
};

export default useAddWmsLayer;