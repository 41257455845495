import React, { useEffect, useState } from 'react';
import { Slider, SliderLabel } from '@progress/kendo-react-inputs';
import { BandInfo, LayerType, ViewLayer, RasterVisualization } from '../../../../types';
import { updateLayerParam } from '../../../../common/viewerConfigHelper';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Basic } from 'react-dial-knob';

interface Props {
  layer: ViewLayer;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

const HillshadeControls: React.FC<Props> = ({ layer, onLayerUpdated }) => {
  const [availableBands, setAvailableBands] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [currentBandIndex, setCurrentBandIndex] = useState(0);
  const [verticalExageration, setVerticalExageration] = useState(1);
  const [sunElevation, setSunElevation] = useState(45);
  const [sunAzimuth, setSunAzimuth] = useState(45);

  useEffect(() => {
    if (layer) {
      const bands: BandInfo[] = layer.paramsMap['bands'];
      if (bands) {
        const visualization: RasterVisualization = layer.paramsMap['visualization'];
        const hillshadeVis = visualization.hillshade;
        setAvailableBands(bands);

        setCurrentBandIndex(hillshadeVis.bandIndex ? hillshadeVis.bandIndex : 0);

        if (hillshadeVis?.verticalExageration !== null && hillshadeVis?.verticalExageration !== undefined) {
          setVerticalExageration(hillshadeVis?.verticalExageration);
        }
        if (hillshadeVis?.sunElevation !== null && hillshadeVis?.sunElevation !== undefined) {
          setSunElevation(hillshadeVis?.sunElevation);
        }
        if (hillshadeVis?.sunAzimuth !== null && hillshadeVis?.sunAzimuth !== undefined) {
          setSunAzimuth(hillshadeVis?.sunAzimuth);
        }
      }
      setInitialized(true);
    }
  }, [layer]);

  const handleBandChange = (newBand: BandInfo) => {
    const vis: RasterVisualization = layer.paramsMap.visualization;
    vis.hillshade.bandIndex = newBand.index;
    vis.hillshade.bandName = newBand.name;
    updateLayerParam(layer, 'visualization', vis);
    onLayerUpdated(layer);
  };

  return (
    <div className="d-flex flex-column p-2 mb-4">
      <FloatingLabel label={'Band'} editorId={'visType'} editorValue={true} className="w-75 mx-auto pb-2">
        <DropDownList
          style={{
            width: '200px',
          }}
          size="small"
          data={availableBands}
          value={availableBands[currentBandIndex]}
          dataItemKey="name"
          textField="name"
          onChange={(event: any) => {
            handleBandChange(event.target.value);
          }}
          disabled={layer === null}
          popupSettings={{ width: 250 }}
        />
      </FloatingLabel>

      <FloatingLabel
        label={'Vertical Exageration'}
        editorId={'visType'}
        editorValue={true}
        className="w-75 mx-auto pb-4"
      >
        <Slider
          min={0}
          max={5}
          step={0.1}
          defaultValue={verticalExageration}
          value={verticalExageration}
          onChange={(e) => {
            const vis: RasterVisualization = layer.paramsMap.visualization;
            vis.hillshade.verticalExageration = e.value;
            setVerticalExageration(e.value);
            updateLayerParam(layer, 'visualization', vis);
            onLayerUpdated(layer);
          }}
        >
          {[
            { pos: 0, text: 0 },
            { pos: verticalExageration, text: verticalExageration },
            { pos: 5, text: 5 },
          ].map((label, i) => (
            <SliderLabel key={i} position={label.pos}>
              {label.text.toFixed(1).toString()}
            </SliderLabel>
          ))}
        </Slider>
      </FloatingLabel>

      <FloatingLabel
        label={'Sun Elevation'}
        editorId={'visType'}
        editorValue={true}
        className="w-75 mx-auto d-flex align-items-center"
      >
        <Basic
          diameter={100}
          min={0}
          max={180}
          step={1}
          value={sunElevation}
          onValueChange={(value) => {
            if (!initialized) return;
            const vis: RasterVisualization = layer.paramsMap.visualization;
            vis.hillshade.sunElevation = value;
            setSunElevation(value);
            updateLayerParam(layer, 'visualization', vis);
            onLayerUpdated(layer);
          }}
        ></Basic>
      </FloatingLabel>

      <FloatingLabel
        label={'Sun Azimuth'}
        editorId={'visType'}
        editorValue={true}
        className="w-75 mx-auto d-flex align-items-center"
      >
        <Basic
          diameter={100}
          min={0}
          max={360}
          step={1}
          value={sunAzimuth}
          onValueChange={(value) => {
            if (!initialized) return;
            const vis: RasterVisualization = layer.paramsMap.visualization;
            vis.hillshade.sunAzimuth = value;
            setSunAzimuth(value);
            updateLayerParam(layer, 'visualization', vis);
            onLayerUpdated(layer);
          }}
        ></Basic>
      </FloatingLabel>
    </div>
  );
};

export default HillshadeControls;
