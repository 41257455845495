import React, { useEffect } from 'react';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement, FieldRenderProps } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { SubmitButton } from '../../components/form';
import { Organization } from '../../types';
import { useInviteOrganizationMember } from '../../hooks/user_management';
import Dialog from '../../components/Dialog';
import { useAppContext } from '../../context/app';
import { useOrganizationCreate } from '../../hooks/user_management';
import { useRefreshUser } from '../../hooks/authentication';

interface DialogProps {
  show: boolean;
  onClose: any;
}

const CreateOrganizationDialog: React.FC<DialogProps> = (props: DialogProps) => {
  const orgCreateMutation = useOrganizationCreate();
  const refreshUserMutation = useRefreshUser();
  const { dispatch } = useAppContext();
  const FieldInput = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  };

  const handleSubmit = (values: { [name: string]: any }) => {
    orgCreateMutation
      .mutateAsync({
        name: values.name,
        description: values.description,
        location: values.location,
        industry: values.industry,
      })
      .then(() => {
        refreshUserMutation.mutateAsync();
      });
  };

  useEffect(() => {
    if (orgCreateMutation.isSuccess) {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Organization created!', type: 'success' } },
      });
      props.onClose();
    }
  }, [orgCreateMutation.isSuccess]);

  return (
    <Dialog title={`Create Organization`} onClose={props.onClose} show={props.show} style={{ width: '50%' }}>
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement
            style={{
              maxWidth: 650,
            }}
          >
            <fieldset className={'k-form-fieldset'}>
              <div className="mb-3">
                <Field name={'name'} type={'name'} component={FieldInput} label={'Name'} />
                <Field name={'description'} type={'description'} component={FieldInput} label={'Description'} />
                <Field name={'industry'} type={'industry'} component={FieldInput} label={'Industry'} />
                <Field name={'location'} type={'location'} component={FieldInput} label={'Location'} />
              </div>
            </fieldset>
            <DialogActionsBar>
              <SubmitButton
                label="Cancel"
                onClick={props.onClose}
                themeColor="base"
                fillMode="outline"
                loading={orgCreateMutation.isLoading}
              />
              <SubmitButton label="Create" themeColor="primary" type="submit" loading={orgCreateMutation.isLoading} />
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default CreateOrganizationDialog;
