import React, { useEffect } from 'react';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement, FieldRenderProps } from '@progress/kendo-react-form';
import { useForm, Controller } from 'react-hook-form';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { REQUIRED_FIELD, VALID_EMAIL_FIELD, VALID_PASSWORD_FIELD } from '../../common/constants';
import { Select, SubmitButton } from '../../components/form';
import Dialog from '../../components/Dialog';
import { useValidateEmail } from '../../hooks/authentication';
import { useAppContext } from '../../context/app';
import { useOrganizations, useUserCreate } from '../../hooks/user_management';
import { Organization } from '../../types';
import { Loader } from '@progress/kendo-react-indicators';

const nullOrg: Organization = {
  id: null,
  name: 'None',
  description: '',
  industry: '',
  location: '',
  userRole: null,
  sharingEnabled: null,
};

interface DialogProps {
  show: boolean;
  onClose: any;
}

interface FormValues {
  firstName: string;
  lastName: string;
  description: string;
  email: string;
  password: string;
  emailValidated: boolean;
  organization: Organization;
}

const validationSchema = yup.object({
  email: yup.string().required(REQUIRED_FIELD).email(VALID_EMAIL_FIELD),
  password: yup
    .string()
    .required(REQUIRED_FIELD)
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/, VALID_PASSWORD_FIELD),
});

const CreateUserDialog: React.FC<DialogProps> = (props: DialogProps) => {
  const userCreateMutation = useUserCreate();
  const organizationsQuery = useOrganizations();
  const { dispatch } = useAppContext();
  const { handleSubmit, control, formState, reset } = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      description: '',
      email: '',
      password: '',
      emailValidated: false,
      organization: nullOrg,
    },
    resolver: yupResolver(validationSchema),
  });
  const useValidateEmailMutation = useValidateEmail();
  const FieldInput = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  };
  const FieldCheckbox = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div style={{ paddingTop: '1.5rem' }}>
        <Checkbox {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  };
  const FieldComboBox = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    let orgsAvailable: Organization[] = [nullOrg];
    orgsAvailable = orgsAvailable.concat(organizationsQuery.data);
    return (
      <div style={{ paddingTop: '1.5rem' }}>
        <Select {...others} className="p-3" textField="name" dataItemKey="id" data={orgsAvailable} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  };

  const createUser = (values: { [name: string]: any }) => {
    useValidateEmailMutation.mutateAsync({ email: values['email'] }).then(() => {
      userCreateMutation.mutateAsync({
        firstName: values.firstName,
        lastName: values.lastName,
        description: values.description,
        email: values.email,
        password: values.password,
        emailValidated: values.emailValidated,
        organizationId: values.organization ? values.organization.id : null,
      });
    });
  };

  useEffect(() => {
    if (userCreateMutation.isSuccess) {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'User created!', type: 'success' } },
      });
      props.onClose();
    }
  }, [userCreateMutation.isSuccess]);

  return (
    <Dialog title={`Create User`} onClose={props.onClose} show={props.show} style={{ width: '50%' }}>
      <Form
        onSubmit={createUser}
        render={(formRenderProps) => (
          <FormElement
            style={{
              maxWidth: 650,
            }}
          >
            <fieldset className={'k-form-fieldset'}>
              <div className="mb-3">
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Field
                      name={'firstName'}
                      type={'firstName'}
                      component={FieldInput}
                      label={'First Name'}
                      error={error?.message}
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Field name={'lastName'} type={'lastName'} component={FieldInput} label={'Last Name'} />
                  )}
                />
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Field name={'description'} type={'description'} component={FieldInput} label={'Description'} />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Field name={'email'} component={FieldInput} label={'Email'} />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Field name={'password'} type="password" component={FieldInput} label={'Password'} />
                  )}
                />
                {organizationsQuery.isLoading && <Loader></Loader>}
                {!organizationsQuery.isLoading && (
                  <Controller
                    control={control}
                    name="organization"
                    render={({ field, fieldState: { error } }) => (
                      <Field
                        name={'organization'}
                        type="organization"
                        component={FieldComboBox}
                        label={'Organization'}
                        data
                      />
                    )}
                  />
                )}

                <Controller
                  name="emailValidated"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Field name={'emailValidated'} component={FieldCheckbox} label={'Email Validated'} />
                  )}
                />
              </div>
            </fieldset>
            <DialogActionsBar>
              <SubmitButton
                label="Cancel"
                onClick={props.onClose}
                themeColor="base"
                fillMode="outline"
                loading={userCreateMutation.isLoading}
              />
              <SubmitButton label="Create" themeColor="primary" type="submit" loading={userCreateMutation.isLoading} />
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default CreateUserDialog;
