import { useMutation } from 'react-query';
import { SigninResponse } from '../../types';
import { useAppContext } from '../../context/app';
import { useAxiosInstance } from '../common';

const endpoint = '/authentication/password';

interface ChangePasswordFormValues {
  currentPassword: string;
  newPassword: string;
}

const useChangePassword = () => {
  const { dispatch } = useAppContext();
  const axios = useAxiosInstance();

  return useMutation(async (passwordChangeRequestData: ChangePasswordFormValues) => { 
    await axios.put<SigninResponse>(endpoint, passwordChangeRequestData);
    dispatch({
      type: 'SHOW_NOTIFICATION',
      payload: { notification: { type: 'success', content: 'Password changed succcessfully.' } },
    });
  });
};

export default useChangePassword;