import { useContext, useEffect, useImperativeHandle, forwardRef, useState } from 'react';
import MapContext from '../Map/MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import { Fill, Icon, Stroke, Style } from 'ol/style.js';
import { Feature } from 'ol';
import { easeOut } from 'ol/easing.js';
import { unByKey } from 'ol/Observable.js';
import VectorSource from 'ol/source/Vector';

interface Props {
  highlight: boolean;
  show: boolean;
  style: any;
  source: any;
  zIndex: number;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
  getOlLayer: (layer: any) => any;
} | null;
const VectorLayer = forwardRef<Ref, Props>(({ show, highlight, source, style, zIndex = 0 }, ref) => {
  const { map } = useContext<any>(MapContext);
  const [vectorLayer, SetVectorLayer] = useState<any>();

  useEffect(() => {
    if (!map) return;

    const newVectorLayer = new OLVectorLayer({
      source,
      style,
      visible: show,
    });

    map.addLayer(newVectorLayer);
    newVectorLayer.setZIndex(zIndex);
    SetVectorLayer(newVectorLayer);

    return () => {
      if (map) {
        map.removeLayer(newVectorLayer);
      }
    };
  }, [map]);

  useEffect(() => {
    if (!vectorLayer) return;
    vectorLayer.setVisible(show);
  }, [show]);

  useEffect(() => {
    if (!vectorLayer) return;

    const duration = 250;
    function flash(feature: Feature, growing: boolean) {
      const start = Date.now();
      const listenerKey = vectorLayer.on('postrender', animate);
      map.render();

      function animate(event: any) {
        const frameState = event.frameState;
        const elapsed = frameState.time - start;
        if (elapsed >= duration) {
          unByKey(listenerKey);
          return;
        }
        const elapsedRatio = elapsed / duration;
        let r = 100 - easeOut(elapsedRatio) * 15;
        let g = 100 + easeOut(elapsedRatio) * 37;
        let b = 100 - easeOut(elapsedRatio) * 15;
        if (!growing) {
          r = 85 + easeOut(elapsedRatio) * 15;
          g = 137 - easeOut(elapsedRatio) * 37;
          b = 85 + easeOut(elapsedRatio) * 15;
        }
        const style = new Style({
          stroke: new Stroke({
            width: 0.75,
            color: 'white',
          }),
          fill: new Fill({
            color: [r, g, b, 0.25],
          }),
        });

        feature.setStyle(style);
        // tell OpenLayers to continue postrender animation
        map.render();
      }
    }

    (vectorLayer.getSource() as VectorSource).getFeatures().forEach((f: any) => {
      flash(f, highlight);
    });
  }, [highlight, vectorLayer]);

  const zoomToLayer = (layer: any) => {
    map.getView().fit(source.getExtent());
  };

  const getOlLayer = (layer: any) => {
    return vectorLayer;
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
    getOlLayer,
  }));

  return null;
});
VectorLayer.displayName = 'VectorLayer';

export default VectorLayer;
