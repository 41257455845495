/* eslint-disable @typescript-eslint/no-explicit-any */
/* Kendo ComboBox uses any so this component is gonna use it for now as well. */
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ComboBox, ComboBoxHandle, ComboBoxChangeEvent, ComboBoxBlurEvent } from '@progress/kendo-react-dropdowns';
import FieldBase, { FieldBaseProps } from './FieldBase';

interface Props extends FieldBaseProps {
  placeholder?: string;
  data?: any[];
  dataItemKey: string;
  textField: string;
  loading?: boolean;
  autoFocus?: boolean;
  value: any;
  onChange?: (e: ComboBoxChangeEvent) => void;
  onBlur?: (e: ComboBoxBlurEvent) => void;
}

const StyledComboBox = styled(ComboBox)`
  border: 2px solid var(--geosap-input-border-color);
  color: var(--geosap-primary-color);
  .k-input-inner {
    padding: 0;
    &:focus {
      color: var(--geosap-primary-color);
    }
    &::placeholder {
      color: var(--geosap-primary-color);
    }
    &::-webkit-input-placeholder {
      color: var(--geosap-primary-color);
    }
    &::-moz-placeholder {
      color: var(--geosap-primary-color);
      opacity: 1;
    }
  }
  .k-button {
    padding: 0;
    background: transparent;
    border: none;
  }
`;

const FilterableSelect: React.FC<Props> = (props) => {
  const { placeholder, data, dataItemKey, textField, loading, autoFocus, value, error, onChange, onBlur } = props;
  const ref = useRef<ComboBoxHandle>();

  const focusElement = () => {
    if (!ref.current?.element?.children) {
      return;
    }
    const innerInput = Array.from(ref.current.element.children).find((el) => el.tagName === 'INPUT');
    if (innerInput) {
      (innerInput as HTMLInputElement).focus();
    }
  };

  useEffect(() => {
    if (!loading && autoFocus) {
      focusElement();
    }
  }, [loading]);

  return (
    <FieldBase {...props}>
      <StyledComboBox
        data={data}
        dataItemKey={dataItemKey}
        textField={textField}
        className="p-3"
        placeholder={loading ? 'Loading...' : placeholder}
        disabled={loading}
        clearButton={false}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        valid={!error}
      />
    </FieldBase>
  );
};

export default FilterableSelect;
