import React, { useState, useEffect, useRef, ComponentType, ReactNode } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Grid, GridProps, GridColumn, GridDetailRowProps } from '@progress/kendo-react-grid';
import styled from 'styled-components';
import { useServerTasks } from '../../hooks/system';
import { Skeleton } from '@progress/kendo-react-indicators';

const StyledGrid = styled(Grid as ComponentType<GridProps & { children: ReactNode }>)`
  border: none;
  .k-grid-header {
    padding-inline-end: 0 !important;
  }
  .k-grid-content {
    overflow-y: auto;
  }
  .k-grid-container {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`;

const TasksViewer: React.FC<GridDetailRowProps> = (props: GridDetailRowProps) => {
  const serverTasksQuery = useServerTasks(props.dataItem.id, props.dataItem.type);
  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (divRef.current?.parentElement?.tagName === 'TD') {
      const tdEl = divRef.current.parentElement as HTMLTableCellElement;
      tdEl.colSpan = tdEl.colSpan + 1;
      tdEl.ariaColIndex = '1';
    }
  }, []);

  return (
    <div ref={divRef}>
      <div className="px-4 py-2 border-bottom">
        <Typography.h4 fontWeight="bold" fontSize="small" className="m-0">
          Tasks
        </Typography.h4>
      </div>
      <div className="">
        {serverTasksQuery.isLoading && <Skeleton shape="rectangle" style={{ height: '70px' }} />}
        {serverTasksQuery.isSuccess && serverTasksQuery.data.length === 0 && (
          <Typography.p className="px-4">No tasks found for server</Typography.p>
        )}
        {serverTasksQuery.isSuccess && serverTasksQuery.data.length > 0 && (
          <StyledGrid
            data={serverTasksQuery.data}
            className=""
            selectable={{ enabled: true }}
            onRowClick={({ dataItem }) => {
              //
            }}
            rowRender={(trElement, props) => {
              const trProps: any = {
                style: { cursor: 'pointer' },
              };
              return React.cloneElement(
                trElement,
                {
                  ...trProps,
                },
                trElement.props.children as any
              );
            }}
          >
            <GridColumn field="finishTime" title="Date" format="{0:h:mm a - M/d/yy}" />
            <GridColumn field="type" title="Type" />
            <GridColumn field="resource.resourceType" title="Resource" width={170} />
            <GridColumn field="currentStatus" title="Status" width={170} />
          </StyledGrid>
        )}
      </div>
    </div>
  );
};
export default TasksViewer;
