import { useQuery } from 'react-query';
import { SapConfig } from '../../types/DataDelivery';
import { useAxiosInstance } from '../common';

const useSapConfig = (configId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['sapConfig', configId], async () => {
    if (configId === null) return null;
    const result = await axios.get<SapConfig>('file_sapconfig/' + configId);
    return result.data;
  });
};

export default useSapConfig;
