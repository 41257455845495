import React, { useContext, useEffect, useImperativeHandle, forwardRef, useState, useRef } from 'react';
import MapContext from '../Map/MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import Extent from 'ol/extent';
import { transformExtent } from 'ol/proj.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { Layer2DClickResult, RasterVisualization, ViewLayer, VisualizationType } from '../../../../types';
import TileLayer from './TileLayer';
import { TileWMS } from 'ol/source';
import MapContextData from '../Map/MapContextData';
import { Feature, MapBrowserEvent } from 'ol';
import VectorSource from 'ol/source/Vector';
import debounce from 'lodash.debounce';
import { Circle, Fill, Style } from 'ol/style';
import { useConsumeViewerState } from '../../../../context/viewer';
import { getRasterPreviewLayerUrl, getWfsPreviewLayerUrl } from '../../../../common/viewLayerHelper';

interface Props {
  show: boolean;
  layer: ViewLayer;
  zIndex: number;
  onClick?: () => void; // Add onClick prop
}

type Ref = {
  zoomToLayer: (layer: any) => void;
  getOlLayer: (layer: any) => any;
  handleMapClick: (evt: MapBrowserEvent<any>) => Promise<Layer2DClickResult>;
} | null;
const WmsPreviewLayer = forwardRef<Ref, Props>(({ show, layer, zIndex = 0, onClick }, ref) => {
  const { dispatch, olMapInitialized } = useConsumeViewerState();
  const { map } = useContext<MapContextData>(MapContext);
  const [zoomWhenReady, setZoomWhenReady] = useState(false);
  const previewOlLayerRef = useRef<any>(null);
  const [wmsSource, setWmsSource] = useState<TileWMS>(null);
  const [projectedExtents, setProjectedExtents] = useState(null);

  useEffect(() => {
    if (!map || !olMapInitialized) return;

    const visualization: RasterVisualization = layer.paramsMap['visualization'];
    let style = layer.paramsMap['wmsStyle'];
    let env = '';

    if (visualization) {
      if (visualization.type === VisualizationType.SINGLEBAND) {
        style = 'RasterSingleBand';
        env += 'bandIndex:' + (visualization.singleband.bandIndex + 1) + ';';
        env += 'bandMin:' + visualization.singleband.currentMin + ';';
        env += 'bandMax:' + visualization.singleband.currentMax + ';';
      } else if (visualization.type === VisualizationType.RGB) {
        style = 'RasterRGB';
        env += 'redBandIndex:' + (visualization.rgb.redBandIndex + 1) + ';';
        env += 'redMin:' + visualization.rgb.redBandCurrentMin + ';';
        env += 'redMax:' + visualization.rgb.redBandCurrentMax + ';';
        env += 'greenBandIndex:' + (visualization.rgb.greenBandIndex + 1) + ';';
        env += 'greenMin:' + visualization.rgb.greenBandCurrentMin + ';';
        env += 'greenMax:' + visualization.rgb.greenBandCurrentMax + ';';
        env += 'blueBandIndex:' + (visualization.rgb.blueBandIndex + 1) + ';';
        env += 'blueMin:' + visualization.rgb.blueBandCurrentMin + ';';
        env += 'blueMax:' + visualization.rgb.blueBandCurrentMax + ';';
      }
    }

    const layerName = layer.paramsMap['workspace']
      ? layer.paramsMap['workspace'] + ':' + layer.paramsMap['layer']
      : layer.paramsMap['layer'];

    setWmsSource(
      new TileWMS({
        url: getRasterPreviewLayerUrl(layer),
        params: {
          FORMAT: 'image/png',
          VERSION: layer.paramsMap['version'],
          tiled: true,
          STYLES: style,
          LAYERS: layerName,
          //exceptions: 'application/vnd.ogc.se_inimage',
          env: env,
        },
      })
    );

    // TODO: This is a hack to bypass the view.getProjection not available here yet:
    setTimeout(() => {
      if (layer.paramsMap['extents'] && layer.paramsMap['projection']) {
        const projection = layer.paramsMap['projection'];
        const extents = layer.paramsMap['extents'];

        // Project the extents to the map's projection
        const transformedExtents = transformExtent(extents, projection, map.getView().getProjection().getCode());
        setProjectedExtents(transformedExtents);
      }
    }, 1000);
  }, [map, olMapInitialized, layer]);

  const zoomToLayer = (layer: any) => {
    setZoomWhenReady(true);
  };

  const handleMapClick = async (evt: MapBrowserEvent<any>): Promise<Layer2DClickResult> => {
    const clickedCoordinate = evt.coordinate;
    // Check if the clicked coordinate is within the layer's extents
    if (projectedExtents) {
      const [minX, minY, maxX, maxY] = projectedExtents;
      const withinExtents =
        clickedCoordinate[0] >= minX &&
        clickedCoordinate[0] <= maxX &&
        clickedCoordinate[1] >= minY &&
        clickedCoordinate[1] <= maxY;
      if (!withinExtents) {
        return null; // Clicked coordinate is outside the layer's extents, discard the click
      }
    }
    const viewResolution = /** @type {number} */ map.getView().getResolution();
    const url = wmsSource.getFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {
      INFO_FORMAT: 'application/json',
    });
    if (url) {
      const response = await fetch(url);
      const json = await response.text();
      const features = new GeoJSON().readFeatures(JSON.parse(json));
      features.forEach((feature) => {
        const properties = feature.getProperties();
      });
      if (features.length > 0) {
        return { layer };
      }
    }
    return null;
  };

  useEffect(() => {
    if (zoomWhenReady && map.getView().getProjection()) {
      const projection = layer.paramsMap['projection'];
      const extents = layer.paramsMap['extents'];
      if (projection && extents) {
        let olExtents: Extent.Extent = [extents[0], extents[1], extents[2], extents[3]];
        if (projection !== map.getView().getProjection().getCode()) {
          olExtents = transformExtent(olExtents, projection, map.getView().getProjection().getCode());
        }
        map.getView().fit(olExtents);
      }
      setZoomWhenReady(false);
    }
  }, [zoomWhenReady, map.getView().getProjection()]);

  const getOlLayer = (layer: any) => {
    return previewOlLayerRef;
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
    getOlLayer,
    handleMapClick,
  }));
  if (!wmsSource) return null;
  return (
    <TileLayer
      ref={(el: any) => {
        previewOlLayerRef.current = el;
      }}
      key={layer.id}
      layer={layer}
      show={show}
      zIndex={1}
      source={wmsSource}
    ></TileLayer>
  );
});
WmsPreviewLayer.displayName = 'WmsPreviewLayer';

export default WmsPreviewLayer;
