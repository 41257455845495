import React from 'react';
import { BackgroundContainer, CentralizedContent } from './layout';
import { SubmitButton } from './form';
import Modal from './Modal';
import { useUser } from '../hooks/authentication';
import styled from 'styled-components';
import { Typography } from '@progress/kendo-react-common';
import { AuthorityLevel, AuthorityType } from '../types';
import { useNavigate } from 'react-router-dom';

const BrandImage = styled.img`
  display: block;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
`;

interface DialogProps {
  show: boolean;
  onClose: any;
}

const UnsubscribedDialog: React.FC<DialogProps> = (props: DialogProps) => {
  const { userHasAuthority } = useUser();
  const navigate = useNavigate();

  return (
    <Modal
      title={'Unsubscribed'}
      onClose={props.onClose}
      show={props.show}
      style={{ width: '800px', maxWidth: '80%', maxHeight: '80%', minHeight: '600px' }}
    >
      <BackgroundContainer style={{ height: '100%' }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '4rem',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <BrandImage src="/assets/brand-black.png" alt="GeoSAP brand" className="p-3 pb-4" />
          <Typography.h2 className="text-center">No active Subscription</Typography.h2>
          <Typography.p className="text-center">{`Your Organization's doesn't have an active subscription. ${
            userHasAuthority(AuthorityType.ORG_AUTHORITY, AuthorityLevel.UPDATE)
              ? "Go to your organization's settings page to update your subscription information."
              : 'Ask an administrator of your organization to update the subscription information.'
          }`}</Typography.p>
          <div className="w-100 row justify-content-center">
            <div className="col-md-6 p-3">
              {userHasAuthority(AuthorityType.ORG_AUTHORITY, AuthorityLevel.UPDATE) && (
                <SubmitButton
                  label="GO TO SETTINGS"
                  onClick={() => {
                    navigate('/settings/organization');
                    props.onClose();
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </BackgroundContainer>
    </Modal>
  );
};

export default UnsubscribedDialog;
