import { useQuery } from 'react-query';
import { ReusableFileStructure } from '../../../types';
import { useAxiosInstance } from '../../common';

const endpoint = 'file_manager/reusable_files';

const useReusableFiles  = () => {
  const axios = useAxiosInstance();
  return useQuery(['reusableFiles'], async () => {
    const result = await axios.get<ReusableFileStructure[]>(endpoint);
    return result.data;
  });
};

export default useReusableFiles;
