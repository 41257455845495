import React from 'react';
import { useWebsocketClient } from '../hooks/messaging';
import { Button } from '@progress/kendo-react-buttons';

const Messaging: React.FC = () => {
  const { initWebsocketClient, sendMessageToServer, closeConnection } = useWebsocketClient();

  return (
    <div>
      Messaging!!
      <Button onClick={() => initWebsocketClient()}>Init Client!</Button>
      <Button onClick={closeConnection}>Close Connection To Server!</Button>
      <Button onClick={sendMessageToServer}>Send Message To Server!</Button>
    </div>
  );
};

export default Messaging;
