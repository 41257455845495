import { useQuery } from 'react-query';
import { EServerType, ServerTask } from '../../types';
import { useAxiosInstance } from '../common';

const useServers = (serverId: string, serverType: EServerType) => {
  const axios = useAxiosInstance();
  return useQuery(['serverTasks', serverId, serverType], async () => {
    const result = await axios.get<ServerTask[]>("server/" + serverId + "/tasks", {params:{server_type: serverType}});
    return result.data;
  });
};

export default useServers;
