import React from 'react';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { SubmitButton } from './form';
import Dialog from './Dialog';

interface DialogProps {
  show: boolean;
  onConfirm: any;
  onClose: any;
  title: any;
  text: any;
  loading: boolean;
  style?: any;
}

const ConfirmationDialog: React.FC<DialogProps> = (props: DialogProps) => {
  return (
    <Dialog title={props.title} onClose={props.onClose} show={props.show} style={props.style}>
      <p
        style={{
          margin: '25px',
          textAlign: 'center',
        }}
      >
        {props.text}
      </p>
      <DialogActionsBar>
        <SubmitButton label="No" onClick={props.onClose} themeColor="base" fillMode="outline" loading={props.loading} />
        <SubmitButton label="Yes" themeColor="error" onClick={props.onConfirm} loading={props.loading} />
      </DialogActionsBar>
    </Dialog>
  );
};

export default ConfirmationDialog;
