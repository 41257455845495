import React, { useState } from 'react';
import styled from 'styled-components';

const HamburgerContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 40px;

  span {
    height: 2px;
    margin: 4px 0;
    transition: 0.4s;
  }

  &.open span:nth-child(1) {
    transform: rotate(-45deg) translate(-8px, 6px);
  }

  &.open span:nth-child(2) {
    opacity: 0;
  }

  &.open span:nth-child(3) {
    transform: rotate(45deg) translate(-8px, -6px);
  }
`;

const HamburgerMenu: React.FC<{
  open: boolean;
  toggle: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  bgColor: string;
}> = ({ open, toggle, bgColor }) => {
  return (
    <HamburgerContainer className={open ? 'open' : ''} onClick={toggle}>
      <span style={{ backgroundColor: bgColor }}></span>
      <span style={{ backgroundColor: bgColor }}></span>
      <span style={{ backgroundColor: bgColor }}></span>
    </HamburgerContainer>
  );
};

export default HamburgerMenu;
