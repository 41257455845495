import { useQuery } from 'react-query';
import { SapFlowType } from '../../../types';
import { useAxiosInstance } from '../../common/';

const endpoint = '/sap_config/type/all';

const sorter = (sapFlowA: SapFlowType, sapFlowB: SapFlowType): number => {
  if (sapFlowA.name < sapFlowB.name) {
    return -1;
  }
  if (sapFlowB.name < sapFlowA.name) {
    return 1;
  }
  return 0;
};

const useTypes = () => {
  const axios = useAxiosInstance();
  return useQuery(['sapFlowTypes'], async () => {
    const result = await axios.get<SapFlowType[]>(endpoint);
    return result.data.sort(sorter);
  });
};

export default useTypes;