import { useQuery } from 'react-query';
import { SapFlowConfigParamDetails } from '../../../types';
import { useAxiosInstance } from '../../common/';
import SapFlowConfigParam from '../../../assets/data/SapFlowConfigParams';

const endpoint = '/parameters/paramdetails';

const useConfigParameters = (configId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['sapFlowConfigParams', configId], async () => {
    const result = await axios.get<SapFlowConfigParamDetails[]>(endpoint, { params: { configid: configId } });
    return result.data; //.sort(sorter);
  });
};

export default useConfigParameters;
