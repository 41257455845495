import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Skeleton } from '@progress/kendo-react-indicators';
import { Typography } from '@progress/kendo-react-common';
import { useProject, useProjectFileDetails, useProjectDetails } from '../../hooks/sapflow';
import { LinkButton } from '../../components/layout';
import ProjectInfoSection from './ProjectInfoSection';
import ProjectActivitySection from './ProjectActivitySection';
import ProjectFilesSection from './ProjectFilesSection';
import { RestrictedDiv } from '../../components/restricted';
import { AuthorityLevel, AuthorityType } from '../../types';

const ProjectDetails: React.FC = () => {
  const { projectId } = useParams();
  const projectQuery = useProject(projectId);
  const { getFileDetails } = useProjectFileDetails();
  const projectDetailsQuery = useProjectDetails(projectId);
  const fileDetailsQuery = getFileDetails(projectId);

  return (
    <div className="container-fluid p-2 p-lg-5">
      <div className="row">
        <div className="col pb-5">
          <div className="row gx-3 gy-2">
            <RestrictedDiv
              authorityType={AuthorityType.TRANSACTION_AUTHORITY}
              authorityLevel={AuthorityLevel.CREATE}
              className="col col-sm-auto d-flex justify-content-center"
            >
              <LinkButton label="Run SAPFlow" to={`/sapflow/${projectId}`} />
            </RestrictedDiv>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Typography.h3 className="text-uppercase">
            {(projectQuery.isLoading || fileDetailsQuery.isLoading) && <Skeleton shape="text" />}
            {projectQuery.isSuccess && fileDetailsQuery.isSuccess && (
              <>
                <Link to="/dashboard" style={{ color: 'var(--geosap-body-text)' }}>
                  Projects
                </Link>
                {' > '}
                <span style={{ color: 'var(--geosap-k-button-md)' }}>{projectQuery.data.name}</span>
              </>
            )}
          </Typography.h3>
        </div>
      </div>
      <div className="row pt-5 gx-5">
        <div className="col-12 col-lg-4">
          <div className="row gy-4">
            <div className="col-12">
              <ProjectInfoSection
                isLoading={projectQuery.isLoading || projectDetailsQuery.isLoading}
                isSuccess={projectQuery.isSuccess && projectDetailsQuery.isSuccess}
                projectData={projectQuery.data}
                projectDetails={projectDetailsQuery.data}
                inputFileData={fileDetailsQuery.data?.input?.filter((file: any) => file)}
                outputFileData={fileDetailsQuery.data?.output?.filter((file: any) => file)}
              />
            </div>
            <div className="col-12">
              <ProjectFilesSection
                isLoading={fileDetailsQuery.isLoading || fileDetailsQuery.isLoading}
                isSuccess={fileDetailsQuery.isSuccess && fileDetailsQuery.isSuccess}
                inputFileData={fileDetailsQuery.data?.input?.filter((file: any) => file)}
                outputFileData={fileDetailsQuery.data?.output?.filter((file: any) => file)}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <ProjectActivitySection projectId={projectId} />
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
