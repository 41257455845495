import React from 'react';
import * as yup from 'yup';
import { Typography } from '@progress/kendo-react-common';
import { useForm, Controller, UseFormReturn } from 'react-hook-form';
import { LayerType, ViewLayer, ViewLayerParam } from '../../../../types';
import Dialog from '../../../../components/Dialog';
import GeotiffProperties from './GeotiffProperties';
import ShapefileProperties from './ShapefileProperties';
import GSRASProperties from './GSRASProperties';
import WFSProperties from './WFSProperties';
import { SubmitButton } from '../../../../components/form';
import { useViewLayerParamsUpdate } from '../../../../hooks/viewerconfig';

interface LayerPropertiesProps {
  show: boolean;
  handleClose: any;
  layer: ViewLayer;
  onLayerUpdated?: (layer: ViewLayer, persistent: boolean, immediate: boolean) => void;
}

const LayerProperties: React.FC<LayerPropertiesProps> = (props: LayerPropertiesProps) => {
  const propertiesForm = useForm<any>();
  const viewLayerParamsUpdateMutation = useViewLayerParamsUpdate();

  const updateProperties = async (formValues: any) => {
    const paramKvp: ViewLayerParam[] = [];
    Object.keys(formValues).forEach((key) => {
      if (typeof formValues[key] === 'string') {
        paramKvp.push({ key, value: formValues[key] });
      } else {
        paramKvp.push({ key, value: JSON.stringify(formValues[key]) });
      }
    });
    props.layer.params = paramKvp;
    props.layer.paramsMap = formValues;
    props.layer.lastUpdated = new Date().getTime();
    props.onLayerUpdated(props.layer, true, true);
    return;
  };

  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>Layer Properties</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
      style={{ width: '600px', maxWidth: '80%', maxHeight: '80%', minHeight: '500px' }}
    >
      <form onSubmit={propertiesForm.handleSubmit(updateProperties)}>
        {props.layer &&
          props.layer.layerType &&
          {
            [LayerType.GeoTif]: <GeotiffProperties layer={props.layer} form={propertiesForm} />,
            [LayerType.LAZ]: null,
            [LayerType.BaseMap]: null,
            [LayerType.COPC]: null,
            [LayerType.GeoJSON]: null,
            [LayerType.LAS]: null,
            [LayerType.OctreeBin]: null,
            [LayerType.PGPointCloud]: null,
            [LayerType.Pointcloud]: null,
            [LayerType.ShapeFile]: <ShapefileProperties layer={props.layer} form={propertiesForm} />,
            [LayerType.UNKNOWN]: null,
            [LayerType.GSRAS]: <GSRASProperties layer={props.layer} form={propertiesForm} />,
            [LayerType.GSVEC]: <WFSProperties layer={props.layer} form={propertiesForm} />,
            [LayerType.WFS]: <WFSProperties layer={props.layer} form={propertiesForm} />,
            [LayerType.WMS]: null,
            [LayerType.Measurement2D]: null,
            [LayerType.Measurement3D]: null,
            [LayerType.Drawing2D]: null,
          }[props.layer.layerType]}

        {props.layer.layerType === LayerType.GeoTif && (
          <SubmitButton
            type="submit"
            label="Update"
            uppercase={false}
            full={false}
            disabled={false}
            loading={viewLayerParamsUpdateMutation.isLoading}
            className="mt-2 ml-auto"
          />
        )}
      </form>
    </Dialog>
  );
};

export default LayerProperties;
