import { useContext, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import MapContext from '../Map/MapContext';
import Extent from 'ol/extent';
import { transformExtent } from 'ol/proj.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { bbox as bboxStrategy } from 'ol/loadingstrategy.js';
import VectorSource from 'ol/source/Vector';
import { LayerType, ViewLayer } from '../../../../types';
import useViewLayer from '../../../../hooks/viewerconfig/useViewLayer';
import VectorLayer from './VectorLayer';
import React from 'react';
import { FileNode } from '../../../../types/DataDelivery';

const proj4 = window.proj4 || require('proj4');

interface Props {
  fileNode: FileNode;
  layer?: ViewLayer;
  layerId?: string;
  show: boolean;
  zIndex: number;
  style?: any;
  highlight: boolean;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
} | null;
const ViewLayerOL = forwardRef<Ref, Props>((props, ref) => {
  const { map } = useContext<any>(MapContext);
  const useViewLayerQuery = useViewLayer(props.layer, props.layerId);
  const [layer, setLayer] = useState(null);
  const [vectorLayer, setVectorLayer] = useState(null);

  useEffect(() => {
    setLayer(useViewLayerQuery.data);
  }, [useViewLayerQuery.isSuccess, useViewLayerQuery.data]);

  const zoomToLayer = (layer: any) => {
    const projection = props.layer?.paramsMap['projection'];
    const extents = props.layer?.paramsMap['extents'];
    if (projection && extents) {
      let olExtents: Extent.Extent = [extents[0], extents[1], extents[2], extents[3]];
      if (projection !== map.getView().getProjection().getCode()) {
        olExtents = transformExtent(olExtents, projection, map.getView().getProjection().getCode());
      }
      map.getView().fit(olExtents);
    }
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
  }));

  if (layer?.layerType === LayerType.WFS) {
    const url = layer.paramsMap['uri'];
    const layerName =
      (layer.paramsMap['workspace'] ? layer.paramsMap['workspace'] + ':' : '') + layer.paramsMap['layer'];
    const source = new VectorSource({
      format: new GeoJSON(),
      loader: function (extent, resolution, projection, success, failure) {
        const proj = projection.getCode();
        const url = `${layer.paramsMap['uri']}?service=WFS&version=${
          layer.paramsMap['version']
        }&request=GetFeature&typename=${layerName}&outputFormat=application/json&srsname=EPSG:3857&bbox=${extent.join(
          ','
        )},EPSG:3857`;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        const onError = function () {
          source.removeLoadedExtent(extent);
          failure();
        };
        xhr.onerror = onError;
        xhr.onload = function () {
          if (xhr.status === 200) {
            const features: any = source.getFormat().readFeatures(xhr.responseText);
            features.forEach((feature: any) => {
              feature.setProperties({ interactable: true, fileNode: props.fileNode });
            });
            source.addFeatures(features);
            success(features);
          } else {
            onError();
          }
        };
        xhr.send();
      },
      strategy: bboxStrategy,
    });

    return (
      <VectorLayer
        show={props.show}
        highlight={props.highlight}
        zIndex={1}
        style={{
          'stroke-width': 0.75,
          'stroke-color': 'white',
          'fill-color': props.highlight ? 'rgba(85,137,85,0.25)' : 'rgba(100,100,100,0.25)',
        }}
        source={source}
      ></VectorLayer>
    );
  }

  return null;
});

ViewLayerOL.displayName = 'ViewLayer';

export default ViewLayerOL;
