import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Stepper, StepProps } from '@progress/kendo-react-layout';
import { RegisterWizardProvider, useCreateRegisterWizardState, RegisterWizardStep } from '../../context/registerWizard';
import { CentralizedContent } from '../../components/layout';
import { useUser } from '../../hooks/authentication';
import CredentialsStep from './Credentials';
import AccountSettingsStep from './AccountSettings';
import { useOrganizationFromInvitation } from '../../hooks/user_management';
import { useAppContext } from '../../context/app';

const RegisterWizard: React.FC = () => {
  const [searchParams] = useSearchParams();
  const wizardState = useCreateRegisterWizardState();
  const { isAuthenticated } = useUser();
  const context = useAppContext();
  const navigate = useNavigate();
  const { step, dispatch } = wizardState;
  const invitationCode = searchParams.get('invitationCode');
  const orgFromInvitationQuery = useOrganizationFromInvitation(invitationCode);

  useEffect(() => {
    if (isAuthenticated()) {
      if (invitationCode) {
        context.dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: {
            notification: {
              content: 'Please logout before accepting invitation from another account.',
              type: 'success',
            },
          },
        });
      }
      navigate('/', { replace: true });
    }
  }, []);

  useEffect(() => {
    if (orgFromInvitationQuery.isSuccess && orgFromInvitationQuery.data) {
      dispatch({
        type: 'ORG_INVITATION',
        payload: {
          organization: orgFromInvitationQuery.data.organization,
          userEmail: orgFromInvitationQuery.data.userEmail,
        },
      });
    } else if (orgFromInvitationQuery.isError) {
      navigate('/login', { replace: true });
    }
  }, [orgFromInvitationQuery.data]);

  const getItems = (): StepProps[] => {
    return [{ label: 'Credentials' }, { label: 'Account Settings', disabled: step < 1 }];
  };

  //const handleStepChange = (event: { value: number }) => {
  //  dispatch({ type: 'NAVIGATE', payload: { step: event.value } });
  //};

  return (
    <RegisterWizardProvider value={wizardState}>
      <CentralizedContent>
        <div className="row align-items-center justify-content-center">
          {true &&
            {
              [RegisterWizardStep.CREDENTIAL_INFOS]: <CredentialsStep />,
              [RegisterWizardStep.ACCOUNT_SETTINGS]: <AccountSettingsStep />,
            }[step]}
        </div>
      </CentralizedContent>
    </RegisterWizardProvider>
  );
};

export default RegisterWizard;
