import { useMutation, useQueryClient } from 'react-query';
import { Organization } from '../../types';
import { useAxiosInstance } from '../common/';

interface Params {
  orgId: string;
}

const useDeleteOrganization = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const result = await axios.delete<string>("/user_management/organization/" + params.orgId);
      return result.data;
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['organizations'], { refetchInactive: true });
        queryClient.removeQueries(['organization', variables.orgId]);
      },
    }
  );
};

export default useDeleteOrganization;
