import React, { useEffect } from 'react';

interface Props {
  title: string;
  children: any;
}

const Page: React.FC<Props> = ({ title, children }) => {
  useEffect(() => {
    document.title = 'GeoSAP - ' + title || 'GeoSAP';
  }, [title]);
  return children;
};

export default Page;
