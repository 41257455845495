import { LayerType, ViewLayer } from '../types';
import { TileWMS } from 'ol/source';

export const getWfsPreviewLayerLegendUrl = (layer: ViewLayer, params: any) => {
    if(layer.layerType !== LayerType.WFS && layer.layerType !== LayerType.GSVEC) return null;
    const wmsSource = new TileWMS({
        url: getWfsPreviewLayerUrl(layer),
        params: {
          FORMAT: 'image/png',
          VERSION: layer.paramsMap['version'],
          tiled: true,
          STYLES: layer.paramsMap['wmsStyle'],
          LAYERS: layer.paramsMap['layer'],
          exceptions: 'application/vnd.ogc.se_inimage',
        },
      });
    return wmsSource.getLegendUrl(null, {transparent: true});
  };

  export const getWfsPreviewLayerUrl = (layer: ViewLayer): string => {
    let url = layer.paramsMap['uri'];
    if(!url.endsWith('/')) {
      url = url.concat('/');
    }
    if (layer.paramsMap['workspace']) {
      url = url + layer.paramsMap['workspace'] + '/';
    }
    url = url + 'wms';
    return url;
  }

  export const getRasterPreviewLayerUrl = (layer: ViewLayer): string => {
    let url = layer.paramsMap['uri'];
    if(!url.endsWith('/')) {
      url = url.concat('/');
    }
    if (layer.paramsMap['workspace']) {
      url = url + layer.paramsMap['workspace'] + '/';
    }
    url = url + 'wms';
    if (url.endsWith('/')) {
      return url.slice(0, -1);
    }
    return url;
  }