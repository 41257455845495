import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '@progress/kendo-react-common';
import { CentralizedContent } from '../../components/layout';
import { SubmitButton } from '../../components/form';
import { useResendConfirmationEmail, useUser } from '../../hooks/authentication';
import { useAppContext } from '../../context/app';
import { AuthorityLevel, AuthorityType } from '../../types';

const BrandImage = styled.img`
  display: block;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
`;

const ConfirmationPending: React.FC = () => {
  const { dispatch } = useAppContext();
  const {getCurrentOrganization, userHasAuthority} = useUser();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();

  const navigationState: any = locationState;


  const goToSettings = () => {
    navigate("/settings");
  }

  return (
    <CentralizedContent>
      <div className="col-xs-12">
        <BrandImage src="/assets/brand-black.png" alt="GeoSAP brand" className="p-3 pb-4" />
          <Typography.h2 className='text-center'>Subscription Expired</Typography.h2>
          <Typography.p className='text-center'>{`Your Organization's subscription expired. ${userHasAuthority(AuthorityType.ORG_AUTHORITY, AuthorityLevel.UPDATE) ? "Go to your organization's settings page to update your subscription information." : "Ask an administrator of your organization to update the subscription information."}`}</Typography.p>
          <div className="row justify-content-center">
            <div className="col-md-6 p-3">
              {userHasAuthority(AuthorityType.ORG_AUTHORITY, AuthorityLevel.UPDATE) && <SubmitButton label="GO TO SETTINGS" onClick={()=>{navigate("/settings/organization")}} />}
            </div>
          </div>
      </div>
    </CentralizedContent>
  );
};

export default ConfirmationPending;