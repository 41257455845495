import React, { useEffect, useState } from 'react';
import { GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { StyledGrid } from '../../../components/styled';
import { useTusUploader } from '../../../hooks/common';
import { ProgressBar } from '../../../components/feedback';
import { TusUpload, TusUploadStatus } from '../../../types';
import { useAppContext } from '../../../context/app';
import { useUploadContext } from '../../../context/uploadContext';
const GRID_PAGESIZE = 10;

interface Props {
  onSelectUpload: (a: any) => void;
}

const StatusCellWithErrorHandler: React.FC<{ dataItem: TusUpload }> = ({ dataItem }) => {
  let bgColor = 'var(--geosap-primary-color)';
  const textColor = 'white';
  let text = 'Uploading';
  if (dataItem.status === TusUploadStatus.UPLOADING) {
    bgColor = 'var(--geosap-primary-color)';
    text = 'Uploading';
  } else if (dataItem.status === TusUploadStatus.COMPLETED) {
    bgColor = 'var(--view-green)';
    text = 'Completed';
  } else if (dataItem.status === TusUploadStatus.PAUSED) {
    bgColor = 'var(--warning-yellow)';
    text = 'Paused';
  } else if (dataItem.status === TusUploadStatus.INTERRUPTED) {
    bgColor = 'var(--warning-yellow)';
    text = 'Interrupted';
  } else if (dataItem.status === TusUploadStatus.CANCELED) {
    bgColor = 'var(--light-red)';
    text = 'Canceled';
  } else if (dataItem.status === TusUploadStatus.ERRORED) {
    bgColor = 'var(--hazard-red)';
    text = 'Errored';
  }
  return (
    <td>
      <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
        <ProgressBar
          progress={dataItem.progress}
          text={text}
          disableAnimation={dataItem.status !== 'uploading'}
          textColor={textColor}
          progressStyle={{ backgroundColor: bgColor }}
        />
      </div>
    </td>
  );
};

const UploadsBrowser: React.FC<Props> = (props: Props) => {
  const { dispatch: uploadDispatch, uploadsOpened, uploads } = useUploadContext();
  const { startUpload, pauseUpload, cancelUpload } = useTusUploader();
  const [page, setPage] = useState(0);

  const handlePageChange = (e: GridPageChangeEvent) => {
    setPage(e.page.skip / GRID_PAGESIZE);
  };

  return (
    <StyledGrid
      pageable={false}
      data={uploads}
      total={Object.values(uploads).length}
      sortable
      expandField="expanded"
      onRowClick={({ dataItem }) => {
        props.onSelectUpload(dataItem);
      }}
      rowRender={(trElement, props) => {
        const trProps: any = { style: { cursor: 'pointer' } };
        return React.cloneElement(
          trElement,
          {
            ...trProps,
          },
          trElement.props.children as any
        );
      }}
      style={{ height: '100%', marginLeft: '-1rem', marginRight: '-1rem' }}
    >
      <GridColumn
        field="file"
        title="File"
        sortable={false}
        cell={({ dataItem }: any) => <td className="fw-bold">{dataItem.file.name}</td>}
      />
      <GridColumn sortable={false} cell={StatusCellWithErrorHandler} title="Processing Status" />
    </StyledGrid>
  );
};

export default UploadsBrowser;
