import { useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../common';
import { Organization } from '../../types';
import { LOCAL_STORAGE_CURRENT_ORG } from '../../common/constants';
import { useAppContext } from '../../context/app';

const endpoint = '/user_management/invitation/accept';

interface Params {
  invitationCode: string;
}

const useAcceptInvitation = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  const { dispatch } = useAppContext();

  return useMutation(
    async (params: Params) => {
      return axios.put<Organization>(endpoint, params);
    },
    {
      onSuccess: (data, variables) => {
        localStorage.setItem(LOCAL_STORAGE_CURRENT_ORG, JSON.stringify(data.data));
        dispatch({ type: 'JOIN_ORGANIZATION', payload: { organization: data.data } });
      },
    }
  );
};

export default useAcceptInvitation;
