import { useContext, useEffect, useImperativeHandle, forwardRef, useState, useRef } from 'react';
import { fromLonLat, get } from 'ol/proj';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector.js';
import View, { ViewOptions } from 'ol/View';
import { register } from 'ol/proj/proj4.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import MapContext from '../Map/MapContext';
import { fetchProj4 } from '../../../../common/proj4Helper';
import { FeatureAttributeType, Layer2DClickResult, ViewLayer } from '../../../../types';
import MapContextData from '../Map/MapContextData';
import { useConsumeViewerState } from '../../../../context/viewer';
import { Fill, Stroke, Style } from 'ol/style';
import { Geometry } from 'ol/geom';
import Feature from 'ol/Feature';
import { StyleLike } from 'ol/style/Style';
import { MapBrowserEvent } from 'ol';

const proj4 = window.proj4 || require('proj4');

const destProjection = get('EPSG:3857'); // OpenLayers default projection (Web Mercator)

interface Props {
  show: boolean;
  style?: any;
  layer: ViewLayer;
  zIndex: number;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
  handleMapClick: (evt: MapBrowserEvent<any>) => Promise<Layer2DClickResult>;
} | null;
const VectorLayer = forwardRef<Ref, Props>(({ show, layer, style, zIndex = 0 }, ref) => {
  const { dispatch, hoveredFeature, featureFilter } = useConsumeViewerState();
  const { map } = useContext<MapContextData>(MapContext);
  //const [vectorLayer, SetVectorLayer] = useState<OLVectorLayer<VectorSource>>();
  const [highlightedInfos, setHighlightedInfos] = useState<{ index: number; ogStyle: StyleLike }>(null);
  const vectorLayerRef = useRef<OLVectorLayer<VectorSource>>(null);
  const defaultStyle: Style = style
    ? style
    : new Style({
        fill: new Fill({
          color: 'rgba(200,100,100,0.5)',
        }),
        stroke: new Stroke({
          width: 0.75,
          color: 'rgba(255,255,255,0.8)',
        }),
      });
  const hiddenStyle: Style = style
    ? style
    : new Style({
        fill: new Fill({
          color: 'rgba(0,0,0,0)',
        }),
        stroke: new Stroke({
          color: 'rgba(0,0,0,0)',
        }),
      });

  const highlightedStyle = new Style({
    stroke: new Stroke({
      color: 'blue', // You can set the highlight color
      width: 2, // Adjust the width as needed
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)', // Adjust the fill color and opacity
    }),
  });

  useEffect(() => {
    if (!map) return;
    const loadShapefile = async () => {
      const geojson: any = layer.geojson; //await shp(layer.uri.substring(0, layer.uri.length - 4));

      let sourceProjection = null;
      const projection = geojson.crs?.proj;
      if (projection) {
        // Projection found during shapefile parsing. Has been converted to WGS84 already.
        sourceProjection = null;
      } else {
        sourceProjection = get('EPSG:6590');
        if (!sourceProjection) {
          await fetchProj4('EPSG:6590');
        }
        sourceProjection = get('EPSG:6590');
      }

      const source = new VectorSource({
        features: new GeoJSON({
          dataProjection: sourceProjection,
          featureProjection: destProjection,
        }).readFeatures(geojson),
      });

      const vectorLayer = new OLVectorLayer({
        source,
        //style: defaultStyle,
        visible: show,
      });
      vectorLayer.setVisible(show);

      source.getFeatures().forEach((feature: Feature<Geometry>, index) => {
        feature.setStyle(defaultStyle);
        feature.setProperties({ interactable: true, layerId: layer.id, featureId: index });
      });

      vectorLayer.setZIndex(zIndex);
      console.log('Adding shapefile layer: ' + layer.id);
      vectorLayerRef.current = vectorLayer;
      map.addLayer(vectorLayer);
    };
    if (layer.geojson) loadShapefile();

    return () => {
      if (map) {
        if (vectorLayerRef.current) {
          map.removeLayer(vectorLayerRef.current);
        }
      }
    };
  }, [map, layer]);

  useEffect(() => {
    if (!map || !vectorLayerRef.current) return;
    vectorLayerRef.current.setVisible(show);
    vectorLayerRef.current.getSource().changed();
    map.updateSize();
  }, [show]);

  useEffect(() => {
    // Check if hoveredFeature is not null and its layerId matches props.layer.id
    if (hoveredFeature && hoveredFeature.layerId === layer.id) {
      // Get the vector source of the layer
      const source = vectorLayerRef.current.getSource();

      if (highlightedInfos) {
        source.getFeatureById(highlightedInfos.index).setStyle(highlightedInfos.ogStyle);
      }

      const highlightedFeature = source.getFeatureById(hoveredFeature.featureId);

      if (highlightedFeature) {
        setHighlightedInfos({ index: hoveredFeature.featureId, ogStyle: highlightedFeature.getStyle() });

        // Apply the highlighted style to the feature
        highlightedFeature.setStyle(highlightedStyle);
      }
    } else {
      // If the condition is false, restore the original style
      if (highlightedInfos) {
        const source = vectorLayerRef.current.getSource();
        source.getFeatureById(highlightedInfos.index).setStyle(highlightedInfos.ogStyle);
      }
      setHighlightedInfos(null);
    }
  }, [hoveredFeature, vectorLayerRef.current, layer.id]);

  useEffect(() => {
    if (vectorLayerRef.current) {
      const source = vectorLayerRef.current.getSource();
      const features = source.getFeatures();
      if (featureFilter && featureFilter.layerId === layer.id) {
        // Iterate through features and update visibility based on the filter
        const { attribute, min, max, attributeValue } = featureFilter;
        features.forEach((feature) => {
          const featureAttrValue = feature.get(attribute.name);

          // Check if the attribute value is within the specified range
          if (attribute.type === FeatureAttributeType.INTEGER || attribute.type === FeatureAttributeType.REAL) {
            if (typeof featureAttrValue === 'number' && featureAttrValue >= min && featureAttrValue <= max) {
              feature.setStyle(defaultStyle); // Set the default style to show the feature
              feature.set('interactable', true);
            } else {
              feature.setStyle(hiddenStyle);
              feature.set('interactable', false);
            }
          } else if (attribute.type === FeatureAttributeType.STRING) {
            if (featureAttrValue === attributeValue) {
              feature.setStyle(defaultStyle); // Set the default style to show the feature
              feature.set('interactable', true);
            } else {
              feature.setStyle(hiddenStyle);
              feature.set('interactable', false);
            }
          }
        });
      } else {
        features.forEach((feature) => {
          feature.setStyle(defaultStyle); // Set the default style to show the feature
          feature.set('interactable', true);
        });
      }
    }
  }, [featureFilter]);

  const zoomToLayer = (layer: any) => {
    try {
      map.getView().fit(vectorLayerRef?.current?.getSource()?.getExtent());
    } catch (e) {
      console.error('Problem while attempting to zoom to layer');
    }
  };

  const handleMapClick = async (evt: MapBrowserEvent<any>): Promise<Layer2DClickResult> => {
    // If the clicked layer is the current layer
    if (vectorLayerRef.current) {
      const feature = map.forEachFeatureAtPixel(evt.pixel, (feature) => {
        return feature;
      });
      return { layer };
    }
    return null;
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
    handleMapClick,
  }));

  return null;
});
VectorLayer.displayName = 'VectorLayer';

export default VectorLayer;
