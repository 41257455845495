import { useMutation } from 'react-query';
import { useAxiosInstance } from '../common';

const endpoint = '/authentication/reset_password';

interface ResetPasswordFormValues {
  password: string;
  resetCode: string;
}

const useRequestPasswordReset = () => {
  const axios = useAxiosInstance();

  return useMutation(async (passwordResetRequestData: ResetPasswordFormValues) => { 
    await axios.post<any>(endpoint, passwordResetRequestData);
  });
};

export default useRequestPasswordReset;