import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: JSX.Element | JSX.Element[];
}

const Container = styled.div`
  background: no-repeat url('/assets/mountain-mesh-bg.png');
  background-position: center bottom;
  background-size: contain;
`;

const BackgroundContainer: React.FC<Props> = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

export default BackgroundContainer;
