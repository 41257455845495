export interface AddLayerConfig {
  id: number;
  name: string;
  description: string;
  type: AddLayerType;
  indications: string[];
  process: LayerAdditionProcess | LayerAdditionProcess[];
}

export enum AddLayerType {
  WMS,
  WFS,
  Geotiff,
  Shapefile,
  Las,
  ProjectData,
}

export enum LayerAdditionProcess {
  URL,
  UPLOAD,
  GEOSERVICE,
  DATAPROJECT,
}
