import { useQueryClient, useMutation } from 'react-query';
import { useAxiosInstance } from '../../common';
import { SapConfig, SapFlowGroup } from '../../../types/DataDelivery';
import { ConfigPathGroup } from '../../../types';

const useGenerateSapGroupsFromPaths = (orgId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: {projectId: string, configId: string, paths: ConfigPathGroup}) => {
      const response = await axios.post<SapFlowGroup>(`data_wrangling/${params.projectId}/configs/${params.configId}/paths/generate`, params.paths);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sapGroups'], { refetchInactive: true });
      },
    }
  );
};

export default useGenerateSapGroupsFromPaths;
