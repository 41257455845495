import { AddLayerConfig, AddLayerType, LayerAdditionProcess } from '../../../../types';

const AddLayerConfigs: AddLayerConfig[] = [
  {
    id: 0,
    name: 'Project Data',
    type: AddLayerType.ProjectData,
    description: 'Add an internal datasets available in your organization.',
    indications: ['Select a dataset.'],
    process: LayerAdditionProcess.DATAPROJECT,
  },
  {
    id: 1,
    name: 'WMS',
    type: AddLayerType.WMS,
    description: 'Add an external Web Map Service layer to your Geosap viewer.',
    indications: ['Enter a WMS Geoservice url to continue.'],
    process: LayerAdditionProcess.GEOSERVICE,
  },
  {
    id: 2,
    name: 'WFS',
    type: AddLayerType.WFS,
    description: 'Add an external Web Feature Service feature to your Geosap viewer.',
    indications: ['Enter a WFS Geoservice url to continue.'],
    process: LayerAdditionProcess.GEOSERVICE,
  },
  /*,
    {
        id: 2,
        name: "Cloud Optimized Geotiff",
        type: AddLayerType.Geotiff,
        description: "Add a Cloud optimized geotiff to your Geosap viewer.",
        indications: ["Upload or enter a COG url to continue."],
        process: [LayerAdditionProcess.UPLOAD, LayerAdditionProcess.URL]
    },
    {
        id: 3,
        name: "Shapefile",
        type: AddLayerType.Shapefile,
        description: "Add a Shapefile to your Geosap viewer.",
        indications: ["Upload or enter a Shapefile url to continue."],
        process: [LayerAdditionProcess.UPLOAD, LayerAdditionProcess.URL]
    },
    {
        id: 4,
        name: "Las",
        type: AddLayerType.Las,
        description: "Add a Las pointcloud to your Geosap viewer.",
        indications: ["Upload or enter a Las url to continue."],
        process: [LayerAdditionProcess.UPLOAD, LayerAdditionProcess.URL]
    }*/
];

export default AddLayerConfigs;
