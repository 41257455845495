import { useQuery } from 'react-query';
import { SapFlowProcessNode } from '../../types/DataDelivery';
import { useAxiosInstance } from '../common';

const useSapProcessNode = (sapFlowId: string, processNodeId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['sapFlowProcessNode', sapFlowId, processNodeId], async () => {
    if (sapFlowId === null || processNodeId === null) return null;
    const result = await axios.get<SapFlowProcessNode>('file_sapflow/' + sapFlowId + "/" + processNodeId);
    return result.data;
  });
};

export default useSapProcessNode;
