import React, { Fragment, useEffect, useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Loader, Skeleton } from '@progress/kendo-react-indicators';
import 'jsoneditor-react/es/editor.min.css';
import Dialog from '../../components/Dialog';
import { useSapFlow, useSapFlowParamsUpdate } from '../../hooks/data_delivery';
import Logs from '../SapFlowDetails/Logs';

interface Props {
  show: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  sapflowId: string;
}

let shown = false;

const SapFlowStatusHistory: React.FC<Props> = (props: Props) => {
  const sapFlowParamsUpdateMutation = useSapFlowParamsUpdate();
  const sapFlowQuery = useSapFlow(shown ? props.sapflowId : null);
  useEffect(() => {
    shown = true;
  }, [props.show]);

  const parametersAvailable = true;
  const loading = sapFlowQuery.isLoading;
  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>SapFlow Status History</Typography.h3>}
      onClose={props.handleClose}
      style={{ display: 'flex', flexDirection: 'column', width: '1200px', height: '800px', maxHeight: '80%' }}
    >
      {loading && <Loader></Loader>}
      {!loading && sapFlowQuery.data && (
        <div style={{ paddingTop: '4rem' }}>
          <Typography.h1 fontWeight="light" textAlign="center">
            {sapFlowQuery.data.sapflowname}
          </Typography.h1>
          <div>
            <Logs statusHistory={sapFlowQuery.data.transactionHistory}></Logs>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default SapFlowStatusHistory;
