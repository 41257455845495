import React, { useState, useRef, useEffect } from 'react';
import Dialog from '../Dialog';
import { Typography } from '@progress/kendo-react-common';
import { Input } from '@progress/kendo-react-inputs';
import { SubmitButton } from '../form';
import { DOWNLOAD_SIZE_WARNING, FileNode } from '../../types/DataDelivery';
import { formatSizeUnits } from '../../converters/formatSizeUnits';

interface DownloadWarningModalProps {
  filenode: FileNode;
  show: boolean;
  handleClose: any;
  onConfirm: any;
}

const DownloadWarningModal: React.FC<DownloadWarningModalProps> = (props: DownloadWarningModalProps) => {
  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>Large dataset download</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
      style={{ display: 'flex', flexDirection: 'column', width: '460px' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography.p>{`Data size is over ${formatSizeUnits(DOWNLOAD_SIZE_WARNING)}.`}</Typography.p>
        <Typography.p>{`Are you sure you wish to download the data?`}</Typography.p>
        <SubmitButton
          type="button"
          label="Submit"
          uppercase={false}
          full={false}
          disabled={false}
          onClick={() => {
            props.onConfirm();
          }}
          loading={false}
          className="mt-2 ml-auto"
        />
      </div>
    </Dialog>
  );
};

export default DownloadWarningModal;
