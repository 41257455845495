// Dropzone.tsx
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

interface DropzoneProps {
  onDrop: (acceptedFiles: File[]) => void;
}

const Dropzone: React.FC<DropzoneProps> = ({ onDrop }) => {
  const onDropCallback = useCallback(
    (acceptedFiles: File[]) => {
      onDrop(acceptedFiles);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropCallback,
  });

  const dropzoneStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '16rem',
    padding: '1rem',
    borderWidth: '2px',
    borderRadius: '0.5rem',
    borderColor: isDragActive ? '#3b82f6' : '#d1d5db',
    borderStyle: 'dashed',
    backgroundColor: isDragActive ? '#ebf8ff' : '#f9fafb',
    cursor: 'pointer',
    outline: 'none',
  };

  const textStyle: React.CSSProperties = {
    textAlign: 'center',
    color: isDragActive ? '#3b82f6' : '#6b7280',
  };

  return (
    <div {...getRootProps()} style={dropzoneStyle}>
      <input {...getInputProps()} />
      <div style={textStyle}>
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>{"Drag 'n' drop some files here, or click to select files"}</p>
        )}
      </div>
    </div>
  );
};

export default Dropzone;
