import React, { useState, useRef } from 'react';
import { SubmitButton } from '../../../../components/form';
import { Typography } from '@progress/kendo-react-common';
import styled from 'styled-components';
import { Reveal } from '@progress/kendo-react-animation';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { AddLayerConfig, AddLayerType } from '../../../../types';
import AddLayerConfigs from './AddLayerConfigs';
import { truncateText } from '../../../../common/stringHelper';
import { AddLayerWizardStep, useConsumeAddLayerWizardState } from '../../../../context/addLayerWizard';
import TransitionAnimation from '../../../../components/TransitionAnimation';
import StyledExpansionPanel from '../../../../components/styled/StyledExpansionPanel';

const AddLayerModal: React.FC = () => {
  const [selectedLayerConfig, setSelectedLayerConfig] = useState<AddLayerConfig>(null);
  const { dispatch, viewerConfigId } = useConsumeAddLayerWizardState();

  const handleExpansionPanelAction = (selected: AddLayerConfig | null, event: ExpansionPanelActionEvent) => {
    setSelectedLayerConfig(event.expanded ? null : selected);
  };

  const handleLayerConfigSelection = (layerConfig: AddLayerConfig) => {
    let nextStep = AddLayerWizardStep.SUBMIT_URL;
    if (layerConfig.type === AddLayerType.Geotiff) {
      nextStep = AddLayerWizardStep.SELECT_GEOTIF;
    } else if (layerConfig.type === AddLayerType.Shapefile) {
      nextStep = AddLayerWizardStep.SELECT_SHAPEFILE;
    } else if (layerConfig.type === AddLayerType.Las) {
      nextStep = AddLayerWizardStep.SELECT_LAS;
    } else if (layerConfig.type === AddLayerType.ProjectData) {
      nextStep = AddLayerWizardStep.SELECT_PROJECTDATA;
    }
    dispatch({ type: 'SELECT_TYPE', payload: { layerConfig, nextStep } });
  };

  const renderLayerSelection = (layerConfig: AddLayerConfig, index: number) => {
    return (
      <StyledExpansionPanel
        key={index}
        title={
          <>
            <Typography.h3 className="flex-grow-1 text-capitalize">{truncateText(layerConfig.name, 46)}</Typography.h3>
          </>
        }
        expanded={selectedLayerConfig?.id === layerConfig.id}
        tabIndex={0}
        onAction={handleExpansionPanelAction.bind(undefined, layerConfig)}
        expandIcon="k-icon k-i-arrow-chevron-right animated-transform rotated-0"
        collapseIcon="k-icon k-i-arrow-chevron-right animated-transform rotated--90"
      >
        <Reveal>
          {selectedLayerConfig?.id === layerConfig.id && (
            <ExpansionPanelContent>
              <Typography.h5
                style={{
                  margin: '25px',
                }}
              >
                {layerConfig.description}
              </Typography.h5>
              <div className="d-flex justify-content-end my-4 me-4">
                <SubmitButton
                  label={`Add ${layerConfig.name} layer`}
                  uppercase={false}
                  full={false}
                  onClick={() => {
                    handleLayerConfigSelection(layerConfig);
                  }}
                />
              </div>
            </ExpansionPanelContent>
          )}
        </Reveal>
      </StyledExpansionPanel>
    );
  };

  return (
    <TransitionAnimation>
      <>{AddLayerConfigs.map((layerConfig: AddLayerConfig, index) => renderLayerSelection(layerConfig, index))}</>
    </TransitionAnimation>
  );
};

export default AddLayerModal;
