import React from 'react';
import { GridRowProps } from '@progress/kendo-react-grid';

interface HoverableGridRowProps extends GridRowProps {
  row: React.ReactElement<HTMLTableRowElement, string | React.JSXElementConstructor<any>>;
  className?: string;
  style?: React.CSSProperties;
  onHover: (dataItem: any) => void;
  onLeave: (dataItem: any) => void;
}

const HoverableGridRow: React.FC<HoverableGridRowProps> = (props: HoverableGridRowProps) => {
  const row: any = props.row;
  const rowWithHover = React.cloneElement(row, {
    className: props.row.props.className + ' ' + props.className,
    style: props.style,
    onMouseEnter: () => {
      props.onHover(props.dataItem);
    },
    onMouseLeave: () => {
      props.onLeave(props.dataItem);
    },
  });

  return rowWithHover;
};

export default HoverableGridRow;
