import { useQueryClient, useMutation } from 'react-query';
import { ViewLayer, ViewLayerParam } from '../../types';
import { useAxiosInstance } from '../common';

interface ViewLayerUpdateDTO {
    viewLayerId: string;
    params: ViewLayerParam[];
}

const endpoint = 'viewer_config/layer';

const useViewLayerParamsUpdate = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: ViewLayerUpdateDTO) => {
      const response = await axios.put<ViewLayer>(endpoint + "/" + params.viewLayerId + "/params", params.params);
      return response.data;
    },
    {
      onSuccess: (data: any, variables: ViewLayerUpdateDTO) => {
        queryClient.invalidateQueries(['viewLayer', variables.viewLayerId], { refetchInactive: true });
      },
    }
  );
};

export default useViewLayerParamsUpdate;
