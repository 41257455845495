import { useQuery } from 'react-query';
import { Organization, User } from '../../types/';
import { useAxiosInstance } from '../common';

const endpoint = '/user_management/users/all';

const useUsers = () => {
  const axios = useAxiosInstance();
  return useQuery(['users'], async () => {
    const result = await axios.get<User[]>(endpoint);
    return result.data;
  });
};

export default useUsers;