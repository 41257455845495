import { useQuery } from 'react-query';
import { OrganizationMember } from '../../types/';
import { useAxiosInstance } from '../common';

const endpoint = '/user_management/organization/';

const useOrganizationMember = (orgId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['organizationMembers', orgId], async () => {
    const result = await axios.get<OrganizationMember[]>(endpoint + orgId + "/users");
    return result.data;
  });
};

export default useOrganizationMember;