import React from 'react';
import { Typography } from '@progress/kendo-react-common';
import { SapFlowProcessNode, SapFlowProcessNodeStatus, SapFlowProcessStatus, SapFlowTransaction } from '../../types';
import { ProgressBar } from '../../components/feedback';


const SummaryProcesses: React.FC<SapFlowTransaction> = ({ processnodes }) => {
  const renderProcessStatus = (processNode: SapFlowProcessNode) => {
    if(!processNode || !processNode.processNodeStatusHistory || processNode.processNodeStatusHistory.length === 0) {
      return <span>No progress information.</span>
    }
    else if(processNode.status === SapFlowProcessStatus.COMPLETED) {
      return (
          <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
            <ProgressBar progress={100} />
          </div>
      );
    } 
    else if(processNode.status === SapFlowProcessStatus.CREATED) {
      return (
          <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
            <p>{"N/A"}</p>
          </div>
      );
    } else if (processNode.status === SapFlowProcessStatus.ERRORED) {
      return (
        <div className="gap-3" style={{ paddingRight: '1rem' }}>
          <span style={{color: "var(--geosap-dark-error)"}}>ERRORED</span>
          <p>{processNode.processMessage}</p>
        </div>)
    }
    else {
      return (
          <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
            <ProgressBar progress={0/*statusHistory[statusHistory.length-1].currentProcessPercentCompleted*/} />
          </div>
      );
    }
  }
  return (
    <div style={{ fontSize: '0.8rem' }}>
      <ul className="overflow-auto" style={{ maxHeight: '500px' }}>
        {processnodes.map((processNode, index) => (
          <li key={processNode.id}>
            <div className="px-4 py-3 rounded-3" style={{ background: 'var(--geosap-kendo-button-bg)' }}>
              <Typography.h6>
                {index + 1}. {processNode.displayname}
              </Typography.h6>
              {/*<p className="mb-0">{description}</p>*/}
              {renderProcessStatus(processNode)}
            </div>
            <div className="py-2 d-flex justify-content-center">
              <svg height="22.0" width="50" id="canvas">
                <polygon points="0,0 50,0 25.0,22.0" style={{ fill: 'var(--geosap-grid-header-bg' }}></polygon>
              </svg>
            </div>
          </li>
        ))}
      </ul>
      <div
        style={{ background: 'var(--geosap-grid-header-bg)', color: 'var(--geosap-primary-color)' }}
        className="text-center py-2 fw-bold rounded-3 mt-2"
      >
        SAPFlow Complete
      </div>
    </div>
  );
};

export default SummaryProcesses;
