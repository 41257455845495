import { useQuery } from 'react-query';
import { SapFlowProjectWithSapFlows } from '../../../types';
import { useAxiosInstance } from '../../common';

const useProject = (projectId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['project', projectId], async () => {
    const result = await axios.get<SapFlowProjectWithSapFlows>("project/" + projectId);
    return result.data;
  });
};

export default useProject;
