import { useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../common';

interface Params {
  configId: string;
  name: any;
  description: any;
  pathingRegex: any;
}

const useSapConfigUpdate = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.put<any>('file_sapconfig/' + params.configId, params);
      return response.data;
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['sapConfigParams', variables.configId], { refetchInactive: true });
      },
    }
  );
};

export default useSapConfigUpdate;
