import { useContext, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import MapContext from '../Map/MapContext';
import OLTileLayer from 'ol/layer/Tile';
import { ViewLayer } from '../../../../types';
import { TileWMS } from 'ol/source';
import { transformExtent } from 'ol/proj';
import { Extent } from 'ol/extent';
import { useConsumeViewerState } from '../../../../context/viewer';

interface Props {
  show: boolean;
  layer?: ViewLayer;
  source?: any;
  zIndex: number;
  style?: any;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
} | null;
const TileLayer = forwardRef<Ref, Props>(({ source, zIndex = 0, show, layer }, ref) => {
  const { map } = useContext<any>(MapContext);
  const { olMapInitialized } = useConsumeViewerState();
  const [tileLayer, setTileLayer] = useState(null);
  const [zoomWhenReady, setZoomWhenReady] = useState(false);
  const [transformedExtents, setTransformedExtents] = useState(null);

  useEffect(() => {
    if (!map) return;

    let newTransformedExtents = null;

    if (source) {
      //
    } else if (layer) {
      // TODO: Add verification to check if something effectively changed in the layer and avoid reloading everything
      const url = layer.paramsMap['uri'] + '/wms';
      const layerName =
        (layer.paramsMap['workspace'] ? layer.paramsMap['workspace'] + ':' : '') + layer.paramsMap['layer'];
      source = new TileWMS({
        url: url,
        params: {
          FORMAT: 'image/png',
          VERSION: layer.paramsMap['version'],
          tiled: true,
          STYLES: '',
          LAYERS: layerName,
          exceptions: 'application/vnd.ogc.se_inimage',
        },
      });

      if (layer.paramsMap['projection']) {
        const projection: string = layer.paramsMap['projection'];
        let extents: Extent = null;
        if (layer.paramsMap['boundingbox']) {
          extents = layer.paramsMap['boundingbox'];
        } else if (layer.paramsMap['extents']) {
          extents = layer.paramsMap['extents'];
        }
        if (extents) {
          newTransformedExtents = transformExtent(extents, projection, map.getView().getProjection().getCode());
          setTransformedExtents(newTransformedExtents);
        }
      }
    } else {
      console.log('A tile layer without source or ViewLayer detected.');
    }

    const newTileLayer = new OLTileLayer({
      source,
      zIndex,
      visible: show,
      extent: newTransformedExtents,
    });
    newTileLayer.setProperties({ title: layer?.displayName, viewLayerId: layer?.id });

    map.addLayer(newTileLayer);
    newTileLayer.setZIndex(zIndex);
    setTileLayer(newTileLayer);

    return () => {
      if (map) {
        map.removeLayer(newTileLayer);
      }
    };
  }, [map, source, layer]);

  useEffect(() => {
    if (!tileLayer) return;
    tileLayer.setVisible(show);
  }, [show]);

  useEffect(() => {
    if (zoomWhenReady && olMapInitialized && transformedExtents) {
      map.getView().fit(transformedExtents);
      setZoomWhenReady(false);
    }
  }, [zoomWhenReady, olMapInitialized, transformedExtents]);

  const zoomToLayer = (layerToZoom: any) => {
    setZoomWhenReady(true);
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
  }));

  return null;
});
TileLayer.displayName = 'TileLayer';

export default TileLayer;
