import { useMutation } from 'react-query';
import { useAxiosInstance } from '../common';

const endpoint = '/authentication/check_user_exists';

interface Params {
    email: string,
}

const useValidateEmail = () => {
  const axios = useAxiosInstance();

  return useMutation(async (validationParams: Params) => { 
    await axios.post<any>(endpoint, validationParams);
  });
};

export default useValidateEmail;
