import { useAppContext } from '../../context/app';

const useNotification = () => {
  const { dispatch, notification } = useAppContext();

  const clearOnRouteChange = () => {
    if (notification?.type === 'error') {
      dispatch({ type: 'CLEAR_NOTIFICATION' });
    }
  };

  return { clearOnRouteChange };
};

export default useNotification;
