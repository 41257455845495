import React, { useEffect, useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Reveal } from '@progress/kendo-react-animation';
import { Avatar, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { useUsers, useUserDelete } from '../../hooks/user_management';
//import { HumanReadableRole, toEnumRole, toReadableRole } from '../../converters/userRoleConverter';
import { AuthorityLevel, AuthorityType, Organization, User } from '../../types';
import { SubmitButton } from '../../components/form';
import CreateUserDialog from './CreateUserDialog';
import { RestrictedSubmitButton } from '../../components/restricted';
import ConfirmationDialog from '../../components/ConfirmationDialog';

const UserManagement: React.FC = () => {
  const usersQuery = useUsers();
  const [creatingUser, setCreatingUser] = useState<boolean>(false);
  const [deletingUser, setDeletingUser] = useState<User>(null);
  const deleteUserMutation = useUserDelete();

  const handleCreateUser = () => {
    setCreatingUser(true);
  };

  const handleConfirmedDeleteUser = () => {
    deleteUserMutation.mutateAsync({ userId: deletingUser.id });
    setDeletingUser(null);
  };

  const renderUserItem = (user: User) => {
    return (
      <div
        className="k-listview-item row p-2 border-bottom align-middle"
        style={{
          margin: 0,
        }}
      >
        <div className="col-2">
          <Avatar type="img">
            <img
              style={{ width: '100%', height: '100%' }}
              src={`https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`}
              alt="KendoReact ListView Contacts Avatar"
            />
          </Avatar>
        </div>
        <div className="col-6">
          <h2
            style={{
              fontSize: 14,
              color: '#454545',
              marginBottom: 0,
            }}
            className="text-uppercase"
          >
            {user?.firstName + ' ' + user?.lastName}
          </h2>
          <div
            style={{
              fontSize: 12,
              color: '#a0a0a0',
            }}
          >
            {user.email}
          </div>
        </div>
        <div className="col-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <RestrictedSubmitButton
            themeColor="error"
            onClick={() => {
              setDeletingUser(user);
            }}
            size="small"
            fillMode={'outline'}
            label={'Delete'}
            authorityType={AuthorityType.SYSTEM_AUTHORITY}
            authorityLevel={AuthorityLevel.DELETE}
            full={false}
            className="mx-1"
          ></RestrictedSubmitButton>
        </div>
      </div>
    );
  };

  const renderUsers = () => {
    return (
      <div>
        {usersQuery.data &&
          usersQuery.data.length > 0 &&
          usersQuery.data
            .sort((user1, user2) => {
              const nameA = user1.lastName.toUpperCase(); // Convert names to uppercase for case-insensitive comparison
              const nameB = user2.lastName.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0; // names are equal
            })
            .map((user) => renderUserItem(user))}
      </div>
    );
  };

  return (
    <div className="container-fluid p-2 bg-white">
      <CreateUserDialog
        show={creatingUser}
        onClose={() => {
          setCreatingUser(false);
        }}
      ></CreateUserDialog>
      <ConfirmationDialog
        show={deletingUser ? true : false}
        onConfirm={handleConfirmedDeleteUser}
        onClose={() => {
          setDeletingUser(null);
        }}
        title={'Delete User'}
        text={
          deletingUser
            ? `Do you want to to delete user ` + deletingUser.firstName + ' ' + deletingUser.lastName + '?'
            : ''
        }
        loading={false}
      ></ConfirmationDialog>
      <div className="d-flex flex-column col-12 col-md-8 mx-auto">
        <Typography.h2 className="text-center pt-3 pb-1">USER MANAGEMENT</Typography.h2>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '2rem' }}>
          <SubmitButton themeColor="primary" label="Create" onClick={handleCreateUser} full={false}></SubmitButton>
        </div>
        {renderUsers()}
      </div>
    </div>
  );
};

export default UserManagement;
