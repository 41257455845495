import { useMutation, useQueryClient } from 'react-query';
import { SapFlowTransactionItem } from '../../../types';
import { useAxiosInstance } from '../../common/';

const endpoint = '/sapflow/itemnode';

interface Params {
  itemnodeid: string;
  name?: string;
  displayName?: string;
}

const useTransactionItemNodeUpdate = (transactionId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.put<SapFlowTransactionItem>(endpoint + '/' + params.itemnodeid, params);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sapFlowView', transactionId], { refetchInactive: true });
      },
    }
  );
};

export default useTransactionItemNodeUpdate;
