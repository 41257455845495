import { useQuery, useQueryClient } from 'react-query';
import { Page, SapFlowTransaction } from '../../../types';
import { useAxiosInstance } from '../../common/';

const endpoint = 'sapflow';

interface TransQueryParam {
  page: number;
  size: number;
  sort?: string;
}

const useTransactions = (refetchInterval: number | false = false, page: number, size: number, sort: string[]) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();

  const params: TransQueryParam = {page, size}
  if(sort && sort.length > 0)
    params.sort = sort.join(',');

  return useQuery(
    ['transactions', page, sort],
    async () => {
      const result = await axios.get<Page<SapFlowTransaction>>(endpoint, {params});
      const transactions = result.data.content;
      // Normalize: Store each transaction separately and return an array of their IDs
      transactions.forEach(tx => {
        queryClient.setQueryData(['transaction', tx.id], tx);
      });

      // Return transaction IDs for pagination
      return {
        ...result.data,
        content: transactions.map(tx => tx.id),
        selectTransactionById: (id: string) => queryClient.getQueryData<SapFlowTransaction>(['transaction', id])
      };
    },
    { refetchInterval, keepPreviousData: true }
  );
};

export default useTransactions;
