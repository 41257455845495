import React from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { SubmitButton } from '../../components/form';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [completingPayment, setCompletingPayment] = React.useState<boolean>(false);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setCompletingPayment(true);

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_GEOSAP_CLIENTURL + "subscriptionreturn",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setCompletingPayment(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <SubmitButton label="Purchase Subscription" disabled={!stripe} loading={completingPayment}/>
    </form>
  )
};

export default CheckoutForm;