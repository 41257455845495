export const formatSizeUnits = (bytes: number): string => {
  let formattedBytes = '0 bytes';
  if (bytes >= 1073741824) {
    formattedBytes = (bytes / 1073741824).toFixed(2) + ' GB';
  } else if (bytes >= 1048576) {
    formattedBytes = (bytes / 1048576).toFixed(2) + ' MB';
  } else if (bytes >= 1024) {
    formattedBytes = (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes > 1) {
    formattedBytes = bytes + ' bytes';
  } else if (bytes === 1) {
    formattedBytes = bytes + ' byte';
  }
  return formattedBytes;
};
