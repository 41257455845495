import React, { useState, useRef } from 'react';
import { SubmitButton } from '../../../../components/form';
import { Typography } from '@progress/kendo-react-common';
import styled from 'styled-components';
import { AddLayerConfig } from '../../../../types';
import { useConsumeAddLayerWizardState } from '../../../../context/addLayerWizard';
import TransitionAnimation from '../../../../components/TransitionAnimation';

const UnsupportedLayer: React.FC = () => {
  const [selectedLayerConfig, setSelectedLayerConfig] = useState<AddLayerConfig>(null);
  const { dispatch } = useConsumeAddLayerWizardState();

  const handleLayerConfigSelection = (layerConfig: AddLayerConfig) => {
    //dispatch({ type: 'SELECT_TYPE', payload: { layerConfig } });
  }

  return (
    <TransitionAnimation>
      <>
        <Typography.h3>Unsupported Layer type</Typography.h3>
      </>
    </TransitionAnimation>
  );
};

export default UnsupportedLayer;