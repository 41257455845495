export interface WSMessage {
  id: string;
  msgHeader: WSMessageHeader;
  payload: any;
  expanded?: boolean;
}

export interface WSMessageHeader {
  msgFrom: string;
  fromType: EMsgDestinationType;
  msgTo: string;
  toType: EMsgDestinationType;
  msgType: EMsgType;
  sendTime: string;
}

export enum EMsgDestinationType {
  GS_SERVER,
  FOLIAGE_SERVER,
  ROOTS_SERVER,
  USER,
  ORGANIZATION,
  SYSTEM,
}

export enum EMsgType {
  SERVER_ERROR = 'SERVER_ERROR',
  SERVER_INFO = 'SERVER_INFO',
  SERVER_STATUS = 'SERVER_STATUS',
  SERVER_TASK_STATUS = 'SERVER_TASK_STATUS',
  SERVER_NEW_SAPS_AVAILABLE = 'SERVER_NEW_SAPS_AVAILABLE',
  TRANSACTION_STARTED = 'TRANSACTION_STARTED',
  TRANSACTION_FINISHED = 'TRANSACTION_FINISHED',
  TRANSACTION_ERROR = 'TRANSACTION_ERROR',
  TRANSACTION_UPDATE = 'TRANSACTION_UPDATE',
  ORGANIZATION_PEER_2_PEER_MSG = 'ORGANIZATION_PEER_2_PEER_MSG',
  ORGANIZATION_NEW_DATA_AVAILABLE = 'ORGANIZATION_NEW_DATA_AVAILABLE',
  HEARTBEAT = 'HEARTBEAT',
  PROCESS_NODE_STATUS_UPDATE = 'PROCESS_NODE_STATUS_UPDATE',
  TRANSACTION_STATUS_UPDATE = 'TRANSACTION_STATUS_UPDATE',
  FILE_SAPGROUP_STATUS_UPDATE = 'FILE_SAPGROUP_STATUS_UPDATE',
  FILE_SAPFLOW_STATUS_UPDATE = 'FILE_SAPFLOW_STATUS_UPDATE',
  FILE_PROCESS_NODE_STATUS_UPDATE = 'FILE_PROCESS_NODE_STATUS_UPDATE',
  ZIP_STATUS_UPDATE = 'ZIP_STATUS_UPDATE',
  FILENODE_STATUS_UPDATE = 'FILENODE_STATUS_UPDATE',
  FILENODE_NEW_ANNOTATION_UPDATE = 'FILENODE_NEW_ANNOTATION_UPDATE',
}
