import { UserRole } from '../types';
// UserRoleUtils.ts

export type HumanReadableRole =
  | 'System Admin'
  | 'Super Admin'
  | 'Administrator'
  | 'Super User'
  | 'User'
  | 'View User'
  | 'Unknown Role';

const roleMapping: { [key in UserRole]: HumanReadableRole } = {
  [UserRole.ROLE_SYSTEMADMIN]: 'System Admin',
  [UserRole.ROLE_SUPERADMIN]: 'Super Admin',
  [UserRole.ROLE_ADMIN]: 'Administrator',
  [UserRole.ROLE_SUPERUSER]: 'Super User',
  [UserRole.ROLE_USER]: 'User',
  [UserRole.ROLE_VIEWUSER]: 'View User',
};

const reverseRoleMapping: { [key in HumanReadableRole]: UserRole } = Object.keys(roleMapping).reduce((acc, key) => {
  const roleKey = key as unknown as UserRole;
  acc[roleMapping[roleKey]] = roleKey;
  return acc;
}, {} as { [key in HumanReadableRole]: UserRole });

export function toReadableRole(role: UserRole): HumanReadableRole {
  return roleMapping[role];
}

export function toEnumRole(role: HumanReadableRole): UserRole {
  return reverseRoleMapping[role];
}
