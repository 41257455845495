import { useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../common';

interface Params {
  firstName: string,
  lastName: string,
  description: string,
  email: string,
  password: string,
  emailValidated: boolean,
  organizationId?: string,
}

const useUserCreate = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();

  return useMutation(async (params: Params) => { 
    return axios.post("/user_management/users", params);
  },
  {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['users'], { refetchInactive: true });
    },
  });
};

export default useUserCreate;
