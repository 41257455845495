import { useQuery } from 'react-query';
import { SapFlowViewConfig, LayerType, ViewLayer, ViewLayerParam, ViewLayerState } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'viewer_config/layer/';

const useViewLayer = (providedLayer: ViewLayer, viewLayerId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['viewLayer', viewLayerId], async () => {
    if (providedLayer) return providedLayer;
    if (!viewLayerId) return null;
    const result = await axios.get<ViewLayer>(endpoint + viewLayerId);
    const viewLayer = result.data;
    viewLayer.paramsMap = {};
    viewLayer.params?.forEach((param: ViewLayerParam) => {
      try {
        viewLayer.paramsMap[param.key] = JSON.parse(param.value);
      } catch (e) {
        viewLayer.paramsMap[param.key] = param.value;
      }
    });
    viewLayer.viewState = ViewLayerState.READY;
    return viewLayer;
  });
};

export default useViewLayer;
