import React from 'react';
import { Loader } from '@progress/kendo-react-indicators';

interface OverlayedloaderProps {
  show: boolean;
}

const Overlayedloader: React.FC<OverlayedloaderProps> = (props: OverlayedloaderProps) => {
  return (
    <div
      style={{
        display: props.show ? 'flex' : 'none',
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 10,
        padding: '1rem',
        borderTopLeftRadius: '1rem',
        backgroundColor: 'rgba(200,200,200,0.6)',
      }}
    >
      <Loader size="small" type={'converging-spinner'} themeColor={'primary'} />
    </div>
  );
};

export default Overlayedloader;
