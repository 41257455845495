import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Typography } from '@progress/kendo-react-common';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import styled from 'styled-components';
import { AppNotification, Category } from '../../types';
import { REQUIRED_FIELD } from '../../common/constants';
import { Input, SubmitButton } from '../../components/form';
import { useConsumeRegisterWizardState } from '../../context/registerWizard';
import { useAppContext } from '../../context/app';
import { useCreateUserAndOrg } from '../../hooks/authentication';
import TransitionAnimation from '../../components/TransitionAnimation';

const BrandImage = styled.img`
  display: block;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
`;

declare const window: any;

interface FormValues {
  accountType: 'Individual' | 'Business';
  organizationName: string;
  jobTitle: string;
  firstName: string;
  lastName: string;
  industry: string;
  location: string;
}

const CredentialsStep: React.FC = () => {
  const [accountType, setAccountType] = React.useState<string>('Individual');
  const [creatingAccount, setCreatingAccount] = React.useState<boolean>(false);
  const { dispatch: appDispatch } = useAppContext();
  const navigate = useNavigate();
  const useCreateUserAndOrgMutation = useCreateUserAndOrg();
  const { handleSubmit, control, formState, reset, setValue } = useForm<FormValues>({
    defaultValues: {
      organizationName: '',
      firstName: '',
      lastName: '',
      jobTitle: '',
      industry: '',
      location: '',
    },
    resolver: yupResolver(
      yup.object({
        organizationName: accountType === 'Business' ? yup.string().required(REQUIRED_FIELD) : yup.string(),
        //jobTitle: yup.string().required(REQUIRED_FIELD),
        industry: yup.string(),
        location: yup.string(),
      })
    ),
  });
  const {
    dispatch,
    organizationName,
    organizationToJoin,
    email,
    password,
    firstName,
    lastName,
    jobTitle,
    industry,
    location,
  } = useConsumeRegisterWizardState();

  const completeRegistration = ({
    organizationName,
    jobTitle,
    firstName,
    lastName,
    industry,
    location,
  }: FormValues) => {
    //window.dataLayer.push({
    //  event: 'Register',
    //  step: '0',
    //  stepName: 'Enter Credentials',
    //  email: email,
    //});
    //dispatch({ type: 'SELECT_CREDENTIALS', payload: { organizationName, jobTitle:formPassword?formPassword:"" } });
    console.log('Displayig notif');
    setCreatingAccount(true);
    useCreateUserAndOrgMutation.mutateAsync(
      {
        user_email: email,
        user_password: password,
        first_name: firstName,
        last_name: lastName,
        organization_id: organizationToJoin ? organizationToJoin.id : null,
        organization_name: accountType === 'Business' ? organizationName : firstName + "'s Company",
        //job_title: jobTitle,
        industry: industry,
        location: location,
      },
      {
        onSuccess: () => {
          const notification: AppNotification = {
            type: 'success',
            content: 'Successfully created user. Please confirm your email.',
          };
          appDispatch({ type: 'SHOW_NOTIFICATION', payload: { notification } });
          navigate('/login');
        },
        onError: (err: any) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message.includes('Account confirmation still pending.')
          ) {
            navigate('/pending', { state: { email, password } });
          }
        },
      }
    );
  };

  useEffect(() => {
    if (organizationToJoin) {
      setAccountType('Business');
      reset({
        organizationName: organizationToJoin.name,
        firstName: '',
        lastName: '',
        jobTitle: '',
        industry: organizationToJoin.industry ? organizationToJoin.industry : '',
        location: organizationToJoin.location ? organizationToJoin.location : '',
      });
    } else {
      reset({
        organizationName: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        industry: '',
        location: '',
      });
    }
  }, [organizationName, organizationToJoin, firstName, lastName, jobTitle, industry, location]);

  console.log('AccountType to ' + accountType);

  return (
    <TransitionAnimation>
      <div className="row align-items-center justify-content-center">
        <div className="col-xs-12 col-lg-6 col-xl-4">
          <BrandImage src="/assets/brand-black.png" alt="GeoSAP brand" className="p-3 pb-4" />
          <Typography.p fontSize="large" textAlign="center" className="px-4 py-2">
            Sign up to start getting more from your geospatial data.
          </Typography.p>
          <form onSubmit={handleSubmit(completeRegistration)}>
            <ButtonGroup width="100%" className="py-2">
              <Button
                type="button"
                togglable={true}
                selected={accountType === 'Individual'}
                onClick={() => {
                  setAccountType('Individual');
                }}
                disabled={organizationToJoin !== null}
              >
                Individual
              </Button>
              <Button
                type="button"
                togglable={true}
                selected={accountType === 'Business'}
                onClick={() => {
                  setAccountType('Business');
                }}
              >
                Business
              </Button>
            </ButtonGroup>
            {accountType === 'Business' && (
              <Controller
                name="organizationName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder="Organization name"
                    maxLength={50}
                    error={error?.message}
                    disabled={organizationToJoin !== null}
                  />
                )}
              />
            )}
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input {...field} type="text" placeholder="First name" maxLength={40} error={error?.message} />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input {...field} type="text" placeholder="Last name" maxLength={40} error={error?.message} />
              )}
            />
            {/*<Controller
                name="jobTitle"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input {...field} type="text" placeholder="Job title" maxLength={40} error={error?.message} />
                )}
                />*/}
            <Controller
              name="industry"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder="Industry"
                  maxLength={40}
                  error={error?.message}
                  disabled={organizationToJoin !== null}
                />
              )}
            />
            <Controller
              name="location"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder="Location"
                  maxLength={40}
                  error={error?.message}
                  disabled={organizationToJoin !== null}
                />
              )}
            />
            <div className="pt-3">
              <SubmitButton
                label="Create Account"
                disabled={!formState.isDirty}
                loading={creatingAccount}
                xMargin="mx-0"
              />
            </div>
          </form>
          <Typography.p textAlign="center" className="py-2">
            {'Have an account? '}
            <Link to="/login">Log in</Link>
          </Typography.p>
        </div>
      </div>
    </TransitionAnimation>
  );
};

export default CredentialsStep;
