import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { FileNode, FileNodeReviewStatus, FileNodeStatus, FileNodeType } from '../../../types/DataDelivery';
import { TreeView, TreeViewExpandChangeEvent } from '@progress/kendo-react-treeview';
import { useFilenodeStructure, useRootStructure } from '../../../hooks/data_delivery';
import FileNodeTreeItem from './FileNodeTreeItem';

interface TreeFileNode {
  id: string;
  name: string;
  path: string;
  fileType: FileNodeType;
  status: FileNodeStatus;
  reviewStatus?: FileNodeReviewStatus;
  isDir: boolean;
  parent?: FileNode;
  items: TreeFileNode[] | null;
  viewLayer: { id: string; uri: string };
  loading: boolean;
  placeholder: boolean;
  expanded: boolean;
  selected: boolean;
}

interface FileNodeExplorerProps {
  rootFileNode: FileNode;
  selectedFileNode: FileNode;
  onItemSelected: (fileNode: FileNode) => void;
  canSelectDirectories?: boolean;
}

const FileExplorer: React.FC<FileNodeExplorerProps> = (props: FileNodeExplorerProps) => {
  const [treeData, setTreeData] = useState<TreeFileNode[]>([]);
  const [currentFileNodeLoading, setCurrentFileNodeLoading] = useState<TreeFileNode>(null);

  useEffect(() => {
    if (props.rootFileNode) {
      const fileNodeToData = (fileNode: FileNode): TreeFileNode => {
        return {
          id: fileNode.id,
          name: fileNode.name,
          path: fileNode.path,
          fileType: fileNode.fileType,
          status: fileNode.status,
          isDir: fileNode.isDir,
          parent: fileNode.parent,
          viewLayer: fileNode.viewLayer,
          items: fileNode.children?.map(fileNodeToData),
          loading: false,
          expanded: false,
          selected: fileNode.id === props.selectedFileNode?.id,
          placeholder: false,
        };
      };
      const newData = props.rootFileNode.children.map(fileNodeToData);
      setTreeData(newData);
    }
  }, [props.rootFileNode]);

  useEffect(() => {
    if (props.rootFileNode && treeData.length > 0) {
      const fileNodeToData = (fileNode: TreeFileNode): TreeFileNode => {
        return {
          id: fileNode.id,
          name: fileNode.name,
          path: fileNode.path,
          fileType: fileNode.fileType,
          status: fileNode.status,
          isDir: fileNode.isDir,
          parent: fileNode.parent,
          viewLayer: fileNode.viewLayer,
          items: fileNode.items?.map(fileNodeToData),
          loading: false,
          expanded: fileNode.expanded,
          selected: fileNode.id === props.selectedFileNode?.id,
          placeholder: false,
        };
      };
      const newData = treeData.map(fileNodeToData);
      setTreeData(newData);
    }
  }, [props.rootFileNode, props.selectedFileNode]);

  const handleItemExpand = async (event: TreeViewExpandChangeEvent) => {
    const itemIndexes = event.itemHierarchicalIndex.split('_');
    const newTreeData = JSON.parse(JSON.stringify(treeData));
    let currentTreeItem = newTreeData[parseInt(itemIndexes[0])];
    for (let i = 1; i < itemIndexes.length; i++) {
      currentTreeItem = currentTreeItem.items[parseInt(itemIndexes[i])];
    }
    if (currentTreeItem.items.length === 1 && currentTreeItem.items[0].placeholder) {
      currentTreeItem.items[0].loading = true;
      setCurrentFileNodeLoading(currentTreeItem);
    } else {
      currentTreeItem.expanded = !currentTreeItem.expanded;
    }
    setTreeData(newTreeData);
  };

  return (
    <div className="container-fluid">
      <TreeView
        data={treeData}
        expandIcons={true}
        item={(props) => {
          return <FileNodeTreeItem {...props} />;
        }}
        textField="name"
        onExpandChange={handleItemExpand}
        selectField="selected"
        onItemClick={(e) => {
          if (props.canSelectDirectories !== false || e.item.fileType !== FileNodeType.DIR) {
            props.onItemSelected(e.item);
          } else {
            handleItemExpand(e);
          }
        }}
      />
    </div>
  );
};

export default FileExplorer;
