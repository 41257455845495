import React, { useReducer, useContext } from 'react';
import { RegisterWizardState, initialState } from './registerWizardState';
import { registerWizardReducer, RegisterWizardAction } from './registerWizardReducer';

interface RegisterWizardProviderValue extends RegisterWizardState {
  dispatch: (action: RegisterWizardAction) => void;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  value: RegisterWizardProviderValue;
}

const RegisterWizardContext = React.createContext({
  ...initialState,
  dispatch: () => {
    // not implemented
  },
} as RegisterWizardProviderValue);

export const RegisterWizardProvider: React.FC<Props> = ({ children, value }) => {
  return <RegisterWizardContext.Provider value={value}>{children}</RegisterWizardContext.Provider>;
};

/*
  Create Register Wizard state.
*/
export const useCreateRegisterWizardState = (): RegisterWizardProviderValue => {
  const [state, dispatch] = useReducer(registerWizardReducer, {
    ...initialState,
  });
  return {
    ...state,
    dispatch,
  };
};

/*
  Consume Register Wizard state.
*/
export const useConsumeRegisterWizardState = () => {
  return useContext(RegisterWizardContext);
};
