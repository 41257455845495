import { FileNode } from '../../types/DataDelivery';

export enum FileNodeUploadWizardStep {
  SELECT_FILES = 0,
  SELECT_DESTINATION = 1,
  TRACK_PROGRESS = 2,
  UNSUPPORTED = -1,
}

export interface FileNodeUploadWizardState {
  step: FileNodeUploadWizardStep;
  rootFileNode: FileNode;
  files: File[];
  parentFileNode: FileNode | null;
  done: boolean;
}

export const initialState: FileNodeUploadWizardState = {
  step: FileNodeUploadWizardStep.SELECT_FILES,
  rootFileNode: null,
  files: [],
  parentFileNode: null,
  done: false,
};
