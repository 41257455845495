import { QueryClient } from 'react-query';
import {
    SapFlowProcessNode,
    SapFlowProcessNodeStatus,
    SapFlowTransaction,
    WSMessage,
  } from '../../../types';

const handleMessage = (wsMessage: WSMessage, queryClient: QueryClient) => {
    const processNodeStatus: SapFlowProcessNodeStatus = wsMessage.payload;
    if (processNodeStatus !== null && processNodeStatus.transactionID) {
      /*let transactionsData: SapFlowTransaction[] = queryClient.getQueryData(['transactions']);
      if (transactionsData && transactionsData.length > 0) {
        transactionsData = JSON.parse(JSON.stringify(transactionsData));
        let i = 0;
        let transIndex = -1;
        for (i = 0; i < transactionsData.length; i++) {
          if (transactionsData[i].id === processNodeStatus.transactionID) {
            transIndex = i;
            break;
          }
        }
        if (transIndex >= 0) {
          const matchingTransaction: SapFlowTransaction = transactionsData[transIndex];
          let procIndex = -1;
          for (i = 0; i < matchingTransaction.processnodes.length; i++) {
            if (matchingTransaction.processnodes[i].id === processNodeStatus.processNodeID) {
              procIndex = i;
              break;
            }
          }
          if (procIndex >= 0) {
            const matchingProcessNode: SapFlowProcessNode = matchingTransaction.processnodes[procIndex];
            // Validate the status isn't already in the history collection
            if (
              matchingProcessNode.processNodeStatusHistory.filter((procStatus) => {
                procStatus.id === processNodeStatus.id;
              }).length === 0
            ) {
              matchingProcessNode.processNodeStatusHistory.push(processNodeStatus);
              matchingProcessNode.percentComplete = processNodeStatus.currentProcessPercentComplete;
              matchingProcessNode.processRuntime = processNodeStatus.currentProcessRuntime;
              matchingProcessNode.status = processNodeStatus.currentProcessStatus;
            } else {
              console.log('Status id found in ProcessNode history');
            }
            matchingTransaction.processnodes[procIndex] = matchingProcessNode;
            transactionsData[transIndex] = matchingTransaction;
            queryClient.setQueryData(['transactions'], transactionsData);
          } else {
            console.log('Matching ProcessNode not found');
          }
        } else {
          console.log('Matching Transaction not found');
        }
      } else {
        console.log('No trans data');
      }*/
      const transactionData: SapFlowTransaction|undefined = queryClient.getQueryData(['transaction', processNodeStatus.transactionID]);
      if(transactionData){
        let procIndex = -1;
        for (let i = 0; i < transactionData.processnodes.length; i++) {
          if (transactionData.processnodes[i].id === processNodeStatus.processNodeID) {
            procIndex = i;
            break;
          }
        }
        if (procIndex >= 0) {
          const matchingProcessNode: SapFlowProcessNode = transactionData.processnodes[procIndex];
          // Validate the status isn't already in the history collection
          if (
            matchingProcessNode.processNodeStatusHistory.filter((procStatus) => {
              procStatus.id === processNodeStatus.id;
            }).length === 0
          ) {
            matchingProcessNode.processNodeStatusHistory.push(processNodeStatus);
            matchingProcessNode.percentComplete = processNodeStatus.currentProcessPercentComplete;
            matchingProcessNode.processRuntime = processNodeStatus.currentProcessRuntime;
            matchingProcessNode.status = processNodeStatus.currentProcessStatus;
          } else {
            console.log('Status id found in ProcessNode history');
          }
          transactionData.processnodes[procIndex] = matchingProcessNode;
          queryClient.setQueryData(['transaction', transactionData.id], transactionData);
        } else {
          console.log('Matching ProcessNode not found');
        }
      }
      
    } else {
      console.log('Invalid Proc Status update');
    }
}

export default handleMessage;