import React, { useState, useRef, useEffect } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { Select, SubmitButton } from '../../../components/form';
import DataDeliveryNavigationOptions from '../../../types/DataDelivery/FileNodeNavigationOptions';
import { StyledDialog } from '../../../components/styled';
import { BaseMap } from '../../../types';
import { useBaseMaps } from '../../../hooks/viewerconfig';

interface NavigationOptionsDialogProps {
  show: boolean;
  handleClose: any;
  currentNavigationOptions: DataDeliveryNavigationOptions;
  onNavigationOptionsChange: (newNavigationOptions: DataDeliveryNavigationOptions) => void;
}

const NavigationOptionsDialog: React.FC<NavigationOptionsDialogProps> = (props: NavigationOptionsDialogProps) => {
  const baseMapsQuery = useBaseMaps();
  const [availableBaseMaps, setAvailableBaseMaps] = useState<BaseMap[]>([]);
  useEffect(() => {
    if (baseMapsQuery.isSuccess) {
      setAvailableBaseMaps(baseMapsQuery.data);
    }
  }, [baseMapsQuery.isSuccess, baseMapsQuery.data]);
  return (
    <StyledDialog
      show={props.show}
      title={<Typography.h3>Navigation Options</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
      style={{ display: 'flex', flexDirection: 'column', width: '40%', minWidth: '380px' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Checkbox
          label={'Show support filenodes'}
          value={props.currentNavigationOptions?.showSupport}
          onChange={(e) => {
            const newNavigationOptions = {
              ...props.currentNavigationOptions,
              ...{ showSupport: !props.currentNavigationOptions?.showSupport },
            };
            props.onNavigationOptionsChange(newNavigationOptions);
          }}
        />
        <Select
          name={'BaseMap'}
          value={props.currentNavigationOptions?.baseMap}
          error={null}
          data={availableBaseMaps}
          dataItemKey="id"
          textField="displayName"
          placeholder=""
          loading={false}
          onChange={(e) => {
            const newNavigationOptions = {
              ...props.currentNavigationOptions,
              ...{ baseMap: e.target.value },
            };
            props.onNavigationOptionsChange(newNavigationOptions);
          }}
          onBlur={() => {
            //
          }}
          autoFocus
        />
        <SubmitButton
          type="button"
          label="Ok"
          uppercase={false}
          full={false}
          disabled={false}
          onClick={() => {
            props.handleClose();
          }}
          loading={false}
          className="mt-2 ml-auto"
        />
      </div>
    </StyledDialog>
  );
};

export default NavigationOptionsDialog;
