import { useMutation } from 'react-query';
import { SapFlowViewConfig } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'viewer_config/wfs';

interface Params {
  baseUrl: string;
  layer: string;
  workspace: string;
  version: string;
  projection: string;
  boundingBox: number[];
}

const useAddWfsFeature = (viewerConfigId: string) => {
  const axios = useAxiosInstance();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post<SapFlowViewConfig>(`viewer_config/${viewerConfigId}/wfs`, params);
      return response.data;
    },
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(['sapFlowView', userId, transactionId], { refetchInactive: true });
      },
    }
  );
};

export default useAddWfsFeature;