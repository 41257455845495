import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../../hooks/authentication';
import { useTransactionItemNodeUpdate, useDeleteTransactionItemNode } from '../../hooks/sapflow';
import {
  useView,
  useViewerConfigUpdate,
  useFileNodeView,
  useViewerConfig,
  useViewLayerUpdate,
} from '../../hooks/viewerconfig';
import { useRemoveLayer } from '../../hooks/viewerconfig';
import GeosapViewer from './GeosapViewer';
import { AuthorityLevel, AuthorityType, LayerType, ViewConfigUpdateDTO, ViewLayer } from '../../types';

const GeosapViewerWrapper: React.FC = () => {
  const { transactionId, rootId, fileNodeId } = useParams();
  const [viewerType, setViewerType] = useState<'FILENODE' | 'TRANSACTION'>(transactionId ? 'TRANSACTION' : 'FILENODE');
  const { getUser, userHasAuthority } = useUser();
  const sapViewQuery = viewerType === 'TRANSACTION' ? useView(transactionId) : useFileNodeView(fileNodeId);
  const viewConfigQuery = useViewerConfig(sapViewQuery?.data?.id);
  const useTransactionItemNodeUpdateMutation = useTransactionItemNodeUpdate(transactionId);
  const useDeleteTransactionItemNodeMutation = useDeleteTransactionItemNode(transactionId);
  const viewLayerUpdateMutation = useViewLayerUpdate();
  const useRemoveLayerMutation = useRemoveLayer();
  const viewerConfigUpdateMutation = useViewerConfigUpdate();

  const handleViewLayerUpdated = (layer: ViewLayer, updatedLayer: ViewLayer) => {
    if (viewerType === 'TRANSACTION') {
      if (layer.displayName !== updatedLayer.displayName) {
        useTransactionItemNodeUpdateMutation.mutate({ itemnodeid: layer.id, displayName: updatedLayer.displayName });
      }
    } else if (viewerType === 'FILENODE') {
      let updateParams = { viewerId: sapViewQuery?.data?.id, layerId: layer.id };
      if (layer.displayName !== updatedLayer.displayName) {
        updateParams = { ...updateParams, ...{ displayName: updatedLayer.displayName } };
      }
      if (layer.active !== updatedLayer.active) {
        updateParams = { ...updateParams, ...{ active: updatedLayer.active } };
      }
      viewLayerUpdateMutation.mutateAsync(updateParams);
    }
  };

  const handleLayerDelete = (layer: ViewLayer) => {
    if (viewerType === 'TRANSACTION') {
      if (layer.layerType === LayerType.WMS || layer.layerType === LayerType.WFS) {
        useRemoveLayerMutation.mutate({ layerId: layer.id, viewerId: sapViewQuery.data.id });
      } else {
        useDeleteTransactionItemNodeMutation.mutate({ itemid: layer.id });
      }
    } else if (viewerType === 'FILENODE') {
      useRemoveLayerMutation.mutateAsync({ layerId: layer.id, viewerId: sapViewQuery.data.id });
    }
  };

  //const handleCameraMoved = (cameraPosition: {}) => {
  //  useSapFlowViewUpdateMutation.mutateAsync(cameraPosition, cameraTarget);
  //};

  return (
    <div
      style={{
        height: '100vh',
      }}
    >
      <GeosapViewer
        viewerType={viewerType}
        viewConfigQuery={viewConfigQuery}
        onLayerUpdated={handleViewLayerUpdated}
        onLayerDeleted={handleLayerDelete}
      ></GeosapViewer>
    </div>
  );
};

export default GeosapViewerWrapper;
