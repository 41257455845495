import { useQueryClient, useMutation } from 'react-query';
import { SapFlowViewConfig, ViewConfigUpdateDTO, ViewLayer } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'viewer_config';

const useSapFlowViewUpdate = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: ViewConfigUpdateDTO) => {
      const response = await axios.put<SapFlowViewConfig>(endpoint + "/" + params.viewerId, params);
      return response.data;
    },
    {
      onSuccess: (data: any, variables: ViewConfigUpdateDTO) => {
        //queryClient.invalidateQueries(['sapFlowView', variables.viewerId], { refetchInactive: true });
        //queryClient.invalidateQueries(['fileNodeView', variables.viewerId], { refetchInactive: true });
      },
    }
  );
};

export default useSapFlowViewUpdate;
