import React from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../context/app';

const StyledTextbox = styled.div`
  text-align: center;
  overflow-wrap: anywhere;
  padding: 8px;
  border: 1px solid #ccc; /* Light border */
  border-radius: 4px;
  transition: border-color 0.2s, background-color 0.2s; /* Smooth transition for hover effect */
  cursor: pointer;

  &:hover {
    border-color: #007bff; /* Highlighted border color on hover */
    background-color: #f8f8f8; /* Background color on hover */
  }
`;

interface Props {
  text: string;
}

const CopyableTextBox: React.FC<Props> = ({ text }) => {
  const { dispatch } = useAppContext();
  const handleOnCopy = () => {
    navigator.clipboard.writeText(text);
    dispatch({
      type: 'SHOW_NOTIFICATION',
      payload: { notification: { type: 'success', content: 'Copied!' } },
    });
  };

  return <StyledTextbox onClick={handleOnCopy}>{text}</StyledTextbox>;
};

export default CopyableTextBox;
