import { useMutation, useQueryClient } from 'react-query';
import { LOCAL_STORAGE_USER, LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_TOKEN_TYPE, LOCAL_STORAGE_CURRENT_ORG } from '../../common/constants';
import { SignoutRequest } from '../../types';
import { useAppContext } from '../../context/app';
import { useAxiosInstance } from '../common';

const endpoint = '/authentication/signout';

const useSignout = () => {
  const { dispatch } = useAppContext();
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();

  return useMutation((user: SignoutRequest) => axios.post(endpoint, user), {
    onSuccess: () => {
      localStorage.removeItem(LOCAL_STORAGE_USER);
      localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_TYPE);
      localStorage.removeItem(LOCAL_STORAGE_CURRENT_ORG);
      dispatch({ type: 'LOGOUT' });

      queryClient.clear();
    },
  });
};

export default useSignout;
