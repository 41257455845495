import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { Typography } from '@progress/kendo-react-common';
import { CentralizedContent } from '../components/layout';
import { Input, SubmitButton } from '../components/form';
import { useRequestPasswordReset } from '../hooks/authentication';

const BrandImage = styled.img`
  display: block;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
`;

interface ForgotPasswordFormValues {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const requestPasswordResetMutation = useRequestPasswordReset();
  const { handleSubmit, control } = useForm<ForgotPasswordFormValues>();

  const resendConfirmationEmail = (data: ForgotPasswordFormValues) => {
    requestPasswordResetMutation.mutate(data);
  }

  return (
    <CentralizedContent>
      <div className="col-xs-12">
        <BrandImage src="/assets/brand-black.png" alt="GeoSAP brand" className="p-3 pb-4" />
          <Typography.h2 className='text-center'>FORGOTTEN PASSWORD</Typography.h2>
          <Typography.p className='text-center'>{"Please enter your geosap account's email below. A email with a password reset link will be sent you."}</Typography.p>
          <form onSubmit={handleSubmit(resendConfirmationEmail)}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => <Input {...field} type="text" placeholder="Email" />}
            />
            <div className="pt-3">
              <SubmitButton label="Request Password Reset" loading={requestPasswordResetMutation.isLoading} />
            </div>
          </form>
      </div>
    </CentralizedContent>
  );
};

export default ForgotPassword;