import React, { Fragment, useEffect, useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Reveal } from '@progress/kendo-react-animation';
import { Loader, Skeleton } from '@progress/kendo-react-indicators';
import { ExpansionPanelActionEvent } from '@progress/kendo-react-layout';
import StyledExpansionPanel from '../../components/styled/StyledExpansionPanel';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { truncateText } from '../../common/stringHelper';
import 'jsoneditor-react/es/editor.min.css';
import Dialog from '../../components/Dialog';
import { Input, SubmitButton, Select } from '../../components/form';
import ProcessParamsForm from '../SapFlowCreation/ProcessParamsForm';
import {
  useSapConfig,
  useSapConfigParams,
  useSapConfigParamsUpdate,
  useSapConfigUpdate,
} from '../../hooks/data_delivery';
import {
  SapConfigParamDetails,
  SapConfigParamType,
  SapConfigProcessNode,
  SapProcessNodeParamDetails,
} from '../../types/DataDelivery';

interface Props {
  show: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  configId: string;
}

interface FormValues {
  pathsToProcess: string;
  processName: string;
  processDescription: string;
  projectTags: string[] | null;
  processNodeSelected: SapConfigProcessNode | null;
}

const getValidationSchema = () =>
  yup.object({
    pathsToProcess: undefined,
    processName: undefined, //yup.object().required(REQUIRED_FIELD),
    processDescription: undefined, //yup.object().required(REQUIRED_FIELD),
    processNodeSelected: undefined,
  });

let shown = false;

const ProvideDetailsStep: React.FC<Props> = (props: Props) => {
  const sapConfigParamsUpdateMutation = useSapConfigParamsUpdate();
  const sapConfigUpdateMutation = useSapConfigUpdate();
  const sapConfigQuery = useSapConfig(shown ? props.configId : null);
  const paramsQuery = useSapConfigParams(shown ? props.configId : null);
  const [validationSchema, setValidationSchema] = useState(getValidationSchema());
  const { handleSubmit, control, formState, reset, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      pathsToProcess: null,
      processDescription: '',
      processNodeSelected: null,
    },
    resolver: yupResolver(validationSchema),
  });
  const paramsForm = useForm<any>();
  const sapFlowConfigValue = watch('processNodeSelected');
  useEffect(() => {
    shown = true;
  }, [props.show]);
  useEffect(() => {
    if (sapConfigQuery.isSuccess && sapConfigQuery.data) {
      setValue('pathsToProcess', sapConfigQuery.data.pathingRegex);
      setValue('processName', sapConfigQuery.data.name);
      setValue('processDescription', sapConfigQuery.data.description);
    }
  }, [sapConfigQuery.data]);

  const handleExpansionPanelAction = (selected: SapConfigProcessNode | null, event: ExpansionPanelActionEvent) => {
    setValue('processNodeSelected', event.expanded ? null : selected, { shouldDirty: true });
  };

  const provideDetails = async ({ processName, processDescription, pathsToProcess }: FormValues) => {
    if (!sapConfigQuery.data) return;
    sapConfigUpdateMutation.mutate({
      configId: props.configId,
      name: processName,
      description: processDescription,
      pathingRegex: pathsToProcess,
    });
    if (paramsQuery.isSuccess && paramsQuery.data.length > 0) {
      // Proceed to update sapflow parameters
      const paramValues = paramsForm.getValues();
      const processnodeparams: any = {};
      sapConfigQuery.data.processnodeconfigs.forEach((processNode: SapConfigProcessNode) => {
        // filter flag params
        const processNodeParams = paramValues[processNode.order];
        const originalValues = paramsQuery.data.filter((param) => param.processNodeOrder === processNode.order)[0]
          .params;
        Object.keys(processNodeParams).filter((param: any) => {
          if (!processNodeParams[param].active) {
            delete processNodeParams[param];
          }
        });
        processnodeparams[processNode.id] = {};
        Object.keys(processNodeParams).forEach((processNodeParam) => {
          const originalParam = originalValues.filter(
            (originalParamDetails) => originalParamDetails['name'] === processNodeParam
          )[0];
          if (originalParam && originalParam.type === SapConfigParamType.ListInt) {
            processnodeparams[processNode.id][processNodeParam] = processNodeParams[processNodeParam].value.map(
              (listValue: any) => listValue.value
            );
          } else {
            processnodeparams[processNode.id][processNodeParam] = processNodeParams[processNodeParam].value;
          }
        });
      });
      const paramUpdateBody = {
        configId: sapConfigQuery.data.id,
        configParams: processnodeparams,
      };
      const updateResponse = await sapConfigParamsUpdateMutation.mutateAsync(paramUpdateBody);
    }
    return;
  };

  const parametersAvailable = true;
  const loading = sapConfigQuery.isLoading;
  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>SapConfig Configuration</Typography.h3>}
      onClose={props.handleClose}
      style={{ display: 'flex', flexDirection: 'column', width: '1200px', height: '800px', maxHeight: '80%' }}
    >
      {loading && <Loader></Loader>}
      {!loading && sapConfigQuery.data && (
        <div style={{ paddingTop: '4rem' }}>
          <Typography.h1 fontWeight="light" textAlign="center">
            {sapConfigQuery.data.name}
          </Typography.h1>
          <div>
            <form onSubmit={handleSubmit(provideDetails)}>
              <div className="w-75 mx-auto">
                <Typography.h4 fontWeight="light" textAlign="center">
                  Details
                </Typography.h4>

                <Controller
                  control={control}
                  name="pathsToProcess"
                  render={({ field, fieldState: { error } }) => (
                    <Input {...field} error={error?.message} type="text" placeholder="Pathing Regex" autoFocus />
                  )}
                />
                <Controller
                  control={control}
                  name="processName"
                  render={({ field, fieldState: { error } }) => (
                    <Input {...field} error={error?.message} type="text" placeholder="Process Name" autoFocus />
                  )}
                />
                <Controller
                  control={control}
                  name="processDescription"
                  render={({ field, fieldState: { error } }) => (
                    <Input {...field} error={error?.message} type="text" placeholder="Process Description" />
                  )}
                />
              </div>
              <Typography.h4 className="mt-4" fontWeight="light" textAlign="center">
                Parameters
              </Typography.h4>
              {parametersAvailable && (
                <div className="m-auto mt-4 w-75">
                  {sapConfigQuery.data === null && (
                    <div>
                      {new Array(5)
                        .fill(<Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />)
                        .map((skeleton, i) => (
                          <Fragment key={i}>{skeleton}</Fragment>
                        ))}
                    </div>
                  )}
                  {sapConfigQuery.data !== null &&
                    sapConfigQuery.data.processnodeconfigs.length > 0 &&
                    sapConfigQuery.data.processnodeconfigs
                      .sort((a, b) => a.order - b.order)
                      .map((processNode: SapConfigProcessNode, index: number) => {
                        let processNodeParams: SapProcessNodeParamDetails[] = [];
                        if (paramsQuery.isSuccess && paramsQuery.data.length > 0) {
                          const processNodeParamDetails: SapConfigParamDetails = paramsQuery.data.filter(
                            (param) => param.processNodeId === processNode.id
                          )[0];
                          if (processNodeParamDetails) {
                            processNodeParams = processNodeParamDetails.params;
                          }
                        }
                        return (
                          <StyledExpansionPanel
                            key={processNode.id}
                            title={
                              <>
                                <Typography.h3 className="flex-grow-1 text-capitalize">
                                  {truncateText(index + 1 + ' - ' + processNode.displayname, 46)}
                                </Typography.h3>
                              </>
                            }
                            expanded={sapFlowConfigValue?.id === processNode.id}
                            tabIndex={0}
                            onAction={handleExpansionPanelAction.bind(undefined, processNode)}
                            expandIcon="k-icon k-i-arrow-chevron-right animated-transform rotated-0"
                            collapseIcon="k-icon k-i-arrow-chevron-right animated-transform rotated--90"
                          >
                            <Reveal>
                              <ProcessParamsForm
                                processNodeIndex={index}
                                processNode={processNode}
                                loading={paramsQuery.isLoading}
                                processNodeParams={processNodeParams}
                                form={paramsForm}
                                show={sapFlowConfigValue?.id === processNode.id}
                              />
                            </Reveal>
                          </StyledExpansionPanel>
                        );
                      })}
                </div>
              )}
              <div className="py-3 w-75 d-flex justify-content-end mx-auto">
                <SubmitButton
                  label="Submit details"
                  uppercase={false}
                  full={false}
                  disabled={!formState.isDirty}
                  //disabled={!formState.isDirty /* && !formHasValue*/}
                  loading={sapConfigUpdateMutation.isLoading || sapConfigParamsUpdateMutation.isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default ProvideDetailsStep;
