import React, { useEffect, useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Loader } from '@progress/kendo-react-indicators';
import { GridColumn, GridDetailRowProps } from '@progress/kendo-react-grid';
import { StyledDialog, StyledGrid } from './styled';
import { useTable } from '../hooks/common';
import { Projection } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import { get as getProjection, getTransform } from 'ol/proj.js';
import { applyTransform } from 'ol/extent';
import { EPSGProjectionInfos } from '../types';

declare const proj4: any;

interface DialogProps {
  show: boolean;
  onClose: any;
  onSelect: (proj: Projection) => void;
}

const PropertiesPanel: React.FC<DialogProps> = (props: DialogProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [foundProjections, setFoundProjections] = useState<EPSGProjectionInfos[]>([]);
  const tableProps = useTable({
    pageSize: 10,
    data: foundProjections,
    sortDescriptor: { field: 'code', dir: 'desc' },
  });
  useEffect(() => {
    //
  }, []);

  const handleProjectionSelected = (projInfos: EPSGProjectionInfos) => {
    const newProjCode = 'EPSG:' + projInfos.code;
    proj4.defs(newProjCode, projInfos.proj4def);
    register(proj4);
    const newProj = getProjection(newProjCode);
    const fromLonLat = getTransform('EPSG:4326', newProj);

    let worldExtent = [projInfos.bbox[1], projInfos.bbox[2], projInfos.bbox[3], projInfos.bbox[0]];
    newProj.setWorldExtent(worldExtent);

    // approximate calculation of projection extent,
    // checking if the world extent crosses the dateline
    if (projInfos.bbox[1] > projInfos.bbox[3]) {
      worldExtent = [projInfos.bbox[1], projInfos.bbox[2], projInfos.bbox[3] + 360, projInfos.bbox[0]];
    }
    const extent = applyTransform(worldExtent, fromLonLat, undefined, 8);
    newProj.setExtent(extent);
    props.onSelect(newProj);
  };

  const searchProjection = () => {
    setSearchCompleted(false);
    fetch('https://epsg.io/?format=json&q=' + searchQuery)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        const results = json['results'];
        const newProjectionsFound: EPSGProjectionInfos[] = [];
        if (results && results.length > 0) {
          for (let i = 0, ii = results.length; i < ii; i++) {
            const result = results[i];
            if (result) {
              const code = result['code'];
              const name = result['name'];
              const proj4def = result['wkt'];
              const bbox = result['bbox'];
              if (code && code.length > 0 && proj4def && proj4def.length > 0 && bbox && bbox.length === 4) {
                newProjectionsFound.push({ code, name, proj4def, bbox });
              }
            }
          }
        }
        setFoundProjections(newProjectionsFound);
        setSearchCompleted(true);
        //setProjection(null, null, null, null);
      });
  };

  const renderLoaderOverlay = () => {
    return (
      <div
        style={{
          background: 'fcfcfcaa',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}
      >
        <Loader size="small" type={'converging-spinner'} themeColor={'primary'} />
      </div>
    );
  };

  const renderResults = () => {
    return (
      <StyledGrid
        {...tableProps}
        pageable={foundProjections.length > 10}
        sortable
        total={foundProjections.length}
        style={{ marginTop: '1rem' }}
        expandField="expanded"
        onRowClick={({ dataItem }) => {
          handleProjectionSelected(dataItem);
        }}
        rowRender={(trElement, props) => {
          let trProps: any = null;
          trProps = {
            style: { cursor: 'pointer' },
          };
          return React.cloneElement(
            trElement,
            {
              ...trProps,
            },
            trElement.props.children as any
          );
        }}
      >
        <GridColumn field="name" title="Name" width={160} />
        <GridColumn field="code" title="Code" width={160} />
        <GridColumn field="proj4def" title="Projection" />
      </StyledGrid>
    );
  };

  return (
    <StyledDialog
      title={`Projection Search`}
      onClose={props.onClose}
      show={props.show}
      style={{ width: '60%', minWidth: '380px', overflow: 'hidden' }}
    >
      {false && renderLoaderOverlay()}
      <div style={{ display: 'flex', flexDirection: 'column', color: 'rgb(136,136,136)', fontSize: '1.2rem' }}>
        <span className="input-group">
          <label className="input-group-text" htmlFor="epsg-query">
            Search projection:&nbsp;
          </label>
          <input
            type="text"
            id="epsg-query"
            placeholder="4326, 27700, 3031, US National Atlas, Swiss, France, ..."
            className="form-control"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            size={50}
          />
          <button className="btn btn-outline-secondary" id="epsg-search" onClick={searchProjection}>
            Search
          </button>
        </span>
        {foundProjections.length > 0 && renderResults()}
        {foundProjections.length === 0 && searchQuery !== '' && searchCompleted && (
          <Typography.h5>No result found.</Typography.h5>
        )}
      </div>
    </StyledDialog>
  );
};

export default PropertiesPanel;
