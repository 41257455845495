import { useQuery } from 'react-query';
import { FileNode, FileNodeType, FileNodeStatus } from '../../types/DataDelivery';
import { useAxiosInstance } from '../common';
import { Organization } from '../../types';

const endpoint = '/user_management/invitation/open';

const useOrganizationFromInvitation = (invitationCode: string) => {
  const axios = useAxiosInstance();
  return useQuery(['organizationFromInvitationCode', invitationCode], async () => {
    if(!invitationCode) return null;
    const result = await axios.get<{organization: Organization, userEmail: string}>(endpoint, {params: {invitationCode}});
    return result.data;
  });
};

export default useOrganizationFromInvitation;