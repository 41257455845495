import React, { useContext, useEffect } from "react";
import { MousePosition } from "ol/control";
import { createStringXY } from "ol/coordinate";
import MapContext from "../Map/MapContext";

const FullScreenControl: React.FC = () => {
	const { map } = useContext<any>(MapContext);

	useEffect(() => {
		if (!map) return;

		const mousePositionControl = new MousePosition({
			className: 'custom-mouse-position',
			target: document.getElementById('location'),
			coordinateFormat: createStringXY(5)
		});

		map.controls.push(mousePositionControl);

		return () => map.controls.remove(mousePositionControl);
	}, [map]);

	return null;
};

export default FullScreenControl;