import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useAppContext } from '../../context/app';
import CheckoutForm from './CheckoutForm';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const CheckoutSubscription: React.FC = () => {
  const { state: locationState } = useLocation();
  const { dispatch } = useAppContext();
  const navigate = useNavigate();

  const navigationState: any = locationState;

  useEffect(() => {
    if (!navigationState || !navigationState.clientSecret) {
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: { notification: { type: 'error', content: 'Subscription infos not provided' } },
        });
        navigate("/organization");
    }
  }, []);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: navigationState.clientSecret,
  };

  return (
    <div className="container">
        <div className="row align-items-center justify-content-center" style={{ paddingTop: '4rem' }}>
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm />
            </Elements>
        </div>
    </div>
  );
};

export default CheckoutSubscription;