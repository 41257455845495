import { Category, SapFlow, SapFlowConfig, SapFlowType, SapFlowProject } from '../../types';
import { SapFlowWizardState, initialState, SapFlowWizardStep } from './sapFlowWizardState';

export interface SelectProjectAction {
  type: 'SELECT_PROJECT';
  payload: {
    project: SapFlowProject;
    projectName?: string;
  };
}

export interface CreateSapFlowAction {
  type: 'CREATE_SAPFLOW';
  payload: {
    sapFlow?: SapFlow;
    project?: SapFlowProject;
    processName?: string;
    processDescription?: string;
  };
}

export interface SelectSapFlowAction {
  type: 'SELECT_SAPFLOW';
  payload: {
    sapFlowConfig: SapFlowConfig;
  };
}

export interface ProvideDetailsAction {
  type: 'PROVIDE_DETAILS';
  payload: any;
}

export interface UploadFilesAction {
  type: 'UPLOAD_FILES';
}

export interface NavigateAction {
  type: 'NAVIGATE';
  payload: {
    step: SapFlowWizardStep;
  };
}

export type SapFlowWizardAction =
  SelectSapFlowAction
  | ProvideDetailsAction
  | UploadFilesAction
  | NavigateAction
  | CreateSapFlowAction
  | SelectProjectAction;

export const sapFlowWizardReducer = (
  currentState: SapFlowWizardState,
  action: SapFlowWizardAction
): SapFlowWizardState => {
  switch (action.type) {
    case 'SELECT_SAPFLOW':
      return {
        ...currentState,
        sapFlowConfig: action.payload.sapFlowConfig,
        step: SapFlowWizardStep.PROVIDE_DETAILS,
      };
    case 'PROVIDE_DETAILS': {
      return {
        ...currentState,
        step: SapFlowWizardStep.UPLOAD,
      };
    }
    case 'UPLOAD_FILES': {
      return {
        ...initialState,
      };
    }
    case 'NAVIGATE': {
      return {
        ...currentState,
        step: action.payload.step,
      };
    }
    case 'SELECT_PROJECT': {
      return {
        ...currentState,
        project: action.payload.project,
        projectName: action.payload.projectName ? action.payload.projectName : null,
      };
    }
    case 'CREATE_SAPFLOW': {
      return {
        ...currentState,
        sapFlow: action.payload.sapFlow,
        project: action.payload.project,
        processName: action.payload.processName ? action.payload.processName : null,
        processDescription: action.payload.processDescription ? action.payload.processDescription : null,
      };
    }
  }
};
