import { useQueryClient, useMutation } from 'react-query';
import { useAxiosInstance } from '../../common';
import { SapConfig, SapFlowGroup } from '../../../types/DataDelivery';

const useGenerateSapFlowsForConfig = (orgId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: {clientProjectId: string, configId: string}) => {
      const response = await axios.post<SapFlowGroup>(`data_wrangling/${params.clientProjectId}/configs/${params.configId}/sapflows/generate`);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sapGroups'], { refetchInactive: true });
      },
    }
  );
};

export default useGenerateSapFlowsForConfig;
