import React, { useState, useEffect, useRef, useMemo } from 'react';
import { TreeView, TreeViewExpandChangeEvent } from '@progress/kendo-react-treeview';
import { areAllChildrenUnselected, toggleItemSelection, updateTreeItemValue } from '../../../common/treeDataOperations';
import { useConfigPaths, useGenerateSapGroupsFromPaths } from '../../../hooks/data_wrangling';
import { ConfigPathGroup, TreeConfigPath } from '../../../types';
import Dialog from '../../../components/Dialog';
import { Loader } from '@progress/kendo-react-indicators';
import { Typography } from '@progress/kendo-react-common';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Input, SubmitButton } from '../../../components/form';
import { useUser } from '../../../hooks/authentication';
import { OverlayedLoader } from '../../../components';

interface Props {
  show: boolean;
  projectId: string;
  configId: string;
  handleClose: () => void;
}
const ConfigPathBrowser: React.FC<Props> = (props: Props) => {
  const { getCurrentOrganization } = useUser();
  const configPathsQuery = useConfigPaths(props?.projectId, props?.configId);
  const [configGroups, setConfigGroups] = useState<TreeConfigPath[]>([]);
  const [expandedPaths, setExpandedPaths] = useState([]);
  const [somethingSelected, setSomethingSelected] = useState(false);
  const [groupName, setGroupName] = useState('');
  const generateSapGroupsMutation = useGenerateSapGroupsFromPaths(getCurrentOrganization().id);
  const configPathToTreeConfigPath = (
    configPath: ConfigPathGroup,
    expandedItems: string[],
    groupType: 'SapGroup' | 'SapFlow'
  ): TreeConfigPath => {
    let items = null;
    if (configPath.groups.length > 0) {
      items = configPath.groups.map((childGroup) => configPathToTreeConfigPath(childGroup, expandedItems, 'SapGroup'));
    } else if (configPath.paths.length > 0) {
      items = configPath.paths.map((path) => configPathToTreeConfigPath(path, expandedItems, 'SapFlow'));
    }
    return {
      groupName: configPath.groupName,
      groupPath: configPath.groupPath,
      groupType,
      items: items,
      expanded: expandedItems.includes(configPath.groupPath),
      selected: false,
    };
  };
  useEffect(() => {
    if (configPathsQuery.isSuccess && configPathsQuery.data) {
      setConfigGroups([configPathToTreeConfigPath(configPathsQuery.data, expandedPaths, 'SapGroup')]);
    }
  }, [configPathsQuery.data]);
  useEffect(() => {
    if (generateSapGroupsMutation.isSuccess) props.handleClose();
  }, [generateSapGroupsMutation.isSuccess]);

  const convergeSelectedGroups = (configPath: TreeConfigPath): ConfigPathGroup => {
    const addPaths = (ogConfigPath: ConfigPathGroup, currentTreePath: TreeConfigPath) => {
      ogConfigPath.paths = ogConfigPath.paths.concat(
        currentTreePath.items
          .filter((item) => item.groupType === 'SapFlow' && item.selected)
          .map((item) => {
            return {
              groupName: item.groupName,
              groupPath: item.groupPath,
              groups: null,
              paths: null,
            };
          })
      );
      currentTreePath.items
        .filter((item) => item.groupType === 'SapGroup' && (item.selected === true || item.selected === null))
        .forEach((item) => {
          addPaths(ogConfigPath, item);
        });
    };
    const newSapGroupPath: ConfigPathGroup = {
      groupName: groupName,
      groupPath: configPath.groupPath,
      groups: [],
      paths: [],
    };
    addPaths(newSapGroupPath, configGroups[0]);
    return newSapGroupPath;
  };
  useEffect(() => {
    if (configPathsQuery.isSuccess && configPathsQuery.data) {
      setConfigGroups([configPathToTreeConfigPath(configPathsQuery.data, expandedPaths, 'SapGroup')]);
    }
  }, [configPathsQuery.data]);

  const handleGenerate = () => {
    const selectedPaths = convergeSelectedGroups(configGroups[0]);
    generateSapGroupsMutation.mutateAsync({
      projectId: props?.projectId,
      configId: props?.configId,
      paths: selectedPaths,
    });
  };

  const handleItemExpand = async (event: TreeViewExpandChangeEvent) => {
    //const itemPath = event.itemHierarchicalIndex;
    const itemPath = event.item.groupPath;
    if (expandedPaths.includes(itemPath)) {
      setExpandedPaths(expandedPaths.filter((path) => path !== itemPath));
      updateTreeItemValue(event.itemHierarchicalIndex, 'expanded', false, configGroups);
    } else {
      setExpandedPaths([...expandedPaths, itemPath]);
      updateTreeItemValue(event.itemHierarchicalIndex, 'expanded', true, configGroups);
    }
    //wizardState.dispatch({ type: 'HandleItemExpand', payload: { itemPath, itemId } });
  };

  const handleItemSelected = async (item: TreeConfigPath, itemPath: string) => {
    const newSelectedTreeData: TreeConfigPath[] = JSON.parse(
      JSON.stringify(toggleItemSelection(configGroups, itemPath))
    );
    setConfigGroups(newSelectedTreeData);
    setSomethingSelected(
      newSelectedTreeData.filter((treeNode) => treeNode.selected === true || treeNode.selected === null).length > 0
    );
    //wizardState.dispatch({ type: 'HandleItemExpand', payload: { itemPath, itemId } });
  };

  const renderCheckbox = (item: TreeConfigPath, itemPath: string) => {
    return (
      <Checkbox
        value={item.selected}
        onChange={() => {
          handleItemSelected(item, itemPath);
        }}
      />
    );
  };

  // Define props for the wrapper component
  interface MemoizedTreePathGroupProps {
    item: any;
    itemHierarchicalIndex: string;
    parent: any; // Update the type of parent according to your requirements
  }

  const MemoizedTreePathGroup: React.FC<MemoizedTreePathGroupProps> = React.memo(
    ({ item, itemHierarchicalIndex, parent }) => {
      return (
        <div style={{ display: 'flex' }}>
          {renderCheckbox(item, itemHierarchicalIndex)}
          <div>{item.groupName}</div>
        </div>
      );
    },
    (prevProps, nextProps) => {
      return true;
    }
  );
  MemoizedTreePathGroup.displayName = 'MemoizedTreePathGroup';

  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>SapFlow Generation</Typography.h3>}
      onClose={props.handleClose}
      style={{ display: 'flex', flexDirection: 'column', width: '1200px', height: '800px', maxHeight: '80%' }}
    >
      <OverlayedLoader show={generateSapGroupsMutation.isLoading}></OverlayedLoader>
      {configPathsQuery.isLoading && <Loader></Loader>}
      <div className="p-5 d-flex flex-column h-100">
        <Input
          name=""
          type="text"
          placeholder="Group name"
          autoFocus
          value={groupName}
          onChange={(e) => {
            setGroupName(e.value);
          }}
        />
        {configPathsQuery.isLoading ? (
          <p>Loading paths...</p>
        ) : (
          <TreeView
            data={configGroups}
            expandIcons={true}
            selectField="somethingElse"
            item={(itemProps) => {
              const itemHierarchy = itemProps.itemHierarchicalIndex.split('_');
              let parent: TreeConfigPath = null;
              if (itemHierarchy.length > 1) {
                parent = configGroups[0];
                for (let i = 1; i < itemHierarchy.length - 1; i++) {
                  parent = parent.items[parseInt(itemHierarchy[i])];
                }
              }
              return (
                <MemoizedTreePathGroup
                  item={itemProps.item}
                  itemHierarchicalIndex={itemProps.itemHierarchicalIndex}
                  parent={parent}
                />
              );
            }}
            textField="name"
            onExpandChange={handleItemExpand}
          />
        )}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 'auto' }}>
          <SubmitButton full={false} label={'Generate'} onClick={handleGenerate} disabled={!somethingSelected} />
        </div>
      </div>
    </Dialog>
  );
};

export default ConfigPathBrowser;
