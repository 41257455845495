import React, { useState } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { SvgIcon } from '@progress/kendo-react-common';
import { downloadIcon } from '@progress/kendo-svg-icons';

interface Props {
  downloading: boolean;
  message?: string;
  disabled?: boolean;
  style?: any;
  iconStyle?: any;
  iconSize?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge';
  iconHoveredStyle?: any;
  onDownload: () => void;
}

const DownloadLink: React.FC<Props> = ({
  style,
  iconStyle,
  iconSize,
  iconHoveredStyle,
  downloading,
  message,
  disabled,
  onDownload,
}) => {
  const [hovered, setHovered] = useState(false);
  const handleDownload = (e: any) => {
    e.preventDefault();
    if (!disabled) {
      onDownload();
    }
  };

  let appliedIconStyle = null;
  if (iconHoveredStyle && hovered) {
    appliedIconStyle = iconHoveredStyle;
  } else if (iconStyle) {
    appliedIconStyle = iconStyle;
  }

  const containerStyle = { ...{ display: 'flex', justifyContent: 'center', alignItems: 'center' }, ...style };

  return (
    <div style={containerStyle}>
      {downloading ? (
        <Loader size="small" type={'converging-spinner'} themeColor={'primary'} />
      ) : (
        <Tooltip anchorElement="target" position="top" style={{ display: 'flex' }}>
          <a
            style={{ display: 'flex' }}
            title={message}
            href=""
            onClick={handleDownload}
            onMouseEnter={() => {
              setHovered(true);
            }}
            onMouseLeave={() => {
              setHovered(false);
            }}
            className={disabled ? 'grayed' : ''}
          >
            <SvgIcon
              icon={downloadIcon}
              style={{ ...{ fontSize: '1.2rem' }, ...appliedIconStyle }}
              size={iconSize ? iconSize : 'large'}
            />
          </a>
        </Tooltip>
      )}
    </div>
  );
};

export default DownloadLink;
