import { useContext, useEffect, useImperativeHandle, forwardRef, useState } from 'react';
import MapContext from '../Map/MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import Extent from 'ol/extent';
import { transformExtent } from 'ol/proj.js';
import { ViewLayer } from '../../../../types';

interface Props {
  show: boolean;
  layer: ViewLayer;
  style: any;
  source: any;
  zIndex: number;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
} | null;
const VectorLayer = forwardRef<Ref, Props>(({ show, layer, source, style, zIndex = 0 }, ref) => {
  const { map } = useContext<any>(MapContext);
  const [vectorLayer, setVectorLayer] = useState<any>();
  const [sourceLoaded, setSourceLoaded] = useState(false);
  const [zoomWhenReady, setZoomWhenReady] = useState(false);

  useEffect(() => {
    if (!map) return;

    const newVectorLayer = new OLVectorLayer({
      source,
      style,
      visible: show,
    });

    map.addLayer(newVectorLayer);
    newVectorLayer.setZIndex(zIndex);
    setVectorLayer(newVectorLayer);

    source.on('change:loadend', () => {
      setSourceLoaded(true);
    });

    return () => {
      if (map) {
        map.removeLayer(newVectorLayer);
      }
    };
  }, [map]);

  useEffect(() => {
    if (!vectorLayer) return;
    vectorLayer.setVisible(show);
  }, [show]);

  useEffect(() => {
    if (zoomWhenReady && map.getView().getProjection()) {
      const projection = layer.paramsMap['projection'];
      const extents = layer.paramsMap['extents'];
      if (projection && extents) {
        let olExtents: Extent.Extent = [extents[0], extents[1], extents[2], extents[3]];
        if (projection !== map.getView().getProjection().getCode()) {
          olExtents = transformExtent(olExtents, projection, map.getView().getProjection().getCode());
        }
        map.getView().fit(olExtents);
      }
      setZoomWhenReady(false);
    }
  }, [sourceLoaded, zoomWhenReady]);

  const zoomToLayer = (layer: any) => {
    setZoomWhenReady(true);
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
  }));

  return null;
});
VectorLayer.displayName = 'VectorLayer';

export default VectorLayer;
