import React, { useEffect, useState } from 'react';

const UPDATE_BADGE_TIMEOUT = 1000;

interface Props {
  isFetching?: boolean;
}

const FetchIndicator: React.FC<Props> = ({ isFetching }) => {
  const [showFetchIndicator, setShowFetchIndicator] = useState(false);

  useEffect(() => {
    let timeoutId: number;
    if (isFetching) {
      setShowFetchIndicator(true);
    } else {
      timeoutId = window.setTimeout(() => setShowFetchIndicator(false), UPDATE_BADGE_TIMEOUT);
    }
    return () => clearTimeout(timeoutId);
  }, [isFetching]);

  return (
    <span className={`badge rounded-pill ${showFetchIndicator ? 'bg-primary' : 'bg-secondary'}`}>
      {showFetchIndicator ? 'Updating...' : 'Updated'}
    </span>
  );
};

export default FetchIndicator;
