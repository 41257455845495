import { useQuery } from 'react-query';
import { Organization } from '../../types/';
import { useAxiosInstance } from '../common';

const endpoint = '/user_management/organization/all';

const useOrganizations = () => {
  const axios = useAxiosInstance();
  return useQuery(['organizations'], async () => {
    const result = await axios.get<Organization[]>(endpoint);
    return result.data;
  });
};

export default useOrganizations;