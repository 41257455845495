import { useContext, useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import MapContext from '../../Map/MapContext';
import olLayer from 'ol/layer';
import OLTileLayer from 'ol/layer/WebGLTile';
import GeoTIFF from 'ol/source/GeoTIFF.js';
import View, { ViewOptions } from 'ol/View';
import Extent from 'ol/extent';
import { transformExtent } from 'ol/proj.js';
import {
  ViewLayer,
  ViewLayerState,
  RasterVisualization,
  VisualizationType,
  RAMP_COLORS,
  RampColor,
  RampColorValue,
  Layer2DClickResult,
} from '../../../../../types';
import { BandInfo } from '../../../../../types';
import { Feature, MapBrowserEvent } from 'ol';
import { deepDiff } from '../../../../../common/objComparator';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
GeoTIFF.prototype.getMetadata = function () {
  return this.metadata_;
};

const proj4 = window.proj4 || require('proj4');

interface Props {
  layer: ViewLayer;
  show: boolean;
  zIndex: number;
  style?: any;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
  handleMapClick: (evt: MapBrowserEvent<any>) => Promise<Layer2DClickResult>;
} | null;
const GeotiffLayer = forwardRef<Ref, Props>((props, ref) => {
  const { map } = useContext<any>(MapContext);
  const [tileLayer, setTileLayer] = useState<OLTileLayer>(null);
  const [source, setSource] = useState(null);
  const layerPropRef = useRef(null);
  const [opacity, setOpacity] = useState(1);
  const previousParamsMapRef = useRef<any>(null);

  layerPropRef.current = props.layer;

  let loadingCounter = 0;

  useEffect(() => {
    if (!map || tileLayer) return;

    const bands: BandInfo[] = props.layer.paramsMap.bands;
    let nodata = null;
    if (bands && bands.length > 0) {
      // Find nodata value
      nodata = bands[0].nodata;
      console.log('Nodata: ' + nodata);
      console.log('Nodata type: ' + typeof nodata);
    }

    const sourceElement: any = {
      url: props.layer.uri,
    };
    if (!props.layer.paramsMap['defaultNodata']) {
      sourceElement['nodata'] = nodata;
    }

    const tileSource = new GeoTIFF({
      sources: [sourceElement],
      normalize: false,
    });
    setSource(tileSource);
    console.log(tileSource.getProperties());

    tileSource.on('tileloadstart', (e) => {
      if (loadingCounter === 0) {
        const layer = layerPropRef.current;
        layer.viewState = ViewLayerState.LOADING;
        props.onLayerUpdated(layer);
      }
      loadingCounter = loadingCounter + 1;
    });
    tileSource.on(['tileloadend', 'tileloaderror'], (e) => {
      loadingCounter = loadingCounter - 1;
      if (loadingCounter === 0) {
        const layer = layerPropRef.current;
        layer.viewState = ViewLayerState.READY;
        props.onLayerUpdated(layer);
      }
    });

    const newTileLayer = new OLTileLayer({
      source: tileSource,
      zIndex: props.zIndex,
    });

    newTileLayer.setVisible(props.show);
    newTileLayer.setProperties({ title: props.layer.displayName, viewLayerId: props.layer.id });

    map.addLayer(newTileLayer);
    newTileLayer.setZIndex(props.zIndex);
    setTileLayer(newTileLayer);

    return () => {
      if (map) {
        map.removeLayer(newTileLayer);
      }
    };
  }, [map]);

  useEffect(() => {
    if (tileLayer) {
      tileLayer.setStyle(props.style);
    }
  }, [props.style]);

  useEffect(() => {
    if (!map || !tileLayer) return;
    tileLayer.setVisible(props.show);
  }, [props.show]);

  useEffect(() => {
    // Construct style from layer information
    if (tileLayer) {
      let currentParamsMap = props.layer.paramsMap;

      if (previousParamsMapRef.current) {
        currentParamsMap = deepDiff(previousParamsMapRef.current, currentParamsMap);
        console.log('Diff: ' + JSON.stringify(currentParamsMap));
      }
      previousParamsMapRef.current = props.layer.paramsMap;

      if (
        currentParamsMap &&
        currentParamsMap.visualization &&
        currentParamsMap.visualization.opacity !== null &&
        currentParamsMap.visualization.opacity !== undefined
      ) {
        tileLayer.setOpacity(currentParamsMap.visualization.opacity);
        setOpacity(currentParamsMap.visualization.opacity);
        //if only opacity changed, return here
        if (Object.keys(currentParamsMap.visualization).length === 1) return;
      }

      const visualization: RasterVisualization = props.layer.paramsMap['visualization'];
      const colorRamp = visualization?.singleband?.rampColor ? visualization?.singleband?.rampColor : RAMP_COLORS[0]; //props.layer.paramsMap.colorRamp ? props.layer.paramsMap.colorRamp : RAMP_COLORS[0];
      const bands = props.layer.paramsMap.bands;
      if (bands) {
        // calculate NDVI, bands come from the sources below
        //['/', ['-', nir, red], ['+', nir, red]],
        let newStyle: any = null;
        let colorExpression: any = null;
        let interpolationExpression = false;
        if ((!visualization?.type && bands.length > 3) || visualization?.type === VisualizationType.RGB) {
          const rgbVis = visualization?.rgb
            ? visualization.rgb
            : {
                redBandIndex: 0,
                blueBandIndex: 1,
                greenBandIndex: 2,
                gamma: 1,
                brightness: 0.5,
                contrast: 1,
                redBandCurrentMin: null,
                redBandCurrentMax: null,
                greenBandCurrentMin: null,
                greenBandCurrentMax: null,
                blueBandCurrentMin: null,
                blueBandCurrentMax: null,
              };
          const gamma = rgbVis?.gamma || 1;
          const brightness = rgbVis?.brightness || 0;
          const contrast = rgbVis?.contrast || 1;
          const redBandIndex = rgbVis?.redBandIndex ? rgbVis.redBandIndex : 1;
          const greenBandIndex = rgbVis?.greenBandIndex ? rgbVis.greenBandIndex : 1;
          const blueBandIndex = rgbVis?.blueBandIndex ? rgbVis.blueBandIndex : 1;
          let redBand: BandInfo = null;
          let greenBand: BandInfo = null;
          let blueBand: BandInfo = null;
          try {
            redBand = bands.filter((bandInfo: BandInfo) => bandInfo.index === redBandIndex)[0];
            greenBand = bands.filter((bandInfo: BandInfo) => bandInfo.index === greenBandIndex)[0];
            blueBand = bands.filter((bandInfo: BandInfo) => bandInfo.index === blueBandIndex)[0];
          } catch (e) {
            console.error('Unable to find matching bands for RGB visualization.');
            return;
          }
          const redBandMin =
            rgbVis.redBandCurrentMin !== null && rgbVis.redBandCurrentMin !== undefined
              ? rgbVis.redBandCurrentMin
              : redBand.min;
          const redBandMax =
            rgbVis.redBandCurrentMax !== null && rgbVis.redBandCurrentMax !== undefined
              ? rgbVis.redBandCurrentMax
              : redBand.max;
          const greenBandMin =
            rgbVis.greenBandCurrentMin !== null && rgbVis.greenBandCurrentMin !== undefined
              ? rgbVis.greenBandCurrentMin
              : greenBand.min;
          const greenBandMax =
            rgbVis.greenBandCurrentMax !== null && rgbVis.greenBandCurrentMax !== undefined
              ? rgbVis.greenBandCurrentMax
              : greenBand.max;
          const blueBandMin =
            rgbVis.blueBandCurrentMin !== null && rgbVis.blueBandCurrentMin !== undefined
              ? rgbVis.blueBandCurrentMin
              : blueBand.min;
          const blueBandMax =
            rgbVis.blueBandCurrentMax !== null && rgbVis.blueBandCurrentMax !== undefined
              ? rgbVis.blueBandCurrentMax
              : blueBand.max;

          const getBandRgbExpression = (band: BandInfo, min: number, max: number) => {
            //return band.bits === 8
            //  ? ['/', ['^', ['band', band.index], ['/', 1, gamma]], 255]
            //  : [
            //      '+',
            //      ['^', ['/', ['/', ['-', ['band', band.index], min], max - min], ['/', max, gamma]], contrast],
            //      brightness,
            //    ];
            return ['/', ['-', ['clamp', ['band', band.index], min, max], min], ['-', max, min]];
          };

          colorExpression = [
            'array',
            getBandRgbExpression(redBand, redBandMin, redBandMax),
            getBandRgbExpression(greenBand, greenBandMin, greenBandMax),
            getBandRgbExpression(blueBand, blueBandMin, blueBandMax),
            1,
          ];
          colorExpression = ['case', ['==', ['band', bands.length + 1], 0], '#00000000', colorExpression];
        } else if (!visualization?.type) {
          const band = bands[0];
          const bandRange = band.currentMax - band.currentMin;
          const grayValue = ['/', ['-', ['band', band.index], band.currentMin], bandRange];
          colorExpression = ['interpolate', ['linear'], grayValue];

          colorRamp.values.forEach((rampValue: RampColorValue) => {
            colorExpression.push(rampValue.value);
            colorExpression.push(rampValue.color);
          });
          colorExpression = ['case', ['==', ['band', bands.length + 1], 0], '#00000000', colorExpression];
          interpolationExpression = true;
        } else if (visualization?.type === VisualizationType.HILLSHADE) {
          const hillshadeVis = visualization.hillshade;
          // From selected band and ramp color, build OL Styling
          const bandArr = bands.filter((bandInfo: BandInfo) => bandInfo.name === hillshadeVis.bandName);
          if (!bandArr && bandArr.length <= 0) {
            console.log('Unabled to find matching Band -_-');
          } else {
            const band: BandInfo = { name: 'Band1', index: 1 }; //bandArr[0];
            // Generates a shaded relief image given elevation data.  Uses a 3x3
            // neighborhood for determining slope and aspect.
            const elevation = (xOffset: any, yOffset: any) => {
              const red = ['band', 1, xOffset, yOffset];
              const green = ['band', 2, xOffset, yOffset];
              const blue = ['band', 3, xOffset, yOffset];

              // band math operates on normalized values from 0-1
              // so we scale by 255
              return ['+', ['*', 255 * 256, red], ['*', 255, green], ['*', 255 / 256, blue], -32768];
            };
            const varVert = hillshadeVis.verticalExageration;
            const dp = ['*', 2, ['resolution']];
            const z0x = ['*', varVert, ['band', band.index, -1, 0]];
            const z1x = ['*', varVert, ['band', band.index, 1, 0]];
            const dzdx = ['/', ['-', z1x, z0x], dp];
            const z0y = ['*', varVert, ['band', band.index, 0, -1]];
            const z1y = ['*', varVert, ['band', band.index, 0, 1]];
            const dzdy = ['/', ['-', z1y, z0y], dp];
            const slope = ['atan', ['sqrt', ['+', ['^', dzdx, 2], ['^', dzdy, 2]]]];
            const aspect = ['clamp', ['atan', ['-', 0, dzdx], dzdy], -Math.PI, Math.PI];
            const sunEl = ['*', Math.PI / 180, hillshadeVis.sunElevation];
            const sunAz = ['*', Math.PI / 180, hillshadeVis.sunAzimuth];

            const cosIncidence = [
              '+',
              ['*', ['sin', sunEl], ['cos', slope]],
              ['*', ['cos', sunEl], ['sin', slope], ['cos', ['-', sunAz, aspect]]],
            ];
            colorExpression = ['*', 255, cosIncidence];
            colorExpression = ['color', colorExpression];
            colorExpression = [
              'case',
              ['==', ['band', props.layer.paramsMap.bands.length + 1], 0],
              '#00000000',
              colorExpression,
            ];
            interpolationExpression = true;
          }
        } else if (visualization?.type === VisualizationType.SINGLEBAND) {
          const singlebandVis = visualization.singleband;
          // From selected band and ramp color, build OL Styling
          const bandArr = bands.filter((bandInfo: BandInfo) => bandInfo.name === singlebandVis.bandName);
          if (bandArr.length <= 0) {
            console.log('Unabled to find matching Band -_-');
          } else {
            const band: BandInfo = bandArr[0];
            const bandRange = singlebandVis.currentMax - singlebandVis.currentMin;
            //const grayValue = (band.bits === 8 ? ['/', ['band', (band.index + 1)], 255] : ['band', (band.index + 1)]);
            const grayValue = ['/', ['-', ['band', band.index], singlebandVis.currentMin], bandRange];
            colorExpression = ['interpolate', ['linear'], grayValue];
            colorRamp.values.forEach((rampValue: RampColorValue) => {
              colorExpression.push(rampValue.value);
              colorExpression.push(rampValue.color);
            });
            colorExpression = ['case', ['==', ['band', bands.length + 1], 0], '#00000000', colorExpression];
            interpolationExpression = true;
          }
        }
        {
          newStyle = {
            color: colorExpression,
          };
        }
        tileLayer.updateStyleVariables({ vert: 2, sunEl: 45, sunAz: 45 });
        tileLayer.setStyle(newStyle);
      }
    }
  }, [props.layer, tileLayer]);

  const variables = {};

  // The method used to extract elevations from the DEM.
  // In this case the format used is Terrarium
  // red * 256 + green + blue / 256 - 32768
  //
  // Other frequently used methods include the Mapbox format
  // (red * 256 * 256 + green * 256 + blue) * 0.1 - 10000
  //
  function elevation(xOffset: any, yOffset: any) {
    const red = ['band', 1, xOffset, yOffset];
    const green = ['band', 2, xOffset, yOffset];
    const blue = ['band', 3, xOffset, yOffset];

    // band math operates on normalized values from 0-1
    // so we scale by 255
    return ['+', ['*', 255 * 256, red], ['*', 255, green], ['*', 255 / 256, blue], -32768];
  }

  // Generates a shaded relief image given elevation data.  Uses a 3x3
  // neighborhood for determining slope and aspect.
  const dp = ['*', 2, ['resolution']];
  const z0x = ['*', ['var', 'vert'], elevation(-1, 0)];
  const z1x = ['*', ['var', 'vert'], elevation(1, 0)];
  const dzdx = ['/', ['-', z1x, z0x], dp];
  const z0y = ['*', ['var', 'vert'], elevation(0, -1)];
  const z1y = ['*', ['var', 'vert'], elevation(0, 1)];
  const dzdy = ['/', ['-', z1y, z0y], dp];
  const slope = ['atan', ['sqrt', ['+', ['^', dzdx, 2], ['^', dzdy, 2]]]];
  const aspect = ['clamp', ['atan', ['-', 0, dzdx], dzdy], -Math.PI, Math.PI];
  const sunEl = ['*', Math.PI / 180, ['var', 'sunEl']];
  const sunAz = ['*', Math.PI / 180, ['var', 'sunAz']];

  const cosIncidence = [
    '+',
    ['*', ['sin', sunEl], ['cos', slope]],
    ['*', ['cos', sunEl], ['sin', slope], ['cos', ['-', sunAz, aspect]]],
  ];
  const scaled = ['*', 255, cosIncidence];

  const zoomToLayer = (layer: any) => {
    const projection = props.layer.paramsMap['projection'];
    const extents = props.layer.paramsMap['extents'];
    if (projection && extents) {
      let olExtents: Extent.Extent = [extents[0], extents[1], extents[2], extents[3]];
      if (projection !== map.getView().getProjection().getCode()) {
        olExtents = transformExtent(olExtents, projection, map.getView().getProjection().getCode());
      }
      map.getView().fit(olExtents);
    }
  };

  const handleMapClick = async (evt: MapBrowserEvent<any>): Promise<Layer2DClickResult> => {
    const data: any = tileLayer.getData(evt.pixel);
    if (data && data.length > 0) {
      // Consider No data value
      if (data[data.length - 1] !== 0) {
        return { layer: props.layer };
      }
    }
    return null;
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
    handleMapClick,
  }));

  return null;
});

GeotiffLayer.displayName = 'GeotiffLayer';

export default GeotiffLayer;
