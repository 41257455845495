
import { ExpansionPanel } from '@progress/kendo-react-layout';
import styled from 'styled-components';

const StyledExpansionPanel = styled(ExpansionPanel)`
  background: var(--geosap-card-bg-color);
  border: 1px solid var(--geosap-card-bg-color);
  border-radius: 0.6rem;
  margin: 0.75rem 0;

  .k-expander {
    &-title .k-h3 {
      color: var(--bs-body-color);
    }

    &-content {
      padding: 0;
    }

    &-indicator {
      background: var(--geosap-light);
      border-radius: 50%;

      & .k-icon {
        font-size: 40px;
        color: var(--geosap-primary-color);
      }
    }
  }

  &.k-expanded {
    & .k-expander-indicator {
      background: none;
      border-radius: 0%;
    }
  }

  &.k-state-focus {
    box-shadow: none;
  }
`;

export default StyledExpansionPanel;