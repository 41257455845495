import { useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../common';

interface Params {
    email: string,
    organizationId: string,
    resend: boolean
}

const useInviteOrganizationMember = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();

  return useMutation(async (params: Params) => { 
    return axios.post("/user_management/organization/" + params.organizationId + "/invite", params);
  },
  {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['organizationInvitations', variables.organizationId], { refetchInactive: true });
    },
  });
};

export default useInviteOrganizationMember;
