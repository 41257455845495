import React from 'react';
import { DashboardTransaction, SapFlowTransactionItemStatus } from '../../types';
import { ProgressBar } from '../../components/feedback';

interface StatusCellProps {
  dataItem: DashboardTransaction;
}
  
const StatusCell: React.FC<StatusCellProps> = ({ dataItem }) => {
  if (dataItem.status === SapFlowTransactionItemStatus.TRANSACTION_CREATED) {
    return (
      <td>
        <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
          <ProgressBar progress={100} text="Upload Required" disableAnimation={true} textColor="var(--geosap-primary-color)" progressStyle={{backgroundColor: "var(--geosap-baby-blue)"}}/>
        </div>
      </td>
    );
  } else if (dataItem.status === SapFlowTransactionItemStatus.TRANSACTION_FINISHED) {
    return (
      <td>
        <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
          <ProgressBar progress={100} />
        </div>
      </td>
    );
  } else if (dataItem.errored) {
    return (
      <td>
        <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
          <ProgressBar progress={dataItem.progress} text="Processing Error" disableAnimation={true} textColor="var(--geosap-dark-error)" progressStyle={{backgroundColor: "var(--geosap-light-error)"}}/>
        </div>
      </td>
    );
  }
  return (
    <td>
      <div className="d-flex gap-3" style={{ paddingRight: '1rem' }}>
        <ProgressBar progress={dataItem.progress} progressStyle={{backgroundColor: "var(--geosap-selected-blue)"}}/>
      </div>
    </td>
  );
};

export default StatusCell;