import React, { useContext, useEffect, useImperativeHandle, forwardRef, useState, useRef } from 'react';
import MapContext from '../Map/MapContext';
import TileLayer from './TileLayer';
import { TileWMS, XYZ } from 'ol/source';
import MapContextData from '../Map/MapContextData';
import { Feature, MapBrowserEvent } from 'ol';
import { useConsumeViewerState } from '../../../../context/viewer';
import { osm } from '../Source';
import BingMaps from 'ol/source/BingMaps.js';
import Google from 'ol/source/Google.js';
import { Layer2DClickResult } from '../../../../types';

interface Props {
  show: boolean;
  zIndex: number;
}

type Ref = {
  getOlLayer: (layer: any) => any;
  handleMapClick: (evt: MapBrowserEvent<any>) => Promise<Layer2DClickResult>;
} | null;
const BaseMapLayer = forwardRef<Ref, Props>(({ show, zIndex = 0 }, ref) => {
  const { dispatch, selectedFeature, olMapInitialized, baseMap } = useConsumeViewerState();
  const { map } = useContext<MapContextData>(MapContext);
  const [zoomWhenReady, setZoomWhenReady] = useState(false);
  const previewOlLayerRef = useRef<any>(null);
  const [wmsSource, setWmsSource] = useState<any>(null);

  useEffect(() => {
    if (!map || !olMapInitialized) return;

    if (baseMap?.type === 'OpenStreetMap') {
      setWmsSource(osm());
    } else if (baseMap?.type === 'Google') {
      setWmsSource(
        new Google({
          key: process.env.REACT_APP_GOOGLE_API_KEY,
          scale: 'scaleFactor2x',
          highDpi: true,
        })
      );
    } else if (baseMap?.type === 'Bing') {
      setWmsSource(new BingMaps({ key: process.env.REACT_APP_BING_MAPS_KEY, imagerySet: 'Aerial' }));
    } else if (baseMap?.type === 'Other') {
      setWmsSource(new XYZ({ url: baseMap.url, attributions: baseMap.attribution }));
    }
  }, [map, olMapInitialized, baseMap]);

  const handleMapClick = async (evt: MapBrowserEvent<any>): Promise<Layer2DClickResult> => {
    return null;
  };

  const getOlLayer = (layer: any) => {
    return previewOlLayerRef;
  };

  useImperativeHandle(ref, () => ({
    getOlLayer,
    handleMapClick,
  }));
  if (!wmsSource) return null;
  return (
    <TileLayer
      ref={(el: any) => {
        previewOlLayerRef.current = el;
      }}
      show={show}
      zIndex={zIndex}
      source={wmsSource}
    ></TileLayer>
  );
});
BaseMapLayer.displayName = 'BaseMapLayer';

export default BaseMapLayer;
