import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '@progress/kendo-react-common';
import { CentralizedContent } from '../components/layout';
import { SubmitButton } from '../components/form';
import { useResendConfirmationEmail } from '../hooks/authentication';
import { useAppContext } from '../context/app';

const BrandImage = styled.img`
  display: block;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
`;

const ConfirmationPending: React.FC = () => {
  const { dispatch } = useAppContext();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const resendConfirmationEmailMutation = useResendConfirmationEmail();

  const navigationState: any = locationState;

  useEffect(() => {
    if (!navigationState || !navigationState.email || !navigationState.password) {
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: { notification: { type: 'error', content: 'Account not found' } },
        });
        navigate("/login");
    }
  }, []);

  const resendConfirmationEmail = () => {
    resendConfirmationEmailMutation.mutate({email: navigationState.email, password: navigationState.password})
  }

  return (
    <CentralizedContent>
      <div className="col-xs-12">
        <BrandImage src="/assets/brand-black.png" alt="GeoSAP brand" className="p-3 pb-4" />
          <Typography.h2 className='text-center'>CONFIRM YOUR EMAIL</Typography.h2>
          <Typography.p className='text-center'>Please check your inbox for a confirmation email, then click the link inside to confirm your email address.</Typography.p>
          <div className="row">
            <div className="col-md-6 p-3">
              <SubmitButton label="CONTINUE TO LOGIN" onClick={()=>{navigate("login")}} />
            </div>
            <div className="col-md-6 p-3">
              <SubmitButton label="Re-send Confirmation Email" loading={resendConfirmationEmailMutation.isLoading}  onClick={()=>{resendConfirmationEmail()}} themeColor="info"/>
            </div>
          </div>
      </div>
    </CentralizedContent>
  );
};

export default ConfirmationPending;