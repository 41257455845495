import { AddLayerConfig } from '../../types';

export enum AddLayerWizardStep {
  SELECT_TYPE = 0,
  SUBMIT_URL = 1,
  SELECT_WMS_LAYER = 2,
  SELECT_WFS_LAYER = 3,
  SELECT_GEOTIF = 4,
  SELECT_SHAPEFILE = 5,
  SELECT_LAS = 6,
  SELECT_PROJECTDATA = 7,
  UNSUPPORTED = -1,
}

export interface AddLayerWizardState {
  step: AddLayerWizardStep;
  layerConfig: AddLayerConfig | null;
  url: string | null;
  viewerConfigId: string;
  done: boolean;
}

export const initialState: AddLayerWizardState = {
  step: AddLayerWizardStep.SELECT_TYPE,
  layerConfig: null,
  url: null,
  viewerConfigId: null,
  done: false,
};
