import { Organization } from '../../types';
import { RegisterWizardState, RegisterWizardStep } from './registerWizardState';

export interface SelectCredentialsAction {
  type: 'SELECT_CREDENTIALS';
  payload: {
    email: string;
    password: string;
  };
}

export interface CompleteRegistrationAction {
  type: 'COMPLETE_REGISTRATION';
  payload: {
    accountType: "Individual" | "Business";
    organizationName?: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    industry: string;
    location: string;
  };
}

export interface NavigateAction {
  type: 'NAVIGATE';
  payload: {
    step: RegisterWizardStep;
  };
}

export interface InvitedFromOrg {
  type: 'ORG_INVITATION';
  payload: {
    organization: Organization;
    userEmail: string;
  };
}

export type RegisterWizardAction =
  | SelectCredentialsAction
  | CompleteRegistrationAction
  | NavigateAction
  | InvitedFromOrg;

export const registerWizardReducer = (
  currentState: RegisterWizardState,
  action: RegisterWizardAction
): RegisterWizardState => {
  switch (action.type) {
    case 'SELECT_CREDENTIALS':
      return {
        ...currentState,
        email: action.payload.email,
        password: action.payload.password,
        step: RegisterWizardStep.ACCOUNT_SETTINGS,
      };
    case 'COMPLETE_REGISTRATION':
      return {
        ...currentState,
      };

    case 'ORG_INVITATION': {
      return {
        ...currentState,
        organizationToJoin: action.payload.organization,
        email: action.payload.userEmail
      };
    }

    case 'NAVIGATE': {
      return {
        ...currentState,
        step: action.payload.step,
      };
    }
  }
};
