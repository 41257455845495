import React, { useEffect, useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Loader, Skeleton } from '@progress/kendo-react-indicators';
import { useAppContext } from '../../../../context/app';
import { useConsumeAddLayerWizardState } from '../../../../context/addLayerWizard';
import TransitionAnimation from '../../../../components/TransitionAnimation';
import { useViewLayer, useViewerConfigAddExistingViewLayer } from '../../../../hooks/viewerconfig';
import { useAvailableViewLayersFileNode } from '../../../../hooks/data_delivery';
import { FileNode, FileNodeStatus, FileNodeType } from '../../../../types/DataDelivery';
import FileNodeTreeView from '../../../../components/filenodes/FileNodeTreeView/FileNodeTreeView';
import { useConsumeViewerState } from '../../../../context/viewer';

const SelectProjectData: React.FC = () => {
  const appContext = useAppContext();
  const { dispatch, layerConfig, url, viewerConfigId } = useConsumeAddLayerWizardState();
  const { viewConfig, viewConfigParams, layers } = useConsumeViewerState();
  const availableViewLayersFileNodeQuery = useAvailableViewLayersFileNode();
  const viewerConfigAddMutation = useViewerConfigAddExistingViewLayer();
  const [availableFileNodes, setAvailableFileNodes] = useState([]);
  const [selectedViewLayerId, setSelectedViewLayerId] = useState(null);
  const viewLayerQuery = useViewLayer(null, selectedViewLayerId);

  useEffect(() => {
    if (!availableViewLayersFileNodeQuery.isSuccess || !availableViewLayersFileNodeQuery.data) return;
    const viewLayerIds = layers.map((viewLayer) => viewLayer.id);
    const removeFileNodesWithViewLayer = (fileNodes: FileNode[], viewLayerIds: string[]): FileNode[] => {
      // Helper function to check if a FileNode has a viewLayer ID in viewLayerIds
      const hasViewLayerId = (fileNode: FileNode): boolean => {
        if (fileNode.viewLayer && viewLayerIds.includes(fileNode.viewLayer.id)) {
          return true;
        }
        return false;
      };

      // Recursive function to process file nodes and their children
      const processFileNodes = (nodes: FileNode[]): FileNode[] => {
        return nodes
          .filter((node) => !hasViewLayerId(node))
          .map((node) => ({
            ...node,
            children: node.children ? processFileNodes(node.children) : null,
          }));
      };
      return processFileNodes(fileNodes);
    };
    setAvailableFileNodes(removeFileNodesWithViewLayer(availableViewLayersFileNodeQuery.data, viewLayerIds));
  }, [availableViewLayersFileNodeQuery.isSuccess, availableViewLayersFileNodeQuery.data]);

  useEffect(() => {
    if (selectedViewLayerId && viewLayerQuery.isSuccess && viewLayerQuery.data)
      viewerConfigAddMutation.mutateAsync({ viewerId: viewConfig.id, viewLayerId: viewLayerQuery.data.id });
  }, [viewLayerQuery.isSuccess, viewLayerQuery.isRefetching]);

  useEffect(() => {
    if (viewerConfigAddMutation.isSuccess) {
      appContext.dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { type: 'success', content: 'Successfully added Layer.' } },
      });
      dispatch({ type: 'DONE', payload: {} });
    } else if (viewerConfigAddMutation.isError) {
      appContext.dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: { type: 'error', content: 'Unable to add layer at this moment. Pleas try again later.' },
        },
      });
    }
  }, [viewerConfigAddMutation.isSuccess, viewerConfigAddMutation.isError]);

  const loading = availableViewLayersFileNodeQuery.isLoading || availableViewLayersFileNodeQuery.isRefetching;

  return (
    <TransitionAnimation>
      <div style={{ width: '100%', height: '100%', paddingLeft: '2rem', paddingTop: '1rem' }}>
        {!loading && availableViewLayersFileNodeQuery.isSuccess && availableFileNodes.length > 0 && (
          <FileNodeTreeView
            rootFileNode={{
              id: '',
              name: '',
              path: '',
              fileType: FileNodeType.DIR,
              status: FileNodeStatus.READY,
              isDir: true,
              isSupport: false,
              children: availableFileNodes,
            }}
            selectedFileNode={null}
            onItemSelected={(fileNode: FileNode) => {
              if (!fileNode.isDir) {
                console.log('Selected: ' + JSON.stringify(fileNode));
                setSelectedViewLayerId(fileNode.viewLayer.id);
              }
            }}
            canSelectDirectories={false}
          ></FileNodeTreeView>
        )}
        {!loading && availableViewLayersFileNodeQuery.isSuccess && availableFileNodes.length === 0 && (
          <Typography.h2>No layers available.</Typography.h2>
        )}
        {loading && <Loader />}
      </div>
    </TransitionAnimation>
  );
};

export default SelectProjectData;
