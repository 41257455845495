import React, { useState, useRef, useEffect } from 'react';
import Dialog from '../../../components/Dialog';
import { Typography } from '@progress/kendo-react-common';
import { Input } from '@progress/kendo-react-inputs';
import { SubmitButton } from '../../../components/form';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

interface NodeParamsModalProps {
  show: boolean;
  handleClose: any;
  defaultValue: string;
  onConfirm: any;
}

const NodeParamsModal: React.FC<NodeParamsModalProps> = (props: NodeParamsModalProps) => {
  const [value, setValue] = React.useState('');
  const [jsonConfig, setJsonConfig] = useState<any>({});
  const [jsonConfigValid, setJsonConfigValid] = useState<any>(false);

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  const handleJSONChange = (newJson: string): void => {
    if(newJson === null) {
      setJsonConfig({});
      setJsonConfigValid(false);
    }
    else {
      setJsonConfig(newJson);
    }
    return;
  };

  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>Change SAP Parameters</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
      style={{ display: 'flex', flexDirection: 'column', width: '80%', maxWidth: '500px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {props.show &&
        <Editor
          onChange={handleJSONChange}
          value={props.defaultValue}
          onError={(e)=>{console.log("Error occured: " + e);}}
          schema={{}}
          mode="text"
          allowedModes={['tree', 'text']}
          onValidate= {(json) => {
            const errors: any[] = [];
          
            return errors;
          }}
          onValidationError={(errors: any)=>{setJsonConfigValid(errors.length === 0)}}
        />
        }
        <SubmitButton
          type="button"
          label="Submit"
          uppercase={false}
          full={false}
          disabled={false}
          onClick={() => {
            props.onConfirm(jsonConfig);
          }}
          loading={false}
          className="mt-2 ml-auto"
        />
      </div>
    </Dialog>
  );
};

export default NodeParamsModal;
