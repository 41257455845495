import styled from 'styled-components';
import Dialog from '../../components/Dialog';

const StyledDialog = styled(Dialog)`
  .k-window-titlebar {
    padding: 1.75rem 1.75rem !important;
  }
  .k-window-content {
    padding: 1rem 1.75rem 1.75rem !important;
  }
`;

export default StyledDialog;
