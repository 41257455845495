import {
    WSMessage, ZipProgressNotification,
  } from '../../../types';
import { AppAction } from '../../../context/app';

const handleMessage = (wsMessage: WSMessage, dispatch: (action: AppAction) => void) => {
    const zipNotif: ZipProgressNotification = wsMessage.payload;
    dispatch({
      type: 'UPDATE_ZIP_NOTIFICATION',
      payload: {
        zipNotification: zipNotif,
      },
    });
}

export default handleMessage;