import { useQuery } from 'react-query';
import { SapFlowTransaction } from '../../../types';
import { useAxiosInstance } from '../../common/';

const endpoint = 'sapflow';

const useTransactions = (userId: string, refetchInterval: number | false = false) => {
  const axios = useAxiosInstance();

  return useQuery(
    ['transactions'],
    async () => {
      const result = await axios.get<SapFlowTransaction[]>(endpoint);
      return result.data;
    },
    { refetchInterval }
  );
};

export default useTransactions;
