import React, { useContext, useEffect } from 'react';
import { FullScreen, ScaleLine } from 'ol/control';
import MapContext from '../Map/MapContext';
import { useConsumeViewerState } from '../../../../context/viewer';
import { Unit } from '../../../../types';
import { Units } from 'ol/proj/Units';

const FullScreenControl: React.FC = () => {
  const { map } = useContext<any>(MapContext);
  const { units } = useConsumeViewerState();

  useEffect(() => {
    if (!map) return;
    let olUnits: 'degrees' | 'imperial' | 'nautical' | 'metric' | 'us' = 'metric';
    if (units === Unit.FEET) {
      olUnits = 'imperial';
    }
    const control = new ScaleLine({
      units: olUnits,
      /*bar: true,
      steps: 4,
      text: false,
      minWidth: 140,*/
    });

    map.controls.push(control);

    return () => map.controls.remove(control);
  }, [map, units]);

  return null;
};

export default FullScreenControl;
