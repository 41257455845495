import React, { useEffect, useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Reveal } from '@progress/kendo-react-animation';
import { ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { useOrganizations } from '../../hooks/user_management';
//import { HumanReadableRole, toEnumRole, toReadableRole } from '../../converters/userRoleConverter';
import { Organization } from '../../types';
import StyledExpansionPanel from '../../components/styled/StyledExpansionPanel';
import { truncateText } from '../../common/stringHelper';
import { OrganizationSettings } from '../Account';
import { SubmitButton } from '../../components/form';
import CreateOrganizationDialog from './CreateOrganizationDialog';

const OrganizationsManagement: React.FC = () => {
  const [selectedOrganization, setSelectedOrganization] = useState<Organization>(null);
  const [creatingOrganization, setCreatingOrganization] = useState<boolean>(false);
  const organizationsQuery = useOrganizations();

  const handleOrganizationSelection = (org: Organization) => {
    if (!selectedOrganization || selectedOrganization.id !== org.id) setSelectedOrganization(org);
    else setSelectedOrganization(null);
  };

  const handleCreateOrganization = () => {
    setCreatingOrganization(true);
  };

  const renderOrganizations = () => {
    return (
      <div>
        {organizationsQuery.data &&
          organizationsQuery.data.length > 0 &&
          organizationsQuery.data
            .sort((org1, org2) => {
              const nameA = org1.name.toUpperCase(); // Convert names to uppercase for case-insensitive comparison
              const nameB = org2.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0; // names are equal
            })
            .map((item) => (
              <StyledExpansionPanel
                key={item.id}
                style={{
                  background: 'var(--geosap-card-bg-color)',
                }}
                title={
                  <>
                    <Typography.h3 className="flex-grow-1 text-capitalize">{truncateText(item.name, 46)}</Typography.h3>
                  </>
                }
                expanded={selectedOrganization && selectedOrganization.id === item.id}
                tabIndex={0}
                onAction={(e: ExpansionPanelActionEvent) => {
                  handleOrganizationSelection(item);
                }}
                expandIcon="k-icon k-i-arrow-chevron-right animated-transform rotated-0"
                collapseIcon="k-icon k-i-arrow-chevron-right animated-transform rotated--90"
              >
                <Reveal>
                  {selectedOrganization && selectedOrganization.id === item.id && (
                    <ExpansionPanelContent>
                      <OrganizationSettings organization={selectedOrganization} />
                    </ExpansionPanelContent>
                  )}
                </Reveal>
              </StyledExpansionPanel>
            ))}
      </div>
    );
  };

  return (
    <div className="container-fluid p-2 bg-white">
      <CreateOrganizationDialog
        show={creatingOrganization}
        onClose={() => {
          setCreatingOrganization(false);
        }}
      ></CreateOrganizationDialog>
      <div className="d-flex flex-column col-12 col-md-8 mx-auto">
        <Typography.h2 className="text-center pt-3 pb-1">ORGANIZATION MANAGEMENT</Typography.h2>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <SubmitButton
            themeColor="primary"
            label="Create"
            onClick={handleCreateOrganization}
            full={false}
          ></SubmitButton>
        </div>
        {renderOrganizations()}
      </div>
    </div>
  );
};

export default OrganizationsManagement;
