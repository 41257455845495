import { useQuery, useQueryClient } from 'react-query';
import { SapFlowViewConfig, LayerType, ViewLayer, ViewLayerParam, ViewLayerState } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'viewer_config';

const useViewerConfig = (viewConfigId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useQuery(['viewerConfig', viewConfigId], async () => {
    if(!viewConfigId) return null;
    const result = await axios.get<SapFlowViewConfig>("/viewer_config/" + viewConfigId);
    const viewConfig = result.data;
    viewConfig.layers.forEach((layer: ViewLayer) => {
      layer.paramsMap = {};
      layer.params.forEach((param: ViewLayerParam) => {
        try {
          layer.paramsMap[param.key] = JSON.parse(param.value);
        } catch (e) {
          layer.paramsMap[param.key] = param.value;
        }
      });
      layer.viewState = ViewLayerState.READY;
    });
    
    viewConfig.paramsMap = {};
    viewConfig.params?.forEach((param: ViewLayerParam) => {
      try {
        viewConfig.paramsMap[param.key] = JSON.parse(param.value);
      } catch (e) {
        viewConfig.paramsMap[param.key] = param.value;
      }
    });
    return viewConfig;
  });
};

export default useViewerConfig;
