import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Switch } from '@progress/kendo-react-inputs';
import { useUser } from '../../hooks/authentication';
import { SettingsPaths, ACCOUNT_USER, ACCOUNT_GEOSAP, ACCOUNT_NOTIFICATIONS, ACCOUNT_PLANS } from './';
import { StyledTextButton } from '../../components/styled';
import {
  GEOSAP_PLAN_BASIC,
  GEOSAP_PLAN_PRO,
  GEOSAP_PLAN_ENTREPRISE,
  GEOSAP_PLANS,
} from '../../common/subscriptionPlans';

const AccountOverview: React.FC = () => {
  const { getCurrentOrganization, getUser } = useUser();
  const [currentPlan, setCurrentPlan] = React.useState<string>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const billingDetails = getCurrentOrganization().orgBillingDetails;
    if (billingDetails) {
      if (billingDetails.subscriptionActive) {
        if (billingDetails.stripeProductId === GEOSAP_PLANS[GEOSAP_PLAN_BASIC].productId) {
          setCurrentPlan(GEOSAP_PLAN_BASIC);
        } else if (billingDetails.stripeProductId === GEOSAP_PLANS[GEOSAP_PLAN_PRO].productId) {
          setCurrentPlan(GEOSAP_PLAN_PRO);
        } else if (billingDetails.stripeProductId === GEOSAP_PLANS[GEOSAP_PLAN_ENTREPRISE].productId) {
          setCurrentPlan(GEOSAP_PLAN_ENTREPRISE);
        }
      }
    }
  }, []);

  return (
    <div className="container-fluid p-2">
      <div className="row">
        {/* USER OVERVIEW */}
        <div className="p-2 col-12 col-md-6">
          <div
            className="d-flex flex-column p-3 border cursor-pointer"
            onClick={() => {
              navigate(SettingsPaths[ACCOUNT_USER]);
            }}
          >
            <span style={{ fontSize: '0.8rem' }}>USER SETTINGS</span>
            <span className="fw-bold py-1">User Information</span>
            <span className="py-1">You can edit your name, email address, account password and language.</span>
            <div className="d-flex">
              <div className="d-flex flex-column pe-3" style={{ color: 'var(--geosap-secondary-color)' }}>
                <span className="py-1">NAME: </span>
                <span className="py-1">USERNAME: </span>
                <span className="py-1">EMAIL: </span>
                <span className="py-1">PASSWORD: </span>
              </div>
              <div className="d-flex flex-column" style={{}}>
                <span className="py-1">{getUser().firstName + ' ' + getUser().lastName}</span>
                <span className="py-1">{getUser().email}</span>
                <span className="py-1">{getUser().email}</span>
                <span className="py-1">{'************'}</span>
              </div>
            </div>
            <StyledTextButton style={{ marginTop: 'auto', textAlign: 'end' }}>See all user settings</StyledTextButton>
          </div>
        </div>
        {/* GeoSAP OVERVIEW 
        <div className="p-2 col-12 col-md-6 ">
          <div className="d-flex flex-column p-3 border cursor-pointer h-100" onClick={()=>{navigate(SettingsPaths[ACCOUNT_GEOSAP])}}>
            <span style={{fontSize: "0.8rem"}}>GeoSAP SETTINGS</span>
            <span className="fw-bold py-1">Spatial & Measurement Systems</span> 
            <span className="py-1">Change default units, default local spatial coordinate systems, and more.</span>
            <div className="d-flex">
              <div className="d-flex flex-column pe-3" style={{color: "var(--geosap-secondary-color)"}}>
                <span className="py-1">Default Unit: </span>
                <span className="py-1">Local Coords: </span>
              </div>
              <div className="d-flex flex-column" style={{}}>
                <span className="py-1">{"US Survey Feet"}</span>
                <span className="py-1">{"VT State Plane"}</span>
              </div>
            </div>
            <StyledTextButton style={{marginTop: "auto", textAlign:"end"}}>See all GeoSAP settings</StyledTextButton>
          </div>
        </div>*/}

        {/* Notifications OVERVIEW 
        <div className="p-2 col-12 col-md-6 ">
          <div className="d-flex flex-column p-3 border cursor-pointer h-100" onClick={()=>{
                  console.log("navigating");navigate(SettingsPaths[ACCOUNT_NOTIFICATIONS])}}>
            <span style={{fontSize: "0.8rem"}}>Notifications SETTINGS</span>
            <span className="fw-bold py-1">SapFLOW Status Notifications</span> 
            <span className="py-1">You can change how to be notified of SapFlow status.</span>
            <div className="d-flex">
              <div className="d-flex flex-column pe-3" style={{color: "var(--geosap-secondary-color)"}}>
                <span className="py-2">EMAIL: </span>
                <span className="py-2">TEXT MESSAGE (SMS): </span>
              </div>
              <div className="d-flex flex-column" style={{}}>
                <Switch
                  className="neutral my-1"
                  onChange={(event) => {
                    console.log("Clickng on switch");
                    event.nativeEvent.stopPropagation();
                    event.syntheticEvent.stopPropagation();
                    //event.nativeEvent.preventDefault();
                  }}
                  //checked={}
                  //disabled={}
                />
                <Switch
                  className="neutral my-1"
                  onChange={(event) => {
                    console.log("Clickng on switch");
                    event.nativeEvent.stopPropagation();
                    event.syntheticEvent.stopPropagation();
                    //event.nativeEvent.preventDefault();
                  }}
                  //checked={}
                  //disabled={}
                />
              </div>
            </div>
            <StyledTextButton style={{marginTop: "auto", textAlign:"end"}}>See all GeoSAP settings</StyledTextButton>
          </div>
        </div>*/}

        {/* PLANS & BILLING Overview
        <div className="p-2 col-12 col-md-6 ">
          <div className="d-flex flex-column p-3 border cursor-pointer h-100" onClick={()=>{navigate(SettingsPaths[ACCOUNT_PLANS])}}>
            <span style={{fontSize: "0.8rem"}}>PLANS & BILLING</span>
            <span className="fw-bold py-1">Current Plan</span> 
            <span className="py-1">Change or cancel your GeoSAP plan.</span>
            <div className="d-flex">
              <div className="d-flex flex-column pe-3" style={{color: "var(--geosap-secondary-color)"}}>
                <span className="py-1">Current Plan: </span>
              </div>
              <div className="d-flex flex-column" style={{}}>
                {currentPlan && <span className="py-1">{"GeoSAP Basic - 10GB Monthly"}</span>}
                {!currentPlan && <span className="py-1">{"No plan currently active."}</span>}
              </div>
            </div>
            <StyledTextButton style={{marginTop: "auto", textAlign:"end"}}>See all Plans & Billing settings</StyledTextButton>
          </div>
        </div>*/}
      </div>
    </div>
  );
};

export default AccountOverview;
