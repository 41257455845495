import styled from 'styled-components';

const StyledTextButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: var(--geosap-primary-color);
  text-decoration: underline;
  &:hover {
    color: var(--geosap-link-hover-color);
  }
`;

export default StyledTextButton;
