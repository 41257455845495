import React, { useEffect, useState } from 'react';
import { RangeSlider, Slider, SliderLabel } from '@progress/kendo-react-inputs';
import { BandInfo, LayerType, ViewLayer } from '../../../../types';
import { updateLayerParam } from '../../../../common/viewerConfigHelper';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { ColorResult, SketchPicker } from 'react-color';
import { RasterVisualization, RAMP_COLORS, VectorVisualization } from '../../../../types/Rendering';
import { rgbToHex } from '../../../../converters/colorHelper';

interface OLVectorControlsProps {
  layer: ViewLayer;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

const VectorControls: React.FC<OLVectorControlsProps> = ({ layer, onLayerUpdated }) => {
  const [initialized, setInitialized] = useState(false);
  const [choosingColor, setChoosingColor] = React.useState<null | 'FILL' | 'STROKE'>(null);
  const [fillColor, setFillColor] = useState<any>('#FF0000');
  const [strokeColor, setStrokeColor] = useState<any>('#000000');
  const [strokeWidth, setStrokeWidth] = useState(1);
  useEffect(() => {
    if (layer) {
      const visualization: VectorVisualization = layer.paramsMap['visualization'];
      const vectorVis = visualization?.vector;
      if (vectorVis) {
        setFillColor(vectorVis.fillColor);
        setStrokeColor(vectorVis.strokeColor);
        setStrokeWidth(vectorVis.strokeWidth);
      }
      setInitialized(true);
    }
  }, [layer]);

  const handleColorChange = (color: ColorResult) => {
    const newLayer = JSON.parse(JSON.stringify(layer));
    const vis: VectorVisualization = newLayer.paramsMap.visualization;
    const vectorVis = vis.vector;
    if (choosingColor === 'FILL') {
      vectorVis.fillColor = color.hex;
      setFillColor(color.hex);
    } else if (choosingColor === 'STROKE') {
      vectorVis.strokeColor = color.hex;
      setStrokeColor(color.hex);
    }
    updateLayerParam(newLayer, 'visualization', vis);
    onLayerUpdated(newLayer);
  };

  return (
    <div className="d-flex flex-column p-2 mb-4">
      <div className="w-75 mx-auto pb-2 pt-2" style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ fontWeight: 'bold' }}>Fill Color</span>
        <div
          style={{
            padding: '4px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
            marginLeft: 'auto',
            height: 'fit-content',
          }}
          onClick={() => {
            setChoosingColor('FILL');
          }}
        >
          <div
            style={{
              width: '36px',
              height: '14px',
              borderRadius: '2px',
              background: `${fillColor}`,
            }}
          />
        </div>
      </div>
      <div className="w-75 mx-auto pb-2 pt-2" style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ fontWeight: 'bold' }}>Stroke Color</span>
        <div
          style={{
            padding: '4px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
            marginLeft: 'auto',
            height: 'fit-content',
          }}
          onClick={() => {
            setChoosingColor('STROKE');
          }}
        >
          <div
            style={{
              width: '36px',
              height: '14px',
              borderRadius: '2px',
              background: `${strokeColor}`,
            }}
          />
        </div>
      </div>
      <FloatingLabel label={'Stroke Width'} editorId={'visType'} editorValue={true} className="w-75 mx-auto pb-2">
        <Slider
          min={0}
          max={5}
          step={0.01}
          value={strokeWidth}
          onChange={(e) => {
            if (!initialized) return;
            const vis: VectorVisualization = layer.paramsMap.visualization;
            vis.vector.strokeWidth = e.value;
            setStrokeWidth(e.value);
            updateLayerParam(layer, 'visualization', vis);
            onLayerUpdated(layer);
          }}
        >
          <SliderLabel position={strokeWidth}>{strokeWidth.toFixed(1)}</SliderLabel>
        </Slider>
      </FloatingLabel>
      {choosingColor ? (
        <div style={{ position: 'absolute', zIndex: 100 }}>
          <div
            style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
            onClick={() => {
              setChoosingColor(null);
            }}
          />
          <SketchPicker color={choosingColor === 'FILL' ? fillColor : strokeColor} onChange={handleColorChange} />
        </div>
      ) : null}
    </div>
  );
};

export default VectorControls;
