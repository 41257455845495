import React, { useContext, useEffect, useImperativeHandle, forwardRef, useState, useRef } from 'react';
import MapContext from '../Map/MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import Extent from 'ol/extent';
import { transformExtent } from 'ol/proj.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { Layer2DClickResult, VectorVisualization, ViewLayer, VisualizationType } from '../../../../types';
import TileLayer from './TileLayer';
import { TileWMS } from 'ol/source';
import MapContextData from '../Map/MapContextData';
import { Feature, MapBrowserEvent } from 'ol';
import VectorSource from 'ol/source/Vector';
import debounce from 'lodash.debounce';
import { Circle, Fill, Style } from 'ol/style';
import { useConsumeViewerState } from '../../../../context/viewer';
import { getWfsPreviewLayerUrl } from '../../../../common/viewLayerHelper';
import { deepDiff } from '../../../../common/objComparator';

interface Props {
  show: boolean;
  layer: ViewLayer;
  zIndex: number;
  onClick?: () => void; // Add onClick prop
}

type Ref = {
  zoomToLayer: (layer: any) => void;
  getOlLayer: (layer: any) => any;
  handleMapClick: (evt: MapBrowserEvent<any>) => Promise<Layer2DClickResult>;
} | null;
const GsVecLayer = forwardRef<Ref, Props>(({ show, layer, zIndex = 0, onClick }, ref) => {
  const { dispatch, selectedFeature, olMapInitialized } = useConsumeViewerState();
  const { map } = useContext<MapContextData>(MapContext);
  const [zoomWhenReady, setZoomWhenReady] = useState(false);
  const previewOlLayerRef = useRef<any>(null);
  const wfsFeatureLayerRef = useRef<any>(null);
  const [wmsSource, setWmsSource] = useState<TileWMS>(null);
  const [clickedFeature, setClickedFeature] = useState<any>(null);
  const [projectedExtents, setProjectedExtents] = useState(null);
  const [opacity, setOpacity] = useState(1);
  const previousParamsMapRef = useRef<any>(null);

  useEffect(() => {
    if (!map || !olMapInitialized) return;

    let currentParamsMap = layer.paramsMap;

    if (previousParamsMapRef.current) {
      currentParamsMap = deepDiff(previousParamsMapRef.current, currentParamsMap);
      console.log('Diff: ' + JSON.stringify(currentParamsMap));
    }
    previousParamsMapRef.current = layer.paramsMap;
    //if only opacity changed, return here
    if (
      currentParamsMap &&
      currentParamsMap.visualization &&
      Object.keys(currentParamsMap.visualization).length === 1 &&
      currentParamsMap.visualization.opacity !== null &&
      currentParamsMap.visualization.opacity !== undefined
    ) {
      setOpacity(currentParamsMap.visualization.opacity);
      return;
    } else if (currentParamsMap && Object.keys(currentParamsMap).length === 0) {
      return;
    }

    const visualization: VectorVisualization = layer.paramsMap['visualization'];
    let style = layer.paramsMap['wmsStyle'];
    let env = '';

    if (visualization) {
      if (visualization.type === VisualizationType.VECTOR && !style) {
        style = 'VectorBorderedFill';
        env += 'fillColor:' + visualization.vector.fillColor + ';';
        env += 'strokeColor:' + visualization.vector.strokeColor + ';';
        env += 'strokeWidth:' + visualization.vector.strokeWidth + ';';
      }
      setOpacity(visualization.opacity);
    }

    setWmsSource(
      new TileWMS({
        url: getWfsPreviewLayerUrl(layer),
        params: {
          FORMAT: 'image/png',
          VERSION: layer.paramsMap['version'],
          tiled: true,
          STYLES: style,
          LAYERS: layer.paramsMap['layer'],
          exceptions: 'application/vnd.ogc.se_inimage',
          env: env,
        },
        crossOrigin: 'Anonymous', // Fix for ability to take a screenshot
      })
    );

    // TODO: This is a hack to bypass the view.getProjection not available here yet:
    setTimeout(() => {
      if (layer.paramsMap['extents'] && layer.paramsMap['projection']) {
        const projection = layer.paramsMap['projection'];
        const extents = layer.paramsMap['extents'];

        // Project the extents to the map's projection
        const transformedExtents = transformExtent(extents, projection, map.getView().getProjection().getCode());
        setProjectedExtents(transformedExtents);
      }
    }, 1000);
  }, [map, olMapInitialized, layer]);

  useEffect(() => {
    if (selectedFeature && selectedFeature.layerId === layer.id) {
      // Create a vector source with the clicked feature
      const vectorSource = new VectorSource({
        features: [selectedFeature.feature],
      });

      // Create a vector layer with the vector source
      const vectorLayer = new OLVectorLayer({
        source: vectorSource,
        zIndex: 2,
      });

      // Add the vector layer to the map
      map.addLayer(vectorLayer);

      // Save the vector layer reference
      wfsFeatureLayerRef.current = vectorLayer;
      return () => {
        map.removeLayer(wfsFeatureLayerRef.current);
        wfsFeatureLayerRef.current = null;
      };
    }
  }, [selectedFeature]);

  const zoomToLayer = (layer: any) => {
    setZoomWhenReady(true);
  };

  const handleMapClick = async (evt: MapBrowserEvent<any>): Promise<Layer2DClickResult> => {
    const clickedCoordinate = evt.coordinate;
    // Check if the clicked coordinate is within the layer's extents
    if (projectedExtents) {
      const [minX, minY, maxX, maxY] = projectedExtents;
      const withinExtents =
        clickedCoordinate[0] >= minX &&
        clickedCoordinate[0] <= maxX &&
        clickedCoordinate[1] >= minY &&
        clickedCoordinate[1] <= maxY;
      if (!withinExtents) {
        return null; // Clicked coordinate is outside the layer's extents, discard the click
      }
    }
    const viewResolution = /** @type {number} */ map.getView().getResolution();
    const url = wmsSource.getFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {
      INFO_FORMAT: 'application/json',
    });
    if (url) {
      const response = await fetch(url);
      const json = await response.text();
      const features = new GeoJSON().readFeatures(JSON.parse(json));
      features.forEach((feature) => {
        const properties = feature.getProperties();
        properties['layerId'] = layer.id;
        feature.setProperties(properties);
      });
      if (features.length > 0) {
        return { layer, features };
      }
    }
    return null;
  };

  useEffect(() => {
    if (zoomWhenReady && map.getView().getProjection()) {
      const projection = layer.paramsMap['projection'];
      const extents = layer.paramsMap['extents'];
      if (projection && extents) {
        let olExtents: Extent.Extent = [extents[0], extents[1], extents[2], extents[3]];
        if (projection !== map.getView().getProjection().getCode()) {
          olExtents = transformExtent(olExtents, projection, map.getView().getProjection().getCode());
        }
        map.getView().fit(olExtents);
      }
      setZoomWhenReady(false);
    }
  }, [zoomWhenReady, map.getView().getProjection()]);

  const getOlLayer = (layer: any) => {
    return previewOlLayerRef;
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
    getOlLayer,
    handleMapClick,
  }));
  if (!wmsSource) return null;
  return (
    <TileLayer
      ref={(el: any) => {
        previewOlLayerRef.current = el;
      }}
      key={layer.id}
      layer={layer}
      show={show}
      zIndex={1}
      source={wmsSource}
      opacity={opacity}
    ></TileLayer>
  );
});
GsVecLayer.displayName = 'GsVecLayer';

export default GsVecLayer;
