import React, { useContext, useEffect, useImperativeHandle, forwardRef, useState, useRef } from 'react';
import MapContext from '../Map/MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import { DrawingMode } from '../../../../types';
import MapContextData from '../Map/MapContextData';
import { Feature } from 'ol';
import VectorSource from 'ol/source/Vector';
import { useConsumeViewerState } from '../../../../context/viewer';
import { Draw, Modify, Snap } from 'ol/interaction.js';
import { Geometry } from 'ol/geom';
import { DrawEvent } from 'ol/interaction/Draw';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { FeatureLike } from 'ol/Feature';

const style = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.2)',
  }),
  stroke: new Stroke({
    color: 'rgba(0, 0, 0, 0.5)',
    lineDash: [10, 10],
    width: 2,
  }),
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, 0.7)',
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
  }),
});

interface Props {
  show: boolean;
  zIndex: number;
  active: boolean;
  drawingMode: DrawingMode;
  drawings: Feature<Geometry>[];
  onDrawingAdded?: (measurement: Feature) => void;
}

type Ref = {
  getOlLayer: (layer: any) => any;
} | null;
const DrawingTool = forwardRef<Ref, Props>(
  ({ zIndex = 0, show, active, drawings, onDrawingAdded, drawingMode }, ref) => {
    const { dispatch, selectedFeature, olMapInitialized, mode2d } = useConsumeViewerState();
    const { map } = useContext<MapContextData>(MapContext);
    const [vectorLayerState, setVectorLayerState] = useState<any>(null);
    const vectorLayerRef = useRef<OLVectorLayer<VectorSource<Feature<Geometry>>>>(null);
    const sourceRef = useRef<VectorSource>(null);
    const modifyRef = useRef<any>(null);
    const drawingRef = useRef<any>(false);

    const styleFunction = (feature: FeatureLike) => {
      const featureProperties = feature.getProperties();
      const selected = featureProperties['selected'];
      const styles: any[] = [];
      const styling = style;
      if (selected) {
        styling.getFill().setColor('rgba(255, 255, 255, 0.4)');
        styling.getStroke().setColor('#ffcc33');
      } else {
        styling.getFill().setColor('rgba(255, 255, 255, 0.2)');
        styling.getStroke().setColor('rgba(0, 0, 0, 0.5)');
      }
      styles.push(styling);
      return styles;
    };

    useEffect(() => {
      if (olMapInitialized) {
        // Create a vector source with the clicked feature
        const source = new VectorSource();

        const modify = new Modify({ source: source });

        const newVectorLayer = new OLVectorLayer({
          source: source,
          zIndex: 3,
          style: function (feature: FeatureLike) {
            return styleFunction(feature);
          },
        });

        // Add the vector layer to the map
        map.addLayer(newVectorLayer);

        //map.addInteraction(modify);

        // Save the vector layer reference
        vectorLayerRef.current = newVectorLayer;
        sourceRef.current = source;
        modifyRef.current = modify;
        setVectorLayerState(newVectorLayer);

        // Add click event handler
        //map.getViewport().addEventListener('contextmenu', (e: MouseEvent) => {
        //  e.preventDefault();
        //  if (e.button === 2) {
        //    dispatch({ type: 'CHANGE_NAVIGATION_2D', payload: { mode: NavigationMode2D.NORMAL } });
        //  }
        //});
        return () => {
          map.removeLayer(vectorLayerRef.current);
          //map.removeInteraction(modifyRef.current);
          vectorLayerRef.current = null;
          vectorLayerRef.current = null;
          sourceRef.current = null;
          modifyRef.current = null;
        };
      }
    }, [olMapInitialized]);

    const handleCompletedMeasurement = (e: DrawEvent) => {
      drawingRef.current = false;
      modifyRef.current.setActive(true);

      onDrawingAdded(e.feature);
    };

    useEffect(() => {
      if (!vectorLayerState) return;
      sourceRef.current.clear();
      sourceRef.current.addFeatures(drawings);
    }, [vectorLayerState, drawings]);

    useEffect(() => {
      if (!vectorLayerState) return;
      if (!active) return;
      const draw = new Draw({
        source: sourceRef.current,
        type: drawingMode as any,
      });
      draw.on('drawend', handleCompletedMeasurement);
      map.addInteraction(draw);
      const snap = new Snap({ source: sourceRef.current });
      map.addInteraction(snap);
      return () => {
        map.removeInteraction(draw);
        map.removeInteraction(snap);
      };
    }, [vectorLayerState, drawingMode, active]);

    const getOlLayer = (layer: any) => {
      return vectorLayerRef.current;
    };

    useImperativeHandle(ref, () => ({
      getOlLayer,
    }));
    return null;
  }
);
DrawingTool.displayName = 'DrawingTool';

export default DrawingTool;
