import { QueryClient } from 'react-query';
import {
    DeploymentServer,
    WSMessage,
  } from '../../../types';

const handleMessage = (wsMessage: WSMessage, queryClient: QueryClient) => {
    const newServerState: DeploymentServer = wsMessage.payload;
    let serversData: DeploymentServer[] = null;
    try{
        serversData = JSON.parse(JSON.stringify(queryClient.getQueryData(['servers'])));
    }
    catch(e) {
        console.log("Unable to parse current Server Status.");
        return;
    }
    if (serversData) {
        serversData.forEach((serverData: DeploymentServer) => {
            if(serverData.id===newServerState.id){
                serverData.online = newServerState.online
                serverData.geosapServerOnline = newServerState.geosapServerOnline;
                serverData.geosapServerEnabled = newServerState.geosapServerEnabled;
                serverData.rootsServerOnline = newServerState.rootsServerOnline;
                serverData.rootsServerEnabled = newServerState.rootsServerEnabled;
                serverData.foliageServerOnline = newServerState.foliageServerOnline;
                serverData.foliageServerEnabled = newServerState.foliageServerEnabled;
                serverData.communicatorServerOnline = newServerState.communicatorServerOnline;
                serverData.communicatorServerEnabled = newServerState.communicatorServerEnabled;
                serverData.imageServerOnline = newServerState.imageServerOnline;
                serverData.imageServerEnabled = newServerState.imageServerEnabled;
        }});
        queryClient.setQueryData(['servers'], serversData);
    }
}

export default handleMessage;