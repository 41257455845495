export function truncateText(text: string, maxLength: number): string {
  return text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;
}

export function replaceInvalidFilenameChars(filename: string)
{
  return filename.replace(/[/\\?%*:|"<>]/g, '-');
}

export function capitalize(str: string) {
  if(str === null || str === undefined)
    return null;
  return str
    .toLowerCase()                      // Convert the string to lowercase
    .split(' ')                         // Split the string into words by spaces
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize the first letter of each word
    .join(' ');                         // Join the words back together
}

export function centsStrToDollars(centsString: string): string {
  // Convert the string to a number and divide by 100 to get the dollar amount
  const dollars = parseInt(centsString, 10) / 100;
    
  // Format the dollar amount as a currency string
  return dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}