import { useMutation, useQueryClient } from 'react-query';
import { Organization } from '../../types';
import { useAxiosInstance } from '../common/';

interface Params {
  userId: string;
}

const useDeleteUser = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const result = await axios.delete<string>("/user_management/users/" + params.userId);
      return result.data;
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['users'], { refetchInactive: true });
      },
    }
  );
};

export default useDeleteUser;
