import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Skeleton } from '@progress/kendo-react-indicators';
import { SapFlowWizardProvider, useCreateSapFlowWizardState, SapFlowWizardStep } from '../../context/sapFlowWizard';
import { useProjects } from '../../hooks/sapflow';
import SelectSapFlowStep from './SapFlowCreation';
import ProvideDetailsStep from './SapFlowDetails';
import UploadStep from './UploadStep';
import { SapFlow } from '../../types';

interface Props {
  newProject?: boolean;
}

const SapFlowWizard: React.FC<Props> = () => {
  const wizardState = useCreateSapFlowWizardState();
  const { state: locationState } = useLocation();
  const { step, dispatch } = wizardState;
  const { projectId } = useParams();
  const projectQuery = useProjects();
  const [renderStep, setRenderStep] = useState(false);

  useEffect(() => {
    const navigationState: any = locationState;
    if (navigationState && navigationState.sapflow) {
      // SapFlow Wizard was provided a SapFlow to complete
      const sapflow: SapFlow = navigationState.sapflow;
      dispatch({
        type: 'CREATE_SAPFLOW',
        payload: {
          sapFlow: sapflow,
          processName: sapflow.sapflowname,
          processDescription: '',
          project: sapflow.project,
        },
      });
      dispatch({
        type: 'PROVIDE_DETAILS',
        payload: {},
      });
    }
  }, [locationState]);

  useEffect(() => {
    if (projectQuery.isSuccess) {
      setRenderStep(true);
    }
    if (projectId && projectQuery.isSuccess) {
      const selectedProject = projectQuery.data.find((p) => p.id === projectId);
      if (selectedProject) {
        dispatch({ type: 'SELECT_PROJECT', payload: { project: selectedProject } });
      }
    }
  }, [projectId, projectQuery.isSuccess]);

  return (
    <SapFlowWizardProvider value={wizardState}>
      <div className="d-flex flex-column h-100">
        <div className="container">
          <div className="row align-items-center justify-content-center" style={{ paddingTop: '4rem' }}>
            {projectQuery.isLoading && (
              <div className="col-xs-12 col-lg-6 col-xl-4">
                <Skeleton shape="rectangle" className="me-5" style={{ width: '85%', height: '40px' }} />
                <Skeleton shape="rectangle" className="w-100 mt-3" style={{ height: '60px' }} />
                <Skeleton shape="rectangle" className="w-50 mt-4" style={{ height: '50px' }} />
              </div>
            )}

            {renderStep &&
              {
                [SapFlowWizardStep.SELECT_SAPFLOW]: <SelectSapFlowStep />,
                [SapFlowWizardStep.PROVIDE_DETAILS]: <ProvideDetailsStep />,
                [SapFlowWizardStep.UPLOAD]: <UploadStep />,
              }[step]}
          </div>
        </div>
      </div>
    </SapFlowWizardProvider>
  );
};

export default SapFlowWizard;
