import React from "react";
import PropTypes from 'prop-types';

interface Props {
    children: any
}
const Layers: React.FC<Props> = ({ children }) => {
	return <div>{children}</div>;
};

Layers.propTypes = {
    children: PropTypes.any,
};

export default Layers;