import React, { Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, SubmitButton } from '../../../../components/form';
import { Typography } from '@progress/kendo-react-common';
import { Reveal } from '@progress/kendo-react-animation';
import { Skeleton } from '@progress/kendo-react-indicators';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import StyledExpansionPanel from '../../../../components/styled/StyledExpansionPanel';
import { useAppContext } from '../../../../context/app';
import styled from 'styled-components';
import { AddLayerConfig } from '../../../../types';
import { truncateText } from '../../../../common/stringHelper';
import { useConsumeAddLayerWizardState } from '../../../../context/addLayerWizard';
import TransitionAnimation from '../../../../components/TransitionAnimation';
import { useWmsCapabilities } from '../../../../hooks/geoservices';
import { useAddWmsLayer } from '../../../../hooks/viewerconfig';

interface FormValues {
  wmsLayer: any | null;
}

const SelectWMSLayer: React.FC = () => {
  const { control, setValue, handleSubmit, formState, reset } = useForm<FormValues>({
    defaultValues: {
      wmsLayer: null,
    },
  });
  const appContext = useAppContext();
  const { dispatch, layerConfig, url, viewerConfigId } = useConsumeAddLayerWizardState();
  const wmsCapabilitiesQuery = useWmsCapabilities(url);
  const [error, setError] = React.useState<string>(null);
  const [availableLayers, setAvailableLayers] = React.useState<any>(null);
  const [selectedWMSLayer, setSelectedWMSLayer] = React.useState<any>(null);
  const useAddWmsLayerMutation = useAddWmsLayer(viewerConfigId);

  const extractWmsLayers = (wmsLayerObj: any, layers: any[]) => {
    if (Array.isArray(wmsLayerObj)) {
      wmsLayerObj.forEach((layerItem) => {
        extractWmsLayers(layerItem, layers);
      });
    } else {
      if (wmsLayerObj.Layer) {
        extractWmsLayers(wmsLayerObj.Layer, layers);
      } else {
        // This is an actual WMS layer
        layers.push(wmsLayerObj);
      }
    }
  };

  useEffect(() => {
    if (wmsCapabilitiesQuery.isSuccess) {
      const capabilities = wmsCapabilitiesQuery.data;

      if (capabilities?.Capability?.Layer) {
        const wmsLayers: any[] = [];
        extractWmsLayers(capabilities.Capability.Layer, wmsLayers);
        setAvailableLayers(wmsLayers);
        setError(null);
      } else {
        setError("Couldn't extract layers information.");
      }
    } else if (wmsCapabilitiesQuery.isError) {
      setError('Problem occured during capabilities request.');
    }
  }, [wmsCapabilitiesQuery.isSuccess, wmsCapabilitiesQuery.isError]);

  const handleWMSLayerSelection = (wmsLayer: any) => {
    const layerName = wmsLayer.Name;
    let workspace: string = null;
    let layer: string = wmsLayer.Name;
    const version: string = wmsCapabilitiesQuery.data.version;
    const splittedLayerName = layerName.split(':');
    if (splittedLayerName.length > 1) {
      workspace = splittedLayerName[0];
      layer = splittedLayerName[1];
    }
    let proj = null;
    let extent = null;
    for (let i = 0; i < wmsLayer.BoundingBox.length; i++) {
      const itBoundingBoxInfos = wmsLayer.BoundingBox[i];
      if (itBoundingBoxInfos.crs === 'CRS:84') {
        proj = itBoundingBoxInfos.crs;
        extent = itBoundingBoxInfos.extent;
        break;
      } else if (itBoundingBoxInfos.crs === 'EPSG:4326') {
        proj = itBoundingBoxInfos.crs;
        extent = [
          itBoundingBoxInfos.extent[0],
          itBoundingBoxInfos.extent[1],
          itBoundingBoxInfos.extent[2],
          itBoundingBoxInfos.extent[3],
        ];
        break;
      }
    }
    if (!proj || !extent) {
      // TODO
      console.log('Bounding box not supported.');
    }
    useAddWmsLayerMutation.mutateAsync({
      baseUrl: url,
      layer: layer,
      workspace: workspace,
      version: version,
      projection: proj,
      boundingBox: extent,
    });
  };

  useEffect(() => {
    if (useAddWmsLayerMutation.isSuccess) {
      appContext.dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { type: 'success', content: 'Successfully added Layer.' } },
      });
      dispatch({ type: 'DONE', payload: {} });
    } else if (useAddWmsLayerMutation.isError) {
      appContext.dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: { type: 'error', content: 'Unable to add layer at this moment. Pleas try again later.' },
        },
      });
    }
  }, [useAddWmsLayerMutation.isSuccess, useAddWmsLayerMutation.isError]);

  const handleExpansionPanelAction = (selected: any | null, event: ExpansionPanelActionEvent) => {
    setSelectedWMSLayer(event.expanded ? null : selected);
  };

  const renderLayerSelection = (wmsLayer: any, index: number) => {
    return (
      <StyledExpansionPanel
        key={index}
        title={
          <>
            <Typography.h3 className="flex-grow-1 text-capitalize">{truncateText(wmsLayer.Title, 46)}</Typography.h3>
          </>
        }
        expanded={selectedWMSLayer?.Name === wmsLayer.Name}
        tabIndex={0}
        onAction={handleExpansionPanelAction.bind(undefined, wmsLayer)}
        expandIcon="k-icon k-i-arrow-chevron-right animated-transform rotated-0"
        collapseIcon="k-icon k-i-arrow-chevron-right animated-transform rotated--90"
      >
        <Reveal>
          {selectedWMSLayer?.Name === wmsLayer.Name && (
            <ExpansionPanelContent>
              <Typography.h5
                style={{
                  margin: '25px',
                }}
              >
                {wmsLayer.Abstract}
              </Typography.h5>
              <div className="d-flex justify-content-end my-4 me-4">
                <SubmitButton
                  label={`Add layer`}
                  uppercase={false}
                  full={false}
                  onClick={() => {
                    handleWMSLayerSelection(wmsLayer);
                  }}
                  loading={useAddWmsLayerMutation.isLoading}
                />
              </div>
            </ExpansionPanelContent>
          )}
        </Reveal>
      </StyledExpansionPanel>
    );
  };

  return (
    <TransitionAnimation>
      <>
        {error && (
          <Typography.h5
            style={{
              margin: '25px',
            }}
          >
            {error}
          </Typography.h5>
        )}
        {!error && (
          <Typography.h5
            style={{
              margin: '25px',
            }}
          >
            Choose a WMS Layer.
          </Typography.h5>
        )}
        {wmsCapabilitiesQuery.isLoading && (
          <div>
            {new Array(5)
              .fill(<Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />)
              .map((skeleton, i) => (
                <Fragment key={i}>{skeleton}</Fragment>
              ))}
          </div>
        )}
        {availableLayers && (
          <div>
            {availableLayers.map((layerConfig: AddLayerConfig, index: number) =>
              renderLayerSelection(layerConfig, index)
            )}
          </div>
        )}
      </>
    </TransitionAnimation>
  );
};

export default SelectWMSLayer;
