import React, { MouseEventHandler } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { Button } from '@progress/kendo-react-buttons';
import { SVGIcon, SvgIcon } from '@progress/kendo-react-common';
import { useAppContext } from '../../context/app';
import { useUser } from '../../hooks/authentication';

interface Props {
  type?: 'submit' | 'button';
  label: string;
  loading?: boolean;
  size?: 'small' | 'medium' | 'large' | null;
  uppercase?: boolean;
  full?: boolean;
  height?: string;
  disabled?: boolean;
  className?: string;
  themeColor?:
    | 'primary'
    | 'base'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'dark'
    | 'light'
    | 'inverse';
  fillMode?: 'solid' | 'link' | 'outline' | 'flat';
  style?: any;
  onClick?: MouseEventHandler;
  icon?: SVGIcon;
  iconSize?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge' | null;
  xMargin?: 'mx-0' | 'mx-1' | 'mx-2' | 'mx-3' | 'mx-4';
  defaultFont?: boolean;
  buttonRef?: React.MutableRefObject<any>;
  subscriptionRequired?: boolean;
}

const SubmitButton: React.FC<Props> = ({
  type = 'submit',
  label,
  loading = false,
  size = 'medium',
  uppercase = true,
  full = true,
  height = null,
  disabled = false,
  className,
  themeColor = 'primary',
  fillMode = 'solid',
  style = {},
  onClick,
  icon,
  iconSize,
  xMargin,
  defaultFont,
  buttonRef,
  subscriptionRequired,
}) => {
  const { dispatch } = useAppContext();
  const { getOrgSubscriptionValid } = useUser();
  const buttonLabel = uppercase ? label.toUpperCase() : label;
  const fontSize = size === 'small' ? '0.75rem' : size === 'medium' ? '1rem' : '1.25rem';
  const padding = size === 'small' ? '0.25rem 0.5rem' : size === 'medium' ? '0.5rem 1rem' : '1rem 3rem';
  const fontWeight = size === 'small' ? 400 : size === 'medium' ? 500 : 600;
  const bottom = size === 'small' ? 0 : '-0.1rem';

  return (
    <Button
      ref={
        buttonRef
          ? (button) => {
              //console.log('Assigning ref:' + button?.element);
              buttonRef.current = button?.element;
            }
          : null
      }
      disabled={disabled || loading}
      type={type}
      onClick={(e) => {
        if (onClick) {
          if (!subscriptionRequired || getOrgSubscriptionValid()) onClick(e);
          else dispatch({ type: 'CHANGE_SHOW_UNSBSCRIBED_DIALOG', payload: { show: true } });
        }
      }}
      themeColor={themeColor}
      fillMode={fillMode}
      className={`${className ? className : ''}${full ? ' w-100' : ''} ${xMargin ? xMargin : 'mx-3'} ${
        defaultFont ? '' : 'font-exo'
      }`}
      style={{
        ...{ fontSize: fontSize, padding: padding, fontWeight: fontWeight, height: height ? height : 'inherit' },
        ...style,
      }}
      size={size}
      startIcon={icon ? <SvgIcon icon={icon} size={iconSize ? iconSize : 'xxlarge'}></SvgIcon> : null}
      //svgIcon={icon}
    >
      <div className="position-relative" style={{ bottom: bottom }}>
        <span style={{ visibility: loading ? 'hidden' : 'visible' }}>{buttonLabel}</span>
        {loading && (
          <span className="position-absolute top-0 start-0 text-center w-100">
            <Loader size="medium" type="pulsing" themeColor="light" />
          </span>
        )}
      </div>
    </Button>
  );
};

export default SubmitButton;
export { Props as SubmitButtonProps }; // Export the Props interface
