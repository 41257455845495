import { useMutation } from 'react-query';
import { useAxiosInstance } from '../common';

const useRunSapGroup = () => {
  const axios = useAxiosInstance();

  return useMutation(
    async (sapGroupId: string) => {
      return axios.post('/file_sapgroup/' + sapGroupId + '/run_async');
    },
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(['transactions'], { refetchInactive: true });
      },
    }
  );
};

export default useRunSapGroup;
