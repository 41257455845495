import React, { useEffect } from 'react';
import { useDraggable, NormalizedDragEvent } from '@progress/kendo-react-common';
import { SvgIcon } from '@progress/kendo-react-common';
import { xIcon } from '@progress/kendo-svg-icons';

interface DraggableWindowProps {
  visible: boolean;
  onClose: any;
  children: JSX.Element | JSX.Element[] | null;
  style?: React.CSSProperties;
  boundaryElement?: HTMLElement; // Add a new prop for the boundary element
  title: string | JSX.Element | JSX.Element[];
  initialPosition?: { x: number; y: number };
}

const DraggableWindow: React.FC<DraggableWindowProps> = (props: DraggableWindowProps) => {
  const [position, setPosition] = React.useState({
    x: props.initialPosition ? props.initialPosition.x : 240,
    y: props.initialPosition ? props.initialPosition.y : 480,
  });
  const [pressed, setPressed] = React.useState(false);
  const [dragged, setDragged] = React.useState(false);
  const [initial, setInitial] = React.useState<{ x: number; y: number } | null>(null);
  const titleBar = React.useRef(null);

  const handlePress = React.useCallback(() => {
    setPressed(true);
  }, []);

  const handleDragStart = React.useCallback(
    (event: NormalizedDragEvent) => {
      setDragged(true);
      setInitial({
        x: event.clientX - position.x,
        y: event.clientY - position.y,
      });
    },
    [position.x, position.y]
  );

  const handleDrag = React.useCallback(
    (event: NormalizedDragEvent) => {
      if (!initial) {
        return;
      }

      const newPosition = {
        x: event.clientX - initial.x,
        y: event.clientY - initial.y,
      };

      // Confining the window within the boundaries
      if (props.boundaryElement) {
        const boundingBox = props.boundaryElement.getBoundingClientRect();

        newPosition.x = Math.max(Math.min(newPosition.x, boundingBox.width - 300), 0);
        newPosition.y = Math.max(Math.min(newPosition.y, boundingBox.height - 30), 0);
      }

      setPosition(newPosition);

      event.originalEvent.preventDefault();
    },
    [initial, props.boundaryElement]
  );

  const handleDragEnd = React.useCallback(() => {
    setDragged(false);
    setInitial(null);
  }, []);
  const handleRelease = React.useCallback(() => {
    setPressed(false);
  }, []);

  useDraggable(titleBar, {
    onPress: handlePress,
    onDragStart: handleDragStart,
    onDrag: handleDrag,
    onDragEnd: handleDragEnd,
    onRelease: handleRelease,
  });

  const renderTitleBar = () => {
    return (
      <div
        ref={titleBar}
        id="profile_titlebar"
        className="pv-titlebar"
        style={{
          display: 'flex',
          height: '30px',
          width: '100%',
          boxSizing: 'border-box',
          cursor: 'grab',
          position: 'relative',
          paddingLeft: '0.5rem',
          paddingRight: '1.75rem',
        }}
      >
        {typeof props.title === 'string' ? <span style={{ paddingRight: '10px' }}>{props.title}</span> : props.title}
        <SvgIcon
          icon={xIcon}
          style={{
            position: 'absolute',
            right: '0.25rem',
            fontSize: '1.2rem',
            cursor: 'pointer',
            top: 0,
            bottom: 0,
            margin: 'auto 0',
          }}
          onClick={props.onClose}
        />
      </div>
    );
  };

  return (
    <div
      id="potree_window"
      style={{
        ...{
          position: 'absolute',
          width: '300px',
          left: position.x,
          top: position.y,
          margin: '5px',
          border: '1px solid black',
          boxSizing: 'border-box',
          zIndex: 10000,
          display: props.visible ? 'block' : 'none',
        },
        ...props.style,
      }}
    >
      {renderTitleBar()}
      <div
        style={{
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default DraggableWindow;
