export const ACCOUNT_OVERVIEW = 'Overview';
export const ACCOUNT_USER = 'User Settings';
export const ACCOUNT_ORGANIZATION = 'Organization Settings';
export const ACCOUNT_GEOSAP = 'GeoSAP Settings';
export const ACCOUNT_NOTIFICATIONS = 'Notification';
export const ACCOUNT_PLANS = 'Plans & Billing';
export const SettingsPaths = {
  [ACCOUNT_OVERVIEW]: '/settings/',
  [ACCOUNT_USER]: '/settings/user',
  [ACCOUNT_ORGANIZATION]: '/settings/organization',
  [ACCOUNT_GEOSAP]: '/settings/geosap',
  [ACCOUNT_NOTIFICATIONS]: '/settings/notifications',
  [ACCOUNT_PLANS]: '/settings/plans',
};
export { default as SettingsContainer } from './SettingsContainer';
export { default as SettingsOverview } from './Overview';
export { default as UserSettings } from './UserSettings';
export { default as OrganizationSettings } from './OrganizationSettings/OrganizationSettings';
export { default as GeoSAPSettings } from './GeoSAPSettings';
export { default as NotificationSettings } from './NotificationsSettings';
export { default as PlansBillingSettings } from './PlansBilling';
