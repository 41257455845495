import { useMutation } from 'react-query';
import { useAxiosInstance } from '../../common';

const endpoint = 'file_manager/reuse_files';

interface Params {
  target_item: string;
  file_tuples: {file_name: string, item_id: string}[];
}

const useReuseFiles = () => {
  const axios = useAxiosInstance();
  //const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post<any>(endpoint, params);
      return response.data;
    },
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(['sapFlowParams', variables.sapflowid], { refetchInactive: true });
      },
    }
  );
};

export default useReuseFiles;
