import { useQuery } from 'react-query';
import { FileNode } from '../../types/DataDelivery';
import { useAxiosInstance } from '../common';

const useFilenodeStructure = (rootFileNodeId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['fileNodeStructure', rootFileNodeId], async () => {
    if(rootFileNodeId === "00000000-0000-0000-0000-000000000000") {
      return null;
    }
    const result = await axios.get<FileNode>("filenode/" + rootFileNodeId);
    return result.data;
  });
};

export default useFilenodeStructure;
