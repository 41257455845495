import { TreeSapFlow, TreeSapGroup, TreeSapProcessNode } from "../types";

export function sortSelectedSapItems(selectedSapItems: (TreeSapGroup | TreeSapFlow)[]): (TreeSapGroup | TreeSapFlow)[] {
    return selectedSapItems.sort((a, b) => {
        const parseDate = (item: (TreeSapGroup | TreeSapFlow)): Date => {
            if (item.type === "TreeSapGroup") {
                // Parse MMDDYYYY format
                const match = item.name.match(/^(\d{2})(\d{2})(\d{4})$/);
                if (match) {
                    return new Date(`${match[3]}-${match[1]}-${match[2]}`);
                }
            } else if (item.type === "TreeSapFlow") {
                // Parse YYMMDD_HHMMSS format
                const match = item.name.match(/^(\d{2})(\d{2})(\d{2})_(\d{2})(\d{2})(\d{2})$/);
                if (match) {
                    return new Date(`20${match[1]}-${match[2]}-${match[3]}T${match[4]}:${match[5]}:${match[6]}`);
                }
            }
            return null; // If parsing fails
        };

        const dateA = parseDate(a);
        const dateB = parseDate(b);

        if (dateA && dateB) {
            return dateA.getTime() - dateB.getTime();
        } else if (dateA) {
            return -1;
        } else if (dateB) {
            return 1;
        } else {
            // Fall back to regular string comparison if date parsing fails
            return a.name.localeCompare(b.name);
        }
    });
}

export function extractTreeBranchFromItemPath(
    treeData: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[],
    path: string[]
): (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[] | null {
    let currentNodes = treeData;
    const nodePath: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[] = [];

    for (const index of path) {
        const node = currentNodes[parseInt(index, 10)];
        if (!node) return null; // Invalid path
        nodePath.push(node);
        currentNodes = node.items;
    }
    return nodePath;
}

export function treeDataContainsTreeBranch(
    treeData: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[],
    treeBranch: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[]
): boolean {

    for(const node of treeBranch) { 
        const index = treeData.findIndex((treeNode) => treeNode.id === node.id);
        if (index === -1) return false;
        treeData = treeData[index].items;
    }

    return true;
}

// Helper function to check if all children are selected
export function areAllChildrenSelected (node: { groupSelected: boolean; items: any; }): boolean {
    return node.items.every((child: { groupSelected: boolean; }) => child.groupSelected === true);
};

// Helper function to check if all children are unselected
export function areAllChildrenUnselected (node: { groupSelected: boolean; items: any; }): boolean {
    return node.items.every((child: { groupSelected: boolean; }) => child.groupSelected === false);
};

export function getSelectedBranches (tree: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[]): (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[] {
    // Recursive function to filter selected branches
    const filterSelected = (nodes: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[]): (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[] => {
        return nodes.reduce((acc: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[], node) => {
            // Recursively filter the child nodes
            const selectedChildren = node.items ? filterSelected(node.items) : [];

            // Include this node if it is selected, partially selected, or has selected children
            if (node.groupSelected || selectedChildren.length > 0) {
                acc.push({
                    ...node,
                    items: selectedChildren as any // Attach filtered children
                });
            }

            return acc;
        }, []);
    };

    return filterSelected(tree);
};

export function removeTreeSapProcessNodes (tree: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[]): (TreeSapGroup | TreeSapFlow)[]{
    // Recursive function to filter out "TreeSapProcess" nodes
    const filterNodes = (nodes: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[]): (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[] => {
        return nodes.reduce((acc: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[], node) => {
            // If the node is of type "TreeSapProcess", skip it
            if (node.type === "TreeSapProcessNode") {
                return acc;
            }

            // Otherwise, recursively filter its children
            const filteredChildren = filterNodes(node.items);

            // Add the node to the accumulator with filtered children
            acc.push({
                ...node,
                items: filteredChildren as any
            });

            return acc;
        }, []);
    };

    return filterNodes(tree) as (TreeSapGroup | TreeSapFlow)[];
};



export function extractFirstAndLastLeaves (tree: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[]): (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[] {
    let firstLeaf: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode) | null = null;
    let lastLeaf: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode) | null = null;

    const traverseTree = (nodes: (TreeSapGroup | TreeSapFlow | TreeSapProcessNode)[]) => {
        for (const node of nodes) {
            if (node.items.length === 0) {
                // It's a leaf node
                if (!firstLeaf) {
                    firstLeaf = node; // Set first leaf
                }
                lastLeaf = node; // Keep updating the last leaf
            } else {
                // Traverse deeper into the tree
                traverseTree(node.items);
            }
        }
    };

    traverseTree(tree);

    return  [firstLeaf, lastLeaf];
};