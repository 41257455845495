import React, { useEffect } from 'react';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement, FieldRenderProps } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { SubmitButton } from '../../../components/form';
import { Organization } from '../../../types';
import { useInviteOrganizationMember } from '../../../hooks/user_management';
import Dialog from '../../../components/Dialog';
import { useAppContext } from '../../../context/app';

interface DialogProps {
  organization: Organization;
  show: boolean;
  onClose: any;
}

const AddUserDialog: React.FC<DialogProps> = (props: DialogProps) => {
  const inviteMemberMutation = useInviteOrganizationMember();
  const { dispatch } = useAppContext();
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  const emailValidator = (value: string) => (emailRegex.test(value) ? '' : 'Please enter a valid email.');
  const EmailInput = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <Input {...others} />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  };

  const handleSubmit = (values: { [name: string]: any }) => {
    inviteMemberMutation.mutateAsync({ organizationId: props.organization.id, email: values.email, resend: false });
  };

  useEffect(() => {
    if (inviteMemberMutation.isSuccess) {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'New user invited to the organization!', type: 'success' } },
      });
      props.onClose();
    }
  }, [inviteMemberMutation.isSuccess]);

  return (
    <Dialog
      title={`${props.organization.name} collaborators`}
      onClose={props.onClose}
      show={props.show}
      style={{ width: '50%' }}
    >
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement
            style={{
              maxWidth: 650,
            }}
          >
            <fieldset className={'k-form-fieldset'}>
              <legend className={'k-form-legend'}>Invite new users to your organization:</legend>
              <div className="mb-3">
                <Field
                  name={'email'}
                  type={'email'}
                  component={EmailInput}
                  label={'Email'}
                  validator={emailValidator}
                />
              </div>
            </fieldset>
            <DialogActionsBar>
              <SubmitButton
                label="Cancel"
                onClick={props.onClose}
                themeColor="base"
                fillMode="outline"
                loading={inviteMemberMutation.isLoading}
              />
              <SubmitButton
                label="Send Invite"
                themeColor="primary"
                type="submit"
                loading={inviteMemberMutation.isLoading}
              />
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default AddUserDialog;
