import { useQuery } from 'react-query';
import { StripeProduct } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = '/stripe/products';

const useStripeProducts = () => {
    const axios = useAxiosInstance();
    return useQuery(['stripeProducts'], async () => {
      const result = await axios.get<StripeProduct[]>(endpoint);
      return result.data;
    });
};

export default useStripeProducts;
