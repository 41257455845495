import { useQuery } from 'react-query';
import axios from 'axios';
import {XMLParser} from 'fast-xml-parser';
import WFS from 'ol/format/WFS.js';
const baseUrl = 'http://localhost:8080/geoserver/ows';
const service = 'wfs';
const request = 'GetCapabilities';

const featureReader: any = (xmlString: string) => {
    const parser_non_native = new XMLParser();
    const xmlParsed = parser_non_native.parse(xmlString);
    const mainEntry = xmlParsed['wfs:WFS_Capabilities']
    const ServiceIdentifications = mainEntry['ows:ServiceIdentification'];
    const ServiceVersion = ServiceIdentifications['ows:ServiceTypeVersion'];
    const FeatureTypeList = mainEntry['FeatureTypeList']
  
    // Loop over
    const features = FeatureTypeList.FeatureType.map((layer:any) => {
      const defaultCrs = layer['DefaultCRS'];
      const splittedDefaultCRS = defaultCrs.split(':');
      const projection = splittedDefaultCRS[splittedDefaultCRS.length - 3] + ":" + splittedDefaultCRS[splittedDefaultCRS.length - 1];
      return{Name: layer['Name'], Title: layer['Title'], Abstract: layer['Abstract'], Projection: "EPSG:4326", BoundingBox: [...layer['ows:WGS84BoundingBox']['ows:LowerCorner'].split(' '), ...layer['ows:WGS84BoundingBox']['ows:UpperCorner'].split(' ')].map(parseFloat)};
    })
    return {version: ServiceVersion, Features: features};
}

const useWfsCapabilities = (wfsUrl: string) => {
  const url = wfsUrl + '?SERVICE=' + service + /*'&version=' + version + */'&request=' + request;
  return useQuery(['getCapabilities', service, wfsUrl], async () => {
    const result = await axios.get<any>(url);
    const capabilities = featureReader(result.data);
    // The data fetched can be very voluminous because contains all the possible projections.
    // Use the following lines to be able to log and manipulate the data
    //capabilities.Capability.Layer.CRS = null;
    //capabilities.Capability.Layer.Layer.forEach((layer: any) => {layer.CRS = null;})
    //console.log("Capabilities: " + JSON.stringify(capabilities));
    return capabilities;
  });
};

export default useWfsCapabilities;