import { QueryClient } from 'react-query';
import {
    WSMessage,
  } from '../../../types';
import { Annotation, AnnotationAddedDTO } from '../../../types/DataDelivery/Annotation';
import { FileNode } from '../../../types/DataDelivery';
import { findFileNodeRecursively } from '../../../common/fileNodeHelper';

const handleMessage = (wsMessage: WSMessage, queryClient: QueryClient) => {
    const newAnnotationDTO: AnnotationAddedDTO = wsMessage.payload;
    const newAnnotation: Annotation = {
      id: newAnnotationDTO.annotationId,
      filenode: newAnnotationDTO.fileNodeId,
      user: {
        id: newAnnotationDTO.userId,
        firstName: newAnnotationDTO.userFirstName,
        lastName: newAnnotationDTO.userLastName,
        url: null,
      },
      type: newAnnotationDTO.type,
      reviewStatus: newAnnotationDTO.reviewStatus,
      message: newAnnotationDTO.message,
      createdDate: newAnnotationDTO.createdDate
    }
    const annotations: Annotation[] = queryClient.getQueryData(['fileNodeAnnotations', newAnnotation.filenode]);
    if(annotations && annotations.findIndex((ann: Annotation)=>{return ann.id === newAnnotation.id;}) < 0) {
      const newAnnotations = [...annotations, newAnnotation];
      queryClient.setQueryData(['fileNodeAnnotations', newAnnotation.filenode], newAnnotations);
    }
    // Also, if the annotation is a Review Status change, affect the filenode's status.
    const rootFileNode: FileNode = queryClient.getQueryData(['rootStructure']);
    if (rootFileNode) {
      const fileNodeToUpdate: FileNode = findFileNodeRecursively(rootFileNode, newAnnotationDTO.fileNodeId);
      if (fileNodeToUpdate) {
        fileNodeToUpdate.reviewStatus = newAnnotationDTO.reviewStatus;
        queryClient.setQueryData(['rootStructure'], rootFileNode);
      }
    }
    if (queryClient.getQueryData(['fileNodeStructure', newAnnotationDTO.rootFileNodeId])) {
      const rootFileNode2: FileNode = JSON.parse(
        JSON.stringify(queryClient.getQueryData(['fileNodeStructure', newAnnotationDTO.rootFileNodeId]))
      );
      if (rootFileNode2) {
        const fileNodeToUpdate: FileNode = findFileNodeRecursively(rootFileNode2, newAnnotationDTO.fileNodeId);
        if (fileNodeToUpdate) {
          fileNodeToUpdate.reviewStatus = newAnnotationDTO.reviewStatus;
          queryClient.setQueryData(['fileNodeStructure', newAnnotationDTO.rootFileNodeId], rootFileNode2);
        }
      }
    }
}

export default handleMessage;