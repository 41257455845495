import { useContext, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import MapContext from '../Map/MapContext';
import OLTileLayer from 'ol/layer/WebGLTile';
import { register } from 'ol/proj/proj4.js';
import { fetchProj4 } from '../../../../common/proj4Helper';
import View, { ViewOptions } from 'ol/View';
import { transformExtent } from 'ol/proj.js';

const proj4 = window.proj4 || require('proj4');

interface Props {
  show: boolean;
  source: any;
  zIndex: number;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
} | null;
const WebGLTileLayer = forwardRef<Ref, Props>((props, ref) => {
  const { map } = useContext<any>(MapContext);
  const [tileLayer, setTileLayer] = useState(null);

  useEffect(() => {
    if (!map) return;

    // Validate we have a proj4 definition for that
    props.source.on('change', (ev: any) => {
      const projection = props.source.getProjection();
      const code = projection.getCode();
      if (projection && code) {
        let codeDefined = false;
        try {
          proj4(code);
          codeDefined = true;
        } catch (e) {
          console.log();
        }
        if (!codeDefined) {
          fetchProj4(code.split(':')[1])
            .then((projString) => {
              proj4.defs(code, projString);
              register(proj4);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    });
    const newTileLayer = new OLTileLayer({
      source: props.source,
      zIndex: props.zIndex,
    });

    newTileLayer.setVisible(props.show);

    map.addLayer(newTileLayer);
    newTileLayer.setZIndex(props.zIndex);
    setTileLayer(newTileLayer);

    return () => {
      if (map) {
        map.removeLayer(newTileLayer);
      }
    };
  }, [map]);

  useEffect(() => {
    if (!map || !tileLayer) return;
    tileLayer.setVisible(props.show);
  }, [props.show]);

  const zoomToLayer = (layer: any) => {
    const sourceView: Promise<ViewOptions> = props.source.getView();
    sourceView.then((viewOptions: ViewOptions) => {
      const view = new View(viewOptions);
      let extent = view.calculateExtent();
      const projection = view.getProjection();
      if (projection.getCode() !== map.getView().getProjection().getCode()) {
        extent = transformExtent(extent, projection.getCode(), map.getView().getProjection().getCode());
      }
      map.getView().fit(extent);
    });
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
  }));

  return null;
});

WebGLTileLayer.displayName = 'WebGLTileLayer';

export default WebGLTileLayer;
