import React, { Fragment, useState, useEffect } from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Dialog from '../../../components/Dialog';
import { REQUIRED_FIELD } from '../../../common/constants';
import { Input, SubmitButton, ToggleableButton } from '../../../components/form';
import { useTypes, useCategories, useCreateConfigWithBuilder } from '../../../hooks/sapflow';
import { useUser } from '../../../hooks/authentication';
import { Category, SapFlowType } from '../../../types';

interface Props {
  show: boolean;
  handleClose: any;
  defaultValue: string;
  onConfirm: any;
  nodes: any,
  edges: any
}

interface FormValues {
  name: string;
  description: string;
  categories: string[];
  types: string[];
}

const getValidationSchema = () =>
  yup.object({
    name: yup.string().required(REQUIRED_FIELD),
    description: yup.string().required(REQUIRED_FIELD),
    categories: undefined,
    types: undefined,
});

const ConfigDetailsModal: React.FC<Props> = (props: Props) => {
  const { getUser } = useUser();
  const navigate = useNavigate();
  const { handleSubmit, control, formState, reset, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      name: '',
      description: '',
      categories: [],
      types: []
    },
    resolver: yupResolver(getValidationSchema()),
  });
  const typesQuery = useTypes();
  const categoriesQuery = useCategories();
  const createConfigMutation = useCreateConfigWithBuilder();
  const selectedTypes = watch('types');
  const selectedCategories = watch('categories');

  const handleToggleType = (type: SapFlowType) => {
    if (selectedTypes.includes(type.name)) {
        setValue('types', selectedTypes.filter((item) => item !== type.name));
    } else {
        setValue('types', [...selectedTypes, type.name]);
    }
  };

  const handleToggleCategory = (category: Category) => {
    if (selectedCategories.includes(category.name)) {
        setValue('categories', selectedCategories.filter((item) => item !== category.name));
    } else {
        setValue('categories', [...selectedCategories, category.name]);
    }
  };

  const handleCreateConfig = async ({name, description, categories, types}: FormValues) => {
    const nodesToReturn = props.nodes.map((node: any)=>{
        const params: any = {};
        node.data.block.defaultparams.forEach((kvPair: any)=>{ 
          if(!kvPair.value) {
            params[kvPair.key] = null;
          } 
          else if(kvPair.value && kvPair.value.length === 1) {
            params[kvPair.key] = kvPair.value[0];
          }
          else {
            params[kvPair.key] = JSON.stringify(kvPair.value);
          }
        });
        return {
          id: node.id,
          sapname: node.data.block.sapname,
          toolblock: node.data.block.toolblock,
          inputs: node.data.block.inputs,
          outputs: node.data.block.outputs,
          params: params//node.data.block.defaultparams,
        }}
      );
    const edgesToReturn = props.edges.map((edge: any)=>{return {...edge, ...{sourceItem: edge.sourceHandle, targetItem: edge.targetHandle}}});
    const data = {
      userid: getUser().id,
      name: name,
      description: description,
      categories: categories,
      types: types,
      nodes: nodesToReturn,
      edges: edgesToReturn
    }
    const config = await createConfigMutation.mutateAsync(data);
    props.handleClose();
    navigate('/sapflow', { state: { config: config } });
  }

  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>Config Details</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
      style={{ display: 'flex', flexDirection: 'column', width: "75%" }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <form onSubmit={handleSubmit(handleCreateConfig)}>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <Input {...field} className='w-75 mx-auto' error={error?.message} type="text" placeholder="Config Name" autoFocus />
            )}
          />
          <Controller
            control={control}
            name="description"
            render={({ field, fieldState: { error } }) => (
              <Input {...field} className='w-75 mx-auto' error={error?.message} type="text" placeholder="Config Description" />
            )}
          />
          <Typography.h3 fontWeight="light" textAlign="center">
            Select 1 or more categories
          </Typography.h3>
          <div className="w-75 m-auto mt-4 d-flex flex-wrap justify-content-center">
            {(typesQuery.isLoading) && (
              <div>
                {new Array(5)
                  .fill(<Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />)
                  .map((skeleton, i) => (
                    <Fragment key={i}>{skeleton}</Fragment>
                  ))}
              </div>
            )}
            {typesQuery.isSuccess && typesQuery.data.length > 0 &&
                typesQuery.data.map((item, index) => {
                    return <ToggleableButton key={item.id} label={item.name} selected={selectedTypes.includes(item.name)} className="m-2" onClick={() => handleToggleType(item)}></ToggleableButton>
                })
            }
          </div>
          <Typography.h3 fontWeight="light" textAlign="center">
            Select 1 or more categories
          </Typography.h3>
          <div className="w-75 m-auto mt-4 d-flex flex-wrap justify-content-center">
            {(categoriesQuery.isLoading) && (
              <div>
                {new Array(5)
                  .fill(<Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />)
                  .map((skeleton, i) => (
                    <Fragment key={i}>{skeleton}</Fragment>
                  ))}
              </div>
            )}
            {categoriesQuery.isSuccess && categoriesQuery.data.length > 0 &&
                categoriesQuery.data.map((item, index) => {
                    return <ToggleableButton key={item.id} label={item.name} selected={selectedCategories.includes(item.name)} className="m-2" onClick={() => handleToggleCategory(item)}></ToggleableButton>
                })
            }
          </div>
        <SubmitButton
          type="submit"
          label="Submit"
          uppercase={false}
          full={true}
          disabled={selectedCategories.length === 0 || selectedTypes.length === 0}
          loading={createConfigMutation.isLoading}
          className="mt-2"
        />
        </form>
      </div>
    </Dialog>
  );
};

export default ConfigDetailsModal;
