import React, { useEffect, useState } from 'react';
import {
  AddLayerWizardProvider,
  useCreateAddLayerWizardState,
  AddLayerWizardStep,
  AddLayerWizardProviderValue,
} from '../../../../context/addLayerWizard';
import SelectLayerType from './SelectLayerType';
import SubmitUrl from './SubmitUrl';
import SelectWMSLayer from './SelectWMSLayer';
import SelectWFSLayer from './SelectWFSLayer';
import SelectGeotif from './SelectGeotif';
import SelectShapefile from './SelectShapefile';
import SelectLas from './SelectLas';
import SelectProjectData from './SelectProjectData';
import UnsupportedLayer from './UnsupportedLayer';
import { SvgIcon } from '@progress/kendo-react-common';
import { chevronLeftIcon } from '@progress/kendo-svg-icons';

interface Props {
  viewerConfigId: string;
  wizardState: AddLayerWizardProviderValue;
  onDone: () => void;
  onClose: () => void;
}

const AddLayerWizard: React.FC<Props> = ({ viewerConfigId, wizardState, onDone, onClose }) => {
  const { step, dispatch, done } = wizardState;

  useEffect(() => {
    if (done) {
      onDone();
    }
  }, [done]);

  return (
    <AddLayerWizardProvider value={wizardState}>
      <div className="d-flex flex-column h-100 position-relative">
        {step > 0 && (
          <div
            style={{ position: 'absolute', top: 0, left: 0, cursor: 'pointer', zIndex: 1000 }}
            onClick={() => {
              dispatch({ type: 'GO_BACK', payload: {} });
            }}
          >
            <SvgIcon icon={chevronLeftIcon} size="large"></SvgIcon>
          </div>
        )}
        <div className="container">
          <div className="row align-items-center justify-content-center">
            {
              {
                [AddLayerWizardStep.SELECT_TYPE]: <SelectLayerType />,
                [AddLayerWizardStep.SUBMIT_URL]: <SubmitUrl />,
                [AddLayerWizardStep.SELECT_WMS_LAYER]: <SelectWMSLayer />,
                [AddLayerWizardStep.SELECT_WFS_LAYER]: <SelectWFSLayer />,
                [AddLayerWizardStep.SELECT_GEOTIF]: <SelectGeotif />,
                [AddLayerWizardStep.SELECT_SHAPEFILE]: <SelectShapefile />,
                [AddLayerWizardStep.SELECT_LAS]: <SelectLas />,
                [AddLayerWizardStep.SELECT_PROJECTDATA]: <SelectProjectData />,
                [AddLayerWizardStep.UNSUPPORTED]: <UnsupportedLayer />,
              }[step]
            }
          </div>
        </div>
      </div>
    </AddLayerWizardProvider>
  );
};

export default AddLayerWizard;
