import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Typography } from '@progress/kendo-react-common';
import styled from 'styled-components';
import { Category } from '../../types';
import { Input, SubmitButton } from '../../components/form';
import { REQUIRED_FIELD, VALID_EMAIL_FIELD, VALID_PASSWORD_FIELD } from '../../common/constants';
import { useConsumeRegisterWizardState } from '../../context/registerWizard';
import { useValidateEmail } from '../../hooks/authentication';
import TransitionAnimation from '../../components/TransitionAnimation';

const BrandImage = styled.img`
  display: block;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
`;

declare const window: any;

interface FormValues {
  email: string;
  password: string;
  passwordConfirmation: string;
}

const validationSchema = yup.object({
  email: yup.string().required(REQUIRED_FIELD).email(VALID_EMAIL_FIELD),
  password: yup
    .string()
    .required(REQUIRED_FIELD)
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/, VALID_PASSWORD_FIELD),
  passwordConfirmation: yup
    .string()
    .test('passwords-match', "Password confirmation doesn't match", (value, { parent }) => value === parent.password),
});

const CredentialsStep: React.FC = () => {
  const { dispatch, email, password, organizationToJoin } = useConsumeRegisterWizardState();
  const { handleSubmit, control, formState, reset } = useForm<FormValues>({
    defaultValues: {
      email: email ? email : '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const useValidateEmailMutation = useValidateEmail();

  const selectCredentials = ({ email: formEmail, password: formPassword }: FormValues) => {
    useValidateEmailMutation.mutate(
      { email: formEmail },
      {
        onSuccess: (data, variables, context) => {
          //window.dataLayer.push({
          //  event: 'Register',
          //  step: '0',
          //  stepName: 'Enter Credentials',
          //  email: email,
          //});
          dispatch({
            type: 'SELECT_CREDENTIALS',
            payload: { email: formEmail ? formEmail : '', password: formPassword ? formPassword : '' },
          });
        },
      }
    );
  };

  useEffect(() => {
    reset({
      email: email ? email : '',
    });
  }, [email]);

  return (
    <TransitionAnimation>
      <div className="row align-items-center justify-content-center">
        <div className="col-xs-12 col-lg-6 col-xl-4">
          <BrandImage src="/assets/brand-black.png" alt="GeoSAP brand" className="p-3 pb-4" />
          <Typography.p fontSize="large" textAlign="center" className="px-4 py-2">
            Sign up to start getting more from your geospatial data.
          </Typography.p>
          <form onSubmit={handleSubmit(selectCredentials)}>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder="Email Address"
                  maxLength={50}
                  disabled={organizationToJoin !== null}
                  error={error?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input {...field} type="password" placeholder="Password" maxLength={40} error={error?.message} />
              )}
            />
            <Controller
              name="passwordConfirmation"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  type="password"
                  placeholder="Confirm Password"
                  maxLength={40}
                  error={error?.message}
                />
              )}
            />
            <Typography.p textAlign="center" className="py-2 mb-0" style={{ color: 'var(--geosap-secondary-color)' }}>
              {VALID_PASSWORD_FIELD}
            </Typography.p>
            <div className="pt-3">
              <SubmitButton label="Sign up" disabled={!formState.isDirty} xMargin="mx-0" />
            </div>
          </form>
          <Typography.p textAlign="center" className="py-2">
            {'Have an account? '}
            <Link to="/login">Log in</Link>
          </Typography.p>
        </div>
      </div>
    </TransitionAnimation>
  );
};

export default CredentialsStep;
