import { Organization } from "../../types";

export enum RegisterWizardStep {
  CREDENTIAL_INFOS = 0,
  ACCOUNT_SETTINGS = 1
}

export interface RegisterWizardState {
  step: RegisterWizardStep;
  email: string | null;
  password: string | null;
  accountType: "Individual" | "Business";
  organizationToJoin: Organization | null;
  organizationName: string | null;
  firstName: string | null;
  lastName: string | null;
  jobTitle: string | null;
  industry: string | null;
  location: string | null;
}

export const initialState: RegisterWizardState = {
  step: RegisterWizardStep.CREDENTIAL_INFOS,
  email: null,
  password: null,
  accountType: "Individual",
  organizationName: null,
  organizationToJoin: null,
  firstName: null,
  lastName: null,
  jobTitle: null,
  industry: null,
  location: null,
};
