import React from 'react';
import { Link } from 'react-router-dom';
import { AuthorityType, AuthorityLevel } from '../../types';
import { useUser } from '../../hooks/authentication';

interface Props extends React.ComponentProps<typeof Link> {
  authorityType: AuthorityType;
  authorityLevel: AuthorityLevel;
}

const RestrictedLink: React.FC<Props> = (props) => {
  const { userHasAuthority } = useUser();

  // Destructuring to separate custom props and Link element props
  const { authorityType, authorityLevel, children, ...linkProps } = props;

  if (!userHasAuthority(authorityType, authorityLevel)) {
    return null;
  }

  return <Link {...linkProps}>{children}</Link>;
};

export default RestrictedLink;
