import { QueryClient } from 'react-query';
import { SapFlowProcessNodeStatus, SapFlowTransaction, WSMessage } from '../../../types';
import { SapFlow, SapFlowGroup, SapFlowProcessNode, SapFlowStatus } from '../../../types/DataDelivery';

const handleMessage = (wsMessage: WSMessage, queryClient: QueryClient) => {
  const processNodeStatus: SapFlowProcessNodeStatus = wsMessage.payload;
  if (processNodeStatus !== null && processNodeStatus.transactionID) {
    let groupsData: SapFlowGroup[] | undefined = null;
    try {
      groupsData = JSON.parse(JSON.stringify(queryClient.getQueryData(['sapGroups'])));
    } catch (e) {
      return;
    }
    const findAndModifyGroupSapflow = (group: SapFlowGroup) => {
      if (group.sapflows && group.sapflows.length > 0) {
        group.sapflows.forEach((sapflow: SapFlow) => {
          if (sapflow.id === processNodeStatus.transactionID) {
            let procIndex = -1;
            for (let i = 0; i < sapflow.processnodes.length; i++) {
              if (sapflow.processnodes[i].id === processNodeStatus.processNodeID) {
                procIndex = i;
                break;
              }
            }
            if (procIndex >= 0) {
              const matchingProcessNode: SapFlowProcessNode = sapflow.processnodes[procIndex];
              // Validate the status isn't already in the history collection
              if (
                matchingProcessNode.processNodeStatusHistory.filter((procStatus) => {
                  procStatus.id === processNodeStatus.id;
                }).length === 0
              ) {
                matchingProcessNode.processNodeStatusHistory.push(processNodeStatus);
                matchingProcessNode.percentComplete = processNodeStatus.currentProcessPercentComplete;
                matchingProcessNode.processRuntime = processNodeStatus.currentProcessRuntime;
                matchingProcessNode.status = processNodeStatus.currentProcessStatus;
              } else {
                console.log('Status id found in ProcessNode history');
              }
              sapflow.processnodes[procIndex] = matchingProcessNode;
            } else {
              console.log('Matching ProcessNode not found');
            }
          }
        });
      }
      if (group.groups && group.groups.length > 0) {
        group.groups.forEach((group) => {
          findAndModifyGroupSapflow(group);
        });
      }
    };
    groupsData.forEach((group: SapFlowGroup) => {
      findAndModifyGroupSapflow(group);
    });

    queryClient.setQueryData(['sapGroups'], groupsData);
  } else {
    console.log('Invalid Proc Status update');
  }
};

export default handleMessage;
