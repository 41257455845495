import { useQuery, useQueryClient } from 'react-query';
import { SapFlowViewConfig, LayerType, ViewLayer, ViewLayerParam, ViewLayerState } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'viewer_config';

const useFileNodeView = (fileNodeId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useQuery(['fileNodeView', fileNodeId], async () => {
    // return dummyData2;
    const params = {
      filenode_id: fileNodeId,
      refresh: false,
    };
    const result = await axios.get<SapFlowViewConfig>(endpoint, { params });
    const viewConfig = result.data;
    //if (viewConfig.cameraPosition) {
    //  viewConfig.cameraPosition = JSON.parse(viewConfig.cameraPosition);
    //}
    //if (viewConfig.cameraTarget) {
    //  viewConfig.cameraTarget = JSON.parse(viewConfig.cameraTarget);
    //}
    viewConfig.layers.forEach((layer: ViewLayer) => {
      if (layer.params) {
        layer.paramsMap = {};
        layer.params.forEach((param: ViewLayerParam) => {
          try {
            layer.paramsMap[param.key] = JSON.parse(param.value);
          } catch (e) {
            layer.paramsMap[param.key] = param.value;
          }
        });
        layer.viewState = ViewLayerState.READY;
      }
    });
    
    viewConfig.paramsMap = {};
    viewConfig.params?.forEach((param: ViewLayerParam) => {
      try {
        viewConfig.paramsMap[param.key] = JSON.parse(param.value);
      } catch (e) {
        viewConfig.paramsMap[param.key] = param.value;
      }
    });
    queryClient.setQueryData(['viewerConfig', viewConfig.id], viewConfig);
    return viewConfig;
    //return dummyData2;
  });
};

export default useFileNodeView;
