import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Typography } from '@progress/kendo-react-common';
import { useSapFlowBuildingBlocks } from '../../../hooks/sapflow';
import { Reveal } from '@progress/kendo-react-animation';
import { BuilderBlockDetails, LayerType } from '../../../types';
import { SubmitButton } from '../../../components/form';
import { truncateText } from '../../../common/stringHelper';

const StyledDiv = styled.div`
  padding: 8px 12px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
`;

interface BuilderNodeMenuProps {
  opened: boolean;
  projectId: string;
  transactionId: string;
}

const BuilderNodeMenu: React.FC<BuilderNodeMenuProps> = (props: BuilderNodeMenuProps) => {
  const [selectedBlock, setSelectedBlock] = React.useState<BuilderBlockDetails | null>(null);
  const buildingBlocksQuery = useSapFlowBuildingBlocks();
  
  const onDragStart = (event: any, block: BuilderBlockDetails) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify(block));
    event.dataTransfer.effectAllowed = 'move';
  };

  const renderDraggableBlock = (block: BuilderBlockDetails, index: number) => {
    return (
      <StyledDiv key={index} className='' onDragStart={(event) => onDragStart(event, block)} draggable>
        <Typography.h5>
          {block.sapname}
        </Typography.h5>
      </StyledDiv>
      );
  }
  
  return (
    <div className="d-flex flex-column position-absolute animated-left h-100" style={{ zIndex: 10, width: 'var(--geosap-builder-menu-width)', top: 0, overflowX: 'hidden', overflowY: 'scroll', borderRadius: '8px', border: '2px solid gray', padding: '8px' }}>
      <div
        className="d-flex px-3 py-2"
      >
        <Typography.h3 className="mb-2">
          Available SAP processes
        </Typography.h3>
      </div>
      {buildingBlocksQuery.isSuccess && buildingBlocksQuery.data.map((block: BuilderBlockDetails, index: number)=>renderDraggableBlock(block, index))}
    </div>
  );
};

export default BuilderNodeMenu;