import { useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../common';

interface Params {
    organizationId: string,
    userId: string,
}

const useOrganizationMemberRemove = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();

  return useMutation(async (params: Params) => { 
    return axios.post("/user_management/organization/" + params.organizationId + "/remove", {}, {params: {
        userId: params.userId
    }});
  },
  {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['organizationMembers', variables.organizationId], { refetchInactive: true });
    },
  });
};

export default useOrganizationMemberRemove;
