import React from 'react';
import BackgroundContainer from './BackgroundContainer';

interface Props {
  children: JSX.Element;
}

const CentralizedContent: React.FC<Props> = ({ children }) => {
  return (
    <BackgroundContainer>
      <div className="container">
        <div className="row align-items-center justify-content-center vh-100">{children}</div>
      </div>
    </BackgroundContainer>
  );
};

export default CentralizedContent;
