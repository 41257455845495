import { useQuery } from 'react-query';
import { SapFlowProject } from '../../../types';
import { useAxiosInstance } from '../../common/';

const endpoint = 'project';

const useProjects = () => {
  const axios = useAxiosInstance();
  return useQuery(
    ['projects'],
    async () => {
      const result = await axios.get<SapFlowProject[]>(endpoint);
      return result.data;
    },
    { retry: false }
  );
};

export default useProjects;
