import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Avatar } from '@progress/kendo-react-layout';
import { SvgIcon } from '@progress/kendo-react-common';
import { userIcon } from '@progress/kendo-svg-icons';
import { Typography } from '@progress/kendo-react-common';
import { BackgroundContainer, Icon } from '../components/layout';
import { CentralizedContent } from '../components/layout';
import { useSelectOrganization, useUser } from '../hooks/authentication';
import { Organization } from '../types';
import { useAppContext } from '../context/app';

const OrganizationContainer = styled.div`
  border: 2px solid var(--geosap-input-border-color);
  border-radius: 0.5rem;
  background: var(--geosap-light);
  color: var(--geosap-primary-color);
  &:hover {
    border: 2px solid var(--geosap-selected);
    background: var(--geosap-input-border-color);
  }
  &:focus {
    box-shadow: var(--geosap-input-focus-shadow);
  }
  padding: 0.5rem 1rem;
  margin: 1rem 0rem;
  cursor: pointer;
  transition: all 0.15s ease-in;
`;

const OrganizationSelection: React.FC = () => {
  const { availableOrganizations, selectedOrganization } = useAppContext();
  const navigate = useNavigate();
  const { organizationSelected } = useUser();

  const selectOrgMutation = useSelectOrganization();

  useEffect(() => {
    if (organizationSelected()) {
      navigate('/deliver/root', { replace: true });
    } else {
      if (availableOrganizations.length === 0) {
        navigate('/noorganization', { replace: true });
      }
    }
  }, [selectedOrganization]);

  const handleSelectOrganization = (organization: Organization) => {
    selectOrgMutation.mutate(organization);
  };

  const renderOrganization = (org: Organization, index: number) => {
    return (
      <OrganizationContainer
        className="d-flex align-items-center w-100 justify-content-between"
        onClick={() => {
          handleSelectOrganization(org);
        }}
        key={index}
      >
        <Avatar rounded="full" size="large" themeColor="light">
          <SvgIcon size="xxxlarge" icon={userIcon} color="var(--geosap-profile-icon-color)" />
        </Avatar>
        <Typography.h5 textAlign="center" className="">
          {org.name}
        </Typography.h5>
      </OrganizationContainer>
    );
  };

  return (
    <BackgroundContainer style={{ overflow: 'auto' }}>
      <div className="container">
        <div className="row align-items-center justify-content-center vh-100">
          <div className="col-xs-12 col-lg-6 col-xl-4 pt-4">
            <Typography.h2 textAlign="center" fontWeight="light">
              Log in to GeoSAP
            </Typography.h2>
            <Typography.p textAlign="center" className="py-2">
              {'Select an organization.'}
            </Typography.p>
            <div className="p-3 flex-column d-flex align-items-center">
              {availableOrganizations
                .sort((org1, org2) => {
                  const nameA = org1.name.toUpperCase(); // Convert names to uppercase for case-insensitive comparison
                  const nameB = org2.name.toUpperCase();
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0; // names are equal
                })
                .map((org, index) => {
                  return renderOrganization(org, index);
                })}
            </div>
          </div>
        </div>
      </div>
    </BackgroundContainer>
  );
};

export default OrganizationSelection;
