import { QueryClient } from 'react-query';
import {
    SapFlowTransaction,
    SapFlowTransactionStatus,
    WSMessage,
  } from '../../../types';

const handleMessage = (wsMessage: WSMessage, queryClient: QueryClient) => {
  const transactionStatus: SapFlowTransactionStatus = wsMessage.payload;
  if (transactionStatus !== null && transactionStatus.transactionID) {
    /*let transactionsData: SapFlowTransaction[] = queryClient.getQueryData(['transactions']);
    if (transactionsData && transactionsData.length > 0) {
      transactionsData = JSON.parse(JSON.stringify(transactionsData));
      let i = 0;
      let transIndex = -1;
      for (i = 0; i < transactionsData.length; i++) {
        if (transactionsData[i].id === transactionStatus.transactionID) {
          transIndex = i;
          break;
        }
      }
      if (transIndex >= 0) {
        const matchingTransaction: SapFlowTransaction = transactionsData[transIndex];
        // Validate the status isn't already in the history collection
        if (
          matchingTransaction.transactionHistory.filter((transStatus) => transStatus.id === transactionStatus.id).length === 0
        ) {
          matchingTransaction.transactionHistory.push(transactionStatus);
          matchingTransaction.transactionPercentComplete = transactionStatus.currentTransactionPercentComplete;
          matchingTransaction.transactionRuntime = transactionStatus.currentTransactionRuntime;
          matchingTransaction.status = transactionStatus.transactionStatus;
        } else {
          console.log('Status id found in Trans history');
        }
        transactionsData[transIndex] = matchingTransaction;
        queryClient.setQueryData(['transactions'], transactionsData);
      } else {
        console.log('Matching Transaction not found');
      }
    } else {
      console.log('No trans data');
    }*/
    const transactionData: SapFlowTransaction|undefined = queryClient.getQueryData(['transaction', transactionStatus.transactionID]);
    if(transactionData) {
      if (
      transactionData.transactionHistory.filter((transStatus) => transStatus.id === transactionStatus.id).length === 0
    ) {
      transactionData.transactionHistory.push(transactionStatus);
      transactionData.transactionPercentComplete = transactionStatus.currentTransactionPercentComplete;
      transactionData.transactionRuntime = transactionStatus.currentTransactionRuntime;
      transactionData.status = transactionStatus.transactionStatus;
    } else {
      console.log('Status id found in Trans history');
    }
    queryClient.setQueryData(['transaction', transactionData.id], transactionData);
    }
  } else {
    console.log('Invalid Trans Status update');
  }
}

export default handleMessage;