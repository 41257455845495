import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Typography } from '@progress/kendo-react-common';
import { DropDownList } from '@progress/kendo-react-dropdowns'; //https://www.telerik.com/kendo-react-ui/components/dropdowns/#toc-customization
import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from '@progress/kendo-react-common';
import { chartLineStacked100Icon, gearIcon } from '@progress/kendo-svg-icons';
import { replaceInvalidFilenameChars } from '../../../common/stringHelper';

import { ReactComponent as EarthLogo } from '../../../assets/icons/toolbar-earth.svg';
import { ReactComponent as OrbitLogo } from '../../../assets/icons/toolbar-orbit.svg';
import { ReactComponent as MoveLogo } from '../../../assets/icons/toolbar-move.svg';
import { ReactComponent as RotateLogo } from '../../../assets/icons/toolbar-rotate.svg';
import { ReactComponent as PointLogo } from '../../../assets/icons/toolbar-point.svg';
import { ReactComponent as DistanceLogo } from '../../../assets/icons/toolbar-distance.svg';
import { ReactComponent as HeightLogo } from '../../../assets/icons/toolbar-height.svg';
import { ReactComponent as PolygonLogo } from '../../../assets/icons/toolbar-polygon.svg';
import { ReactComponent as AnnotateLogo } from '../../../assets/icons/toolbar-annotate.svg';
import { ReactComponent as FitLogo } from '../../../assets/icons/toolbar-fit.svg';
import { useConsumeViewerState } from '../../../context/viewer';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ShareResourceButton, SubmitButton } from '../../../components/form';
import { useUser } from '../../../hooks/authentication';
import { AuthorityLevel, AuthorityType } from '../../../types';
import IconButton from '../../../components/form/IconButton';

const POINT_MEASUREMENT = 'point';
const DISTANCE_MEASUREMENT = 'distance';
const HEIGHT_MEASUREMENT = 'height';
const VOLUME_MEASUREMENT = 'volume';
const PROFILE_MEASUREMENT = 'profile';

const Container = styled.div`
  background: var(--geosap-navbar-color);
  z-index: 1;
  height: var(--geosap-viewer-toolbar-height);
`;

const ToolSeparator = styled.div`
  width: 2px;
  background: white;
  margin-top: 10px;
  margin-bottom: 10px;
`;

interface ViewerToolbarProps {
  viewer: any;
  projectName: string;
  currentPointcloudAttributes: any;
  currentAttributeValue: string;
  onAttributeChange(event: any): void;
  onSaveView(): void;
}

const ViewerToolbar: React.FC<ViewerToolbarProps> = (props: ViewerToolbarProps) => {
  const { dispatch, profiles, viewConfig, viewConfigPropertiesOpened } = useConsumeViewerState();
  const { userHasAuthority } = useUser();
  const [addingAnnotation, setAddingAnnotation] = useState<boolean>(false);
  const [editingAnnotation, setEditingAnnotation] = useState<any>(null);
  const [mapViewAvailable, setMapViewAvailable] = useState<boolean>(false);
  const [mapViewOpened, setMapViewOpened] = useState<boolean>(false);
  const [currentPotreeControls, setCurrentPotreeControls] = useState<any>(null);
  const [ongoingMeasurementType, setOngoingMeasurementType] = useState<string>('');
  const handleProfileWindowClosedRef = useRef<() => void>();

  const onAnnotationClicked = (e: any) => {
    console.log('Annotation clicked: ' + e.annotation);
    const annotation = e.annotation;
    setEditingAnnotation(annotation);
  };

  handleProfileWindowClosedRef.current = () => {
    props.viewer.profileWindowController.reset();
    profiles.forEach((profile, index) => {
      props.viewer.scene.removeProfile(profile);
    });
    dispatch({ type: 'CLEAR_PROFILES' });
  };

  useEffect(() => {
    if (props.viewer) {
      props.viewer.setControls(props.viewer.earthControls);
      setCurrentPotreeControls(props.viewer.earthControls);
      props.viewer.addEventListener('update', onViewerUpdate);
    }
    return () => {
      if (props.viewer) {
        if (props.viewer.profileWindow) {
          props.viewer.profileWindow.hide();
        }
        props.viewer.removeEventListener('update', onViewerUpdate);
      }
    };
  }, [props.viewer]);

  useEffect(() => {
    const handleEvent = () => {
      handleProfileWindowClosedRef.current();
    };
    if (props.viewer?.profileWindow) {
      props.viewer.profileWindow.addEventListener('profileWindowClosed', handleEvent);
    }
    return () => {
      if (props.viewer?.profileWindow) {
        props.viewer.profileWindow.removeEventListener('profileWindowClosed', handleEvent);
      }
    };
  }, [props.viewer?.profileWindow]);

  const onViewerUpdate = () => {
    setMapViewAvailable(props.viewer.mapView.sceneProjection ? true : false);
  };

  const clearMeasureState = () => {
    setOngoingMeasurementType('');
  };

  const clearAnnotationState = () => {
    setAddingAnnotation(false);
  };

  return (
    <Container className="d-flex h-fit w-100">
      {/* Navigate Tools */}
      <div className="px-3 py-1 flex-column d-flex">
        <Typography.p fontSize="small" themeColor="light" className="mb-0">
          Navigate
        </Typography.p>
        <ul className="flex-grow-1 d-flex">
          {mapViewAvailable && (
            <li
              role="button"
              onClick={() => {
                props.viewer.toggleMap();
                setMapViewOpened(props.viewer.mapView.enabled);
              }}
              className="px-1 position-relative"
            >
              <Tooltip anchorElement="target" position="bottom" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
                <EarthLogo
                  fill={mapViewOpened ? '#89C541' : '#aaa'}
                  stroke={mapViewOpened ? '#89C541' : '#aaa'}
                  style={{
                    width: 'var(--geosap-viewer-toolbar-icon-width)',
                    height: 'var(--geosap-viewer-toolbar-icon-width)',
                  }}
                />
                <a
                  style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 1 }}
                  title={'Overlay map viewer'}
                ></a>
              </Tooltip>
            </li>
          )}
          <li
            role="button"
            onClick={() => {
              props.viewer.setControls(props.viewer.orbitControls);
              setCurrentPotreeControls(props.viewer.orbitControls);
            }}
            className="px-1 position-relative"
          >
            <Tooltip anchorElement="target" position="bottom" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
              <OrbitLogo
                fill={currentPotreeControls === props.viewer?.orbitControls ? '#89C541' : '#aaa'}
                stroke={currentPotreeControls === props.viewer?.orbitControls ? '#89C541' : '#aaa'}
                style={{
                  width: 'var(--geosap-viewer-toolbar-icon-width)',
                  height: 'var(--geosap-viewer-toolbar-icon-width)',
                }}
              ></OrbitLogo>
              <a
                style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 1 }}
                title={"Orbit navigation based on camera's center target"}
              />
            </Tooltip>
          </li>
          <li
            role="button"
            onClick={() => {
              props.viewer.setControls(props.viewer.earthControls);
              setCurrentPotreeControls(props.viewer.earthControls);
            }}
            className="px-1 position-relative"
          >
            <Tooltip anchorElement="target" position="bottom" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
              <MoveLogo
                fill={currentPotreeControls === props.viewer?.earthControls ? '#89C541' : '#aaa'}
                stroke={currentPotreeControls === props.viewer?.earthControls ? '#89C541' : '#aaa'}
                style={{
                  width: 'var(--geosap-viewer-toolbar-icon-width)',
                  height: 'var(--geosap-viewer-toolbar-icon-width)',
                }}
              />
              <a
                style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 1 }}
                title={"Navigation based on scene's point."}
              />
            </Tooltip>
            {/*<ToolImage src="/assets/toolbar-move.svg" className="" />*/}
          </li>
          {/*<li
            role="button"
            onClick={() => {
              props.viewer.setControls(props.viewer.fpControls);
              setCurrentPotreeControls(props.viewer.fpControls);
            }}
            className="px-1 position-relative"
          >
          <Tooltip anchorElement="target" position="bottom" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
            <RotateLogo
              fill={currentPotreeControls === props.viewer?.fpControls ? '#89C541' : '#aaa'}
              stroke={currentPotreeControls === props.viewer?.fpControls ? '#89C541' : '#aaa'}
              style={{
                width: 'var(--geosap-viewer-toolbar-icon-width)',
                height: 'var(--geosap-viewer-toolbar-icon-width)',
              }}
            />
            <a
              style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 1 }}
              title={"Navigation based on scene's point."}
            />
          </Tooltip>
          </li>*/}
          {/*<li
            role="button"
            onClick={() => {
              setZoomingToFit(true);
              setTimeout(() => {
                setZoomingToFit(false);
              }, 500);
              props.viewer.fitToScreen();
            }}
            className="px-1"
          >
            <FitLogo
              fill={zoomingToFit ? '#89C541' : '#aaa'}
              stroke={zoomingToFit ? '#89C541' : '#aaa'}
              style={{
                width: 'var(--geosap-viewer-toolbar-icon-width)',
                height: 'var(--geosap-viewer-toolbar-icon-width)',
              }}
            />
          </li>*/}
        </ul>
      </div>
      {/* Measure Tools */}
      <ToolSeparator />
      <div className="px-3 py-1 flex-column d-flex">
        <Typography.p fontSize="small" themeColor="light" className="mb-0">
          Measure
        </Typography.p>
        <ul className="flex-grow-1 d-flex">
          <li
            role="button"
            onClick={() => {
              setOngoingMeasurementType(POINT_MEASUREMENT);
              const measurement = props.viewer.measuringTool.startInsertion({
                showDistances: false,
                showAngles: false,
                showCoordinates: true,
                showArea: false,
                closed: true,
                maxMarkers: 1,
                name: 'Point',
                callback: clearMeasureState,
              });

              measurement.addEventListener('marker_dropped', clearMeasureState);
            }}
            className="px-1 position-relative"
          >
            <Tooltip anchorElement="target" position="bottom" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
              <PointLogo
                fill={ongoingMeasurementType === POINT_MEASUREMENT ? '#89C541' : '#aaa'}
                stroke={ongoingMeasurementType === POINT_MEASUREMENT ? '#89C541' : '#aaa'}
                style={{
                  width: 'var(--geosap-viewer-toolbar-icon-width)',
                  height: 'var(--geosap-viewer-toolbar-icon-width)',
                }}
              />
              <a
                style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 1 }}
                title={'Annotate specific point positions.'}
              />
            </Tooltip>
          </li>
          <li
            role="button"
            onClick={() => {
              setOngoingMeasurementType(DISTANCE_MEASUREMENT);
              const measurement = props.viewer.measuringTool.startInsertion({
                showDistances: true,
                showArea: false,
                closed: false,
                name: 'Distance',
                callback: clearMeasureState,
              });

              measurement.addEventListener('marker_dropped', clearMeasureState);
            }}
            className="px-1 position-relative"
          >
            <Tooltip anchorElement="target" position="bottom" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
              <DistanceLogo
                fill={ongoingMeasurementType === DISTANCE_MEASUREMENT ? '#89C541' : '#aaa'}
                stroke={ongoingMeasurementType === DISTANCE_MEASUREMENT ? '#89C541' : '#aaa'}
                style={{
                  width: 'var(--geosap-viewer-toolbar-icon-width)',
                  height: 'var(--geosap-viewer-toolbar-icon-width)',
                }}
              />
              <a
                style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 1 }}
                title={'Measure the distance between two points.'}
              />
            </Tooltip>
          </li>
          <li
            role="button"
            onClick={() => {
              setOngoingMeasurementType(HEIGHT_MEASUREMENT);
              const measurement = props.viewer.measuringTool.startInsertion({
                showDistances: false,
                showHeight: true,
                showArea: false,
                closed: false,
                maxMarkers: 2,
                name: 'Height',
                callback: clearMeasureState,
              });
              measurement.addEventListener('marker_dropped', clearMeasureState);
            }}
            className="px-1 position-relative"
          >
            <Tooltip anchorElement="target" position="bottom" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
              <HeightLogo
                fill={ongoingMeasurementType === HEIGHT_MEASUREMENT ? '#89C541' : '#aaa'}
                stroke={ongoingMeasurementType === HEIGHT_MEASUREMENT ? '#89C541' : '#aaa'}
                style={{
                  width: 'var(--geosap-viewer-toolbar-icon-width)',
                  height: 'var(--geosap-viewer-toolbar-icon-width)',
                }}
              />
              <a
                style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 1 }}
                title={'Measure the the height between two points.'}
              />
            </Tooltip>
          </li>
          <li
            role="button"
            onClick={() => {
              setOngoingMeasurementType(VOLUME_MEASUREMENT);
              const measurement = props.viewer.measuringTool.startInsertion({
                showDistances: true,
                showArea: true,
                closed: true,
                name: 'Area',
                callback: clearMeasureState,
              });
              measurement.addEventListener('marker_dropped', clearMeasureState);
            }}
            className="px-1 position-relative"
          >
            <Tooltip anchorElement="target" position="bottom" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
              <PolygonLogo
                fill={ongoingMeasurementType === VOLUME_MEASUREMENT ? '#89C541' : '#aaa'}
                stroke={ongoingMeasurementType === VOLUME_MEASUREMENT ? '#89C541' : '#aaa'}
                style={{
                  width: 'var(--geosap-viewer-toolbar-icon-width)',
                  height: 'var(--geosap-viewer-toolbar-icon-width)',
                }}
              />
              <a
                style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 1 }}
                title={'Area measurements between multiple points.'}
              />
            </Tooltip>
          </li>
          <li
            role="button"
            onClick={() => {
              setOngoingMeasurementType(PROFILE_MEASUREMENT);
              const profile = props.viewer.profileTool.startInsertion({
                name: 'Profile',
                callback: clearMeasureState,
              });
              profile.addEventListener('marker_dropped', clearMeasureState);
              props.viewer.profileWindow.show();
              props.viewer.profileWindowController.setProfile(profile);

              dispatch({ type: 'ADDED_PROFILE', payload: { profile } });

              //let measurementsRoot = $("#jstree_scene").jstree().get_json("measurements");
              //let jsonNode = measurementsRoot.children.find(child => child.data.uuid === profile.uuid);
              //$.jstree.reference(jsonNode.id).deselect_all();
              //$.jstree.reference(jsonNode.id).select_node(jsonNode.id);
            }}
            className="px-1 position-relative"
          >
            <Tooltip anchorElement="target" position="bottom" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
              <SvgIcon
                icon={chartLineStacked100Icon}
                size="medium"
                style={{
                  color: 'rgb(190,190,190)',
                  marginRight: '12px',
                  cursor: 'pointer',
                  fontSize: '28px',
                  marginLeft: '-8px',
                }}
              />
              <a
                style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 1 }}
                title={'Profiling tool. Select an origin and drag it to profile that slice of points.'}
              />
            </Tooltip>
          </li>
        </ul>
      </div>
      {/* Modify Tools */}
      <ToolSeparator />
      <div className="px-3 py-1 flex-column d-flex">
        <Typography.p fontSize="small" themeColor="light" className="mb-0">
          Annotate
        </Typography.p>
        <ul className="flex-grow-1 d-flex">
          <li
            role="button"
            onClick={() => {
              setAddingAnnotation(true);
              const annotation = props.viewer.annotationTool.startInsertion({
                callback: clearAnnotationState,
              });
              annotation.addEventListener('click', (clickEvent: any) => {
                setEditingAnnotation(annotation);
              });
            }}
            className="px-1 position-relative"
          >
            <Tooltip anchorElement="target" position="bottom" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
              <AnnotateLogo
                fill={addingAnnotation ? '#89C541' : '#aaa'}
                stroke={addingAnnotation ? '#89C541' : '#aaa'}
                style={{
                  width: 'var(--geosap-viewer-toolbar-icon-width)',
                  height: 'var(--geosap-viewer-toolbar-icon-width)',
                }}
              />
              <a
                style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 1 }}
                title={'Mark specific points with text annotations.'}
              />
            </Tooltip>
          </li>
          {/*CLIPPING
          <li
            role="button"
            onClick={() => {
              console.log();
            }}
            className="px-1"
          >
            <ToolImage src="/assets/toolbar-clip.svg" className="" />
        </li>*/}
        </ul>
      </div>
      {/* Options Tools */}
      <ToolSeparator />
      <div className="px-3 py-1 flex-column d-flex">
        <Typography.p fontSize="small" themeColor="light" className="mb-0">
          Attribute View
        </Typography.p>

        <DropDownList
          style={{
            width: '200px',
          }}
          size="small"
          data={props.currentPointcloudAttributes}
          value={props.currentAttributeValue}
          dataItemKey="id"
          textField="text"
          onChange={props.onAttributeChange}
          popupSettings={{ width: 250 }}
        />
      </div>
      {/* Options Tools */}
      <ToolSeparator />
      {userHasAuthority(AuthorityType.VIEWER_AUTHORITY, AuthorityLevel.UPDATE) && (
        <div className="px-3 py-1 d-flex align-items-center">
          <SubmitButton
            className="mx-3 mt-2 font-exo"
            style={{ fontSize: '0.875rem', fontWeight: 500, height: 'fit-content' }}
            label={'Set Default View'}
            full={false}
            size="small"
            onClick={props.onSaveView}
          ></SubmitButton>
        </div>
      )}
      {/* */}
      <div style={{ display: 'flex', marginLeft: 'auto' }}>
        {userHasAuthority(AuthorityType.VIEWER_AUTHORITY, AuthorityLevel.UPDATE) && (
          <IconButton
            icon={gearIcon}
            style={{ color: 'white', marginLeft: '0.5rem', marginRight: '0.25rem' }}
            hoveredStyle={{ color: 'lightgray', marginLeft: '0.5rem', marginRight: '0.25rem' }}
            tooltip={'Display viewer options.'}
            onClick={(e) => {
              e.preventDefault();
              dispatch({ type: 'CHANGE_VIEW_CONFIG_PROPERTIES_OPENED', payload: { opened: true } });
              return true;
            }}
          ></IconButton>
        )}
        {userHasAuthority(AuthorityType.VIEWER_AUTHORITY, AuthorityLevel.SHARE) && (
          <ShareResourceButton
            resourceName={viewConfig?.pageName}
            transactionId={viewConfig?.transaction?.id}
            fileNodeId={viewConfig?.filenode?.id}
            style={{ marginLeft: '0.25rem', marginRight: '0.5rem' }}
            iconStyle={{ color: 'white' }}
            iconHoveredStyle={{ color: 'lightgray' }}
          ></ShareResourceButton>
        )}
      </div>
      {/* Export Tools 
      <ToolSeparator />
      <div className="px-3 py-1 flex-column d-flex">
        <Typography.p fontSize="small" themeColor="light" className="mb-0" style={{ color: 'var(--geosap-disabled)' }}>
          Export
        </Typography.p>

        <div className="py-1 d-flex">
          <Button
            fillMode="flat"
            size={'small'}
            themeColor={'light'}
            onClick={() => {
              props.viewer.takeScreenshot(replaceInvalidFilenameChars(viewConfig?.project?.name));
            }}
          >
            .png
          </Button>
          <Button fillMode="flat" size={'small'} themeColor={'light'} disabled={true}>
            .laz
          </Button>
          <Button fillMode="flat" size={'small'} themeColor={'light'} disabled={true}>
            .tif
          </Button>
          <Button fillMode="flat" size={'small'} themeColor={'light'} disabled={true}>
            .shp
          </Button>
          <Button fillMode="flat" size={'small'} themeColor={'light'} disabled={true}>
            .dxf
          </Button>
          <Button fillMode="flat" size={'small'} themeColor={'light'} disabled={true}>
            .tin
          </Button>
        </div>
      </div>*/}
    </Container>
  );
};

export default ViewerToolbar;
