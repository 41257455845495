import React, { useReducer, useContext } from 'react';
import { ViewerState, initialState } from './viewerState';
import { viewerReducer, ViewerAction } from './viewerReducer';

export interface ViewerProviderValue extends ViewerState {
  dispatch: (action: ViewerAction) => void;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  value: ViewerProviderValue;
}

const ViewerContext = React.createContext({
  ...initialState,
  dispatch: () => {
    // not implemented
  },
} as ViewerProviderValue);

export const ViewerProvider: React.FC<Props> = ({ children, value }) => {
  return <ViewerContext.Provider value={value}>{children}</ViewerContext.Provider>;
};

/*
  Create Sap Flow Wizard state.
*/
export const useCreateViewerState = (viewerType: 'TRANSACTION'|'FILENODE'): ViewerProviderValue => {
  const [state, dispatch] = useReducer(viewerReducer, {
    ...initialState,
    viewerType
  });
  return {
    ...state,
    dispatch,
  };
};

/*
  Consume Sap Flow Wizard state.
*/
export const useConsumeViewerState = () => {
  return useContext(ViewerContext);
};
