import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { GridColumn, GridDetailRowProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Typography } from '@progress/kendo-react-common';
import Dialog from './Dialog';
import { WSMessage, EMsgType } from '../types';
import { SubmitButton } from './form';
import { StyledGrid } from './styled/';
import { useTable } from '../hooks/common';

interface DialogProps {
  show: boolean;
  onClose: any;
}

const WebsocketMonitor: React.FC<DialogProps> = (props: DialogProps) => {
  const wsLogsQuery = useQuery(
    'websocketLogs',
    () => {
      return [];
    },
    { enabled: false }
  );
  const [logs, setLogs] = useState<WSMessage[]>([]);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [showHeartbeats, setShowHeartbeats] = useState<boolean>(true);
  const tableProps = useTable({
    pageSize: 10,
    data: logs,
    sortDescriptor: { field: 'created', dir: 'desc' },
  });

  useEffect(() => {
    if (wsLogsQuery.data && wsLogsQuery.data.length > 0) {
      const newLogs: WSMessage[] = [];
      wsLogsQuery.data.forEach((wsLog: WSMessage) => {
        if (showHeartbeats || wsLog.msgHeader.msgType !== EMsgType.HEARTBEAT) {
          const index = expandedIds.indexOf(wsLog.id);
          wsLog.expanded = index !== -1;
          newLogs.push(wsLog);
        }
      });
      setLogs(newLogs);
    }
  }, [wsLogsQuery.data, showHeartbeats]);

  const handleExpand = (wsMessageClicked: WSMessage) => {
    const newLogs = logs.map((item) => ({
      ...item,
      expanded: wsMessageClicked.id === item.id ? !item.expanded : item.expanded,
    }));
    setLogs(newLogs);
    const newExpandedIds = [...expandedIds];
    const index = newExpandedIds.indexOf(wsMessageClicked.id);
    if (index !== -1) {
      newExpandedIds.splice(index, 1); // Remove the string if it's found
    } else {
      newExpandedIds.push(wsMessageClicked.id); // Add the string if it's not found
    }
    setExpandedIds(newExpandedIds);
  };

  return (
    <Dialog
      title={'Websocket Monitor'}
      onClose={props.onClose}
      show={props.show}
      style={{ width: '800px', maxWidth: '80%', maxHeight: '80%', minHeight: '600px' }}
    >
      <Checkbox
        label={'Show heartbeat logs'}
        value={showHeartbeats}
        onChange={(e) => {
          setShowHeartbeats(e.value);
        }}
      />
      <StyledGrid
        {...tableProps}
        pageable
        sortable
        total={logs.length}
        detail={DetailsLogViewer}
        expandField="expanded"
        onRowClick={({ dataItem }) => {
          if (dataItem.msgHeader.msgType !== EMsgType.HEARTBEAT) handleExpand(dataItem);
        }}
        rowRender={(trElement, props) => {
          let trProps: any = null;
          trProps = {
            style: { cursor: 'pointer' },
          };
          return React.cloneElement(
            trElement,
            {
              ...trProps,
            },
            trElement.props.children as any
          );
        }}
      >
        <GridColumn field="msgHeader.msgType" title="Type" />
        <GridColumn field="msgHeader.sendTime" title="Timestamp" format="{0:h:mm:ss a - M/d/yy}" />
        <GridColumn field="payload.statusMessage" title="Message" />
      </StyledGrid>
    </Dialog>
  );
};

const DetailsLogViewer: React.FC<GridDetailRowProps> = (props: GridDetailRowProps) => {
  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (divRef.current?.parentElement?.tagName === 'TD') {
      const tdEl = divRef.current.parentElement as HTMLTableCellElement;
      tdEl.colSpan = tdEl.colSpan + 1;
      tdEl.ariaColIndex = '1';
    }
  }, []);

  const renderPayloadValue = (value: any) => {
    if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'boolean') {
      return value ? 'true' : 'false';
    } else {
      return '' + value;
    }
  };

  return (
    <div ref={divRef}>
      <div className="px-4 pb-1">
        {props.dataItem && props.dataItem.payload && typeof props.dataItem.payload === 'object' && (
          <ul>
            {Object.keys(props.dataItem.payload) /*.slice(0, 5)*/
              .map((payloadKey: string) => (
                <li key={`${payloadKey}`} className="py-1">
                  <span className="ps-1">{payloadKey}</span>
                  <span className="ps-1">:</span>
                  <span className="ps-1">{renderPayloadValue(props.dataItem.payload[payloadKey])}</span>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default WebsocketMonitor;
