import React, { useEffect } from 'react';
import { RangeSlider, Slider } from '@progress/kendo-react-inputs';
import {
  RGBA,
  INTENSITY,
  INTENSITY_GRADIENT,
  CLASSIFICATION,
  USER_DATA,
  ELEVATION,
  HEIGHT,
  DEFAULT_ATTRIBUTE_VALUES,
  NUMBER_RETURNS,
  RETURN_NUMBER,
  SOURCE_ID,
  POINT_SOURCE_ID,
} from './SupportedAttributes';
import ClassificationToggle from './ClassificationToggle';
import DraggableWindow from '../../../components/DraggableWindow';
import { useConsumeViewerState } from '../../../context/viewer';
import { AuthorityLevel, AuthorityType, PCAttributeType, PointcloudVisualization, ViewLayer } from '../../../types';
import { updateLayerParam } from '../../../common/viewerConfigHelper';
import { useUser } from '../../../hooks/authentication';

// To avoid transpiler error on window.Potree  https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
declare const window: any;
const Potree = window.Potree;

export enum PotreePanel {
  APPEARANCE,
  TOOLS,
  SCENE,
  FILTERS,
  ABOUT,
}

interface AttributeConfigProps {
  visible: boolean;
  attribute: string;
  pointcloud: any;
  onClose: any;
  viewer: any;
  layer: ViewLayer;
  onLayerUpdate?: (layer: ViewLayer, persistent?: boolean) => void;
  onClassificationUpdate?: (
    layer: ViewLayer,
    attributeName: string,
    id: number,
    name: string,
    visible: boolean,
    color: number[]
  ) => void;
}

const AttributeConfigWindow: React.FC<AttributeConfigProps> = (props: AttributeConfigProps) => {
  const { domViewerRef } = useConsumeViewerState();
  const [editingAttributeName, setEditingAttributeName] = React.useState(null);
  const { userHasAuthority } = useUser();

  const handleAttributeChange = (visualizationParamPath: string, value: any) => {
    const visualization: PointcloudVisualization = props.layer.paramsMap['visualization'];
    let paramToChange: any = visualization.attributeValues;
    const pathElements = visualizationParamPath.split('.');
    for (let i = 0; i < pathElements.length - 1; i++) {
      paramToChange = paramToChange[pathElements[i]];
    }
    paramToChange[pathElements[pathElements.length - 1]] = value;
    //const modifiedLayer = JSON.stringify();
    updateLayerParam(props.layer, 'visualization', visualization);
    //props.onLayerUpdate(props.layer, true);
  };

  const handleAttributeRangeChange = (visualizationAttribute: string, newMin: any, newMax: any) => {
    const visualization: PointcloudVisualization = props.layer.paramsMap['visualization'];
    visualization.attributeValues[visualizationAttribute] = {
      attributeType: PCAttributeType.RANGE,
      min: newMin,
      max: newMax,
    };
    updateLayerParam(props.layer, 'visualization', visualization);
    //props.onLayerUpdate(props.layer, true);
  };

  const renderAttributeSettings = () => {
    if (!props.attribute || !props.pointcloud || (!props.pointcloud.material && props.attribute !== HEIGHT)) {
      return null;
    }
    /*****RGBA*****/
    if (props.attribute === RGBA) {
      const gamma = props.pointcloud.material.rgbGamma;
      const brightness = props.pointcloud.material.rgbBrightness;
      const contrast = props.pointcloud.material.rgbContrast;
      return (
        <div className="flex-column d-flex m-2">
          <span>Gamma</span>
          <Slider
            min={0}
            max={4}
            step={0.01}
            defaultValue={gamma}
            onChange={(e) => {
              props.pointcloud.material.rgbGamma = e.value;
              handleAttributeChange('rgba.gamma', e.value);
            }}
          ></Slider>
          <span>Brightness</span>
          <Slider
            min={-1}
            max={1}
            step={0.01}
            defaultValue={brightness}
            onChange={(e) => {
              props.pointcloud.material.rgbBrightness = e.value;
              handleAttributeChange('rgba.brightness', e.value);
            }}
          ></Slider>
          <span>Contrast</span>
          <Slider
            min={-1}
            max={1}
            step={0.01}
            defaultValue={contrast}
            onChange={(e) => {
              props.pointcloud.material.rgbContrast = e.value;
              handleAttributeChange('rgba.contrast', e.value);
            }}
          ></Slider>
        </div>
      );
    } else if (props.attribute === INTENSITY) {
      /*****INTENSITY*****/
      const intensityAttribute = props.pointcloud.getAttribute('intensity');
      const [min, max] = intensityAttribute.range;
      const [rangeStart, rangeEnd] = props.pointcloud.material.intensityRange;
      const gamma = props.pointcloud.material.intensityGamma;
      const brightness = props.pointcloud.material.intensityBrightness;
      const contrast = props.pointcloud.material.intensityContrast;
      return (
        <div className="flex-column d-flex m-2">
          <span>
            Range: {min.toFixed(2)} to {max.toFixed(2)}
          </span>
          <RangeSlider
            key={props.attribute} //https://stackoverflow.com/questions/35792275/how-to-force-remounting-on-react-components
            min={min}
            max={max}
            step={0.01}
            defaultValue={{ start: rangeStart, end: rangeEnd }}
            onChange={(e: any) => {
              props.pointcloud.material.intensityRange = [e.value.start, e.value.end];
              handleAttributeRangeChange(INTENSITY, e.value.start, e.value.end);
            }}
          ></RangeSlider>
          <span>Gamma</span>
          <Slider
            min={0}
            max={4}
            step={0.01}
            defaultValue={gamma}
            onChange={(e) => {
              props.pointcloud.material.intensityGamma = e.value;
            }}
          ></Slider>
          <span>Brightness</span>
          <Slider
            min={-1}
            max={1}
            step={0.01}
            defaultValue={brightness}
            onChange={(e) => {
              props.pointcloud.material.intensityBrightness = e.value;
            }}
          ></Slider>
          <span>Contrast</span>
          <Slider
            min={-1}
            max={1}
            step={0.01}
            defaultValue={contrast}
            onChange={(e) => {
              props.pointcloud.material.intensityContrast = e.value;
            }}
          ></Slider>
        </div>
      );
    } else if (props.attribute === ELEVATION) {
      /*****ELEVATION*****/
      const aPosition = props.pointcloud.getAttribute('position');

      let bMin, bMax;

      if (aPosition) {
        // for new format 2.0 and loader that contain precomputed min/max of attributes
        const min = aPosition.range[0][2];
        const max = aPosition.range[1][2];
        const width = max - min;

        bMin = min - 0.2 * width;
        bMax = max + 0.2 * width;
      } else {
        // for format up until exlusive 2.0
        let box = [props.pointcloud.pcoGeometry.tightBoundingBox, props.pointcloud.getBoundingBoxWorld()].find(
          (v) => v !== undefined
        );

        props.pointcloud.updateMatrixWorld(true);
        box = Potree.Utils.computeTransformedBoundingBox(box, props.pointcloud.matrixWorld);

        const bWidth = box.max.z - box.min.z;
        bMin = box.min.z - 0.2 * bWidth;
        bMax = box.max.z + 0.2 * bWidth;
      }
      const [min, max] = props.pointcloud.material.elevationRange;
      return (
        <div className="flex-column d-flex m-2">
          <span>
            Range: {min.toFixed(2)} to {max.toFixed(2)}
          </span>
          <RangeSlider
            key={props.attribute} //https://stackoverflow.com/questions/35792275/how-to-force-remounting-on-react-components
            min={bMin}
            max={bMax}
            step={0.01}
            defaultValue={{ start: props.pointcloud.material.heightMin, end: props.pointcloud.material.heightMax }}
            onChange={(e: any) => {
              props.pointcloud.material.heightMin = e.value.start;
              props.pointcloud.material.heightMax = e.value.end;
              handleAttributeRangeChange(ELEVATION, e.value.start, e.value.end);
            }}
          ></RangeSlider>
        </div>
      );
    } else if (props.attribute === HEIGHT) {
      return (
        <div className="flex-column d-flex m-2">
          <span>
            Range: {0} to {100}
          </span>
          <Slider
            min={-200}
            max={300}
            step={0.01}
            defaultValue={0}
            onChange={(e: any) => {
              props.pointcloud.position.set(props.pointcloud.position.x, props.pointcloud.position.y, e.value);
            }}
          ></Slider>
        </div>
      );
    } else if (props.attribute === CLASSIFICATION) {
      const classificationAttribute = props.pointcloud.getAttribute('classification');
      let classificationIds = [2, 3, 4, 5, 6];
      if (classificationAttribute.distinctValues && classificationAttribute.distinctValues.length > 0) {
        classificationIds = classificationAttribute.distinctValues;
      }
      classificationIds.sort(function (a, b) {
        return a - b;
      });
      return (
        <div className="d-flex flex-column m-2">
          {classificationIds.map((id, index) => {
            const classificationData = props.viewer.classifications['DEFAULT'][id]
              ? props.viewer.classifications['DEFAULT'][id]
              : { visible: true, name: 'Unknown', color: [0.5, 0.5, 0.5, 1] }; //DEFAULT_ATTRIBUTE_VALUES['DEFAULT'][id];
            return (
              <ClassificationToggle
                key={id}
                index={id}
                classificationData={classificationData}
                editingName={editingAttributeName === id}
                onEditName={() => {
                  if (userHasAuthority(AuthorityType.VIEWER_AUTHORITY, AuthorityLevel.UPDATE))
                    setEditingAttributeName(id);
                }}
                onChange={(id: number, name: string, visible: boolean, color: number[]) => {
                  props.onClassificationUpdate(props.layer, props.attribute, id, name, visible, color);
                  setEditingAttributeName(null);
                }}
              />
            );
          })}
        </div>
      );
    } else if (props.attribute === NUMBER_RETURNS) {
      return null;
    } else if (props.attribute === RETURN_NUMBER) {
      return null;
    } else if ([SOURCE_ID, POINT_SOURCE_ID].includes(props.attribute)) {
      return null;
    } else {
      const customAttribute = props.pointcloud.getAttribute(props.attribute);
      if (!customAttribute) {
        return null;
      } else if (customAttribute.distinctValues && customAttribute.distinctValues.length > 0) {
        return (
          <div className="d-flex flex-column m-2">
            {customAttribute.distinctValues.map((id: any, index: any) => {
              let classificationData: any = null;
              if (props.viewer.classifications[props.attribute] && props.viewer.classifications[props.attribute][id]) {
                classificationData = props.viewer.classifications[props.attribute][id];
              } else {
                classificationData = { visible: true, name: 'Unknown', color: [0.5, 0.5, 0.5, 1] };
              }
              return (
                <ClassificationToggle
                  key={id}
                  index={id}
                  classificationData={classificationData}
                  editingName={editingAttributeName === id}
                  onEditName={() => {
                    setEditingAttributeName(id);
                  }}
                  onChange={(id: number, name: string, visible: boolean, color: number[]) => {
                    props.onClassificationUpdate(props.layer, props.attribute, id, name, visible, color);
                    setEditingAttributeName(null);
                  }}
                />
              );
            })}
          </div>
        );
      } else {
        const [min, max] = customAttribute.range;

        let selectedRange = props.pointcloud.material.getRange(customAttribute.name);

        if (!selectedRange) {
          selectedRange = [...customAttribute.range];
        }
        const [rangeStart, rangeEnd] = selectedRange;

        const minMaxAreNumbers = typeof min === 'number' && typeof max === 'number';
        if (minMaxAreNumbers) {
          return (
            <div className="d-flex flex-column m-2">
              <span>
                Range: {Math.floor(rangeStart)} to {Math.floor(rangeEnd)}
              </span>
              <RangeSlider
                key={props.attribute}
                min={min}
                max={max}
                step={1}
                defaultValue={{ start: rangeStart, end: rangeEnd }}
                onChange={(e: any) => {
                  props.pointcloud.material.setRange(props.attribute, [e.value.start, e.value.end]);
                  handleAttributeRangeChange(props.attribute, e.value.start, e.value.end);
                }}
              ></RangeSlider>
            </div>
          );
        } else {
          return null;
        }
      }
    }
  };

  return (
    <DraggableWindow
      {...props}
      title={'Attributes configuration'}
      boundaryElement={domViewerRef?.current}
      initialPosition={{ x: 60, y: 120 }}
    >
      {renderAttributeSettings()}
    </DraggableWindow>
  );
};

export default AttributeConfigWindow;
