import { useQuery } from 'react-query';
import { SapFlowTransaction } from '../../../types';
import { useAxiosInstance } from '../../common/';

const endpoint = 'sapflow';

const useTransaction = (transactionId: string, refetchInterval: number | false = false) => {
  const axios = useAxiosInstance();

  return useQuery(
    ['transaction', transactionId],
    async () => {
      const result = await axios.get<SapFlowTransaction>("sapflow/" + transactionId);
      return result.data;
    },
    { refetchInterval }
  );
};

export default useTransaction;
