
export interface BandInfo {
    name: string,
    index?: number,
    bits?: number,
    min?: number,
    max?: number,
    currentMin?: number,
    currentMax?: number,
    mean?: number,
    stddev?: number,
    nodata?: number,
    datatype?: string,
    validPercent?: number
}

export enum BandType {
    RED,
    GREEN,
    BLUE,
    GRAY,
    NODATA,
    OTHER,
}