export interface PointcloudVisualization {
  attribute: string;
  attributeValues: {
    [key: string]: PCAttributeVisualization; // Index signature for dynamic properties
  };
}

export interface PCAttributeVisualization {
  attributeType: PCAttributeType;
  min?: number;
  max?: number;
  gamma?: number;
  brightness?: number;
  contrast?: number;
  classificationSchemas?: { [key: string]: PCClassification };
}

export enum PCAttributeType {
  COLORED = 'COLORED',
  RANGE = 'RANGE',
  CLASSIFICATION = 'CLASSIFICATION',
}

export interface RangeVisualization {
  min: number;
  max: number;
}

export interface PCClassification {
  index: number;
  visible: boolean;
  name: string;
  color: number[];
}

export interface PotreePointcloudAttribute {
  name: string;
  type: {
    ordinal: number;
    name: string;
    size: number;
  };
  numElements: number;
  byteSize: number;
  description: string;
  range: number[];
  distinctValues: any[];
  initialRange: number[];
}
