import { useMutation } from 'react-query';
import { useAxiosInstance } from '../common';

interface CheckoutParams {
  priceId: string
}

const useCheckoutSession = () => {
  const axios = useAxiosInstance();
  return useMutation(
    async (params: CheckoutParams) => {
      const response = await axios.post("stripe/checkoutSession/" + params.priceId);
      return response.data;
    },
    {
      onSuccess: (data, variables) => {
        //
      },
    }
  );
};

export default useCheckoutSession;