import React, { useState, useRef, useEffect } from 'react';
import Dialog from '../Dialog';
import { Typography } from '@progress/kendo-react-common';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import { SubmitButton } from '../form';
import { FileNode } from '../../types/DataDelivery';
import { useUser } from '../../hooks/authentication';
import { useRootStructure } from '../../hooks/data_delivery';
import { CornerLoader } from '..';

interface CreateFolderFileNodeModalProps {
  show: boolean;
  handleClose: any;
  defaultValue: string;
  onConfirm: (value: string) => void;
  parent?: FileNode;
}

const CreateFolderFileNodeModal: React.FC<CreateFolderFileNodeModalProps> = (props: CreateFolderFileNodeModalProps) => {
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(null);
  const { getCurrentOrganization } = useUser();
  const rootStructureQuery = useRootStructure(getCurrentOrganization().id);

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  const handleNewValue = ({ value }: InputChangeEvent) => {
    if (rootStructureQuery.isSuccess && rootStructureQuery.data && rootStructureQuery.data?.children) {
      const matchingFileNodes = rootStructureQuery.data.children.filter((rootFileNode) => {
        return rootFileNode.name === value;
      });
      if (matchingFileNodes.length > 0) {
        setError('A Project with the same name already exists. Enter a different name.');
      } else {
        setError(null);
      }
    }
    setValue(value);
  };

  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>{props.parent ? 'Create folder' : 'Create Project'}</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
      style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
    >
      <CornerLoader show={rootStructureQuery.isLoading || rootStructureQuery.isRefetching} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Input
          style={{ marginBottom: '0.5rem' }}
          valid={true}
          disabled={false}
          maxLength={255}
          value={value}
          onChange={handleNewValue}
        />
        {error !== null && (
          <Typography.h6
            style={{
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              color: 'var(--geosap-error)',
              textAlign: 'center',
            }}
          >
            {error}
          </Typography.h6>
        )}
        <SubmitButton
          type="button"
          label="Submit"
          uppercase={false}
          full={false}
          disabled={error !== null}
          onClick={() => {
            setValue(props.defaultValue);
            props.onConfirm(value);
          }}
          loading={false}
        />
      </div>
    </Dialog>
  );
};

export default CreateFolderFileNodeModal;
