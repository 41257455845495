import { LOCAL_STORAGE_CURRENT_ORG } from '../../common/constants';
import {
  TusUpload,
  TusUploadStatus,
} from '../../types';
import { UploadContextState } from './uploadContextState';
import { saveUpload, getUploads, deleteUpload, saveUploads } from '../../common/idbHelper';
import { Upload } from 'tus-js-client';

export interface UploadsVisibilityAction {
  type: 'UPLOADS_VISIBILITY';
  payload: {
    visibility: boolean;
    selectedUpload?: TusUpload | null;
  };
}

export interface SelectUploadsAction {
  type: 'SELECT_UPLOADS';
  payload: {
    upload: TusUpload;
  };
}

export interface UpdateUploadsAction {
  type: 'UPDATE_UPLOADS';
  payload: {
    uploads: TusUpload[];
  };
}

export interface AddUploadAction {
  type: 'ADD_UPLOAD';
  payload: {
    upload: TusUpload;
  };
}

export interface UpdateUploadStateAction {
  type: 'UPDATE_UPLOAD_STATE';
  payload: {
    fileId: string;
    status?: TusUploadStatus;
    progress?: number;
    errorMessage?: string;
    uploaded?: number;
    upload?: Upload;
  };
}

export interface RemoveUploadAction {
  type: 'REMOVE_UPLOAD';
  payload: {
    fileId: string;
  };
}

export type UploadContextAction =
  | UploadsVisibilityAction
  | SelectUploadsAction
  | UpdateUploadsAction
  | AddUploadAction
  | UpdateUploadStateAction
  | RemoveUploadAction;

export const uploadContextReducer = (currentState: UploadContextState, action: UploadContextAction): UploadContextState => {
  switch (action.type) {
    case 'UPLOADS_VISIBILITY':
      return {
        ...currentState,
        uploadsOpened: action.payload.visibility,
        selectedUpload: action.payload.selectedUpload?action.payload.selectedUpload:currentState.selectedUpload,
      };
    case 'SELECT_UPLOADS': {
      return {
        ...currentState,
        selectedUpload: action.payload.upload,
      };
    }
    case 'UPDATE_UPLOADS': {
      const newUploads = action.payload.uploads.map((upload) =>{
        saveUpload(upload);
        return upload;
      });
      return {
        ...currentState,
        uploadsRetrieved: true,
        uploads: action.payload.uploads,
      };
    }
    case 'ADD_UPLOAD': {
      // Check if uploads already contain the same id
      let newUploads = currentState.uploads;
      if(!currentState.uploads.find((upload) => upload.fileId === action.payload.upload.fileId)){
        newUploads = [...currentState.uploads, action.payload.upload];
      }
      saveUpload(action.payload.upload);
      return {
        ...currentState,
        uploadsRetrieved: true,
        uploads: newUploads,
      };
    }
    case 'UPDATE_UPLOAD_STATE':{
      const newUploads = currentState.uploads.map((upload) =>{
        if(action.payload.fileId === upload.fileId) {
          const newUpload = { ...upload, ...action.payload };
          saveUpload(newUpload);
          return newUpload;
        }
        else {
          return upload;
        }
      });
      return {
        ...currentState,
        uploads: newUploads
      };
    }
    case 'REMOVE_UPLOAD':{
      const newUploads = currentState.uploads.filter((upload) => {
        if(upload.fileId === action.payload.fileId){
          deleteUpload(upload.fileId);
          return false;
        }
        return true
      });
      return {
        ...currentState,
        uploads: newUploads
      };
    }
  }
};
