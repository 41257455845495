import React from 'react';
import SubmitButton, { SubmitButtonProps } from '../form/SubmitButton'; // Adjust the import path
import { AuthorityType, AuthorityLevel } from '../../types'; // Adjust the import path
import { useUser } from '../../hooks/authentication'; // Adjust the import path

interface RestrictedProps extends SubmitButtonProps {
  authorityType: AuthorityType;
  authorityLevel: AuthorityLevel;
}

const RestrictedSubmitButton: React.FC<RestrictedProps> = (props) => {
  const { userHasAuthority } = useUser();

  const { authorityType, authorityLevel, ...submitButtonProps } = props;

  if (!userHasAuthority(authorityType, authorityLevel)) {
    return null;
  }

  return <SubmitButton {...submitButtonProps} />;
};

export default RestrictedSubmitButton;
