import React, { ComponentType, useContext, useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import 'ol/ol.css';
import GeoJSON from 'ol/format/GeoJSON.js';
import Overlay from 'ol/Overlay';
import MapContext from '../Map/MapContext';
import { Layer } from 'ol/layer';
import OLTileLayer from 'ol/layer/Tile';
import OLWebTileLayer from 'ol/layer/WebGLTile';
import { SvgIcon } from '@progress/kendo-react-common';
import { xIcon } from '@progress/kendo-svg-icons';
import { useConsumeViewerState } from '../../../../context/viewer';
import { BandInfo, NavigationMode2D, ViewLayer } from '../../../../types';
import { Window, WindowProps } from '@progress/kendo-react-dialogs';
import styled from 'styled-components';
import { WrappedOrEllipsisSpan } from '../../../../components';

const StyledPopup = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid #cccccc;
  bottom: 12px;
  font-size: 0.8rem;
  background: black;
  color: white;

  &:after,
  &:before {
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    top: 99%;
    border-top-color: black;
    border-width: 10px;
    left: 50%;
    margin-left: -10px;
  }

  &:before {
    top: 100%;
    border-top-color: #cccccc;
    border-width: 11px;
    left: 50%;
    margin-left: -11px;
  }

  #popup-title {
    padding: 0.25rem 0.5rem;
    margin-right: 22px;
  }
  #popup-content {
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  }
`;

interface BandValue {
  name: string;
  value: number;
}

const PixelInfo: React.FC = () => {
  const { map } = useContext<any>(MapContext);
  const { layers, mode2d } = useConsumeViewerState();
  const [popup, setPopup] = useState<Overlay | null>(null);
  const [hovering, setHovering] = useState(false);
  const [pixelData, setPixelData] = useState<{ data: BandValue[]; coordinate: any }>(null);
  const modeRef = useRef<NavigationMode2D>(mode2d);

  useEffect(() => {
    if (!map) return;

    // Initialize popup
    const popupElement = document.getElementById('popup')!;
    const popupInstance = new Overlay({
      element: popupElement,
      positioning: 'bottom-center',
      stopEvent: false,
    });
    map.addOverlay(popupInstance);

    // Add click event handler
    map.on('click', (e: any) => {
      if (modeRef.current !== NavigationMode2D.NORMAL) return;
      const mapLayers = map.getAllLayers();
      let dataFound = false;
      mapLayers.forEach((mapLayer: Layer) => {
        if (mapLayer instanceof OLWebTileLayer) {
          const tileLayer: OLWebTileLayer = mapLayer as OLWebTileLayer;
          const data: any = tileLayer.getData(e.pixel);
          if (data && data.length > 0) {
            // Consider No data value
            if (data[data.length - 1] !== 0) {
              const viewLayerId = mapLayer.getProperties()['viewLayerId'];
              if (viewLayerId) {
                layers.forEach((layer: ViewLayer) => {
                  if (layer.id === viewLayerId) {
                    const bandInfos: BandInfo[] = layer.paramsMap['bands'];
                    const dataArray: number[] = Array.from(data);
                    setPixelData({
                      coordinate: e.coordinate,
                      data: bandInfos.map((bandInfo, index) => {
                        return { name: bandInfo.name, value: dataArray[index] };
                      }),
                    });
                  }
                });
                dataFound = true;
                return;
              }
            }
          }
        } else if (mapLayer instanceof OLTileLayer) {
          const clickedCoordinate = e.coordinate;
          const viewResolution = /** @type {number} */ map.getView().getResolution();
          let url = null;
          try {
            url = mapLayer.getSource().getFeatureInfoUrl(e.coordinate, viewResolution, 'EPSG:3857', {
              INFO_FORMAT: 'application/json',
            });
          } catch (e) {
            console.log();
          }
          if (url) {
            fetch(url).then((response) => {
              response.text().then((json) => {
                const features = new GeoJSON().readFeatures(JSON.parse(json));
                const viewLayerId = mapLayer.getProperties()['viewLayerId'];
                features.forEach((feature) => {
                  const properties = feature.getProperties();
                  if (viewLayerId) {
                    layers.forEach((layer: ViewLayer) => {
                      if (layer.id === viewLayerId) {
                        const bandInfos: BandInfo[] = layer.paramsMap['bands'];
                        setPixelData({
                          coordinate: e.coordinate,
                          data: bandInfos.map((bandInfo, index) => {
                            return { name: bandInfo.name, value: properties[bandInfo.name] };
                          }),
                        });
                      }
                    });
                  }
                  //setPixelData({ viewLayerId, coordinate: e.coordinate, data: Object.values(properties) });
                });
              });
            });
          }
        }
      });
      if (!dataFound) setPixelData(null);
    });

    setPopup(popupInstance);

    return () => {
      map.setTarget(null);
    };
  }, [map]);

  useEffect(() => {
    modeRef.current = mode2d;
    if (mode2d !== NavigationMode2D.NORMAL) {
      setPixelData(null);
    }
  }, [mode2d]);

  useEffect(() => {
    if (pixelData) {
      renderPopup(pixelData.coordinate, pixelData.data);
    } else {
      popup?.setPosition(undefined);
    }
  }, [pixelData]);

  const renderPopup = (coordinate: [number, number], data: BandValue[]) => {
    const a = () => {
      return (
        <div>
          {data.map((bandValue, index) => {
            return (
              <div key={index} style={{ display: 'flex' }}>
                <WrappedOrEllipsisSpan wrapped={false}>{`${data?.length > 1 ? data[index].name : 'Value'}: ${
                  bandValue.value
                }`}</WrappedOrEllipsisSpan>
              </div>
            );
          })}
        </div>
      );
    };
    if (popup) {
      popup.setPosition(coordinate);
      const jsxString = ReactDOMServer.renderToString(a());
      //popup.getElement().querySelector('#popup-title').innerHTML = viewLayer?.displayName;
      popup.getElement().querySelector('#popup-content').innerHTML = jsxString;
    }
  };

  return (
    <StyledPopup
      id="popup"
      className="ol-popup animated-general"
      style={{}}
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      <SvgIcon
        icon={xIcon}
        style={{
          position: 'absolute',
          right: '0.25rem',
          fontSize: '1.2rem',
          cursor: 'pointer',
          top: '0.25rem',
        }}
        onClick={() => {
          popup?.setPosition(undefined);
        }}
      />
      {/*<div id="popup-title"></div>*/}
      <div id="popup-content"></div>
    </StyledPopup>
  );
};

export default PixelInfo;
