import React, { useState, useRef, useEffect } from 'react';
import { Popup, Offset } from '@progress/kendo-react-popup';
import { Menu, MenuItem, MenuItemModel } from '@progress/kendo-react-layout';
import { AuthorityLevel, AuthorityType, EPSGProjectionInfos, LayerType, ViewLayer } from '../../../types';
import { Projection } from 'ol/proj';

interface ProjectionContextMenuProps {
  offset: Offset;
  availableProjections: EPSGProjectionInfos[];
  onProjectionSelected(projection: EPSGProjectionInfos): void;
}

const ProjectionContextMenu: React.FC<ProjectionContextMenuProps> = (props: ProjectionContextMenuProps) => {
  const availableOptions: MenuItemModel[] = [];
  props.availableProjections.forEach((projection) => {
    availableOptions.push({
      text: projection.name,
      data: {
        action: () => {
          props.onProjectionSelected(projection);
        },
      },
    });
  });

  return (
    <Popup show={props.offset !== null} offset={props.offset} popupClass={'popup-content'}>
      <Menu
        vertical={true}
        style={{ display: 'inline-block' }}
        items={availableOptions}
        onSelect={({ item, itemId, nativeEvent, target }) => {
          item.data.action();
        }}
      ></Menu>
    </Popup>
  );
};

export default ProjectionContextMenu;
