import { useQuery } from 'react-query';
import { SapFlowViewConfig, LayerType } from '../../../types';
import { useAxiosInstance } from '../../common';

const endpoint = 'sap_builder/builderblockdetails';

const useSapFlowBuildingBlocks = () => {
  const axios = useAxiosInstance();
  return useQuery(['buildingBlocks'], async () => {
    const result = await axios.get<any>(endpoint);
    return result.data;
  });
};

export default useSapFlowBuildingBlocks;
