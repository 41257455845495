import {useQuery} from 'react-query';
import { useAxiosInstance } from '../../common/';
import {SapFlowTransactionItem} from "../../../types/SapFlowTransaction";

const useListTransactionItems = (userId: string, transactionId: string) => {
    const axios = useAxiosInstance();
    return useQuery(
        ['listTransaction', transactionId],
        async () => {
            const result = await axios.get<SapFlowTransactionItem[]>("sapflow/" + transactionId + "/itemnode");
            return result.data;
        },
        { retry: false }
    );


};

export default useListTransactionItems;
