import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Typography } from '@progress/kendo-react-common';
import { useUser } from '../../hooks/authentication';
import { useStripeSubscription, useCancelStripeSubscription } from '../../hooks/billing';
import { SubmitButton } from '../../components/form';
import { StyledTextButton } from '../../components/styled';
import { useAppContext } from '../../context/app';
import { OrgBillingDetails } from '../../types';
import { GEOSAP_PLAN_BASIC, GEOSAP_PLAN_PRO, GEOSAP_PLAN_ENTREPRISE, GEOSAP_PLANS } from '../../common/subscriptionPlans';

const AccountOverview: React.FC = () => {
  const { getCurrentOrganization } = useUser();
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = React.useState<typeof GEOSAP_PLAN_BASIC | typeof GEOSAP_PLAN_PRO | typeof GEOSAP_PLAN_ENTREPRISE>(GEOSAP_PLAN_BASIC);
  const [currentPlan, setCurrentPlan] = React.useState<null | typeof GEOSAP_PLAN_BASIC | typeof GEOSAP_PLAN_PRO | typeof GEOSAP_PLAN_ENTREPRISE>(null);
  const [billingDetails, setBillingDetails] = React.useState<OrgBillingDetails>(null);
  const [subscriptionActive, setSubscriptionActive] = React.useState<boolean>(false);
  const [purchasingSubscription, setPurchasingSubscription] = React.useState<boolean>(false);
  const [changingSubscription, setChangingSubscription] = React.useState<boolean>(false);
  const stripeSubscriptionMutation = useStripeSubscription();
  const stripeCancellationMutation = useCancelStripeSubscription();
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();

  useEffect(() => {
    const billingDetails = getCurrentOrganization().orgBillingDetails;
    if(billingDetails) {
      if(billingDetails.subscriptionActive) {
        setSubscriptionActive(billingDetails.subscriptionActive);
        if(billingDetails.stripeProductId === GEOSAP_PLANS[GEOSAP_PLAN_BASIC].productId) {
          setCurrentPlan(GEOSAP_PLAN_BASIC);
        }
        else if(billingDetails.stripeProductId === GEOSAP_PLANS[GEOSAP_PLAN_PRO].productId) {
          setCurrentPlan(GEOSAP_PLAN_PRO);
        }
        else if(billingDetails.stripeProductId === GEOSAP_PLANS[GEOSAP_PLAN_ENTREPRISE].productId) {
          setCurrentPlan(GEOSAP_PLAN_ENTREPRISE);
        }
        else {
          dispatch({
            type: 'SHOW_NOTIFICATION',
            payload: { notification: { type: 'error', content: 'Could not find matching Subscription plan.' } },
          });
          setPurchasingSubscription(true);
          setChangingSubscription(true);
        }
      }
      setBillingDetails(billingDetails);
    }
  }, []);

  useEffect(() => {
    if(stripeSubscriptionMutation.isSuccess) {
      console.log("Successfully created a Stripe Subscription " + JSON.stringify(stripeSubscriptionMutation.data));
      navigate("/checkout", { state: { clientSecret: stripeSubscriptionMutation.data.clientSecret } });
    }
  }, [stripeSubscriptionMutation.isLoading, stripeSubscriptionMutation.isSuccess, stripeSubscriptionMutation.data]);

  useEffect(() => {
    if(stripeCancellationMutation.isSuccess) {
      console.log("Successfully cancelled a Stripe Subscription " + JSON.stringify(stripeSubscriptionMutation.data));
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { type: 'success', content: 'Successfully cancelled a GeoSAP Subscription.' } },
      });
      queryClient.invalidateQueries(['organization', getCurrentOrganization().id], { refetchInactive: true });
      //navigate("/checkout", { state: { clientSecret: stripeSubscriptionMutation.data.clientSecret } });
    }
  }, [stripeCancellationMutation.isLoading, stripeCancellationMutation.isSuccess, stripeCancellationMutation.data]);

  const handleSelectPlan = (plan:any) => {
    setSelectedPlan(plan);
  }

  const handlePurchasePlan = () => {
    setPurchasingSubscription(true);
    if(selectedPlan === GEOSAP_PLAN_ENTREPRISE) {
      // The entreprise plan has customized pricing in the Database.
      const billingDetails = getCurrentOrganization().orgBillingDetails;
      if(billingDetails && billingDetails.stripeEntreprisePriceId) {
        // Customer has a price ID assign, attempt a purchase with it.
        stripeSubscriptionMutation.mutateAsync({productId: GEOSAP_PLANS[selectedPlan].productId, priceId: billingDetails.stripeEntreprisePriceId}); 
      }
      else {
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: { notification: { type: 'error', content: 'Could not find customized Subscription plan.' } },
        }); 
      }
    }
    else {
      stripeSubscriptionMutation.mutateAsync({productId: GEOSAP_PLANS[selectedPlan].productId, priceId: GEOSAP_PLANS[selectedPlan].priceId});
    }
  }

  const handleChangePlan = () => {
    dispatch({
      type: 'SHOW_NOTIFICATION',
      payload: { notification: { type: 'error', content: 'Subscription change aren\'t available at this time. Please try again later.' } },
    }); 
  }
  const renderCurrentPlanStatus = () => {
    if(billingDetails.billingActive) {
      return (<div className="border p-2 text-center" style={{color: "white", backgroundColor: "var(--geosap-baby-blue)", marginTop: "-1px", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px"}}>
        <span>{"Re-billing on " + billingDetails.subscriptionExpiration}</span>
      </div>);
    }
    else {
      return (
        <div className="border p-2 text-center" style={{color: "white", backgroundColor: "var(--geosap-light-error)", marginTop: "-1px", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px"}}>
          <span>{"Subscription expiring on " + billingDetails.subscriptionExpiration}</span>
        </div>
      );
    }
  }

  const renderCurrentPlan = () => {
    if(subscriptionActive && currentPlan) {
      return (
        <div className="py-4">
          <Typography.h5 className=''>
            Your Plan
          </Typography.h5>
          <div className="d-flex align-items-center border p-2 justify-content-around">
            <span>{GEOSAP_PLANS[currentPlan].title}</span>
            <span style={{fontSize: "0.8rem", color: "var(--geosap-secondary-color)"}}>{GEOSAP_PLANS[currentPlan].description}</span>
            <span style={{fontSize: "0.8rem", color: "var(--geosap-secondary-color)"}}>{GEOSAP_PLANS[currentPlan].price}</span>
          </div>
          {renderCurrentPlanStatus()}
        </div>
      );
    }
    else {
      return (
        <div className="py-4">
          <Typography.h5 className=''>
            No plan currently active.
          </Typography.h5>
        </div>
      );
    }
  }

  return (
    <div className="container-fluid p-2 border bg-white">
      <div className="d-flex flex-column col-12 col-md-8 mx-auto">
        <Typography.h2 className='text-center pt-3 pb-1'>
          PLANS & BILLING
        </Typography.h2>

        {renderCurrentPlan()}

        <div className="py-4">
          <Typography.h5 className=''>
            {currentPlan ? "Change Plan" : "Select Plan"}
          </Typography.h5>
          <div className="d-flex row w-100">
            {Object.keys(GEOSAP_PLANS).map((planType: typeof GEOSAP_PLAN_BASIC | typeof GEOSAP_PLAN_PRO | typeof GEOSAP_PLAN_ENTREPRISE, index)=>{
              return (
                <div key={index} className="col-12 col-sm-4 d-flex flex-column position-relative">
                  <div className="d-flex flex-column border p-2 mx-1 text-center cursor-pointer" style={{height: "180px"}} onClick={()=>handleSelectPlan(planType)}>
                    <input
                      className="my-1 cursor-pointer"
                      type="radio"
                      value={planType}
                      checked={selectedPlan === planType}
                      onChange={()=>handleSelectPlan(planType)}/>
                    <span className="py-1 fw-bold">{GEOSAP_PLANS[planType].title}</span>
                    <span className="py-1" style={{fontSize: "0.8rem", color: "var(--geosap-secondary-color)", whiteSpace: "pre-wrap"}}>{GEOSAP_PLANS[planType].description}</span>
                    <span className="mt-auto" style={{fontWeight: "bold", fontSize: "0.8rem", color: "var(--geosap-secondary-color)"}}>{GEOSAP_PLANS[planType].price}</span>
                  </div>
                  {currentPlan===planType && 
                    <div className="border p-2 mx-1 text-center" style={{color: "white", backgroundColor: "var(--geosap-selected-blue)", marginTop: "-1px", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px"}}>
                      <span>Current Plan</span>
                    </div>
                  }
                </div>);
            })}
          </div>
          {subscriptionActive && 
            <div className="py-2 w-100 text-center">
              <StyledTextButton onClick={()=>{stripeCancellationMutation.mutateAsync()}}>Cancel Subscription</StyledTextButton>
            </div>}
          <div className="py-2 d-flex justify-content-center">
            {currentPlan &&
              <SubmitButton label={"Change Plan"} disabled={currentPlan === selectedPlan || selectedPlan === GEOSAP_PLAN_ENTREPRISE} loading={changingSubscription} full={false} onClick={()=>{handleChangePlan()}}/>
            }
            {!currentPlan &&
              <SubmitButton label={"Purchase Plan"} disabled={selectedPlan === GEOSAP_PLAN_ENTREPRISE && !billingDetails.stripeEntreprisePriceId} loading={purchasingSubscription} full={false} onClick={()=>{handlePurchasePlan()}}/>    
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountOverview;
