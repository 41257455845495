import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CaretRightFill, CaretLeftFill } from 'react-bootstrap-icons';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { SapFlowProject } from '../../types';
import { usePaginatedProjects } from '../../hooks/sapflow';

const PAGE_SIZE = 3;

const StyledLink = styled(Link)`
  text-decoration: none;
  background: linear-gradient(#fff, #828282);
  width: 200px;
  height: 120px;
  &:hover {
    color: unset;
    text-decoration: underline;
  }
`;

const StyledPagesBox = styled.span`
  width: 9.5rem;
  display: inline-block;
`;

const StyledPageBtnBox = styled.span<{ active: boolean }>`
  color: ${({ active }) => (active ? 'var(--geosap-secondary-color)' : 'unset')};
  cursor: ${({ active }) => (active ? 'unset' : 'pointer')};
`;

const ProjectList: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const projectsQuery = usePaginatedProjects(currentPage, PAGE_SIZE, ['lastModifiedDate', 'asc']);

  const getLastPage = () => Math.ceil(projectsQuery.data.totalElements / PAGE_SIZE) - 1;

  const padNumber = (n: number, length: number) => n.toString(10).padStart(length, '0');

  const getPages = (): string => {
    const maxLength = projectsQuery.data.totalElements.toString(10).length;
    const start = padNumber(projectsQuery.data.pageable.offset + 1, maxLength);
    const end = padNumber(projectsQuery.data.pageable.offset + projectsQuery.data.numberOfElements, maxLength);
    return `Viewing ${start} - ${end} of ${projectsQuery.data.totalElements}`;
  };

  const handleBackBtnClick = () =>
    setCurrentPage((currentState) => (currentState > 0 ? currentState - 1 : currentState));

  const handleNextBtnClick = () =>
    setCurrentPage((currentState) => (currentState < getLastPage() ? currentState + 1 : currentState));

  return (
    <div className="container-fluid px-0 pt-3">
      <div className="row g-3">
        {projectsQuery.isLoading && (
          <div className="col-auto">
            <Skeleton shape="rectangle" style={{ height: 120, width: 200 }} />
          </div>
        )}
        {projectsQuery.isSuccess &&
          projectsQuery.data.content.map((item) => (
            <div className="col-auto" key={item.id}>
              <StyledLink
                to={`/project/${item.id}`}
                className="d-flex justify-content-center align-items-center rounded-3 text-reset p-1"
              >
                <span className="pb-4 text-center">{item.name}</span>
              </StyledLink>
            </div>
          ))}
      </div>
      {projectsQuery.isSuccess && projectsQuery.data.totalElements > PAGE_SIZE && (
        <div className="row-12 pt-2">
          <Typography.p className="pe-2" fontSize="small" fontWeight="bold" themeColor="primary">
            <StyledPagesBox>{getPages()}</StyledPagesBox>
            <span className="fs-5 mb-1 ms-2">
              <StyledPageBtnBox active={currentPage === 0}>
                <CaretLeftFill onClick={handleBackBtnClick} />
              </StyledPageBtnBox>
              <StyledPageBtnBox active={currentPage === getLastPage()}>
                <CaretRightFill onClick={handleNextBtnClick} />
              </StyledPageBtnBox>
            </span>
          </Typography.p>
        </div>
      )}
    </div>
  );
};

export default ProjectList;
