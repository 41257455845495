function relativeTimeSince(dateString: string): string {
    const inputDate = new Date(`${dateString} EDT`);
    const currentDate = new Date();
    const diffInSeconds = (currentDate.getTime() - inputDate.getTime()) / 1000;

    // Less than 1 hour
    if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    }

    // Less than 16 hours
    if (diffInSeconds < 57600) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    }

    // Less than 7 days
    if (diffInSeconds < 604800) {
        const days = Math.floor(diffInSeconds / 86400);
        return `${days} day${days === 1 ? '' : 's'} ago`;
    }

    // More than 7 days
    return dateString.split(' ')[0];  // Returns only the date part
}
export default relativeTimeSince;