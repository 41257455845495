import React from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Reveal } from '@progress/kendo-react-animation';
import { truncateText } from '../common/stringHelper';
import { ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import StyledExpansionPanel from '../components/styled/StyledExpansionPanel';

interface Props {
    id: string;
    title: string;
    expanded: boolean;
    children: any;
    disabled?: boolean;
    onAction: (expanded: boolean) => void;
}

const SelectGeotif: React.FC<Props> = ({title, expanded, onAction, children, disabled}) => {

  const handleExpansionPanelAction = (event: ExpansionPanelActionEvent) => {
    onAction(event.expanded);
  };

  return (
    <StyledExpansionPanel
        title={
          <>
            <Typography.h3 className="flex-grow-1 text-capitalize">
              {truncateText(title, 46)}
            </Typography.h3>
          </>
        }
        className={disabled?"disabled":""}
        expanded={expanded}
        tabIndex={0}
        onAction={handleExpansionPanelAction.bind(undefined)}
        expandIcon="k-icon k-i-arrow-chevron-right animated-transform rotated-0"
        collapseIcon="k-icon k-i-arrow-chevron-right animated-transform rotated--90"
      >
        <Reveal>
          {expanded && (
            <ExpansionPanelContent>
                {children}
            </ExpansionPanelContent>
          )}
        </Reveal>
      </StyledExpansionPanel>
  );
};

export default SelectGeotif;
