import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { BackgroundContainer, Icon } from '../components/layout';
import { SubmitButton } from '../components/form';
import { useSignout, useUser } from '../hooks/authentication';

const NoOrganizationAvailable: React.FC = () => {
  const { getUser, getAccessToken, getCurrentOrganization, userHasRole } = useUser();
  const signoutMutation = useSignout();
  const navigate = useNavigate();

  const handleLogout = () => {
    const { email } = getUser();
    const bearerToken = getAccessToken();
    signoutMutation.mutateAsync({ email, bearerToken }).then(() => {
      navigate('/login');
    });
  };

  return (
    <BackgroundContainer style={{ overflow: 'auto' }}>
      <div className="container">
        <div className="row align-items-center justify-content-center vh-100">
          <div className="col-xs-12 col-lg-6 col-xl-4 pt-4">
            <Typography.h2 textAlign="center" fontWeight="light">
              No organization available.
            </Typography.h2>
            <Typography.p textAlign="center" className="py-2">
              {'Your user account is not associated to any Organization.'}
            </Typography.p>
            <div className="p-3 flex-column d-flex align-items-center">
              <SubmitButton
                label="Log out"
                onClick={handleLogout}
                themeColor="base"
                fillMode="outline"
                loading={signoutMutation.isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </BackgroundContainer>
  );
};

export default NoOrganizationAvailable;
