import React, { useState, useEffect } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { mapTree, extendDataItem, TreeListCellProps } from '@progress/kendo-react-treelist';
import { useParams } from 'react-router-dom';
import { SvgIcon } from '@progress/kendo-react-common';
import { downloadIcon } from '@progress/kendo-svg-icons';
import { ItemNodeFilesDetails } from '../../types';
import { useTable } from '../../hooks/common';
import { useUser } from '../../hooks/authentication';
import { StyledTreeList } from '../../components/styled';
import { formatSizeUnits } from '../../converters/formatSizeUnits';
import { v4 as uuidv4 } from 'uuid';
const subItemsField = 'items';
const expandField = 'expanded';

enum RowType {
  Type,
  Item,
  File,
}

const FileGridActionCell: React.FC<TreeListCellProps> = ({ dataItem, level }) => {
  console.log(dataItem.name + ': Level: ' + JSON.stringify(level));
  const { getUser } = useUser();
  const { transactionId } = useParams();
  let downloadUrl = '';
  if (dataItem.type === RowType.Type) {
    return <td></td>;
  } else if (dataItem.type === RowType.Item) {
    downloadUrl =
      process.env.REACT_APP_GEOSAP_SERVERURL +
      '/file_manager/download_items?user_id=' +
      getUser().id +
      '&transaction_id=' +
      transactionId +
      '&item_id_list=' +
      dataItem.id +
      '&filename=' +
      dataItem.name;
  } else if (dataItem.type === RowType.File) {
    downloadUrl =
      process.env.REACT_APP_GEOSAP_SERVERURL +
      '/file_manager/download_files?user_id=' +
      getUser().id +
      '&transaction_id=' +
      transactionId +
      '&item_id=' +
      dataItem.parentId +
      '&filename_list=' +
      dataItem.name +
      '&filename=' +
      dataItem.name;
  }
  return (
    <td>
      <a
        //className={`${false ? '' : 'disabled'}`}
        download
        href={downloadUrl}
      >
        <SvgIcon icon={downloadIcon} style={{ fontSize: '1.2rem' }} />
      </a>
    </td>
  );
};

const columns = [
  {
    field: 'name',
    title: 'Name',
    width: '55%',
    className: 'mw-200',
    expandable: true,
  },
  //{
  //  field: "format",
  //  title: "Format",
  //  width: "18%",
  //  format: "{0:d}",
  //},
  {
    field: 'fileSize',
    title: 'File Size',
    width: '12%',
  },
  {
    field: 'date',
    title: 'Created date',
    width: '15%',
  },
  {
    field: '',
    title: 'Actions',
    width: '15%',
    cell: (cellProps: any) => <FileGridActionCell {...cellProps} />,
  },
];

interface RowModel {
  id: string;
  name: string;
  format: string;
  fileSize: string;
  date: string;
  status: string;
  expanded: boolean;
  items: RowModelItem[];
}

type RowModelItem = Omit<RowModel, 'items' | 'expanded'>;

const mockData: RowModel[] = [
  {
    id: uuidv4(),
    name: 'Uploads',
    format: '',
    fileSize: '0MB',
    date: '-',
    status: '-',
    expanded: false,
    items: [],
  },
  {
    id: uuidv4(),
    name: 'Outputs',
    format: '',
    fileSize: '0MB',
    date: '-',
    status: '-',
    expanded: false,
    items: [],
  },
];

interface Props {
  isLoading?: boolean;
  isSuccess?: boolean;
  inputFileData: ItemNodeFilesDetails[];
  outputFileData: ItemNodeFilesDetails[];
}

const Files: React.FC<Props> = ({ isLoading, isSuccess, inputFileData, outputFileData }) => {
  const [state, setState] = React.useState({
    data: [],
    dataState: {
      sort: [
        {
          field: 'name',
          dir: 'asc',
        },
      ],
      filter: [],
    },
    expanded: [1, 2, 32],
  });
  const onExpandChange = (e: any) => {
    setState({
      ...state,
      expanded: e.value ? state.expanded.filter((id) => id !== e.dataItem.id) : [...state.expanded, e.dataItem.id],
    });
  };
  const handleDataStateChange = (event: any) => {
    setState({
      ...state,
      dataState: event.dataState,
    });
  };
  const addExpandField = (dataTree: any) => {
    const expanded = state.expanded;
    return mapTree(dataTree, subItemsField, (item) =>
      extendDataItem(item, subItemsField, {
        [expandField]: expanded.includes(item.id),
      })
    );
  };
  const processData = () => {
    const { data, dataState } = state;
    return addExpandField(data);
  };

  const [data, setData] = useState<RowModel[]>(mockData);
  const tableProps = useTable({
    pageSize: 10,
    data,
    sortDescriptor: { field: 'created', dir: 'desc' },
  });

  useEffect(() => {
    if (isSuccess) {
      console.log('inputFileData: ' + JSON.stringify(inputFileData));
      const newTreeData: any = [
        {
          id: uuidv4(),
          name: 'Uploads',
          fileSize: formatSizeUnits(
            inputFileData.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0)
          ),
          type: RowType.Type,
          items: inputFileData.map((itemNodesFilesData) => {
            return {
              id: itemNodesFilesData.id,
              name: itemNodesFilesData.name,
              format: itemNodesFilesData.formats.toString(),
              fileSize: formatSizeUnits(itemNodesFilesData.size),
              type: RowType.Item,
              date: itemNodesFilesData.createdDate,
              items: itemNodesFilesData.files.map((fileElement: any) => {
                return {
                  name: fileElement.name,
                  id: fileElement.fileURIPath,
                  fileSize: formatSizeUnits(fileElement.size),
                  type: RowType.File,
                  parentId: fileElement.parent_item_id,
                };
              }),
            };
          }),
        },
        {
          id: uuidv4(),
          name: 'Outputs',
          fileSize: formatSizeUnits(
            outputFileData.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0)
          ),
          type: RowType.Type,
          items: outputFileData.map((fileData) => {
            return {
              id: fileData.id,
              name: fileData.name,
              fileSize: formatSizeUnits(fileData.size),
              type: RowType.Item,
              date: fileData.createdDate,
              items: fileData.files.map((fileElement: any) => {
                return {
                  name: fileElement.name,
                  id: fileElement.fileURIPath,
                  fileSize: formatSizeUnits(fileElement.size),
                  type: RowType.File,
                  parentId: fileElement.parent_item_id,
                };
              }),
            };
          }),
        },
      ];
      setData(newTreeData);
      setState({
        ...state,
        data: newTreeData,
      });
    }
  }, [isSuccess, inputFileData, outputFileData]);

  return (
    <div className="pb-4">
      <Typography.h4>Sap Flow Files</Typography.h4>

      <StyledTreeList
        style={{
          overflow: 'auto',
        }}
        expandField={expandField}
        subItemsField={subItemsField}
        onExpandChange={onExpandChange}
        data={processData()}
        onDataStateChange={handleDataStateChange}
        columns={columns}
      />
    </div>
  );
};

export default Files;
