import styled from 'styled-components';

const StyledTextButton = styled.div`
  background: white;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  left: 0px;
  width: 32px;
  top: 80px;
  z-index: 5;
`;

export default StyledTextButton;
