import { useQuery, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../common';
import { ShareLinkInfos } from '../../types';

interface Params {
    shareId: string
}

const useGenerateViewToken = (shareId: string | undefined) => {
  const axios = useAxiosInstance();
  return useQuery(['shareToken', shareId], async () => {
    const result = await axios.get<ShareLinkInfos>("/user_management/sharing/" + shareId + "/generateToken");
    return result.data;
  });
};

export default useGenerateViewToken;
