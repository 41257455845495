import React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label, Error } from '@progress/kendo-react-labels';

export interface FieldBaseProps {
  name: string;
  label?: string;
  error?: string;
}

interface Props extends FieldBaseProps {
  children: JSX.Element;
}

const FieldBase: React.FC<Props> = ({ name, label, error, children }) => {
  const isValid = !error;

  return (
    <FieldWrapper className="py-2">
      {label && (
        <Label editorId={name} editorValid={isValid}>
          {label}
        </Label>
      )}
      <div className={'k-form-field-wrap'}>
        {children}
        {!isValid && <Error>{error}</Error>}
      </div>
    </FieldWrapper>
  );
};

export default FieldBase;
