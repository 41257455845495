import { useQuery } from 'react-query';
import axios from 'axios';
import xmldom from 'xmldom';
import WMSCapabilities from 'wms-capabilities';

const service = 'wms';
const request = 'GetCapabilities';

const useWmsCapabilities = (wmsUrl: string) => {
  const url = wmsUrl + '?SERVICE=' + service + /*'&version=' + version + */'&request=' + request;
  return useQuery(['getCapabilities', service, wmsUrl], async () => {
    const result = await axios.get<any>(url);
    const capabilities = new WMSCapabilities(result.data, xmldom.DOMParser).toJSON();
    console.log("Capabilities: " + JSON.stringify(capabilities));
    return capabilities;
  });
};

export default useWmsCapabilities;