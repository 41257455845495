import { QueryClient } from 'react-query';
import { SapFlowTransaction, SapFlowTransactionStatus, WSMessage } from '../../../types';
import { SapFlow, SapFlowGroup, SapGroupStatus } from '../../../types/DataDelivery';

const handleMessage = (wsMessage: WSMessage, queryClient: QueryClient) => {
  const groupStatus: SapGroupStatus = wsMessage.payload;
  if (status !== null && groupStatus.groupID) {
    let groupsData: SapFlowGroup[] | undefined = null;
    try {
      groupsData = JSON.parse(JSON.stringify(queryClient.getQueryData(['sapGroups'])));
    } catch (e) {
      return;
    }
    const findAndModifyGroupSapflow = (group: SapFlowGroup) => {
      if (group.id === groupStatus.groupID) {
        group.status = groupStatus.status;
      }
      if (group.groups && group.groups.length > 0) {
        group.groups.forEach((group) => {
          findAndModifyGroupSapflow(group);
        });
      }
    };
    groupsData.forEach((group: SapFlowGroup) => {
      findAndModifyGroupSapflow(group);
    });

    queryClient.setQueryData(['sapGroups'], groupsData);
  } else {
    console.log('Invalid Trans Status update');
  }
};

export default handleMessage;
