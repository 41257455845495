import React, {useEffect, useState} from 'react';
import { Typography } from '@progress/kendo-react-common';
import { SapFlowConfigItemNode, SapFlowTransaction } from '../../types';

const SummaryDeliverables: React.FC<SapFlowTransaction> = (sapFlowInfos) => {
  const [deliverables, setDeliverables] = useState<SapFlowConfigItemNode[]>([]);

  useEffect(()=>{
    setDeliverables(sapFlowInfos.itemnodes.filter((itemNode)=>{return itemNode.type === "GENERATED"}))
  },[sapFlowInfos]);

  return (
    <div style={{ fontSize: '0.8rem' }}>
      <p className="px-4">The following deliverables are output through the {sapFlowInfos.sapflowname} SAPFlow:</p>
      <ul className="overflow-auto" style={{ maxHeight: '500px' }}>
        {deliverables.map(({ id, name, /*description,*/ fileformat }, index) => (
          <li key={id} className={`border border-dark px-4 py-3 rounded-3 ${index ? 'mt-2' : ''}`}>
            <Typography.h6>{name}</Typography.h6>
            <p className="mb-0">
              {/*<strong>Description: </strong>
              {description}
              <br />*/}
              <strong>Output format: </strong>
              {fileformat}
              <br />
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SummaryDeliverables;
