import React from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { REQUIRED_FIELD, VALID_EMAIL_FIELD, VALID_PASSWORD_FIELD } from '../../common/constants';
import { useChangePassword, useUser } from '../../hooks/authentication';
import { Input, SubmitButton } from '../../components/form';
import { useUserUpdate } from '../../hooks/user_management';

interface UserInfosFormValues {
  firstName: string;
  lastName: string;
  email: string;
}

interface PasswordChangeFormValues {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

const userInfosValidationSchema = yup.object({
  firstName: yup.string().required(REQUIRED_FIELD),
  lastName: yup.string().required(REQUIRED_FIELD),
  email: yup.string().required(REQUIRED_FIELD).email(VALID_EMAIL_FIELD),
});

const passwordChangeValidationSchema = yup.object({
  currentPassword: yup.string().required(REQUIRED_FIELD),
  password: yup
    .string()
    .required(REQUIRED_FIELD)
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/, VALID_PASSWORD_FIELD),
  passwordConfirmation: yup
    .string()
    .test('passwords-match', "Password confirmation doesn't match", (value, { parent }) => value === parent.password),
});

const AccountOverview: React.FC = () => {
  const { getUser } = useUser();
  const updateUserMutation = useUserUpdate(getUser().id);
  const changePasswordMutation = useChangePassword();
  const userInfosForm = useForm<UserInfosFormValues>({
    defaultValues: {
      firstName: getUser().firstName,
      lastName: getUser().lastName,
      email: getUser().email,
    },
    resolver: yupResolver(userInfosValidationSchema),
  });
  const changePasswordForm = useForm<PasswordChangeFormValues>({
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
    resolver: yupResolver(passwordChangeValidationSchema),
  });

  const submitUserInfos = (data: UserInfosFormValues) => {
    updateUserMutation.mutateAsync({ firstName: data.firstName, lastName: data.lastName });
  };

  const submitNewPassword = (data: PasswordChangeFormValues) => {
    changePasswordMutation.mutateAsync({ currentPassword: data.currentPassword, newPassword: data.password });
  };

  return (
    <div className="container-fluid p-2 border bg-white">
      <div className="d-flex flex-column col-12 col-md-8 mx-auto">
        <Typography.h5 className="">User Information</Typography.h5>

        <form onSubmit={userInfosForm.handleSubmit(submitUserInfos)}>
          <div className="d-flex flex-column py-3">
            <div className="d-flex">
              <span className="col-3 text-center d-flex align-items-center ps-3">FIRST NAME</span>
              <Controller
                control={userInfosForm.control}
                name="firstName"
                render={({ field, fieldState: { error } }) => (
                  <Input {...field} error={error?.message} type="text" placeholder="" className="col-9" />
                )}
              />
            </div>
            <div className="d-flex">
              <span className="col-3 text-center d-flex align-items-center ps-3">LAST NAME</span>
              <Controller
                control={userInfosForm.control}
                name="lastName"
                render={({ field, fieldState: { error } }) => (
                  <Input {...field} error={error?.message} type="text" placeholder="" className="col-9" />
                )}
              />
            </div>
            <div className="d-flex">
              <span className="col-3 text-center d-flex align-items-center ps-3">EMAIL</span>
              <Controller
                control={userInfosForm.control}
                name="email"
                render={({ field, fieldState: { error } }) => (
                  <Input {...field} error={error?.message} type="text" placeholder="" className="col-9" disabled />
                )}
              />
            </div>
            <div className="pt-1 d-flex justify-content-center">
              <SubmitButton
                label="Save Changes"
                disabled={!userInfosForm.formState.isDirty}
                full={false}
                loading={updateUserMutation.isLoading}
              />
            </div>
          </div>
        </form>

        <form onSubmit={changePasswordForm.handleSubmit(submitNewPassword)}>
          <div className="d-flex flex-column py-3">
            <div className="d-flex">
              <span className="col-3 text-center d-flex align-items-center ps-3">Current Password</span>
              <Controller
                control={changePasswordForm.control}
                name="currentPassword"
                render={({ field, fieldState: { error } }) => (
                  <Input {...field} error={error?.message} type="password" placeholder="" className="col-9" />
                )}
              />
            </div>
            <div className="d-flex">
              <span className="col-3 text-center d-flex align-items-center ps-3">New Password</span>
              <Controller
                control={changePasswordForm.control}
                name="password"
                render={({ field, fieldState: { error } }) => (
                  <Input {...field} error={error?.message} type="password" placeholder="" className="col-9" />
                )}
              />
            </div>
            <div className="d-flex">
              <span className="col-3 text-center d-flex align-items-center ps-3">Password Confirmation</span>
              <Controller
                control={changePasswordForm.control}
                name="passwordConfirmation"
                render={({ field, fieldState: { error } }) => (
                  <Input {...field} error={error?.message} type="password" placeholder="" className="col-9" />
                )}
              />
            </div>
            <div className="pt-1 d-flex justify-content-center">
              <SubmitButton
                label="Change Password"
                disabled={!changePasswordForm.formState.isDirty}
                full={false}
                loading={changePasswordMutation.isLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccountOverview;
