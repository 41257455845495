import { useMutation } from 'react-query';
import { useAxiosInstance } from '../common';
import { SapFlowItemStatus } from '../../types/DataDelivery';

interface Params {
  sapFlowId: string;
  processNodeId: string;
  status: SapFlowItemStatus;
}

const useSetSapFlowProcessNodeStatus = () => {
  const axios = useAxiosInstance();

  return useMutation(
    async (params: Params) => {
      return axios.put(
        '/file_sapflow/' + params.sapFlowId + '/' + params.processNodeId + '/status',
        {},
        { params: { newstatus: params.status } }
      );
    },
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(['transactions'], { refetchInactive: true });
      },
    }
  );
};

export default useSetSapFlowProcessNodeStatus;
