import { useMutation } from 'react-query';
import { SigninRequest } from '../../types';
import { useAppContext } from '../../context/app';
import { useAxiosInstance } from '../common';

const endpoint = '/authentication/resend_confirmation_email';

const useConfirmEmail = () => {
  const { dispatch } = useAppContext();
  const axios = useAxiosInstance();

  return useMutation(async (newUser: SigninRequest) => { 
    await axios.post<any>(endpoint, newUser);
    dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { type: 'success', content: 'Successfully resent confirmation email.' } },
      });
  });
};

export default useConfirmEmail;