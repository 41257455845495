import {Organization} from './Organization';

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  organizations?: Organization[];
}

export enum UserRole {
  ROLE_SYSTEMADMIN = "ROLE_SYSTEMADMIN",
  ROLE_SUPERADMIN = "ROLE_SUPERADMIN",
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_SUPERUSER = "ROLE_SUPERUSER",
  ROLE_USER = "ROLE_USER",
  ROLE_VIEWUSER = "ROLE_VIEWUSER"
}