import { ProjectInfo, Organization, SapFlowProjectWithSapFlows } from '../types';

export const toProjectInfo = (sapFlowProject: SapFlowProjectWithSapFlows, organization: Organization): ProjectInfo => ({
  id: sapFlowProject.id,
  name: sapFlowProject.name,
  description: sapFlowProject.description,
  typeId: 'NOT_IMPLEMENTED',
  typeName: 'not implemented',
  lastEdited: new Date(sapFlowProject.lastModifiedDate).toLocaleDateString(),
  ownerId: organization.id,
  ownerName: organization.name,
  coordinateSystem: 'not implemented',
  numberOfFiles: sapFlowProject.sapflows
    .map((sapflow) => {
      return sapflow.itemnodes.length;
    })
    .reduce((accumulator, value) => {
      return accumulator + value;
    }, 0),
  filesUploaded: sapFlowProject.sapflows.reduce((accumulator, sapflow) => {
    sapflow.itemnodes.forEach((itemnode) => {
      if (itemnode.type === 'SUPPLIED') accumulator = accumulator + 1;
    });
    return accumulator;
  }, 0),
  typesOfDeliverables: 'not implemented',
  creditsUsed: 0,
  totalProcessingTime: 'not implemented',
});
