import React, { Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, SubmitButton } from '../../../../components/form';
import { Typography } from '@progress/kendo-react-common';
import { Reveal } from '@progress/kendo-react-animation';
import { Skeleton } from '@progress/kendo-react-indicators';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import StyledExpansionPanel from '../../../../components/styled/StyledExpansionPanel';
import { useAppContext } from '../../../../context/app';
import styled from 'styled-components';
import { AddLayerConfig } from '../../../../types';
import { truncateText } from '../../../../common/stringHelper';
import { useConsumeAddLayerWizardState } from '../../../../context/addLayerWizard';
import TransitionAnimation from '../../../../components/TransitionAnimation';
import { useWfsCapabilities } from '../../../../hooks/geoservices';
import { useAddWfsFeature } from '../../../../hooks/viewerconfig';

interface FormValues {
  wmsLayer: any | null;
}

const SelectWFSLayer: React.FC = () => {
  const { control, setValue, handleSubmit, formState, reset } = useForm<FormValues>({
    defaultValues: {
      wmsLayer: null,
    },
  });
  const appContext = useAppContext();
  const { dispatch, layerConfig, url, viewerConfigId } = useConsumeAddLayerWizardState();
  const wfsCapabilitiesQuery = useWfsCapabilities(url);
  const [error, setError] = React.useState<string>(null);
  const [availableFeatures, setAvailableFeatures] = React.useState<any>(null);
  const [selectedWMSLayer, setSelectedWMSLayer] = React.useState<any>(null);
  const useAddWfsFeatureMutation = useAddWfsFeature(viewerConfigId);

  useEffect(() => {
    if (wfsCapabilitiesQuery.isSuccess) {
      const capabilities = wfsCapabilitiesQuery.data;
      setAvailableFeatures(capabilities.Features);
    }
  }, [wfsCapabilitiesQuery.isSuccess]);
  useEffect(() => {
    if (wfsCapabilitiesQuery.isSuccess) {
      const capabilities = wfsCapabilitiesQuery.data;
      setAvailableFeatures(capabilities.Features);
    } else if (wfsCapabilitiesQuery.isError) {
      setError('Problem occured during capabilities request.');
    }
  }, [wfsCapabilitiesQuery.isSuccess, wfsCapabilitiesQuery.isError]);

  const handleWFSLayerSelection = (wfsFeature: any) => {
    console.log('Feature selected: ' + JSON.stringify(wfsFeature));
    const layerName = wfsFeature.Name;
    let workspace: string = null;
    let layer: string = null;
    //const version: string = wmsCapabilitiesQuery.data.version;
    const splittedLayerName = layerName.split(':');
    if (splittedLayerName.length > 0) {
      workspace = splittedLayerName[0];
      layer = splittedLayerName[1];
    }
    useAddWfsFeatureMutation.mutateAsync({
      baseUrl: url,
      layer: layer,
      workspace: workspace,
      version: wfsCapabilitiesQuery.data.version, //version,
      projection: wfsFeature.Projection,
      boundingBox: wfsFeature.BoundingBox,
    });
  };

  useEffect(() => {
    if (useAddWfsFeatureMutation.isSuccess) {
      appContext.dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { type: 'success', content: 'Successfully added Layer.' } },
      });
      dispatch({ type: 'DONE', payload: {} });
    } else if (useAddWfsFeatureMutation.isError) {
      appContext.dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: { type: 'error', content: 'Unable to add layer at this moment. Pleas try again later.' },
        },
      });
    }
  }, [useAddWfsFeatureMutation.isSuccess, useAddWfsFeatureMutation.isError]);

  const handleExpansionPanelAction = (selected: any | null, event: ExpansionPanelActionEvent) => {
    setSelectedWMSLayer(event.expanded ? null : selected);
  };

  const renderLayerSelection = (wfsLayer: any, index: number) => {
    return (
      <StyledExpansionPanel
        key={index}
        title={
          <>
            <Typography.h3 className="flex-grow-1 text-capitalize">{truncateText(wfsLayer.Name, 46)}</Typography.h3>
          </>
        }
        expanded={selectedWMSLayer?.Name === wfsLayer.Name}
        tabIndex={0}
        onAction={handleExpansionPanelAction.bind(undefined, wfsLayer)}
        expandIcon="k-icon k-i-arrow-chevron-right animated-transform rotated-0"
        collapseIcon="k-icon k-i-arrow-chevron-right animated-transform rotated--90"
      >
        <Reveal>
          {selectedWMSLayer?.Name === wfsLayer.Name && (
            <ExpansionPanelContent>
              <Typography.h5
                style={{
                  margin: '25px',
                }}
              >
                {wfsLayer.Abstract}
              </Typography.h5>
              <div className="d-flex justify-content-end my-4 me-4">
                <SubmitButton
                  label={`Add layer`}
                  uppercase={false}
                  full={false}
                  onClick={() => {
                    handleWFSLayerSelection(wfsLayer);
                  }}
                  loading={useAddWfsFeatureMutation.isLoading}
                />
              </div>
            </ExpansionPanelContent>
          )}
        </Reveal>
      </StyledExpansionPanel>
    );
  };

  return (
    <TransitionAnimation>
      <>
        {error && (
          <Typography.h5
            style={{
              margin: '25px',
            }}
          >
            {error}
          </Typography.h5>
        )}
        {!error && (
          <Typography.h5
            style={{
              margin: '25px',
            }}
          >
            Choose a WMS Layer.
          </Typography.h5>
        )}
        {wfsCapabilitiesQuery.isLoading && (
          <div>
            {new Array(5)
              .fill(<Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />)
              .map((skeleton, i) => (
                <Fragment key={i}>{skeleton}</Fragment>
              ))}
          </div>
        )}
        <div>
          {wfsCapabilitiesQuery.data?.Features?.map((layerConfig: AddLayerConfig, index: number) =>
            renderLayerSelection(layerConfig, index)
          )}
        </div>
      </>
    </TransitionAnimation>
  );
};

export default SelectWFSLayer;
