import { useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../common';
import FileNodeReviewStatus from '../../types/DataDelivery/FileNodeReviewStatus';
import { Annotation, AnnotationType } from '../../types/DataDelivery/Annotation';

interface Params {
    type: AnnotationType;
    message: string;
    reviewStatus: FileNodeReviewStatus;
}

const useAddAnnotation = (fileNodeId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post<Annotation>("/filenode/" + fileNodeId + "/annotation", params);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['fileNodeAnnotations', fileNodeId], { refetchInactive: true });
      },
    }
  );
};

export default useAddAnnotation;
