import React from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { SVGIcon } from '@progress/kendo-svg-icons';
import { SvgIcon, IconSize } from '@progress/kendo-react-common';

interface Props {
  show: boolean;
  onClick?: () => void;
  icon: SVGIcon;
  message: string;
  iconStyle?: any;
  size?: IconSize;
}

const TooltipIcon: React.FC<Props> = (props: Props) => {
  if (!props.show) return null;
  return (
    <Tooltip anchorElement="target" position="top" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
      <div
        style={{ marginRight: '8px', position: 'relative' }}
        onClick={(e) => {
          if (props.onClick) {
            e.stopPropagation();
            props.onClick();
            return true;
          }
        }}
      >
        <a style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 1 }} title={props.message}></a>
        <SvgIcon
          icon={props.icon}
          size={props.size ? props.size : 'medium'}
          style={{ ...{ color: 'var(--geosap-light-error)', position: 'relative' }, ...props.iconStyle }}
        ></SvgIcon>
      </div>
    </Tooltip>
  );
};
export default TooltipIcon;
