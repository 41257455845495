import React, { useEffect, useState, useRef } from 'react';
import { Grid, GridColumn, GridRowProps } from '@progress/kendo-react-grid';
import { SvgIcon } from '@progress/kendo-react-common';
import { filterIcon, filterClearIcon, xIcon } from '@progress/kendo-svg-icons';
import styled from 'styled-components';
import { FeatureAttributeType, LayerType, ViewLayer } from '../../../types';
import { useConsumeViewerState } from '../../../context/viewer';
import { HoverableGridRow } from '../../../components/form';
import VectorAttributeFilter from './VectorAttributeFilter';
import DraggableWindow from '../../../components/DraggableWindow';

const StyledSvgIcon = styled(SvgIcon)`
  font-size: 1.2rem;
  cursor: pointer;
  &:hover {
    color: white;
  }
`;

// To avoid transpiler error on window.Potree  https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
declare const window: any;
const Potree = window.Potree;

interface Props {
  visible: boolean;
  onClose: any;
}

const VectorAttributes: React.FC<Props> = (props: Props) => {
  const { dispatch, selectedLayer, hoveredFeature, featureFilter, domViewerRef, viewerHeight } =
    useConsumeViewerState();
  const [configuringFilter, setConfiguringFilter] = React.useState<boolean>(false);
  const [visible, setVisible] = React.useState(false);
  const [attributes, setAttributes] = React.useState([]);
  const [featuresProperties, setFeaturesProperties] = React.useState<any[]>([]);
  const windowRef = useRef(null);
  const titleBar = useRef(null);
  const gridRef = useRef(null);

  //useEffect(() => {
  //  const parentElement = windowRef.current.parentElement;
  //  if (parentElement) {
  //    const parentRect = parentElement.getBoundingClientRect();
  //    const componentHeight = windowRef.current.clientHeight;
  //    console.log('Window parent bottom: ' + parentRect.bottom);
  //    console.log('Window height: ' + 300);
  //    setPosition({
  //      x: 0,
  //      y: parentRect.bottom - 301,
  //    });
  //  }
  //}, []); // Run this effect once when the component mounts

  useEffect(() => {
    setVisible(
      props.visible &&
        selectedLayer !== null &&
        selectedLayer !== undefined &&
        selectedLayer.layerType === LayerType.ShapeFile &&
        selectedLayer.paramsMap &&
        selectedLayer.paramsMap['attributes']
    );
  }, [props.visible, selectedLayer]);

  useEffect(() => {
    dispatch({ type: 'CLEAR_FEATURE_FILTER' });
    if (selectedLayer) {
      const paramAttributes = selectedLayer?.paramsMap ? selectedLayer?.paramsMap['attributes'] : null;
      if (paramAttributes) {
        const newAtttributes = Object.keys(paramAttributes).map((attributeName: string) => {
          return { ...paramAttributes[attributeName], ...{ name: attributeName } };
        });
        setAttributes(newAtttributes);
      }
    }
  }, [selectedLayer]);

  useEffect(() => {
    if (selectedLayer && selectedLayer.geojson) {
      const newFeaturesProperties: any[] = [];
      selectedLayer.geojson.features.forEach((feature, index) => {
        if (featureFilter) {
          const attributeValue = feature.properties[featureFilter.attribute.name];

          // Check if the attribute value is within the specified range
          if (
            (featureFilter.attribute.type === FeatureAttributeType.INTEGER ||
              featureFilter.attribute.type === FeatureAttributeType.REAL) &&
            attributeValue >= featureFilter.min &&
            attributeValue <= featureFilter.max
          ) {
            newFeaturesProperties.push({ ...feature.properties, index, id: feature.id });
          } else if (
            featureFilter.attribute.type === FeatureAttributeType.STRING &&
            attributeValue === featureFilter.attributeValue
          ) {
            newFeaturesProperties.push({ ...feature.properties, index, id: feature.id });
          }
        } else {
          newFeaturesProperties.push({ ...feature.properties, index, id: feature.id });
        }
      });
      setFeaturesProperties(newFeaturesProperties);
    }
  }, [selectedLayer, featureFilter]);

  useEffect(() => {
    if (gridRef.current && selectedLayer && hoveredFeature && hoveredFeature.layerId === selectedLayer.id) {
      const dataItem = featuresProperties.find((item) => item.id === hoveredFeature.featureId);
      if (dataItem) {
        const grid: any = gridRef.current;
        const rowElement = grid.element?.querySelector(`tr[data-grid-row-index="${dataItem.index}"]`);

        if (rowElement) {
          // Calculate the row's position relative to the viewport
          const rect = rowElement.getBoundingClientRect();
          const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;

          // Scroll the row into view only if it's not already in view
          if (!isInViewport) {
            grid.scrollIntoView({
              rowIndex: hoveredFeature.featureId,
            });
          }
        } else {
          console.log('Cannot find row element');
        }
      }
    }
  }, [selectedLayer, hoveredFeature, featuresProperties]);

  const renderGridRow = (
    row: React.ReactElement<HTMLTableRowElement, string | React.JSXElementConstructor<any>>,
    props: GridRowProps
  ) => {
    const rowHovered =
      selectedLayer &&
      hoveredFeature &&
      hoveredFeature.layerId === selectedLayer.id &&
      props.dataItem.id === hoveredFeature.featureId;
    return (
      <HoverableGridRow
        row={row}
        className={rowHovered ? 'k-table-highlight-row' : ''}
        {...props}
        onHover={(dataItem: any) => {
          dispatch({
            type: 'FEATURE_ENTER',
            payload: { layerId: selectedLayer.id, featureId: dataItem.index },
          });
        }}
        onLeave={(dataItem: any) => {
          dispatch({
            type: 'FEATURE_LEAVE',
            payload: { layerId: selectedLayer.id, featureId: dataItem.index },
          });
        }}
      />
    );
  };

  return (
    <DraggableWindow
      {...props}
      style={{ width: '100%', height: '300px', margin: 0 }}
      initialPosition={{ x: 0, y: viewerHeight - 300 }}
      title={
        <div style={{ display: 'flex', width: '100%' }}>
          <span style={{ paddingRight: '10px' }}>Attribute configuration</span>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <StyledSvgIcon
              icon={featureFilter ? filterClearIcon : filterIcon}
              onClick={() => {
                if (featureFilter) {
                  dispatch({ type: 'CLEAR_FEATURE_FILTER' });
                } else {
                  setConfiguringFilter(true);
                }
              }}
            />
          </div>
        </div>
      }
      boundaryElement={domViewerRef?.current}
    >
      <VectorAttributeFilter
        visible={configuringFilter}
        onClose={() => {
          setConfiguringFilter(false);
        }}
        attributes={attributes}
      ></VectorAttributeFilter>
      <div
        style={{
          width: '100%',
          height: '270px',
          boxSizing: 'border-box',
        }}
      >
        {featuresProperties && featuresProperties.length > 0 && (
          <Grid
            ref={gridRef}
            data={featuresProperties}
            style={{ height: '100%' }}
            resizable={true}
            rowRender={renderGridRow}
          >
            <GridColumn field={'index'} title={'index'} key={'index'} />
            {attributes &&
              attributes.map((attribute: any) => {
                return <GridColumn field={attribute.name} title={attribute.name} key={attribute.name} />;
              })}
          </Grid>
        )}
      </div>
    </DraggableWindow>
  );
};

export default VectorAttributes;
