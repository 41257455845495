import React, { useEffect, useState } from 'react';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Typography } from '@progress/kendo-react-common';
import { Fade } from '@progress/kendo-react-animation';
import { useAppContext } from '../../context/app';
import { UploadProgressNotification } from '../../types';
import { ProgressBar } from './';
import { useUploadFileNode } from '../../hooks/data_delivery';
import { useUser } from '../../hooks/authentication';
import { formatSizeUnits } from '../../converters/formatSizeUnits';
import { useFileNodeActions } from '../../actions';
import { FileNode } from '../../types/DataDelivery';

let timeoutId: number;

interface NotificationProps {
  notif: UploadProgressNotification;
}

const UploadProgressNotificationItem: React.FC<NotificationProps> = (props: NotificationProps) => {
  const { dispatch } = useAppContext();
  const { getCurrentOrganization } = useUser();
  const [progress, setProgress] = useState<number>(0);
  const [loaded, setLoaded] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [uploadedFileNode, setUploadedFileNode] = useState<FileNode>(null);
  const { handleProcessFileNode } = useFileNodeActions(getCurrentOrganization().id);
  const uploadFileNodeMutation = useUploadFileNode(getCurrentOrganization().id);

  useEffect(() => {
    if (!props.notif.started) {
      props.notif.started = true;
      dispatch({
        type: 'UPDATE_UPLOAD_NOTIFICATION',
        payload: {
          uploadNotification: props.notif,
        },
      });

      uploadFileNodeMutation
        .mutateAsync({
          files: props.notif.file,
          parentId: props.notif.parentFileNode.id,
          rootId: props.notif.parentFileNode.rootId,
        })
        .then((fileNodeUploaded: FileNode) => {
          setUploadedFileNode(fileNodeUploaded);
        });
    }
  });

  useEffect(() => {
    if (props.notif.started && uploadFileNodeMutation.progress) {
      setProgress(uploadFileNodeMutation.progress);
      setLoaded(uploadFileNodeMutation.loaded);
      setTotal(uploadFileNodeMutation.total);
    }
  }, [uploadFileNodeMutation.progress]);

  useEffect(() => {
    if (uploadedFileNode) {
      handleProcessFileNode(uploadedFileNode);
    }
  }, [uploadedFileNode]);

  return (
    <Fade>
      <Notification
        type={{ style: progress === 100 ? 'success' : 'info', icon: true }}
        closable={true}
        onClose={() => {
          dispatch({ type: 'CLEAR_UPLOAD_NOTIFICATION', payload: { uploadNotification: props.notif } });
        }}
      >
        <Typography.h6 fontSize="small">
          {progress === 100 && (
            <span>
              <span style={{ fontStyle: 'italic' }}>{props.notif.fileName}</span> uploaded.
            </span>
          )}
          {progress !== 100 && (
            <span>
              Uploading <span style={{ fontStyle: 'italic' }}>{props.notif.fileName}</span> to{' '}
              <span style={{ fontStyle: 'italic' }}>{props.notif.parentFileNode?.name}</span>
            </span>
          )}
        </Typography.h6>
        <ProgressBar progress={progress} progressStyle={{ backgroundColor: 'var(--geosap-selected-blue)' }} />

        {progress !== 100 && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontStyle: 'italic' }}>{formatSizeUnits(loaded)}</span>
            <span style={{ fontStyle: 'italic' }}>/</span>
            <span style={{ fontStyle: 'italic' }}>{formatSizeUnits(total)}</span>
          </div>
        )}
      </Notification>
    </Fade>
  );
};

const UploadProgressNotifications: React.FC = () => {
  const { uploadNotifications } = useAppContext();

  return (
    <NotificationGroup
      style={{
        right: 500,
        top: 0,
        alignItems: 'flex-start',
        flexWrap: 'wrap-reverse',
      }}
    >
      {uploadNotifications.map((notif: UploadProgressNotification, index) => {
        return <UploadProgressNotificationItem notif={notif} key={index}></UploadProgressNotificationItem>;
      })}
    </NotificationGroup>
  );
};

export default UploadProgressNotifications;
