import { useQuery } from 'react-query';
import { useAxiosInstance } from '../common';

const endpoint = '/user_management/organization/';

const useOrganizationInvitations = (orgId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['organizationInvitations', orgId], async () => {
    const result = await axios.get<{id: string, userEmail: string}[]>(endpoint + orgId + "/invitations");
    return result.data;
  });
};


export default useOrganizationInvitations;
