import { useQueryClient, useMutation } from 'react-query';
import { useAxiosInstance } from '../common';

interface MoveParams {
  fileNodeId: string;
  newParent: string;
  rootId: string;
  newRootId: string;
}

const useForgetFileNode = (ordId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: MoveParams) => {
      const response = await axios.put("filenode/" + params.fileNodeId + "/move", params);
      return response.data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['rootStructure', ordId], { refetchInactive: true });
        queryClient.invalidateQueries(['fileNodeStructure', variables.rootId], { refetchInactive: true });
        queryClient.invalidateQueries(['fileNodeStructure', variables.newRootId], { refetchInactive: true });
      },
    }
  );
};

export default useForgetFileNode;