import { openDB, IDBPDatabase } from 'idb';
import { TusUpload } from '../types';

const DB_NAME = 'TusUploaderDB';
const DB_VERSION = 1;
const STORE_NAME = 'uploads';

let db: IDBPDatabase<unknown>;

const initDB = async () => {
  db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      db.createObjectStore(STORE_NAME, { keyPath: 'fileId' });
    },
  });
};

export const saveUpload = async (upload: TusUpload) => {
  if (!db) await initDB();
  const uploadClone = {...upload};
  delete uploadClone.upload;
  await db.put(STORE_NAME, uploadClone);
};

export const saveUploads = async (uploads: TusUpload[]) => {
  if (!db) await initDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  for (const upload of uploads) {
    const uploadClone = {...upload};
    delete uploadClone.upload;
    await store.put(uploadClone);
  }
  await tx.done;
};

export const getUploads = async (): Promise<TusUpload[]> => {
  if (!db) await initDB();
  return db.getAll(STORE_NAME);
};

export const deleteUpload = async (fileId: string) => {
  if (!db) await initDB();
  await db.delete(STORE_NAME, fileId);
};
