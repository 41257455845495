import { useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../common';
import { UserRole } from '../../types';

const endpoint = '/user_management/role/assign';

interface Params {
    user_id: string,
    org_id: string,
    new_role: UserRole
}

const useUpdateUserOrgRole = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();

  return useMutation(async (params: Params) => { 
    return axios.put(endpoint, {}, {params});
  },
  {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['organizationMembers', variables.org_id], { refetchInactive: true });
    },
  });
};

export default useUpdateUserOrgRole;
