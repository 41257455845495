import { useState } from 'react';
import { GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';

interface PageState {
  skip: number;
  take: number;
}

interface UseTableParams {
  pageSize: number;
  data: unknown[];
  sortDescriptor: SortDescriptor;
}

interface UseTableResult {
  data: unknown[];
  skip: number;
  take: number;
  total: number;
  sort: SortDescriptor[];
  onPageChange: (e: GridPageChangeEvent) => void;
  onSortChange: (e: GridSortChangeEvent) => void;
}

const useTable = ({ pageSize, data, sortDescriptor }: UseTableParams): UseTableResult => {
  const [page, setPage] = useState<PageState>({ skip: 0, take: pageSize });
  const [sort, setSort] = useState<SortDescriptor[]>([sortDescriptor]);

  const handlePageChange = ({ page }: GridPageChangeEvent) => setPage(page);
  const handleSortChange = ({ sort }: GridSortChangeEvent) => setSort(sort);

  return {
    skip: page.skip,
    take: page.take,
    total: data.length,
    sort,
    data: orderBy(data, sort).slice(page.skip, page.take + page.skip),
    onPageChange: handlePageChange,
    onSortChange: handleSortChange,
  };
};

export default useTable;
