import { useMutation } from 'react-query';
import { useAxiosInstance } from '../common';

const endpoint = '/user_management/user_org';

interface Params {
    user_email: string,
    user_password: string,
    first_name: string,
    last_name: string,
    organization_id: string,
    organization_name: string,
    industry: string,
    location: string
}

const useSignup = () => {
  const axios = useAxiosInstance();

  return useMutation(async (newUserAndOrgParams: Params) => { 
    return axios.post(endpoint, newUserAndOrgParams);
  });
};

export default useSignup;
