import React, { useState, useRef } from 'react';
import { Input, SubmitButton } from '../../../../components/form';
import { Typography } from '@progress/kendo-react-common';
import { Upload, UploadFileInfo } from '@progress/kendo-react-upload';
import { useConsumeAddLayerWizardState } from '../../../../context/addLayerWizard';
import TransitionAnimation from '../../../../components/TransitionAnimation';
import { useUser } from '../../../../hooks/authentication';
import { useSapFlow, useCreateTransactionItemNode } from '../../../../hooks/sapflow';
import ExpansionPanel from '../../../../components/ExpansionPanel';
import { useAxiosInstance } from '../../../../hooks/common';
import { useAppContext } from '../../../../context/app';
import { useQueryClient } from 'react-query';

const URL = 'URL';
const UPLOAD = 'UPLOAD';

const SelectLas: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const { dispatch, viewerConfigId } = useConsumeAddLayerWizardState();
  const { getUploadUrl } = useSapFlow();
  const [url, setUrl] = useState<string>('');
  const uploadRef = useRef<Upload>(null);
  const { getUser } = useUser();
  const axios = useAxiosInstance();
  const transactionItemMutation = useCreateTransactionItemNode(viewerConfigId);
  const queryClient = useQueryClient();
  const appContext = useAppContext();

  const handleAddLas = async () => {
    const fileName = url.substring(url.lastIndexOf('/') + 1);
    const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
    const transactionItem = await transactionItemMutation.mutateAsync({
      name: fileName ? fileName : 'urlFile',
      fileformat: fileExtension ? fileExtension : '.temp',
      visualize: true,
      url: url && url !== '' ? url : null,
    });
  };

  const handleUploadLas = async (
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ) => {
    // First, parse the added files and identify the file types
    let i = 0;
    let fileName = 'Testfile';
    let fileExtension: any = null;
    for (i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.extension === '.las') {
        fileName = file.name;
        fileExtension = '.las';
      } else if (file.extension === '.laz') {
        fileName = file.name;
        fileExtension = '.laz';
      }
    }

    // TODO: Then, validate provided files...

    // Create the transaction node for the specified files.
    if (fileExtension !== null) {
      const transactionItem = await transactionItemMutation.mutateAsync({
        name: fileName,
        fileformat: fileExtension,
        visualize: true,
        url: '',
      });

      // TODO

      //i = 0;
      //for (i = 0; i < files.length; i++) {
      //  const formData = new FormData();
      //  const file = files[i];
      //  const rawFile = file.getRawFile();
      //  formData.append('files', rawFile);
      //  await axios.post(getUploadUrl(transactionId, transactionItem.id, true), formData, {
      //    headers: {
      //      'Content-Type': 'multipart/form-data',
      //    },
      //    onUploadProgress: (progressEvent) => {
      //      onProgress(file.uid, progressEvent);
      //      const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      //      if (percentCompleted === 100) {
      //        uploadRef.current.onUploadSuccess(file.uid);
      //      }
      //    },
      //  });
      //}
      //queryClient.invalidateQueries(['sapFlowView', getUser().id, transactionId], { refetchInactive: true });
      //appContext.dispatch({
      //  type: 'SHOW_NOTIFICATION',
      //  payload: { notification: { type: 'success', content: 'Successfully added Layer.' } },
      //});

      dispatch({ type: 'DONE', payload: {} });
    } else {
      for (i = 0; i < files.length; i++) {
        const file = files[i];
        onProgress(file.uid, new ProgressEvent('abort'));
      }
      return;
    }

    return { uid: '' };
  };

  return (
    <TransitionAnimation>
      <div style={{ paddingTop: '2rem' }}>
        {/* URL */}
        <ExpansionPanel
          key={URL}
          id={URL}
          title={URL}
          expanded={selectedOption === URL}
          onAction={(expanded) => {
            setSelectedOption(expanded ? null : URL);
          }}
          disabled
        >
          <Typography.h5
            style={{
              margin: '25px',
            }}
          >
            {'Please enter a Geotif URL.'}
          </Typography.h5>

          <div
            style={{
              padding: '0px 25px',
            }}
          >
            <Input
              type="text"
              name="URL"
              value={url}
              onChange={(e: any) => {
                setUrl(e.target.value);
              }}
            />
          </div>
          <div className="d-flex justify-content-end my-4 me-4">
            <SubmitButton
              label={`Add Geotif layer`}
              uppercase={false}
              full={false}
              onClick={() => {
                handleAddLas();
              }}
            />
          </div>
        </ExpansionPanel>

        {/* UPLOAD */}
        <ExpansionPanel
          key={UPLOAD}
          id={UPLOAD}
          title={UPLOAD}
          expanded={selectedOption === UPLOAD}
          onAction={(expanded) => {
            setSelectedOption(expanded ? null : UPLOAD);
          }}
        >
          <div style={{ margin: '25px' }}>
            <Upload
              ref={uploadRef}
              autoUpload={false}
              batch={true}
              defaultFiles={[]}
              withCredentials={false}
              saveUrl={handleUploadLas}
              accept={'.las, .laz'}
              restrictions={{
                allowedExtensions: ['.las', '.laz'],
              }}
              selectMessageUI={() => <span>Upload visualization files</span>}
            />
          </div>
        </ExpansionPanel>
      </div>
    </TransitionAnimation>
  );
};

export default SelectLas;
