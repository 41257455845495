export interface BuilderBlockDetails {
    id: string;
    defaultparams: any[];
    inputs: BuilderBlockItemDetail[];
    outputs: BuilderBlockItemDetail[];
    sapname: string;
    toolblock: string;
}

export interface BuilderBlockItemDetail {
    name: string;
    fileformat: string;
    disabled: boolean;
    state: BuilderBlockItemState;
}

export enum BuilderBlockItemState {
    READY,
    VALID,
    INVALID
  }