import { useQuery } from 'react-query';
import { FileNode, FileNodeType, FileNodeStatus } from '../../types/DataDelivery';
import { useAxiosInstance } from '../common';

const endpoint = 'filenode/root_structure';

const dummyData: FileNode = {
  id: 'adde6bd9-b95c-47f6-bc41-2cbb10c43a4a',
  name: 'Hornell Region',
  path: 'C:\\Users\\jchai\\Documents\\Test\\FileSystemTest',
  fileType: FileNodeType.NONE,
  status: FileNodeStatus.CREATED,
  geolocation: {
    id: '487cb990-df25-4e20-b3cf-68d5efd34a68',
    latCenter: 42.434987,
    lonCenter: -77.59979,
    latMin: 42.275301,
    latMax: 42.555905,
    lonMin: -77.897648,
    lonMax: -77.301945,
  },
  children: [
    {
      id: '978ca990-df25-4e20-b3cf-68d5efd34a68',
      name: 'Hornell Airport',
      path: 'C:\\Users\\jchai\\Documents\\Test\\FileSystemTest\\ProjectABC',
      fileType: FileNodeType.POINT_CLOUD,
      status: FileNodeStatus.CREATED,
      parentId: 'adde6bd9-b95c-47f6-bc41-2cbb10c43a4a',
      parentPath: 'C:\\Users\\jchai\\Documents\\Test\\FileSystemTest',
      geolocation: {
        id: '487cb990-df25-4e20-b3cf-68d5efd34a69',
        latCenter: 42.380854,
        lonCenter: -77.681703,
        latMin: 42.360427,
        latMax: 42.4798,
        lonMin: -77.667655,
        lonMax: -77.497539,
      },
      isDir: false,
      isSupport: false,
      children: null,
    },
    {
      id: '948ca990-df25-4e20-b3cf-68d5efd34123',
      name: 'Maple Hill',
      path: 'C:\\Users\\jchai\\Documents\\Test\\FileSystemTest\\ProjectABC',
      fileType: FileNodeType.NONE,
      status: FileNodeStatus.CREATED,
      parentId: 'adde6bd9-b95c-47f6-bc41-2cbb10c43a4a',
      parentPath: 'C:\\Users\\jchai\\Documents\\Test\\FileSystemTest',
      geolocation: {
        id: '487cb990-df25-4e20-b3cf-68d5efd34a68',
        latCenter: 42.434987,
        lonCenter: -77.59979,
        latMin: 42.360427,
        latMax: 42.4798,
        lonMin: -77.667655,
        lonMax: -77.497539,
      },
      children: null,
      isDir: true,
      isSupport: false,
    },
    {
      id: '055364a4-62d3-41ef-93ba-f211caed24ef',
      name: 'Avoca',
      path: 'C:\\Users\\jchai\\Documents\\Test\\FileSystemTest\\ProjectABC\\Section1',
      status: FileNodeStatus.CREATED,
      fileType: FileNodeType.NONE,
      parentId: 'adde6bd9-b95c-47f6-bc41-2cbb10c43a4a',
      parentPath: 'C:\\Users\\jchai\\Documents\\Test\\FileSystemTest\\ProjectABC',
      geolocation: {
        id: '488cb990-df25-4e20-b3cf-68d5efd34a68',
        latCenter: 42.402666,
        lonCenter: -77.432299,
        latMin: 42.381386,
        latMax: 42.416947,
        lonMin: -77.474886,
        lonMax: -77.409704,
      },
      children: null,
      isDir: true,
      isSupport: false,
    },
  ],
  isDir: true,
  isSupport: false,
};

const useRootStructure = (orgId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['rootStructure', orgId], async () => {
    //return dummyData;
    const result = await axios.get<FileNode>(endpoint);
    return result.data;
  });
};

export default useRootStructure;