import { useMutation } from 'react-query';
import { useAxiosInstance } from '../common';

interface Params {
  sapFlowId: string;
  processNodeId: string;
}

const useRunProcessNode = () => {
  const axios = useAxiosInstance();

  return useMutation(
    async (param: Params) => {
      return axios.post('/file_sapflow/' + param.sapFlowId + '/' + param.processNodeId + '/run_async');
    },
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(['transactions'], { refetchInactive: true });
      },
    }
  );
};

export default useRunProcessNode;
