import { useMutation, useQueryClient } from 'react-query';
import { useUser } from '../../hooks/authentication';
import { SapFlowViewConfig } from '../../types';
import { useAxiosInstance } from '../common';

const endpoint = 'processing/inspect_geotiff';

interface Params {
  url: string;
}

const useInspectGeotiff = () => {
  const axios = useAxiosInstance();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post<any>(endpoint, {}, {
        params: {
          geotiffUrl: params.url,
        },
      });
      return response.data;
    },
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(['sapFlowView', getUser().id, transactionId], {
        //  refetchInactive: true,
        //});
      },
    }
  );
};

export default useInspectGeotiff;