import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '@progress/kendo-react-common';
import { CentralizedContent } from '../components/layout';
import { Input, SubmitButton } from '../components/form';
import { useSignin, useUser } from '../hooks/authentication';
import { useAppContext } from '../context/app';

const BrandImage = styled.img`
  display: block;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
`;

interface LoginFormValues {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const { recentlyCreatedUser } = useAppContext();
  const navigate = useNavigate();
  const { isAuthenticated, organizationSelected } = useUser();
  const { handleSubmit, reset, control, getValues } = useForm<LoginFormValues>();
  const location = useLocation();
  //const { from } = location.state || { from: { pathname: '/' } };
  const { from } = (location.state as any) || { from: { pathname: '/', search: '', hash: '' } };

  const signInMutation = useSignin(
    () => {
      // if user is being invited, redirect to accept
      console.log('From: ' + location?.state?.from?.pathname);
      if (
        (location?.state?.from?.pathname && location?.state?.from?.pathname.includes('acceptInvitation')) ||
        organizationSelected()
      ) {
        navigate(from.pathname + from.search + from.hash, { replace: true });
        //navigate(from.pathname, { replace: true });
      } else {
        navigate('/selectorganization');
      }
    },
    (err: any) => {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.message &&
        err.response.data.message.includes('Account confirmation still pending.')
      ) {
        navigate('/pending', { state: { email: getValues('email'), password: getValues('password') } });
      }
    }
  );

  const signIn = (data: LoginFormValues) => {
    signInMutation.mutate(data);
  };

  useEffect(() => {
    if (isAuthenticated()) {
      if (organizationSelected()) {
        navigate('/', { replace: true });
      } else {
        navigate('/selectorganization');
      }
    }
  }, []);

  useEffect(() => {
    if (recentlyCreatedUser) {
      reset({ email: recentlyCreatedUser, password: '' });
    }
  }, [recentlyCreatedUser]);

  return (
    <CentralizedContent>
      <div className="col-xs-12 col-lg-6 col-xl-4">
        <BrandImage src="/assets/brand-black.png" alt="GeoSAP brand" className="p-3 pb-4" />
        <form onSubmit={handleSubmit(signIn)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => <Input {...field} type="text" placeholder="Email" />}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => <Input {...field} type="password" placeholder="Password" />}
          />
          <div className="pt-3">
            <SubmitButton
              label="Log in"
              className="font-exo"
              style={{ fontSize: '1rem', padding: '0.75rem 2rem', fontWeight: 500 }}
              loading={signInMutation.isLoading}
              xMargin="mx-0"
            />
          </div>
        </form>
        <Typography.p textAlign="center" className="py-2 mb-0">
          <Link to="/forgotpassword">Forgot password?</Link>
        </Typography.p>
        <Typography.p textAlign="center" className="py-2">
          {"Don't have an account? "}
          <Link to="/register">Sign up</Link>
        </Typography.p>
      </div>
    </CentralizedContent>
  );
};

export default Login;
