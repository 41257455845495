import React from 'react';
import { Navigate, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../../hooks/authentication';
import { BackgroundContainer } from '../../components/layout';
import {
  Home,
  NewSapFlowWizard,
  ConfigBuilder,
  Messaging,
  Dashboard,
  Test,
  ProjectDetails,
  GeosapViewerWrapper as GeosapViewer,
  DataDelivery,
  DataDeliveryViewer,
  ProjectDownload,
  SapFlowDetails,
  SubscriptionExpired,
  CheckoutSubscription,
  SubscriptionReturn,
  SubscriptionReturnSuccess,
  AcceptInvitation,
  Servers,
} from '../../pages';
import {
  SettingsContainer,
  SettingsOverview,
  UserSettings,
  OrganizationSettings,
  GeoSAPSettings,
  NotificationSettings,
  PlansBillingSettings,
  SettingsPaths,
  ACCOUNT_OVERVIEW,
  ACCOUNT_USER,
  ACCOUNT_GEOSAP,
  ACCOUNT_NOTIFICATIONS,
  ACCOUNT_PLANS,
  ACCOUNT_ORGANIZATION,
} from '../../pages/Account';
import Navbar from './Navbar/Navbar';
import Page from './Page';
import AdminRoutes from './AdminRoutes';
import { useAppContext } from '../../context/app';

const PrivateRoutes: React.FC = () => {
  const { isAuthenticated, getCurrentOrganization } = useUser();
  const { availableOrganizations, selectedOrganization } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  if (!isAuthenticated()) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  } else if (getCurrentOrganization() === null) {
    if (availableOrganizations.length > 0) {
      return <Navigate to="/selectorganization" replace state={{ from: location }} />;
    } else if (!location.pathname.includes('/acceptInvitation')) {
      return <Navigate to="/noorganization" replace />;
    }
  }

  return (
    <div className="d-flex flex-column flex-lg-row vh-100">
      <Navbar />
      <BackgroundContainer className="flex-grow-1 overflow-auto">
        <Routes>
          <Route
            path="/"
            element={
              <Page title="Home">
                <Home />
              </Page>
            }
          />
          <Route path="/sapflow">
            <Route
              path="builder"
              element={
                <Page title="SapFlow Config Builder">
                  {/*<SapFlowWizardWithBuilder newProject />*/}
                  <ConfigBuilder />
                </Page>
              }
            />
            <Route
              path=":projectId"
              element={
                <Page title="Project Creation">
                  <NewSapFlowWizard />
                </Page>
              }
            />
            <Route
              path=""
              element={
                <Page title="SapFlow Creation">
                  {/*<SapFlowWizard />*/}
                  <NewSapFlowWizard />
                </Page>
              }
            />
          </Route>
          <Route path="/messaging" element={<Messaging />} />
          <Route
            path="/acceptInvitation"
            element={
              <Page title="Joining teamates">
                <AcceptInvitation />
              </Page>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Page title="Dashboard">
                <Dashboard />
              </Page>
            }
          />
          <Route
            path="/settings"
            element={
              <Page title="Settings">
                <SettingsContainer />
              </Page>
            }
          >
            <Route
              path={SettingsPaths[ACCOUNT_OVERVIEW]}
              element={
                <Page title="Settings Overview">
                  <SettingsOverview />
                </Page>
              }
            />
            <Route
              path={SettingsPaths[ACCOUNT_USER]}
              element={
                <Page title="User Settings">
                  <UserSettings />
                </Page>
              }
            />
            <Route
              path={SettingsPaths[ACCOUNT_ORGANIZATION]}
              element={
                <Page title="Organization Settings">
                  <OrganizationSettings organization={getCurrentOrganization()} />
                </Page>
              }
            />
            <Route
              path={SettingsPaths[ACCOUNT_GEOSAP]}
              element={
                <Page title="GeoSAP Settings">
                  <GeoSAPSettings />
                </Page>
              }
            />
            <Route
              path={SettingsPaths[ACCOUNT_NOTIFICATIONS]}
              element={
                <Page title="Notifications Settings">
                  <NotificationSettings />
                </Page>
              }
            />
            <Route
              path={SettingsPaths[ACCOUNT_PLANS]}
              element={
                <Page title="Plans & Billing">
                  <PlansBillingSettings />
                </Page>
              }
            />
          </Route>
          <Route
            path="/checkout"
            element={
              <Page title="Checkout">
                <CheckoutSubscription />
              </Page>
            }
          />
          <Route
            path="/subexpired"
            element={
              <Page title="Subscription Expired">
                <SubscriptionExpired />
              </Page>
            }
          />
          <Route
            path="/subscriptionreturn"
            element={
              <Page title="Subscription Return">
                <SubscriptionReturn />
              </Page>
            }
          />
          <Route
            path="/stripe/success"
            element={
              <Page title="Subscribing ...">
                <SubscriptionReturnSuccess />
              </Page>
            }
          />
          <Route
            path="/viewer/:transactionId"
            element={
              <Page title="Viewer">
                <GeosapViewer />
              </Page>
            }
          />
          <Route
            path="/process"
            element={
              <Page title="Process">
                <DataDelivery />
              </Page>
            }
          />
          <Route
            path="/deliver/:rootId"
            element={
              <Page title="Deliver">
                <DataDeliveryViewer />
              </Page>
            }
          />
          <Route
            path="/deliver/:rootId/:fileNodeId"
            element={
              <Page title="Viewer">
                <GeosapViewer />
              </Page>
            }
          />
          <Route path="/test" element={<Test />} />
          <Route
            path="/project/:projectId"
            element={
              <Page title="Project Details">
                <ProjectDetails />
              </Page>
            }
          />
          <Route path="/download-artifact/:transactionId" element={<ProjectDownload />} />
          {/*  (/:projectid)(/:userid)(/:transactionid)  /:projectid/:userid/:transactionid*/}
          <Route
            path="/details/:transactionId"
            element={
              <Page title="SapFlow Details">
                <SapFlowDetails />
              </Page>
            }
          />
          <Route key="private" path="*" element={<AdminRoutes />} />
        </Routes>
      </BackgroundContainer>
    </div>
  );
};

export default PrivateRoutes;
