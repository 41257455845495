import React, { MouseEventHandler } from 'react';
import { Button } from '@progress/kendo-react-buttons';

interface Props {
  label: string;
  selected: boolean;
  uppercase?: boolean;
  full?: boolean;
  disabled?: boolean;
  className?: string;
  themeColor?: "primary" | "base" | "secondary" | "tertiary" | "info" | "success" | "warning" | "error" | "dark" | "light" | "inverse";
  style?: any;
  onClick?: MouseEventHandler;
}

const ToggleableButton: React.FC<Props> = ({
  label,
  selected,
  uppercase = true,
  full = false,
  disabled = false,
  className,
  themeColor = "primary",
  style = {},
  onClick,
}) => {
  const buttonLabel = uppercase ? label.toUpperCase() : label;

  return (
    <Button
      type='button'
      disabled={disabled}
      togglable={true}
      onClick={onClick}
      selected={selected}
      //themeColor={themeColor}
      className={`${className}${full ? ' w-100' : ''}`}
      style={style}
    >
      <div className="position-relative">
        <span>{buttonLabel}</span>
      </div>
    </Button>
  );
};

export default ToggleableButton;
