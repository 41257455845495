import React, { useState, useRef, useEffect } from 'react';
import { Popup, Offset } from '@progress/kendo-react-popup';
import { Menu, MenuItem, MenuItemModel } from '@progress/kendo-react-layout';
import { AuthorityLevel, AuthorityType, LayerType, UserRole, ViewLayer } from '../../../types';
import { useSignout, useUser } from '../../../hooks/authentication';
import { FileNode, FileNodeType, FileNodeStatus, FileNodeGeolocation } from '../../../types/DataDelivery';
import { useNavigate } from 'react-router-dom';
import { logoutIcon, userIcon } from '@progress/kendo-svg-icons';
import { SvgIcon, Typography } from '@progress/kendo-react-common';

interface MobileMenuProps {
  show: boolean;
  anchor: HTMLElement;
  handleClose: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = (props: MobileMenuProps) => {
  const navigate = useNavigate();
  const { getUser, getAccessToken } = useUser();
  const signoutMutation = useSignout();
  const availableOptions: MenuItemModel[] = [
    {
      text: 'Processing Manager',
      data: {
        action: () => {
          navigate('/process');
        },
      },
    },
    {
      text: 'Account',
      data: {
        action: () => {
          navigate('/settings');
          props.handleClose();
        },
      },
      svgIcon: userIcon,
    },
    {
      text: 'Logout',
      data: {
        action: () => {
          const { email } = getUser();
          const bearerToken = getAccessToken();
          signoutMutation.mutate({ email, bearerToken });
          props.handleClose();
        },
      },
      svgIcon: logoutIcon,
    },
  ];
  return (
    <Popup
      show={props.show}
      anchor={props.anchor}
      popupClass={'popup-content'}
      style={{ width: '500px', maxWidth: '100%' }}
    >
      <Menu className="mobileMenu" vertical={true} style={{ display: 'inline-block', padding: 0 }}>
        {availableOptions.map((item, index) => (
          <MenuItem
            key={index}
            text={item.text}
            render={() => {
              return (
                <div style={{ width: '100%', cursor: 'pointer' }} onClick={item.data.action}>
                  <Typography.h4 style={{ marginBottom: 0, height: '32px' }}>
                    {item.svgIcon && <SvgIcon size={'xxlarge'} icon={item.svgIcon} />}
                    <span style={{ paddingLeft: '2rem', position: 'relative', bottom: '-0.2rem' }}>{item.text}</span>
                  </Typography.h4>
                </div>
              );
            }}
          ></MenuItem>
        ))}
      </Menu>
    </Popup>
  );
};

export default MobileMenu;
