import React, { useState, useRef } from 'react';
import { Input, SubmitButton } from '../../../../components/form';
import { Typography } from '@progress/kendo-react-common';
import {
  Upload,
  UploadOnAddEvent,
  UploadOnBeforeUploadEvent,
  UploadOnStatusChangeEvent,
  UploadFileStatus,
  UploadFileInfo,
  UploadOnRemoveEvent,
} from '@progress/kendo-react-upload';
import { AddLayerWizardStep, useConsumeAddLayerWizardState } from '../../../../context/addLayerWizard';
import TransitionAnimation from '../../../../components/TransitionAnimation';
import { useUser } from '../../../../hooks/authentication';
import { useSapFlow, useCreateTransactionItemNode } from '../../../../hooks/sapflow';
import ExpansionPanel from '../../../../components/ExpansionPanel';
import { useAuthorization, useAxiosInstance } from '../../../../hooks/common';

const URL = 'URL';
const UPLOAD = 'UPLOAD';

const SelectShapefile: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const { dispatch, viewerConfigId } = useConsumeAddLayerWizardState();
  const { getUploadUrl } = useSapFlow();
  const [url, setUrl] = useState<string>('');
  const uploadRef = useRef<Upload>(null);
  const [filesSelectionError, setFilesSelectionError] = useState<string>(null);
  const { getUser } = useUser();
  const axios = useAxiosInstance();
  const transactionItemMutation = useCreateTransactionItemNode(viewerConfigId);

  const validateUploadingIntegrity = (event: { newState: any }) => {
    let requirements: any[] = [];
    event.newState.forEach((fileInfo: UploadFileInfo) => {
      const filename = fileInfo.name.replace(/\.[^/.]+$/, '');
      if (fileInfo.extension === '.shp') {
        const matchingRequirement: any = requirements.filter((requirement: any) => {
          return requirement.name === filename && requirement.extension === '.shp';
        });
        if (matchingRequirement.length > 0) {
          requirements = requirements.filter((requirement: any) => {
            return requirement.name !== filename || requirement.extension !== '.shp';
          });
        } else {
          requirements.push({
            name: filename,
            extension: '.dbf',
            errorMessage: fileInfo.name + ' requires to be uploaded with a .dbf file.',
          });
        }
      } else if (fileInfo.extension === '.dbf') {
        const matchingRequirement: any = requirements.filter((requirement: any) => {
          return requirement.name === filename && requirement.extension === '.dbf';
        });
        if (matchingRequirement.length > 0) {
          requirements = requirements.filter((requirement: any) => {
            return requirement.name !== filename || requirement.extension !== '.dbf';
          });
        } else {
          requirements.push({
            name: filename,
            extension: '.shp',
            errorMessage: fileInfo.name + ' requires to be uploaded with a .shp file.',
          });
        }
      }
    });
    if (requirements.length > 0) {
      setFilesSelectionError(requirements.map((requirement) => requirement.errorMessage).join('\n'));
    } else {
      setFilesSelectionError(null);
    }
  };

  const handleAddUrl = async () => {
    const fileName = url.substring(url.lastIndexOf('/') + 1);
    const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
    const transactionItem = await transactionItemMutation.mutateAsync({
      name: fileName ? fileName : 'urlFile',
      fileformat: fileExtension ? fileExtension : '.temp',
      visualize: true,
      url: url,
    });
  };

  const handleUpload = async (
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ) => {
    // First, parse the added files and identify the file types
    let i = 0;
    let fileName = 'Testfile';
    let fileExtension: any = null;
    for (i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.extension === '.shp') {
        fileName = file.name;
        fileExtension = '.shp';
      }
    }

    // TODO: Then, validate provided files...

    // Create the transaction node for the specified files.
    if (fileExtension !== null) {
      const transactionItem = await transactionItemMutation.mutateAsync({
        name: fileName,
        fileformat: fileExtension,
        visualize: true,
        url: '',
      });

      i = 0;
      for (i = 0; i < files.length; i++) {
        const formData = new FormData();
        const file = files[i];
        const rawFile = file.getRawFile();
        formData.append('files', rawFile);
        throw new Error('Unsupported. TODO');
        //console.log('Bout to upload with projectid ' + projectId);
        //await axios.post(getUploadUrl(transactionId, transactionItem.id, true), formData, {
        //  headers: {
        //    'Content-Type': 'multipart/form-data',
        //  },
        //  onUploadProgress: (progressEvent) => {
        //    onProgress(file.uid, progressEvent);
        //    const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        //    if (percentCompleted === 100) {
        //      uploadRef.current.onUploadSuccess(file.uid);
        //      console.log('Upload success');
        //    }
        //  },
        //});
      }

      dispatch({ type: 'DONE', payload: {} });
    } else {
      for (i = 0; i < files.length; i++) {
        const file = files[i];
        onProgress(file.uid, new ProgressEvent('abort'));
      }
      return;
    }

    return { uid: '' };
  };

  return (
    <TransitionAnimation>
      <div style={{ paddingTop: '2rem' }}>
        {/* URL */}
        <ExpansionPanel
          key={URL}
          id={URL}
          title={URL}
          expanded={selectedOption === URL}
          onAction={(expanded) => {
            setSelectedOption(expanded ? null : URL);
          }}
          disabled
        >
          <Typography.h5
            style={{
              margin: '25px',
            }}
          >
            {'Please enter a Shapefile URL.'}
          </Typography.h5>
          <Input
            type="text"
            name="URL"
            value={url}
            onChange={(e: any) => {
              setUrl(e.target.value);
            }}
          />
          <div className="d-flex justify-content-end my-4 me-4">
            <SubmitButton
              label={`Add Shapefile layer`}
              uppercase={false}
              full={false}
              onClick={() => {
                handleAddUrl();
              }}
            />
          </div>
        </ExpansionPanel>

        {/* UPLOAD */}
        <ExpansionPanel
          key={UPLOAD}
          id={UPLOAD}
          title={UPLOAD}
          expanded={selectedOption === UPLOAD}
          onAction={(expanded) => {
            setSelectedOption(expanded ? null : UPLOAD);
          }}
        >
          <div style={{ margin: '25px' }}>
            <Upload
              ref={uploadRef}
              autoUpload={false}
              batch={true}
              defaultFiles={[]}
              withCredentials={false}
              saveUrl={handleUpload}
              accept={'.shp, .dbf, .cpg, .shx, .prj, .sbn, .sbx'}
              restrictions={{
                allowedExtensions: ['.shp', '.dbf', '.cpg', '.shx', '.prj', '.sbn', '.sbx'],
              }}
              selectMessageUI={() => <span>Upload visualization files</span>}
              className={filesSelectionError ? 'upload-disabled' : ''}
              onAdd={(event) => {
                validateUploadingIntegrity(event);
              }}
              onRemove={(event) => {
                validateUploadingIntegrity(event);
              }}
            />
            {filesSelectionError && (
              <Typography.p themeColor="error" style={{ whiteSpace: 'break-spaces' }}>
                {filesSelectionError}
              </Typography.p>
            )}
          </div>
        </ExpansionPanel>
      </div>
    </TransitionAnimation>
  );
};

export default SelectShapefile;
