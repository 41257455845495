import { useQuery } from 'react-query';
import { useAxiosInstance } from '../common';
import { Annotation } from '../../types/DataDelivery/Annotation';

const useFileNodeAnnotations = (fileNodeId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['fileNodeAnnotations', fileNodeId], async () => {
    if(!fileNodeId)
      return [];
    const result = await axios.get<Annotation[]>("filenode/" + fileNodeId + "/annotations");
    return result.data;
  });
};

export default useFileNodeAnnotations;