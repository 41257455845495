import React, { useReducer, useContext } from 'react';
import { DataProcessingContextState, initialState } from './dataProcessingContextState';
import { dataProcessingContextReducer, DataProcessingContextAction } from './dataProcessingContextReducer';

interface DataProcessingContextProviderValue extends DataProcessingContextState {
  dispatch: (action: DataProcessingContextAction) => void;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  value: DataProcessingContextProviderValue;
}

const DataProcessingContextContext = React.createContext({
  ...initialState,
  dispatch: () => {
    // not implemented
  },
} as DataProcessingContextProviderValue);

export const DataProcessingContextProvider: React.FC<Props> = ({ children, value }) => {
  return <DataProcessingContextContext.Provider value={value}>{children}</DataProcessingContextContext.Provider>;
};

/*
  Create Sap Flow Wizard state.
*/
export const useCreateDataProcessingContextState = (): DataProcessingContextProviderValue => {
  const [state, dispatch] = useReducer(dataProcessingContextReducer, {
    ...initialState,
  });
  return {
    ...state,
    dispatch,
  };
};

/*
  Consume Sap Flow Wizard state.
*/
export const useConsumeDataProcessingContextState = () => {
  return useContext(DataProcessingContextContext);
};
