import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Typography } from '@progress/kendo-react-common';
import {
  useBuildConfigs,
  useClearConfig,
  useClearConfigs,
  useClientProjects,
  useGenerateSapFlows,
  useGenerateSapFlowsForConfig,
} from '../../hooks/data_delivery';
import { useUser } from '../../hooks/authentication';
import { Skeleton } from '@progress/kendo-react-indicators';
import { AuthorityLevel, AuthorityType, ClientProject } from '../../types';
import StyledExpansionPanel from '../../components/styled/StyledExpansionPanel';
import { truncateText } from '../../common/stringHelper';
import { ExpansionPanelActionEvent } from '@progress/kendo-react-layout';
import { Reveal } from '@progress/kendo-react-animation';
import { SubmitButton } from '../../components/form';
import { RestrictedSubmitButton } from '../../components/restricted';
import { OverlayedLoader } from '../../components';
import { useNavigate } from 'react-router-dom';
import { SapConfig } from '../../types/DataDelivery';
import ConfigPathBrowser from './ConfigPathBrowser/ConfigPathBrowser';

const DataProject: React.FC = () => {
  const { getCurrentOrganization } = useUser();
  const navigate = useNavigate();
  const clientProjectsQuery = useClientProjects(getCurrentOrganization().id);
  const buildConfigsMutation = useBuildConfigs(getCurrentOrganization().id);
  const generateSapFlowsMutation = useGenerateSapFlows(getCurrentOrganization().id);
  const generateSapFlowsForConfigMutation = useGenerateSapFlowsForConfig(getCurrentOrganization().id);
  const clearConfigsMutation = useClearConfigs(getCurrentOrganization().id);
  const clearConfigMutation = useClearConfig(getCurrentOrganization().id);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [showConfigPathBrowser, setShowConfigPathBrowser] = useState(null);

  const handleExpansionPanelAction = (clientProject: ClientProject | null, event: ExpansionPanelActionEvent) => {
    setSelectedProjectId(selectedProjectId === clientProject.id ? null : clientProject.id);
  };

  const handleBuildConfigs = (clientProject: ClientProject) => {
    buildConfigsMutation.mutateAsync(clientProject.id);
  };
  const handleGenerateSapFlows = (clientProject: ClientProject) => {
    generateSapFlowsMutation.mutateAsync(clientProject.id);
  };
  const handleGenerateSapFlowsForConfig = (clientProject: ClientProject, config: SapConfig) => {
    generateSapFlowsForConfigMutation.mutateAsync({ clientProjectId: clientProject.id, configId: config.id });
  };
  const handleClear = (clientProject: ClientProject) => {
    clearConfigsMutation.mutateAsync(clientProject.id);
  };
  const handleClearConfig = (clientProject: ClientProject, config: SapConfig) => {
    clearConfigMutation.mutateAsync({ clientProjectId: clientProject.id, configId: config.id });
  };

  const renderClientProjectConfig = (clientProject: ClientProject, config: any) => {
    return (
      <div key={config.id} style={{ display: 'flex', justifyContent: 'space-between', padding: '0.5rem 0' }}>
        <span>{config.name}</span>
        <div>
          <RestrictedSubmitButton
            label={'Generate SapFlows'}
            fillMode="outline"
            themeColor={'primary'}
            size="small"
            height="fit-content"
            full={false}
            onClick={() => {
              //handleGenerateSapFlowsForConfig(clientProject, config);
              console.log('Project', clientProject);
              setShowConfigPathBrowser({ projectId: clientProject.id, configId: config.id });
            }}
            authorityType={AuthorityType.DATA_AUTHORITY}
            authorityLevel={AuthorityLevel.EXECUTE}
            xMargin="mx-4"
          ></RestrictedSubmitButton>
          <RestrictedSubmitButton
            label={'Clear'}
            fillMode="outline"
            themeColor={'error'}
            size="small"
            height="fit-content"
            full={false}
            onClick={() => {
              handleClearConfig(clientProject, config);
            }}
            authorityType={AuthorityType.DATA_AUTHORITY}
            authorityLevel={AuthorityLevel.EXECUTE}
            xMargin="mx-4"
          ></RestrictedSubmitButton>
        </div>
      </div>
    );
  };

  const renderClientProjectInfos = (clientProject: ClientProject, index: number) => {
    const loading =
      buildConfigsMutation.isLoading ||
      generateSapFlowsMutation.isLoading ||
      generateSapFlowsForConfigMutation.isLoading ||
      clearConfigMutation.isLoading ||
      clearConfigsMutation.isLoading;
    return (
      <StyledExpansionPanel
        key={clientProject.id}
        title={
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography.h3 className="flex-grow-1 text-capitalize">
                {truncateText(clientProject.name, 46)}
              </Typography.h3>
              <Typography.h6 className="flex-grow-1" style={{ textTransform: 'none' }}>
                {truncateText(clientProject.id, 46)}
              </Typography.h6>
            </div>
            <RestrictedSubmitButton
              label={'Build Configs'}
              fillMode="outline"
              themeColor={'primary'}
              size="medium"
              height="fit-content"
              full={false}
              onClick={() => {
                handleBuildConfigs(clientProject);
              }}
              authorityType={AuthorityType.DATA_AUTHORITY}
              authorityLevel={AuthorityLevel.EXECUTE}
              xMargin="mx-4"
            ></RestrictedSubmitButton>
          </div>
        }
        className="position-relative"
        expanded={selectedProjectId === clientProject.id}
        tabIndex={0}
        onAction={handleExpansionPanelAction.bind(undefined, clientProject)}
        expandIcon="k-icon k-i-arrow-chevron-right animated-transform rotated-0"
        collapseIcon="k-icon k-i-arrow-chevron-right animated-transform rotated--90"
      >
        <OverlayedLoader show={loading} />
        <Reveal>
          <div
            style={{
              flexDirection: 'column',
              paddingBlock: 'var(--kendo-spacing-4)',
              paddingInline: 'var(--kendo-spacing-6)',
              display: selectedProjectId === clientProject.id ? 'flex' : 'none',
            }}
          >
            <div className="py-4">
              <Typography.h5 className="">{clientProject.path}</Typography.h5>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {clientProject.configs.map((config) => {
                return renderClientProjectConfig(clientProject, config);
              })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', paddingTop: '1rem' }}>
              <RestrictedSubmitButton
                label={'Clear All'}
                fillMode="outline"
                themeColor={'error'}
                size="medium"
                height="fit-content"
                full={false}
                onClick={() => {
                  handleClear(clientProject);
                }}
                authorityType={AuthorityType.DATA_AUTHORITY}
                authorityLevel={AuthorityLevel.EXECUTE}
                xMargin="mx-4"
              ></RestrictedSubmitButton>
            </div>
          </div>
        </Reveal>
      </StyledExpansionPanel>
    );
  };

  return (
    <div className="container-fluid p-5">
      <ConfigPathBrowser
        show={showConfigPathBrowser}
        handleClose={() => setShowConfigPathBrowser(null)}
        projectId={showConfigPathBrowser?.projectId}
        configId={showConfigPathBrowser?.configId}
      ></ConfigPathBrowser>
      <Typography.h3
        fontWeight="normal"
        className="text-uppercase"
        style={{ display: 'flex', width: '100%', paddingBottom: '1rem', justifyContent: 'space-between' }}
      >
        Client Projects Explorer
        <RestrictedSubmitButton
          label={'Go to Processing'}
          fillMode="outline"
          themeColor={'primary'}
          size="medium"
          height="fit-content"
          full={false}
          onClick={() => {
            navigate('/dataprocessing');
          }}
          style={{ marginLeft: 'auto' }}
          authorityType={AuthorityType.DATA_AUTHORITY}
          authorityLevel={AuthorityLevel.EXECUTE}
          xMargin="mx-4"
        ></RestrictedSubmitButton>
      </Typography.h3>
      <div className="m-auto mt-4 w-75">
        {!clientProjectsQuery.data && (
          <div>
            {new Array(5)
              .fill(<Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />)
              .map((skeleton, i) => (
                <Fragment key={i}>{skeleton}</Fragment>
              ))}
          </div>
        )}
        {clientProjectsQuery.data &&
          clientProjectsQuery.data.length > 0 &&
          clientProjectsQuery.data.map(renderClientProjectInfos)}
      </div>
    </div>
  );
};

export default DataProject;
