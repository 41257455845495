import { QueryClient } from 'react-query';
import { SapFlowTransaction, SapFlowTransactionStatus, WSMessage } from '../../../types';
import { SapFlow, SapFlowGroup, SapFlowStatus } from '../../../types/DataDelivery';

const handleMessage = (wsMessage: WSMessage, queryClient: QueryClient) => {
  const transactionStatus: SapFlowStatus = wsMessage.payload;
  if (transactionStatus !== null && transactionStatus.transactionID) {
    let groupsData: SapFlowGroup[] | undefined = null;
    try {
      groupsData = JSON.parse(JSON.stringify(queryClient.getQueryData(['sapGroups'])));
    } catch (e) {
      return;
    }
    const findAndModifyGroupSapflow = (group: SapFlowGroup) => {
      if (group.sapflows && group.sapflows.length > 0) {
        group.sapflows.forEach((sapflow: SapFlow) => {
          if (sapflow.id === transactionStatus.transactionID) {
            if (
              sapflow.transactionHistory.filter((transStatus) => transStatus.id === transactionStatus.id).length === 0
            ) {
              sapflow.transactionHistory.push(transactionStatus);
              sapflow.transactionPercentComplete = transactionStatus.currentTransactionPercentComplete;
              sapflow.transactionRuntime = transactionStatus.currentTransactionRuntime;
              sapflow.status = transactionStatus.transactionStatus;
            }
          }
        });
      }
      if (group.groups && group.groups.length > 0) {
        group.groups.forEach((group) => {
          findAndModifyGroupSapflow(group);
        });
      }
    };
    groupsData.forEach((group: SapFlowGroup) => {
      findAndModifyGroupSapflow(group);
    });

    queryClient.setQueryData(['sapGroups'], groupsData);
  } else {
    console.log('Invalid Trans Status update');
  }
};

export default handleMessage;
