import { useQuery } from 'react-query';
import { SapFlowConfig } from '../../../types';
import { useAxiosInstance } from '../../common';

const endpoint = '/sap_config';

const sorter = (configA: SapFlowConfig, configB: SapFlowConfig): number => {
  if (configA.isCustom && !configB.isCustom) {
    return -1;
  }
  if (configB.isCustom && !configA.isCustom) {
    return 1;
  }
  if (configA.name < configB.name) {
    return -1;
  }
  if (configB.name < configA.name) {
    return 1;
  }
  return 0;
};

const useAllConfigs = () => {
  const axios = useAxiosInstance();
  return useQuery(['allSapFlowConfigs'], async () => {
    const result = await axios.get<SapFlowConfig[]>(endpoint);
    const configs = result.data;
    configs.forEach((config: SapFlowConfig) => {
      config.processnodeconfigs.sort((a, b) => a.order - b.order);
    });
    return configs.sort(sorter);
  });
};

export default useAllConfigs;
