export enum TusUploadStatus {
   UPLOADING='uploading',
   //UPLOADED='uploaded',
   PROCESSING='processing',
   PAUSED='paused',
   INTERRUPTED='interrupted',
   CANCELED='canceled',
   COMPLETED='completed',
   ERRORED='errored'
}
  